import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Col, Row, Card, Button } from 'react-bootstrap';
import { sendAlert } from '../../actions/utils';
import { 
  quotationCartProducts,
  removeQuotationFromCart,
  deleteProductFromCart
} from '../../requests/autoparts';
import Checkout from '../Checkout/CheckoutIndex';
import { Trash } from 'react-feather';

const Cart = ({ cart, hashid, setModalTitle, setModalBody }) => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState(cart.products);
  const [quotations, setQuotations] = useState(cart.quotations);
  const [total, setTotal] = useState(cart.total);

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const changeQuantity = (product, quantity, quotation=null) => {
    product.quantity = quantity;
    if (quotation) {
      recalculateQuotationTotal(quotation);
    } else {
      setProducts(products);
      recalculateTotal();
    }
  };

  const removeProductFromOt = (ot, categoryId, productId, quotationId) => {
    const categories = {};
    categories[categoryId] = [{id: productId, quantity: 0}];
    quotationCartProducts(hashid, ot, {
      dispatch,
      params: {categories: categories},
      successCallback: () => {
        quotations[quotationId].products.find(p => p.id === productId)._destroy = true;
        recalculateQuotationTotal(quotations[quotationId]);
      },
      failureCallback: () => {}
    });
  };

  const removeProduct = (product) => {
    deleteProductFromCart(hashid, product.id, {
      dispatch,
      successCallback: () => {
        product._destroy = true;
        setProducts(products);
        recalculateTotal();
      },
      failureCallback: () => {}
    });
  };

  const removeQuotation = (quotationId) => {
    removeQuotationFromCart(quotationId, {
      dispatch,
      successCallback: () => {
        quotations[quotationId]._destroy = true;
        setQuotations(quotations);
        recalculateTotal();
      },
      failureCallback: handleFailureRequest
    });
  };

  const recalculateTotal = () => {
    const quotationsTotal = Object.values(quotations).reduce((acc, curr) => {
      if (!curr || curr._destroy) return acc;
      return acc + curr.total;
    }, 0);
    const productsTotal = products.reduce((acc, curr) => {
      return acc + (curr._destroy ? 0 : curr.quantity * curr.price);
    }, 0);
    setTotal(quotationsTotal + productsTotal);
  };

  const recalculateQuotationTotal = (quotation) => {
    quotation.total = quotation.products.reduce((acc, curr) => {
      return acc + (curr._destroy ? 0 : curr.quantity * curr.price);
    }, 0);
    setQuotations(quotations);
    recalculateTotal();
  };

  const buy = async () => {
    await Promise.all(Object.values(quotations).filter(quotation => quotation && !quotation._destroy).map(quotation => {
      return new Promise((resolve, reject) => {
        const categories = {};
        quotation.products.forEach(p => {
          const categoryId = p.categoryId;
          if (!(categoryId in categories)) categories[categoryId] = [];
          categories[categoryId].push({id: p.id, quantity: parseInt(p.quantity)});
        });
        
        quotationCartProducts(hashid, quotation.ot, {
          dispatch,
          params: { categories },
          successCallback: () => resolve(1),
          failureCallback: () => resolve(0)
        });
      });
    }));
    // update products quantity
    cart = null;
    setModalTitle('Resumen Compra');
    setModalBody(
      <Checkout
        companyHashId={hashid}
      />
    );
  };

  return (
    <>
      <p className='mb-1'>Si compras antes de las <strong>12:00</strong> llega el <strong>mismo día</strong>. Si compras antes de las <strong>17:00</strong> llega a la <strong>mañana siguiente</strong>.</p>
      <p className='mb-1'>(Aplica para días hábiles. Consulta por disponibilidad de despacho los sábados.)</p>
      <section className="h-100" style={{ backgroundColor: "#eee" }}>
        <Container className="py-3 h-100">
          <Row className="justify-content-center align-items-center h-100">
            <Col>
              {Object.entries(quotations).map(([id, quotation]) => {
                if (!quotation || quotation._destroy) return undefined;
                return (
                  <Card className="rounded-3 mb-3 border border-primary">
                    <Card.Header className="h3 border-bottom border-primary">
                      {quotation.title}
                      <Trash
                        size={18}
                        strokeWidth={3}
                        className="float-right text-danger"
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeQuotation(id)}
                      />
                      <p className='float-right mb-0 mr-3'>${quotation.total?.toLocaleString('es-CL')}</p>
                    </Card.Header>
                    <Card.Body className="p-4">
                    {quotation.products?.map(product => {
                      if (!product || product._destroy) return undefined;
                      return <Row className="justify-content-between align-items-center">
                        <Col md="2">
                          <Card.Img className="rounded-3" fluid
                            src={product.image}
                            alt={product.name} />
                        </Col>
                        <Col md="6">
                          <p className="lead fw-normal mb-0">{product.name}{product.position && ` (${product.position})`}</p>
                        </Col>
                        <Col md="1" className="d-flex align-items-center justify-content-around">
                          <input
                            min={0}
                            defaultValue={product.quantity}
                            type="number"
                            style={{ width: '40px' }}
                            onChange={(e) => changeQuantity(product, e.target.value, quotation)}
                          />
                        </Col>
                        <Col md="2">
                          <strong tag="h5" className="mb-0">
                            ${(product.price * product.quantity).toLocaleString('es-CL')}
                          </strong>
                        </Col>
                        {/* <Col md="1" className="px-0">
                          <span style={{ fontSize: 'xx-small' }}>({product.deliveryTime})</span>
                        </Col> */}
                        <Col md="1" className="text-end">
                          <Trash
                            size={16}
                            className="text-danger"
                            style={{ cursor: 'pointer' }}
                            onClick={() => removeProductFromOt(quotation.ot, product.categotyId, product.id, id)}
                          />
                        </Col>
                      </Row>
                    })}
                    </Card.Body>
                  </Card>
                )
              })}
              {products.map(item => {
                if (!item || item._destroy) return undefined;
                return (
                  <Card className="rounded-3 mb-1">
                    <Card.Body className="p-4">
                      <Row className="justify-content-between align-items-center">
                        <Col md="2">
                          <Card.Img className="rounded-3" fluid
                            src={item.image}
                            alt={item.name} />
                        </Col>
                        <Col md="5">
                          <p className="lead fw-normal mb-2">{item.name}</p>
                          <p>{item.category}</p>
                          <p>
                            <span className="text-muted">Marca: </span>{item.brand}
                            <span className="text-muted ml-2">OT: </span>{item.ot}
                          </p>
                        </Col>
                        <Col md="1" className="d-flex align-items-center justify-content-around">
                          <input
                            min={0}
                            defaultValue={item.quantity}
                            type="number"
                            style={{ width: '40px' }}
                            onChange={(e) => changeQuantity(item, e.target.value)}
                          />
                        </Col>
                        <Col md="2">
                          <strong tag="h5" className="mb-0">
                            ${(item.price * item.quantity).toLocaleString('es-CL')}
                          </strong>
                        </Col>
                        <Col md="1" className="text-end">
                          {/* <p>{item.deliveryTime}</p> */}
                          <Trash
                            className="text-danger"
                            size={18}
                            style={{ cursor: 'pointer' }}
                            onClick={() => removeProduct(item)}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                )
              })}

              <Card className="mt-2">
                <Card.Body>
                  <Row>
                    <Col>
                      <p className="h2 text-center mb-0">TOTAL: ${total.toLocaleString('es-CL')} (IVA inc.)</p>
                    </Col>
                    <Col>
                      <Button className="ms-3" color="warning" block size="lg" onClick={buy}>
                        Comprar
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Cart;
