/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import '../../services/yupCustomMethods';
import { CategoryProductCategorySelect, InputRemoteSelect } from '../../components';
import { debounceIndexProductsRequest, indexProductsRequest } from '../../requests/products';

const ManualMovementForm = ({
  errors,
  handleModalClose,
  onHide,
  product,
  setFieldValue,
  submitVariant,
  touched,
  values
}) => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const [products, setProducts] = useState([]);

  const fetchProducts = (inputValue, callback) => {
    const productCategoryId = getIn(values, 'product')?.productCategoryId || '';
    debounceIndexProductsRequest({
      dispatch,
      params: {
        query: inputValue,
        for_selector: true,
        sort_column: 'code',
        sort_direction: 'asc',
        display_length: 20,
        inventoryable: true,
        product_category_id: productCategoryId,
      },
      successCallback: response => callback(response.data.data)
    });
  };

  const fetchInitialProducts = useCallback(
    params => {
      indexProductsRequest({
        dispatch: stableDispatch,
        params: {
          ...params,
          active: true,
          for_selector: true,
          sort_column: 'code',
          sort_direction: 'asc',
          display_length: 100,
          inventoryable: true
        },
        successCallback: response => {
          setProducts(response.data.data);
        }
      });
    },
    [stableDispatch]
  );

  const clearSelectedProduct = () => {
    setFieldValue('product[productId]', '');
    setFieldValue('product[productName]', '');
    setFieldValue('product[sku]', '');
  };

  const handleSubCategoryChange = value => {
    clearSelectedProduct();
    if (value) {
      fetchInitialProducts({ productCategoryId: value });
    }
  };

  const handleParentCategoryChange = () => {
    clearSelectedProduct();
    setProducts([]);
  };

  useEffect(() => {
    if (product.productCategoryId) {
      fetchInitialProducts({ productCategoryId: product.productCategoryId });
    }
  }, [product.productCategoryId, fetchInitialProducts]);

  return (
    <Form>
      <Row>
        <Col md={8}>
          <Row>
            <CategoryProductCategorySelect
              categoryAbbr
              productCategoryAbbr
              modelKey="product"
              currentModel={getIn(values, 'product')}
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              onParentCategoryChange={handleParentCategoryChange}
              onSubCategoryChange={handleSubCategoryChange}
              isModal
            />
          </Row>
        </Col>
        <Col md={4}>
          <Field name="product[productId]">
            {({ field }) => (
              <InputRemoteSelect
                {...field}
                abbr
                defaultOptions={products}
                label="Buscar Ítem"
                values={values.product}
                model={[product, 'product']}
                placeholder="Buscar"
                onChange={data => {
                  setFieldValue(`product[categoryId]`, data.category.id);
                  setFieldValue(`product[categoryName]`, data.category.name);
                  setFieldValue(`product[productCategoryId]`, data.sub_category.id);
                  setFieldValue(`product[productCategoryName]`, data.sub_category.name);
                  setFieldValue(field.name, data ? data.value : '');
                  setFieldValue('product[sku]', data ? data.sku : '');
                }}
                request={fetchProducts}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          <Field className="hidden" name="product[sku]" />
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-3">
        <Col md={2}>
          <Button className="btn mb-3" variant="primary" block onClick={handleModalClose}>
            Cancelar
          </Button>
        </Col>
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            Agregar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ product }) => {
  return { product };
};

const validationSchema = Yup.object().shape({
  product: Yup.object().shape({
    categoryId: Yup.number().required('Debes seleccionar una categoría'),
    productCategoryId: Yup.number().required('Debes seleccionar una subcategoría'),
    productId: Yup.number().required('Debes seleccionar un ítem')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(ManualMovementForm);
