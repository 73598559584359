import React from 'react';
import { Col, Row, Button, Card } from 'react-bootstrap';
import { Trash, Plus, Minus } from 'react-feather';
import { withFormik, Form, Field, getIn, useFormikContext } from 'formik';
import { FormikNumberFormat } from '../../../components';

const SelectedAutopartsModal = ({ autopart, onSubmit }) => {
    const {errors, setFieldValue, touched} = useFormikContext();

    const changeInCartQuantity = (product, qty) => {
        if (qty) {
            if (qty < 1) qty = 1;
            product.inCart = qty;
            setFieldValue(product.id, qty);
        }
    };

    const removeProduct = (product) => {
        product.inCart = 0;
        setFieldValue(product.id, 0);
    }

    return (
        <Form>
            {autopart.selectedProducts.map(product => {
                if (product.inCart && product.inCart > 0) {
                return (
                    <Card className="my-2 w-100">
                        <Card.Body className="p-3">
                        <Row>
                            <Col md={3}>
                            <Card.Img variant="top" src={product.image} alt={product.name} />
                            </Col>
                            <Col className="pl-0">
                            <Trash
                                color="red"
                                style={{float: 'right'}}
                                className="clickable"
                                size={16}
                                onClick={() => {removeProduct(product)}}
                            />
                            <h3 className="fw-bold">{product.name}</h3>
                            <Row>
                                {product.position && 
                                <Col><strong>{product.position}</strong></Col>
                                }
                                <Col>Marca: <br/><strong>{product.brand}</strong></Col>
                                <Col>Código: <br/><strong>{product.code}</strong></Col>
                                <Col md={2}>
                                    <Field name={`${product.id}`}>
                                        {({field}) => (
                                        <div className='d-flex'>
                                            <span 
                                                style={{ color: '#00517B', cursor: 'pointer' }}
                                                className="font-weight-bold mt-2"
                                                onClick={() => changeInCartQuantity(product, product.inCart -= 1)}
                                            >
                                                <Minus className="icon-file-text float-right" strokeWidth="1.5" size={16} />
                                            </span>
                                            <div>
                                                <FormikNumberFormat
                                                    {...field}
                                                    defaultValue={product.inCart}
                                                    onChange={(e) => changeInCartQuantity(product, parseInt(e.target.value))}
                                                    error={getIn(errors, field.name)}
                                                    touched={getIn(touched, field.name)}
                                                />
                                            </div>
                                            <span
                                                style={{ color: '#00517B', cursor: 'pointer' }}
                                                className="font-weight-bold mt-2"
                                                onClick={() => changeInCartQuantity(product, product.inCart += 1)}
                                            >
                                                <Plus className="icon-file-text float-right" strokeWidth="1.5" size={16} />
                                            </span>
                                        </div>
                                        )}
                                    </Field>
                                </Col>
                                <Col>
                                    <strong className='text-primary h3'>${product.price.toLocaleString('es-CL')}</strong>
                                </Col>
                            </Row>
                            </Col>
                        </Row>
                        </Card.Body>
                    </Card>
                )
                } else {
                return undefined;
                }
            })}
            <Button className="px-5 float-right" onClick={onSubmit}>
                Aceptar
            </Button>
        </Form>
    );
};

export default withFormik({
  enableReinitialize: true,
  validateOnChange: false
})(SelectedAutopartsModal);
