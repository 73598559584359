import camelCaseRecursive from 'camelcase-keys-recursive';
import api_v2 from '../services/api_v2';
import useQuery, { useInvalidateQuery } from './useQuery';

const listProjectChecklistsByProject = (projectId, view) =>
  api_v2.get(`/projects/${projectId}/project_checklists.json?view=${view}`).then(res => res.data);

const createProjectChecklistsByProject = (projectCode, params) =>
  api_v2.post(`/projects/${projectCode}/project_checklists.json`, params).then(res => res.data);

const removeSelectedProjectChecklists = id =>
  api_v2.delete(`/project_checklists/${id}/remove_selected.json`).then(res => res.data);

export const useListProjectChecklistsByProject = (projectId, view = 'list') => {
  const { data = [], isLoading } = useQuery(['project_checklists', projectId, view], listProjectChecklistsByProject, {
    enabled: !!projectId
  });
  const invalidateQueries = useInvalidateQuery();
  const reFetch = () => {
    invalidateQueries(['project_checklists', projectId, view]);
  };
  const projectChecklists = camelCaseRecursive(data);
  return { projectChecklists, reFetch, isLoading };
};

export const useCreateCommentsByProjects = projectCode => {
  return async (values, options = {}) => {
    try {
      const data = await createProjectChecklistsByProject(projectCode, values);
      options.onSuccess && options.onSuccess(data);
    } catch (error) {
      options.onError && options.onError(error);
    }
  };
};

export const useRemoveSelectedProjectChecklists = () => {
  return async (id, options = {}) => {
    try {
      const data = await removeSelectedProjectChecklists(id);
      options.onSuccess && options.onSuccess(data);
    } catch (error) {
      options.onError && options.onError(error);
    }
  };
};
