import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { format, startOfMonth } from 'date-fns';
import ComponentDataTable from '../../../components/Utils/DataTable';
import { indexPurchaseReceiptsRequest } from '../../../requests/purchaseReceipts';
import { sendAlert } from '../../../actions/utils';
import downloadFile from '../../../services/utils';

const PurchaseBookDataTable = ({ columns, moreData, setMoreData }) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [purchaseBook, setPurchaseBook] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const [queries, setQueries] = useState({});
  const [metadata, setMetadata] = useState({});

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleSuccessRequest = response => {
    const responsePurchaseBook = response.data.data;
    setMetadata(response.data.meta);
    const { amount: responseAmount } = response.data.meta;
    setPurchaseBook(responsePurchaseBook);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    const sendParams = {
      ...params,
      q: {
        emission_date_from: params.date_from,
        emission_date_to: params.date_to,
        query: params.query
      }
    };
    indexPurchaseReceiptsRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'project_code':
        return { sort_column: 'project_id' };
      case 'invoice_date':
        return { sort_column: 'created_at' };
      case 'parsed_amount':
        return { sort_column: 'amount' };
      case 'client_full_name':
        return { sort_by_client: name };
      default:
        return { sort_column: name };
    }
  };

  const handleSuccessDownload = response => downloadFile(response);

  const downloadIndex = (format, params) => {
    indexPurchaseReceiptsRequest({
      dispatch,
      params: {
        ...params,
        q: {
          emission_date_from: params.date_from,
          emission_date_to: params.date_to,
          query: params.query
        },
        display_length: 100000
      },
      format,
      successCallback: handleSuccessDownload,
      failureCallback: handleFailureRequest
    });
  };

  const currentDate = new Date();

  return (
    <>
      <Row>
        <Col md={2} className="d-flex">
          <div className="font-weight-bold mr-1">Monto Neto:</div>
          <div>{metadata?.sums?.parsed_net}</div>
        </Col>
        <Col md={2} className="d-flex">
          <div className="font-weight-bold mr-1">Monto total:</div>
          <div>{metadata?.sums?.parsed_total}</div>
        </Col>
      </Row>
      <Row className="justify-content-end">
        <Col md={2}>
          <Button variant="primary" block onClick={() => downloadIndex('xlsx', queries)}>
            Exportar
          </Button>
        </Col>
      </Row>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns()}
        handleSortCase={sortColumnCase}
        data={purchaseBook}
        totalRows={amount}
        moreData={moreData}
        startDate={format(startOfMonth(currentDate), 'yyyy-MM-dd')}
        endDate={format(currentDate, 'yyyy-MM-dd')}
        withDate
        resourceRequest={response => {
          setQueries({
            ...response
          });
          if (!onRequest) handleIndexRequest(response);
        }}
      />
    </>
  );
};

export default PurchaseBookDataTable;
