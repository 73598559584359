import React from 'react';
import { Form, Formik } from 'formik';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { discountTypes } from './FormOptions';
import { useGetProject } from '../../../hooks/useProjects';
import useCountryFactory from '../../../hooks/useCountryFactory';
import NumberInputField from '../../../components/Utils/Input/NumberInputField';
import SelectInputField from '../../../components/Utils/Input/SelectInputField';

const ProjectDiscountForm = ({ projectId, onSave }) => {
  const { project, isLoading } = useGetProject(projectId, 'edit');
  const countryFactory = useCountryFactory();

  const initialValues = {
    project: {
      discountTypeCode: project.discountTypeCode,
      priceDiscount: project.priceDiscount,
      percentageDiscount: project.percentageDiscount
    }
  };

  const handleChangeOfDiscountType = (setFieldValue) => {
    setFieldValue('project[priceDiscount]', '');
    setFieldValue('project[percentageDiscount]', '');
  };

  if (isLoading) return null;
  return (
    <Formik initialValues={initialValues} onSubmit={onSave}>
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <Row>
            <Col md={12} className="mb-2">
              <Alert variant="secondary">
                <p className="font-weight-bold mb-0">
                  Si agrega un descuento general, no podrá emitir comprobante detallado.
                </p>
              </Alert>
            </Col>
            <Col md={12}>
              <SelectInputField
                name="project[discountTypeCode]"
                label="Tipo de descuento"
                placeholder="Seleccionar"
                options={discountTypes}
                isClearable
                onChange={() => handleChangeOfDiscountType(setFieldValue)}
              />
            </Col>
            {values.project.discountTypeCode === 'price' && (
              <Col md={12}>
                <NumberInputField
                  name="project[priceDiscount]"
                  leftAddon={countryFactory.getSymbol()}
                  label="Descuento [en pesos]"
                />
              </Col>
            )}
            {values.project.discountTypeCode === 'percentage' && (
              <Col md={12}>
                <NumberInputField
                  name="project[percentageDiscount]"
                  leftAddon="%"
                  label="Descuento [porcentaje]"
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col md={12}>
              <Button block disabled={isSubmitting} type="submit" variant="success">
                {isSubmitting ? 'Guardando...' : 'Guardar'}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
export default ProjectDiscountForm;