import React, { useState } from 'react';
import { Col, Dropdown, Button, Row, Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import CollectionPaymentButton from '../../ProjectPayment/CollectionPaymentButton';
import PaymentLinkButton from '../../ProjectPayment/PaymentLinkButton';
import ProjectProgressEmailButton from './ProjectProgressEmailButton';
import { SimpleCenteredModal } from '../../../components';
import ProjectQuoteTable from './ProjectQuoteTable';
import { getChecklistPdfRequest, getPdfRequest } from '../../../requests/projects';
import downloadFile from '../../../services/utils';
import { sendAlert } from '../../../actions/utils';
import ProjectDetailAdvancedConfigurationModal from '../../ProjectDetail/ProjectAdvancedConfiguration';

const ProductShow = ({ project, token, version }) => {
  const {
    id,
    totalCostFormatted,
    totalDiscountFormatted,
    netPriceFormatted,
    totalMarginFormatted,
    totalPriceFormatted,
    totalPriceWithTaxFormatted,
    totalTaxFormatted,
    totalCost,
    totalPrice,
    parsedMarginAmount,
    checklists,
    company
  } = project;
  const { paymentServiceEnabled } = company;
  const dispatch = useDispatch();
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const isDiscounted = () => {
    if (totalDiscountFormatted) {
      const discount = parseInt(totalDiscountFormatted.substring(1), 10);
      if (discount) return true;
    }
    return false;
  };

  const downloadPdf = pdfType => {
    getPdfRequest({
      id,
      type: pdfType,
      dispatch,
      format: 'pdf',
      successCallback: response => downloadFile(response),
      failureCallback: error => dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }))
    });
  };

  const downloadChecklistPdf = type => {
    getChecklistPdfRequest({
      id,
      type,
      dispatch,
      format: 'pdf',
      successCallback: response => downloadFile(response)
    });
  };

  const handleAdvancedConfiguration = vProjectDetail => {
    setModalBody(
      <ProjectDetailAdvancedConfigurationModal
        onClose={() => setModalShow(false)}
        projectDetailId={vProjectDetail.id}
      />
    );
    setModalShow(true);
  };

  return (
    <Row>
      <Col md={paymentServiceEnabled ? 4 : 6} className="mb-3">
        <strong>Ítems</strong>
      </Col>
      <Col md={2} className="mb-3">
        <ProjectProgressEmailButton variant="warning" project={project} />
      </Col>
      {paymentServiceEnabled && (
        <Col md={2} className="mb-3">
          <PaymentLinkButton project={project} />
        </Col>
      )}
      <Col md={2} className="mb-3">
        <CollectionPaymentButton project={project} />
      </Col>
      <Col md={2} className="mb-3">
        <Dropdown>
          <Dropdown.Toggle as={Button} id="dropdown-basic-button" className="btn-block">
            Descargar PDFs
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {checklists?.map(checklist => (
              <Dropdown.Item key={checklist.name} onClick={() => downloadChecklistPdf(checklist.checklistType)}>
                PDF {checklist.name}
              </Dropdown.Item>
            ))}
            <Dropdown.Item onClick={() => downloadPdf('quote')}>PDF Presupuesto</Dropdown.Item>
            <Dropdown.Item onClick={() => downloadPdf('onepager')}>PDF resumen/caja</Dropdown.Item>
            <Dropdown.Item onClick={() => downloadPdf('quality_control')}>PDF de Calidad</Dropdown.Item>
            <Dropdown.Item onClick={() => downloadPdf('work_checklist')}>PDF Checklist de trabajos</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col md={12}>
        <ProjectQuoteTable
          project={project}
          handleAdvancedConfiguration={handleAdvancedConfiguration}
          token={token}
          version={version}
        />
      </Col>
      <Col md={{ span: 6, offset: 6 }}>
        <Table responsive borderless hover>
          <thead className="border-top-borderless-table">
            <tr className="header-border-bottom">
              <th>Resumen Financiero de Proyecto</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr className="font-weight-bold">
              <td>Costo Total Neto Proyecto</td>
              <td>{totalCostFormatted}</td>
            </tr>
            <tr className="font-weight-bold">
              <td>Valor Total Neto Proyecto</td>
              <td>{totalPriceFormatted}</td>
            </tr>
            {isDiscounted() && (
              <>
                <tr className="font-weight-bold">
                  <td>Descuento</td>
                  <td className="text-secondary">{totalDiscountFormatted}</td>
                </tr>
                <tr className="font-weight-bold">
                  <td>Valor Total Neto Real</td>
                  <td>{netPriceFormatted}</td>
                </tr>
              </>
            )}
            <tr className="font-weight-bold">
              <td>Valor Total Proyecto (inc. IVA)</td>
              <td>
                {totalPriceWithTaxFormatted}{' '}
                <span className="font-weight-normal font-italic">{`(IVA: ${totalTaxFormatted})`}</span>
              </td>
            </tr>
            <tr className="font-weight-bold">
              <td>Margen Proyecto</td>
              <td className={totalPrice - totalCost > 0 ? 'text-success' : 'text-danger'}>
                {totalCostFormatted ? `${totalMarginFormatted} (${parsedMarginAmount})` : 'No aplica'}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
      <SimpleCenteredModal
        size="md"
        title="Configuración Avanzada"
        body={modalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </Row>
  );
};

export default ProductShow;
