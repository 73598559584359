import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { HelpCircle } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import PurchaseBookDataTable from './PurchaseBookDataTable';
import columns from './Columns';

const PurchaseBookIndex = () => {
  const [moreData, setMoreData] = useState(false);

  return (
    <>
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <Row className="mt-4">
            <Col md={10}>
              <h2 className="text-uppercase mb-4">
                LIBROS DE COMPRA
                <HelpCircle
                  role="button"
                  width="17px"
                  height="17px"
                  className="ml-1 mb-1"
                  data-tip
                  data-for="tooltip"
                />
                <ReactTooltip id="tooltip" place="top">
                  Suma en base a los <br /> registros filtrados
                </ReactTooltip>
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <PurchaseBookDataTable columns={columns} moreData={moreData} setMoreData={setMoreData} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default PurchaseBookIndex;