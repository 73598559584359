import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { sendAlert } from '../../../actions/utils';
import ProjectWorkInput from '../../ProjectWork/ProjectWorkInput';
import { InputRemoteSelect } from '../../../components/Utils/Select';
import { debounceIndexWorksRequest } from '../../../requests/works';
import { copyObject } from '../../../services/utils';
import { uuid } from '../../../utils/utils';

const newProjectWork = {
  done: false,
  name: '',
  projectDetailsAttributes: [
    {
      categoryId: '',
      hidden: false,
      price: '',
      productCategoryId: '',
      productId: '',
      productNameCustom: '',
      quantity: '',
      unitPrice: ''
    }
  ],
  userId: '',
  workHours: 0,
  workId: ''
};

const ProjectWorkTab = ({ projectWorksAttributes, modelKey, indexProjectReview, onHide, fromProjectReview }) => {
  const { setFieldValue } = useFormikContext();
  const [workSelector, setWorkSelector] = useState('');
  const [copyWorkMsg, setCopyWorkMsg] = useState('');
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const resultFetchData = response => {
    const result = response.data.data;
    return result.map(element => ({
      ...element,
      label: element.name,
      value: element.id
    }));
  };

  const fetchWorks = (inputValue, callback) => {
    debounceIndexWorksRequest({
      dispatch,
      params: {
        query: inputValue,
        sort_column: 'name',
        display_length: 50
      },
      successCallback: response => callback(resultFetchData(response)),
      failureCallback: handleFailureRequest
    });
  };

  const copyWorkDetails = workDetails => {
    return workDetails.map(workDetail => {
      const keysToCopy = [
        'categoryId',
        'productCategoryId',
        'productId',
        'product',
        'quantity',
        'unitPrice',
        'productNameCustom',
        'categoryName',
        'productCategoryName',
        'productName',
        'productCategoryMargin',
        'productCategoryInverse',
        'parsedProductCategoryMargin'
      ];

      const objectCreated = copyObject(workDetail, keysToCopy);

      if (workDetail.copyPrice) {
        objectCreated.unitPrice = workDetail.product?.salePrice;
      }

      objectCreated.key = uuid();
      return objectCreated;
    });
  };

  const copyWork = useCallback(work => {
    const copiedWorkDetails = copyWorkDetails(work.workDetailsAttributes);

    const newWork = {
      done: false,
      name: work.name,
      workId: work.id,
      key: uuid(),
      projectDetailsAttributes: copiedWorkDetails
    };

    setCopyWorkMsg(`Trabajo "${work.name}" copiado con éxito`);

    return newWork;
  }, []);

  const handleAddWorkClick = useCallback(({ work = null }) => {
    const newWork = work ? copyWork(work) : newProjectWork;
    setFieldValue(modelKey, [...projectWorksAttributes, newWork]);
    if (!work) setCopyWorkMsg('');
  }, [projectWorksAttributes, setFieldValue, modelKey, copyWork]);

  const projectWorkInputs = useMemo(() => {
    return projectWorksAttributes.map((projectWork, index) => {
      if (projectWork._destroy) return null;
      return (
        <ProjectWorkInput
          key={`project-work-input-${indexProjectReview}-${index}`}
          projectWorksAttributes={projectWorksAttributes}
          projectWork={projectWork}
          index={index}
          modelKey={modelKey}
          firstMapIndex={indexProjectReview}
          fromProjectReview={fromProjectReview}
          work
        />
      );
    });
  }, [projectWorksAttributes, indexProjectReview, modelKey, fromProjectReview]);

  return (
    <Row>
      {fromProjectReview && (
        <Col md={{ span: 3, offset: 9 }} className="modal-new-form-btn text-right mt-4">
          <Button className="btn" variant="link" onClick={handleAddWorkClick}>
            Crear Trabajo
          </Button>
        </Col>
      )}
      <Col md={12} className="mb-1">
        <Field>
          {({ field }) => (
            <InputRemoteSelect
              {...field}
              isClearable
              label="Trabajo"
              placeholder="Seleccionar Trabajo"
              value={workSelector}
              onChange={data => {
                if (data) handleAddWorkClick({ work: camelCaseRecursive(data) });
                setWorkSelector('');
              }}
              request={fetchWorks}
            />
          )}
        </Field>
        {copyWorkMsg && (
          <Alert
            variant="success"
            className="text-success text-center font-weight-bold"
            dismissible
            onClose={() => setCopyWorkMsg('')}
          >
            {copyWorkMsg}
          </Alert>
        )}
      </Col>
      {fromProjectReview && (
        <Col md={12} style={{ padding: '0 30px' }}>
          {projectWorkInputs}
        </Col>
      )}
      <Col md={12}>
        <Button variant="success" onClick={onHide} block className="mb-3">
          Listo
        </Button>
      </Col>
    </Row>
  );
};

export default ProjectWorkTab;
