/* eslint-disable no-nested-ternary */
import React from 'react';
import {Button, Col, Row, Card} from 'react-bootstrap';
import { Plus, Minus } from 'react-feather';
import { withFormik, Form, Field } from 'formik';
import { FormikNumberFormat } from '../../../components';

const ProjectAutopartsProductCard = ({
  product,
  values,
  setFieldValue,
  errors,
  touched
}) => {
  return (
    <Form>
      <Card className="my-2">
        <Card.Body className="p-3">
          <Row>
            <Col md={4}>
              <Card.Img variant="top" src={product.image} alt={product.name} />
            </Col>
            <Col md={8} className="pl-0">
              <h3 className="fw-bold">{product.name}</h3>
              <div className='mb-3'>
                <Row>
                  {product.position && 
                    <Col><strong>{product.position}</strong></Col>
                  }
                  <Col>Marca: <br/><strong>{product.brand}</strong></Col>
                  <Col>Código: <br/><strong>{product.code}</strong></Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col md={4}>
                    <strong className='text-primary h3'>${product.price.toLocaleString('es-CL')}</strong>
                  </Col>
                  <Col md={4}>
                    <div className='d-flex'>
                      <Field name='qtyInput'>
                          {({field}) => (
                          <div className='d-flex'>
                              <span 
                                  style={{ color: '#00517B', cursor: 'pointer' }}
                                  className="font-weight-bold mt-2"
                                  onClick={() => {if (values.qtyInput > 1) setFieldValue('qtyInput', parseInt(values.qtyInput) - 1)}}
                              >
                                  <Minus className="icon-file-text float-right" strokeWidth="1.5" size={16} />
                              </span>
                              <div>
                                  <FormikNumberFormat
                                      {...field}
                                  />
                              </div>
                              <span
                                  style={{ color: '#00517B', cursor: 'pointer' }}
                                  className="font-weight-bold mt-2"
                                  onClick={() => setFieldValue('qtyInput', parseInt(values.qtyInput) + 1)}
                              >
                                  <Plus className="icon-file-text float-right" strokeWidth="1.5" size={16} />
                              </span>
                          </div>
                          )}
                      </Field>
                    </div>
                  </Col>
                  <Col md={4}>
                    <Button
                      variant='primary'
                      className='float-right'
                      type='submit'
                    >
                      Agregar
                    </Button>
                  </Col>
                </Row>
                {errors.qtyInput && touched.qtyInput && (
                  <div className="text-danger">{errors.qtyInput}</div>
                )}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Form>
  );
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: () => {return {qtyInput: 1}},
  handleSubmit,
  validate: (values) => {
    const errors = {};
    if (values.qtyInput < 1) {
      errors.qtyInput = 'La cantidad no puede ser menor a 1';
    }
    return errors;
  }
})(ProjectAutopartsProductCard);
