import { Table } from 'react-bootstrap';
import React, { Fragment, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ShoppingCart } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import snakeCaseKeys from 'snakecase-keys';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import PurchaseReceivedForm from '../../ProjectDetail/PurchaseReceivedForm';
import { updateProjectDetailRequest } from '../../../requests/projectDetails';
import { publicUpdateProjectWorksApprovalStatusRequest } from '../../../requests/projects';
import { SimpleCenteredModal } from '../../../components';
import { sendAlert } from '../../../actions/utils';

const ProjectQuoteTableVersion = ({ project, handleAdvancedConfiguration, setMoreData, token, version }) => {
  const { projectWorksAttributes } = project;
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const approvementRef = useRef(null);

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSuccessUpdateRequest = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Detalle actualizado con éxito' }));
    setMoreData(m => !m);
    handleModalClose();
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const basicPurchaseReceived = {
    purchaseReceivedDate: new Date(),
    imagesInfo: [],
    projectContactsAttributes: []
  };
  const handleUpdateRequest = params => {
    updateProjectDetailRequest(params.id, {
      dispatch,
      params: { project_detail: snakeCaseKeys(params, { exclude: ['_destroy'] }) },
      formData: true,
      successCallback: handleSuccessUpdateRequest,
      failureCallback: handleFailureRequest
    });
  };

  const projectDetailBody = (vProjectDetail, indexWork, indexDetail) => {
    const {
      categoryName,
      parsedCost,
      parsedTotalCost,
      parsedMargin,
      parsedUnitPrice,
      parsedPrice,
      productCategoryName,
      productId,
      productNameShow,
      quantity,
      parsedPriceDiscount,
      unitPrice
    } = vProjectDetail;
    const inputName = `project[projectWorksAttributes][${indexWork}][projectDetailsAttributes][${indexDetail}]`;
    const nameToShow = productNameShow;

    const openModal = () => {
      const id = vProjectDetail?.id;
      const imagesInfo = vProjectDetail?.purchaseReceivedImagesInfo || [];
      const date = vProjectDetail?.purchaseReceivedDate;
      const projectContactsAttributes = vProjectDetail?.projectContactsAttributes || [];
      let purchaseReceivedDate = new Date();
      if (date) {
        purchaseReceivedDate = new Date(date);
      }
      setModalBody(
        <PurchaseReceivedForm
          formRequest={handleUpdateRequest}
          handleModalClose={handleModalClose}
          submitVariant="success"
          disabled={date}
          purchaseReceived={{
            ...basicPurchaseReceived,
            id,
            imagesInfo,
            purchaseReceivedDate,
            projectContactsAttributes
          }}
          projectId={project?.id}
        />
      );
      setModalShow(true);
    };
    return (
      <>
        <td>
          {vProjectDetail?.hasPurchaseReceived && (
            <>
              <ShoppingCart
                data-tip
                data-for={`purchase-received-${vProjectDetail?.id}`}
                className="mr-1"
                width="15px"
                height="15px"
                color={vProjectDetail?.purchaseReceivedDate ? '#5CC689' : 'black'}
                onClick={openModal}
              />
              <ReactTooltip id={`purchase-received-${vProjectDetail?.id}`} place="top">
                {vProjectDetail?.purchaseReceivedDate ? 'Compra recibida' : 'Compra no recibida'}
              </ReactTooltip>
            </>
          )}
          {categoryName}
        </td>
        {!project?.hideSubcategory && <td>{productCategoryName}</td>}
        <td className={nameToShow ? '' : 'font-italic text-muted'}>
          <Link className="text-uppercase" to={`/products/${productId}`}>
            {nameToShow || 'Ítem sin Nombre'}
          </Link>
        </td>
        <td>
          <div
            className="text-primary"
            role="button"
            onClick={() => handleAdvancedConfiguration(vProjectDetail, inputName, productId)}
          >
            {parsedCost}
          </div>
        </td>
        <td>{quantity || 0}</td>
        <td>{parsedTotalCost}</td>
        <td>{parsedUnitPrice}</td>
        <td className="text-warning">{parsedPriceDiscount}</td>
        <td>{parsedPrice}</td>
        <td>{unitPrice > 0 ? parsedMargin : 'No aplica'}</td>
      </>
    );
  };

  const projectWorksBody = () =>
    projectWorksAttributes &&
    projectWorksAttributes.map((projectWork, indexWork) => {
      const projectDetails = projectWork.projectDetailsAttributes.filter(projectDetail => !projectDetail.hidden);
      return (
        <Fragment key={`project-work-${indexWork.toString()}`}>
          {projectDetails.map((projectDetail, indexDetail) =>
            indexDetail === 0 ? (
              <>
                <tr>
                  <td className="font-weight-bold" style={{ color: '#5e99b8' }}>
                    {projectWork.name}
                  </td>
                </tr>
                <tr key={`project-detail-${indexDetail.toString()}`}>
                  {projectDetailBody(projectDetail, indexWork, indexDetail)}
                </tr>
              </>
            ) : (
              <tr key={`project-detail-${indexDetail.toString()}`}>
                {projectDetailBody(projectDetail, indexWork, indexDetail)}
              </tr>
            )
          )}
        </Fragment>
      );
    });

  const handleSubmit = values => {
    if (values.projectWorksApprovals.filter(v => v.value === 'pending').length) {
      dispatch(sendAlert({ kind: 'error', message: 'Se deben seleccionar todos.' }));
    } else {
      const updatedValues = values.projectWorksApprovals.filter(v => !v.disabled);
      updatedValues.forEach(uv => {
        if (uv.value === 'doubt' && (!uv.notes || uv.notes === '') && values.all.notes && values.all.notes !== '') {
          uv.notes = values.all.notes;
        }
      });
      publicUpdateProjectWorksApprovalStatusRequest(token, version, {
        dispatch,
        params: { project: { project_works_approval_status: updatedValues } },
        successCallback: response => {
          dispatch(sendAlert({ kind: 'success', message: 'Enviado con éxito' }));
          history.push(response.data.path);
        },
        failureCallback: handleFailureRequest
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={{}}
        onSubmit={values => {
          handleSubmit(values);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form ref={approvementRef}>
            <Table responsive borderless hover>
              <thead className="border-top-borderless-table">
                <tr className="header-border-bottom">
                  <th>Categoría</th>
                  {!project?.hideSubcategory && <th>Subcategoría</th>}
                  <th>Ítem</th>
                  <th>Costo Neto Unit</th>
                  <th>Cantidad</th>
                  <th>Costo Neto Total</th>
                  <th>P. Venta Unit</th>
                  <th>Descuento</th>
                  <th>P. Venta Total</th>
                  <th>Margen</th>
                </tr>
              </thead>
              <tbody>{projectWorksBody(values, setFieldValue)}</tbody>
            </Table>
          </Form>
        )}
      </Formik>
      <SimpleCenteredModal
        size="lg"
        title="Registro de compra recibida"
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
        closeButton
      />
    </>
  );
};
export default ProjectQuoteTableVersion;
