import camelCaseRecursive from 'camelcase-keys-recursive';
import api_v2 from '../services/api_v2';
import useQuery, { useInvalidateQuery } from './useQuery';

const getProjectDetail = (projectDetailId, view) => {
  return api_v2.get(`/project_details/${projectDetailId}.json?view=${view}`).then(res => res.data);
};

const updateProjectDetail = (projectDetailId, params) => {
  return api_v2.put(`/project_details/${projectDetailId}.json`, params).then(res => res.data)};

export const useGetProjectDetail = (projectDetailId, view) => {
  const { data = {}, isLoading } = useQuery(['project_detail', projectDetailId?.toString(), view], getProjectDetail, {
    enabled: !!projectDetailId
  });
  const invalidateQueries = useInvalidateQuery();
  const projectDetail = camelCaseRecursive(data);
  return { projectDetail, reFetch: invalidateQueries, isLoading };
};

export const useUpdateProjectDetail = projectDetailId => {
  const update = async (values, options = {}) => {
    try {
      const data = await updateProjectDetail(projectDetailId, values);
      options.onSuccess && options.onSuccess(data);
    } catch (error) {
      options.onError && options.onError(error);
    }
  };
  return { update };
};
