import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, getIn } from 'formik';
import { X } from 'react-feather';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { debounceIndexProductsRequest, scanProductRequest } from '../../requests/products';
import { FormikInput } from '../Utils/Input';
import { InputRemoteSelect } from '../Utils/Select';
import { uuid } from '../../utils/utils';
import { sendAlert } from '../../actions/utils';
import { camelCaseEmptyStringRecursive } from '../../services/utils';

const ProductCustomSelect = ({
  clearItemHidden,
  disabled,
  errors,
  modelDetail,
  modelKey,
  products,
  setCurrentDetailQuantity,
  setCurrentDetailUnitPrice,
  setFieldValue,
  setFirstProductsOption,
  showCost,
  touched,
  values,
  fromProjectDetailInput,
  isModal
}) => {
  const [customNameText, setCustomNameText] = useState('');
  const dispatch = useDispatch();

  const productIdContainerId = `${modelKey}[productId]Div`;
  const productNameCustomContainerId = `${modelKey}[productNameCustom]Div`;
  const productIdDivElement = document.getElementById(productIdContainerId);
  const productNameCustomDivElement = document.getElementById(productNameCustomContainerId);
  const vehicleBrandId = values?.project?.vehicle?.vehicleBrandId;
  const vehicleModelId = values?.project?.vehicle?.vehicleModelId;

  const mapProduct = product => {
    let { label } = product;
    if (product.inventoryable) {
      label = `${product.label} (Stock ${product.productCurrentStock} ${product.translatedUnit})`;
    }
    return {
      ...product,
      label,
      isProjectDetail: fromProjectDetailInput
    };
  };

  const resultFetchData = response => {
    const result = camelCaseRecursive(response.data.data);
    return fromProjectDetailInput ? result.map(mapProduct) : result;
  };

  const productsOptions = fromProjectDetailInput ? products.map(mapProduct) : products;

  const fetchProducts = (inputValue, callback) => {
    setCustomNameText(inputValue);
    const productCategoryId = getIn(values, modelKey)?.productCategoryId || '';
    debounceIndexProductsRequest({
      dispatch,
      params: {
        active: true,
        query: inputValue,
        for_selector: true,
        sort_column: 'code',
        sort_direction: 'asc',
        display_length: 50,
        product_category_id: productCategoryId,
        vehicle_brand_id: productCategoryId ? '' : vehicleBrandId,
        vehicle_model_id: productCategoryId ? '' : vehicleModelId
      },
      successCallback: response => callback(setFirstProductsOption(resultFetchData(response)))
    });
  };

  const handleProductChange = (data, field) => {
    if (data) {
      if (!data.custom) {
        setFieldValue(`${modelKey}[categoryId]`, data.category.id);
        setFieldValue(`${modelKey}[categoryName]`, data.category.name);
        setFieldValue(`${modelKey}[productCategoryId]`, data.subCategory.id);
        setFieldValue(`${modelKey}[productCategoryName]`, data.subCategory.name);
      }
      const prevQuantity = getIn(values, `${modelKey}[quantity]`);
      if (data.defaultQty && data.defaultQty > 0) {
        setCurrentDetailQuantity(data.defaultQty);
        setFieldValue(`${modelKey}[quantity]`, data.defaultQty);
      } else if (prevQuantity) {
        setCurrentDetailQuantity(prevQuantity);
        setFieldValue(`${modelKey}[quantity]`, prevQuantity);
      } else {
        setCurrentDetailQuantity(1);
        setFieldValue(`${modelKey}[quantity]`, 1);
      }
      const associatedProducts = [];
      if (data.paintProduct) associatedProducts.push({ product: data.paintProduct, categoryName: 'PINTURA' });
      if (data.autopartProduct)
        associatedProducts.push({ product: data.autopartProduct, categoryName: 'REPUESTOS SEGURO' });
      associatedProducts.forEach(ap => {
        const projectWorksAttributes = getIn(values, `project[projectWorksAttributes]`);
        const categoryWorkIndex = projectWorksAttributes.findIndex(pw => pw.name === ap.categoryName && !pw._destroy);
        if (categoryWorkIndex >= 0) {
          const projectDetailsAttributes = projectWorksAttributes[categoryWorkIndex].projectDetailsAttributes;
          const categoryDetails = projectDetailsAttributes.filter(
            pd => pd.categoryName === ap.categoryName && pd.productCategoryName === data.subCategory.name
          );
          if (categoryDetails.findIndex(p => p.product && p.product.value === ap.product.value) === -1) {
            const emptyDetailIndex = projectDetailsAttributes.findIndex(
              pd =>
                pd.categoryName === ap.categoryName &&
                pd.productCategoryName === data.subCategory.name &&
                pd.productId === ''
            );
            if (emptyDetailIndex >= 0) {
              const arrayValuesLength = projectDetailsAttributes.length;
              const deleteValue = projectDetailsAttributes[emptyDetailIndex];
              const newValues = [
                ...projectDetailsAttributes.slice(0, emptyDetailIndex),
                ...projectDetailsAttributes.slice(emptyDetailIndex + 1, arrayValuesLength)
              ];
              if (deleteValue && deleteValue.id) {
                deleteValue._destroy = 'true';
                newValues.push(deleteValue);
              }
              projectWorksAttributes[categoryWorkIndex].projectDetailsAttributes = newValues;
            }
            projectWorksAttributes[categoryWorkIndex].projectDetailsAttributes.push({
              cost: '',
              categoryId: ap.product.category.id,
              categoryName: ap.product.category.name,
              freeQuotedCost: '',
              hidden: false,
              price: ap.product.defaultQty * ap.product.salePrice,
              productCategoryId: ap.product.subCategory.id,
              productCategoryName: ap.product.subCategory.name,
              product: ap.product,
              productId: ap.product.value,
              productNameCustom: '',
              quantity: ap.product.defaultQty ? ap.product.defaultQty : 1,
              unitPrice: ap.product.salePrice
            });
            setFieldValue(
              `project[projectWorksAttributes][${categoryWorkIndex}][projectDetailsAttributes]`,
              projectWorksAttributes[categoryWorkIndex].projectDetailsAttributes
            );
          }
        } else {
          projectWorksAttributes.push({
            done: false,
            name: ap.categoryName,
            projectDetailsAttributes: [
              {
                cost: '',
                categoryId: ap.product.category.id,
                categoryName: ap.product.category.name,
                freeQuotedCost: '',
                hidden: false,
                price: ap.product.defaultQty * ap.product.salePrice,
                productCategoryId: ap.product.subCategory.id,
                productCategoryName: ap.product.subCategory.name,
                productId: ap.product.value,
                productNameCustom: '',
                quantity: ap.product.defaultQty ? ap.product.defaultQty : 1,
                unitPrice: ap.product.salePrice,
                key: uuid(),
                product: ap.product
              }
            ],
            userId: '',
            workHours: 0,
            workMinutes: 0,
            key: uuid()
          });
          setFieldValue(
            `project[projectWorksAttributes][${projectWorksAttributes.length - 1}]`,
            projectWorksAttributes[projectWorksAttributes.length - 1]
          );
        }
      });
    } else {
      setCurrentDetailQuantity(0);
      setFieldValue(`${modelKey}[quantity]`, 0);
    }
    if (data?.value === 0) {
      setFieldValue(field.name, '');
      setFieldValue(`${modelKey}[product]`, '');
      setFieldValue(`${modelKey}[unitPrice]`, '');
      setCurrentDetailUnitPrice('');
      setFieldValue(`${modelKey}[productNameCustom]`, customNameText);
      productIdDivElement.classList.add('hidden');
      productNameCustomDivElement.classList.remove('hidden');
    } else {
      const checkHidden = getIn(values, `${modelKey}[hidden]`);
      const currentUnitPrice = checkHidden
        ? 0
        : showCost
        ? parseFloat(data?.cost || 0)
        : parseFloat(data?.salePrice || 0);
      setFieldValue(field.name, data ? data.value : '');
      setFieldValue(`${modelKey}[product]`, data || '');
      setFieldValue(`${modelKey}[unitPrice]`, currentUnitPrice);
      setFieldValue(`${modelKey}[parsedUnitPrice]`, currentUnitPrice);
      setFieldValue(`${modelKey}[profitMargin]`, currentUnitPrice - parseFloat(data?.cost || 0));
      setFieldValue(`${modelKey}[parsedProfitMargin]`, currentUnitPrice - parseFloat(data?.cost || 0));
      setCurrentDetailUnitPrice(currentUnitPrice);
    }
  };

  const handleHideInput = () => {
    setFieldValue(`${modelKey}[productNameCustom]`, '');
    productIdDivElement.classList.remove('hidden');
    productNameCustomDivElement.classList.add('hidden');
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessScan = (response, field) => {
    if (response.data.value) {
      handleProductChange(camelCaseEmptyStringRecursive(response.data), field);
    }
  };

  const scanProduct = (sku, field) => {
    scanProductRequest({
      dispatch,
      params: {
        sku,
        for_selector: true
      },
      successCallback: response => handleSuccessScan(response, field),
      failureCallback: handleFailureRequest
    });
  };

  const handleKeyDownProduct = (e, field) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      scanProduct(e.target.value, field);
    }
  };

  return (
    <>
      <div id={productIdContainerId} className={modelDetail.productNameCustom ? 'hidden' : ''}>
        <Field name={`${modelKey}[productId]`}>
          {({ field }) => (
            <InputRemoteSelect
              {...field}
              isClearable
              disabled={disabled}
              fromProjectDetailInput
              defaultOptions={productsOptions}
              label="Ítem"
              values={modelDetail}
              model={[modelDetail || {}, 'product']}
              placeholder="Seleccionar..."
              onChange={data => handleProductChange(data, field)}
              request={fetchProducts}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              menuPortalTarget={!isModal ? document.body : null}
              onKeyDown={e => handleKeyDownProduct(e, field)}
            />
          )}
        </Field>
      </div>
      <div id={productNameCustomContainerId} className={modelDetail.productNameCustom ? '' : 'hidden'}>
        {!clearItemHidden && (
          <X width="15px" height="15px" onClick={handleHideInput} className="float-right clickable" />
        )}
        <Field name={`${modelKey}[productNameCustom]`}>
          {({ field }) => (
            <FormikInput
              {...field}
              label="Ítem"
              disabled={disabled}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </div>
    </>
  );
};

export default ProductCustomSelect;
