import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Col } from 'react-bootstrap';
import { Field, getIn } from 'formik';
import ReactTooltip from "react-tooltip";
import { debounceIndexProductCategoriesRequest, indexProductCategoriesRequest } from '../../requests/productCategories';
import { InputRemoteSelect } from '../Utils/Select';

const CategoryProductCategorySelect = ({
  categoryAbbr,
  currentModel,
  disabled,
  fromProjectDetailInput,
  modelKey,
  errors,
  onParentCategoryChange,
  onSubCategoryChange,
  productCategoryAbbr,
  subcategoryMargin,
  setFieldValue,
  isModal,
  touched,
  values
}) => {
  const [categories, setCategories] = useState([]);
  const [productCategoryDisabled, setProductCategoryDisabled] = useState(!currentModel.productCategoryId);
  const [productCategories, setProductCategories] = useState([]);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const currentValue = getIn(values, modelKey);
  const currentCategoryId = currentValue ? currentValue.categoryId : '';

  const resultFetchData = response => {
    return response.data.data;
  };

  const fetchInitialProductCategories = useCallback(
    params => {
      indexProductCategoriesRequest({
        dispatch: stableDispatch,
        params: {
          ...params,
          filter_parents: false,
          for_selector: true,
          sort_column: 'name',
          sort_direction: 'asc',
          display_length: 100
        },
        successCallback: data => setProductCategories(resultFetchData(data))
      });
      setProductCategoryDisabled(false);
    },
    [stableDispatch]
  );

  useEffect(() => {
    indexProductCategoriesRequest({
      dispatch,
      params: {
        filter_parents: true,
        for_selector: true,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 100
      },
      successCallback: data => setCategories(resultFetchData(data))
    });
    if (currentCategoryId) {
      fetchInitialProductCategories({ categoryId: currentCategoryId });
    }
  }, [dispatch, currentCategoryId, fetchInitialProductCategories]);

  const handleParentCategoryChange = value => onParentCategoryChange && onParentCategoryChange(value);
  const handleSubCategoryChange = value => onSubCategoryChange && onSubCategoryChange(value);

  const handleCategoryChange = value => {
    setFieldValue(`${modelKey}[categoryId]`, value);
    setFieldValue(`${modelKey}[productCategoryId]`, '');
    setFieldValue(`${modelKey}[productCategoryName]`, '');
    if (value !== '') {
      fetchInitialProductCategories({ categoryId: value });
    } else {
      setProductCategoryDisabled(true);
    }
  };

  const initialCategoryValue = () => {
    const currentValues = getIn(values, modelKey) || {};
    if (currentValues.categoryId && currentModel.categoryId && currentValues.categoryId === currentModel.categoryId) {
      return { label: currentModel.categoryName, value: currentModel.categoryId };
    }
    if (!currentValues.categoryId) {
      return null;
    }
    return undefined;
  };

  const initialProductCategoryValue = () => {
    const currentValues = getIn(values, modelKey) || {};
    if (currentValues.productCategoryId) {
      return { label: currentValues.productCategoryName, value: currentValues.productCategoryId };
    }
    return false;
  };

  const fetchCategories = (inputValue, callback) => {
    debounceIndexProductCategoriesRequest({
      dispatch,
      params: {
        query: inputValue,
        filter_parents: true,
        for_selector: true,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 20
      },
      successCallback: data => callback(resultFetchData(data))
    });
  };

  const fetchProductCategories = (inputValue, callback) => {
    const { categoryId } = getIn(values, modelKey);
    debounceIndexProductCategoriesRequest({
      dispatch,
      params: {
        query: inputValue,
        filter_parents: false,
        for_selector: true,
        category_id: categoryId,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 50
      },
      successCallback: data => callback(resultFetchData(data))
    });
  };

  return (
    <>
      <Col md={6} xs={12} className={fromProjectDetailInput ? 'work-detail-col' : ''}>
        <Field name={`${modelKey}[categoryId]`}>
          {({ field }) => (
            <InputRemoteSelect
              {...field}
              abbr={categoryAbbr}
              defaultOptions={categories}
              isClearable={!categoryAbbr}
              label="Categoría"
              placeholder="Seleccionar..."
              value={initialCategoryValue()}
              disabled={disabled}
              onChange={data => {
                const newValue = data ? data.value : '';
                handleParentCategoryChange(newValue);
                handleCategoryChange(newValue);
                setFieldValue(field.name, newValue);
                setFieldValue(`${modelKey}[categoryName]`, data ? data.label : '');
              }}
              request={fetchCategories}
              menuPortalTarget={!isModal ? document.body : null}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={6} xs={12} className={fromProjectDetailInput ? 'work-detail-col subcategory-detail' : ''}>
        <Field name={`${modelKey}[productCategoryId]`}>
          {({ field }) => (
            <InputRemoteSelect
              {...field}
              data-tip
              data-for={`full-name-${currentValue?.id}`}
              abbr={productCategoryAbbr}
              isClearable={!productCategoryAbbr}
              label={subcategoryMargin ? `Subcategoría   (${subcategoryMargin})` : 'Subcategoría'}
              placeholder="Seleccionar..."
              value={initialProductCategoryValue()}
              disabled={disabled || productCategoryDisabled}
              onChange={data => {
                const newValue = data ? data.value : '';
                handleSubCategoryChange(newValue);
                setFieldValue(field.name, newValue);
                setFieldValue(`${modelKey}[productCategoryName]`, data ? data.label : '');
              }}
              defaultOptions={productCategories}
              menuPortalTarget={!isModal ? document.body : null}
              request={fetchProductCategories}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
        {currentValue?.productCategoryName && <ReactTooltip id={`full-name-${currentValue?.id}`} place="top">
          {currentValue.productCategoryName}
        </ReactTooltip>}
      </Col>
    </>
  );
};

export default CategoryProductCategorySelect;
