import React from 'react';
import { useDispatch } from 'react-redux';
import { useGetProjectDetail, useUpdateProjectDetail } from '../../hooks/useProjectDetails';
import ProjectShowAdvancedConfiguration from './ProjectShowAdvancedConfiguration';
import { sendAlert } from '../../actions/utils';

const ProjectDetailAdvancedConfigurationModal = ({ projectDetailId, onClose }) => {
  const { projectDetail, reFetch: reFetchProjectDetail, isLoading } = useGetProjectDetail(
    projectDetailId,
    'advance_configuration'
  );
  const { update: updateProjectDetail } = useUpdateProjectDetail(projectDetailId);
  const dispatch = useDispatch();

  const onSaveProjectDetail = values => {
    updateProjectDetail(values, {
      onSuccess: async () => {
        reFetchProjectDetail();
        dispatch(sendAlert({ kind: 'success', message: 'Configuración actualizada con éxito' }));
        onClose(true, values);
      },
      onError: error => {
        console.error('Update Error:', error?.response?.data);
        dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
        onClose(false);
      }
    });
  };

  const handleCancel = () => {
    onClose(false);
  };

  if (isLoading) return null;

  return (
    <ProjectShowAdvancedConfiguration 
      onCancel={handleCancel} 
      projectDetail={projectDetail} 
      onSave={onSaveProjectDetail}
      isFreeItem={!projectDetail?.product?.id} 
    />
  );
};

export default ProjectDetailAdvancedConfigurationModal;
