import React, { createContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCart } from '../requests/autoparts'

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const { user } = useSelector(state => state.auth);
  const hashid = user.companyAttributes?.hashid ?? '';
  const dispatch = useDispatch();
  const [cart, setCart] = useState(null);
  const [cartEmpty, setCartEmpty] = useState(true);

  const updateCartEmpty = (value) => {
    setCartEmpty(value);
  };

  const updateCart = cart => {
    setCart(cart);
    setCartEmpty(!Object.values(cart.quotations).filter(quotation => quotation && !quotation._destroy).length);
  };

  const addQuotation = (quotationId, quotation) => {
    quotation.total = quotation.products.reduce((total, product) => total + (product.price ?? 0) * product.quantity, 0);
    cart.quotations[quotationId] = quotation;
    cart.total += quotation.total;
    updateCart(cart);
  };

  const removeQuotation = quotationId => {
    cart.total -= cart.quotations[quotationId].total;
    cart.quotations[quotationId] = null;
    updateCart(cart);
  };

  const refreshCart = () => {
    getCart(hashid, {
        dispatch,
        successCallback: response => updateCart(response.data)
    });
  };

  return (
    <CartContext.Provider value={{ cart, cartEmpty, updateCartEmpty, refreshCart, addQuotation, removeQuotation }}>
      {children}
    </CartContext.Provider>
  );
};