import React from 'react';
import { useField } from 'formik';
import { Form } from 'react-bootstrap';

const CheckboxInputField = ({
  label,
  required,
  disabled,
  placeholderText,
  typeSwitch,
  onChange,
  parentClass = '',
  ...props
}) => {
  const [field, meta] = useField({ type: 'checkbox', ...props });
  const { error, touched } = meta;

  const handleChange = e => {
    field.onChange({
      target: {
        name: props.name,
        value: e.target.checked,
        checked: e.target.checked
      }
    });
    if (onChange) onChange(e.target.checked);
  };

  return (
    <Form.Group className={`${disabled ? 'disabled' : ''} ${error && touched ? 'is-invalid' : ''} ${parentClass}`}>
      <Form.Check
        {...field}
        custom
        type={typeSwitch ? 'switch' : 'checkbox'}
        disabled={disabled}
        id={field.name}
        label={label}
        onChange={handleChange}
      />
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    </Form.Group>
  );
};

export default CheckboxInputField;
