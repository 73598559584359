import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, getIn, FieldArray, Formik } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import { format } from 'date-fns';
import '../../services/yupCustomMethods';
import { Dropzone } from '../../components';
import { createDropZoneFileRequest, deleteDropZoneFileRequest } from '../../requests/dropzone';
import { indexNotifiablesRequest } from '../../requests/projectDetails';
import { sendAlert } from '../../actions/utils';
import DateInputField from '../../components/Utils/Input/DateInputField';
import { useGetProjectDetail, useUpdateProjectDetail } from '../../hooks/useProjectDetails';
import SelectInputField from '../../components/Utils/Input/SelectInputField';
import { capitalizeFirstLetter } from '../../services/utils';

const PurchaseReceivedModalForm = ({ errors, onHide, submitVariant, touched, projectId, projectDetailId }) => {
  const dispatch = useDispatch();

  const { projectDetail, reFetch: reFetchProjectDetail, isLoading } = useGetProjectDetail(
    projectDetailId,
    'purchase_received'
  );
  const { update: updateProjectDetail } = useUpdateProjectDetail(projectDetailId);
  const { purchaseReceivedImagesInfo, projectContactsAttributes, purchaseReceivedDate } = projectDetail;
  const maxAvatarSize = 20971520;
  const [documentCode, setDocumentCode] = useState(Date.now());
  const [documentKeys, setDocumentKeys] = useState([]);
  const [uploadImage, setUploadImage] = useState(false);
  const [contactsList, setContactsList] = useState([]);
  const [userContacts, setUserContacts] = useState([]);
  const [purchaseReceived, setPurchaseReceived] = useState({
    currentImages: [],
    persistedImages: purchaseReceivedImagesInfo || []
  });

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessRequest = data => {
    const users = data.data.filter(item => item.contactable_type === 'User');
    const others = data.data.filter(item => item.contactable_type !== 'User');
    setUserContacts(
      users.map(contact => ({
        ...contact,
        label: contact.project_contactable_label,
        value: contact.id,
        contactableType: contact.contactable_type
      }))
    );
    setContactsList(
      others.map(contact => ({
        ...contact,
        label: contact.project_contactable_label,
        value: contact.id,
        contactableType: contact.contactable_type
      }))
    );
  };

  const getProjectContactsAttributes = () => {
    indexNotifiablesRequest({
      dispatch,
      params: { project_id: projectId },
      formData: true,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleOnDrop = (code, documents) => {
    setUploadImage(true);
    const dropzoneFiles = {
      drop_zone_file: {
        code,
        document_type: 'purchase_received_images',
        documents
      }
    };
    return createDropZoneFileRequest({
      dispatch,
      params: dropzoneFiles,
      formData: true,
      successCallback: response => {
        const { data } = response;
        const newDocumentKeys = documentKeys;
        data.map(element => newDocumentKeys.push(element.document_key));
        setDocumentKeys(newDocumentKeys);
        return response;
      }
    });
  };

  const handleUpdateImages = (images, setFieldValue) => {
    setUploadImage(false);
    const { persistedImages } = purchaseReceived;
    const updatedState = {
      ...purchaseReceived,
      currentImages: [...images],
      persistedImages: [...persistedImages]
    };

    setFieldValue('projectDetail[purchaseReceivedImageKeys]', documentKeys);
    setPurchaseReceived(updatedState);
    setDocumentCode(Date.now());
  };

  const handleOnDelete = (image, setFieldValue) => {
    deleteDropZoneFileRequest(image.id, {
      dispatch,
      params: { dropZoneFile: { documentKey: image.code } },
      successCallback: () => {
        const newDocumentKeys = documentKeys.filter(element => element !== image.code);
        setDocumentKeys(newDocumentKeys);
        setFieldValue('projectDetail[purchaseReceivedImageKeys]', newDocumentKeys);
      }
    });
  };

  const selectedContactable = (index, data, setFieldValue) => {
    setFieldValue(
      `projectDetail[projectContactsAttributes][${index}][contactableType]`,
      capitalizeFirstLetter(data.contactableType)
    );
  };

  const onSave = async params => {
    await updateProjectDetail(params, {
      onSuccess: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Configuración actualizada con éxito' }));
        reFetchProjectDetail();
        onHide();
      },
      onError: error => {
        dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
      }
    });
  };

  useEffect(getProjectContactsAttributes, []);

  if (isLoading) return null;

  const initialValues = {
    projectDetail: {
      purchaseReceivedImagesInfo,
      purchaseReceivedDate: purchaseReceivedDate || format(new Date(), 'yyyy-MM-dd'),
      projectContactsAttributes
    }
  };

  const disabled = !!purchaseReceivedDate;

  return (
    <Formik initialValues={initialValues} onSubmit={onSave}>
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <Row>
            <Col md={12}>
              <DateInputField
                disabled={disabled}
                label="Fecha de Recepción de Compra"
                name="projectDetail[purchaseReceivedDate]"
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-between mt-3">
            <Col md={6}>
              <Dropzone
                modalPreview
                id={documentCode}
                fileAccept="image/*"
                documentCounter={
                  values.projectDetail.purchaseReceivedImageKeys?.length || purchaseReceivedImagesInfo?.length || 0
                }
                maxSize={maxAvatarSize} // 20MB
                btnText="Seleccionar imágenes"
                persistedFiles={purchaseReceived.persistedImages}
                files={purchaseReceived.currentImages}
                onDrop={handleOnDrop}
                onDropUploaded={images => handleUpdateImages(images, setFieldValue)}
                onDelete={image => handleOnDelete(image, setFieldValue)}
                name="projectDetail[purchaseReceivedImageKeys]"
                error={getIn(errors, 'projectDetail[purchaseReceivedImageKeys]')}
                touched={getIn(touched, 'projectDetail[purchaseReceivedImageKeys]')}
                disabled={disabled}
              />
            </Col>
            {(contactsList.length > 0 || userContacts.length > 0) && (
              <Col md={12} className="mt-2">
                <h3>Notificar a:</h3>
              </Col>
            )}
            {contactsList.length > 0 && (
              <>
                <Col md={12} className="mb-3">
                  <h4>Clientes y contactos:</h4>
                  <FieldArray name="projectDetail[projectContactsAttributes]">
                    {({ push }) => (
                      <>
                        {values.projectDetail.projectContactsAttributes?.map((contact, index) => {
                          return (
                            !contact._destroy &&
                            contact.contactableType !== 'User' && (
                              <Row key={`contact${index}`}>
                                <Col xs={10}>
                                  <SelectInputField
                                    name={`projectDetail[projectContactsAttributes][${index}][contactableId]`}
                                    placeholder="Seleccione contacto"
                                    options={contactsList}
                                    disabled={disabled}
                                    onChange={data => selectedContactable(index, data, setFieldValue)}
                                    required
                                  />
                                </Col>
                                <Col xs={2} className="mt-1">
                                  {!disabled && (
                                    <Button
                                      variant="link"
                                      onClick={() => {
                                        setFieldValue(
                                          `projectDetail[projectContactsAttributes][${index}][_destroy]`,
                                          true
                                        );
                                      }}
                                    >
                                      Eliminar
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            )
                          );
                        })}
                        {!disabled && (
                          <Button
                            style={{ width: '170px' }}
                            onClick={() => push({ contactableId: '', contactableType: '' })}
                          >
                            Agregar cliente o contacto
                          </Button>
                        )}
                      </>
                    )}
                  </FieldArray>
                </Col>
              </>
            )}
            {userContacts.length > 0 && (
              <>
                <Col md={12}>
                  <h4>Usuarios del taller:</h4>
                  <FieldArray name="projectDetail[projectContactsAttributes]">
                    {({ push }) => (
                      <>
                        {values.projectDetail.projectContactsAttributes?.map((contact, index) => {
                          return (
                            !contact._destroy &&
                            contact.contactableType === 'User' && (
                              <Row key={`contact${index}`}>
                                <Col xs={10}>
                                  <SelectInputField
                                    name={`projectDetail[projectContactsAttributes][${index}][contactableId]`}
                                    placeholder="Seleccione contacto"
                                    options={userContacts}
                                    disabled={disabled}
                                    onChange={data => selectedContactable(index, data, setFieldValue)}
                                    required
                                  />
                                </Col>
                                <Col xs={2} className="mt-1">
                                  {!disabled && (
                                    <Button
                                      variant="link"
                                      onClick={() => {
                                        setFieldValue(
                                          `projectDetail[projectContactsAttributes][${index}][_destroy]`,
                                          true
                                        );
                                      }}
                                    >
                                      Eliminar
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            )
                          );
                        })}
                        {!disabled && (
                          <Button
                            style={{ width: '170px' }}
                            onClick={() => push({ contactableId: '', contactableType: 'User' })}
                          >
                            Agregar usuario
                          </Button>
                        )}
                      </>
                    )}
                  </FieldArray>
                </Col>
              </>
            )}
            {!disabled && (
              <Col md={12} className="my-2">
                <Row className="float-right">
                  <Col md={6}>
                    <Button style={{ width: '100px' }} className="btn" variant="light-darker" block onClick={onHide}>
                      Cancelar
                    </Button>
                  </Col>
                  <Col md={6}>
                    <Button
                      type="submit"
                      variant={submitVariant}
                      block
                      style={{ width: '100px' }}
                      disabled={uploadImage || isSubmitting}
                    >
                      {isSubmitting ? 'Guardando...' : 'Guardar'}
                    </Button>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default PurchaseReceivedModalForm;
