import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { es } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import ProjectWorkHourReportShow from './ProjectWorkHourReportShow';
import downloadFile, { camelCaseEmptyStringRecursive } from '../../services/utils';
import { debounceIndexProjectWorksRequest, indexProjectWorkHoursRequest } from '../../requests/projectWorkHours';
import { indexUsersRequest } from '../../requests/user';
import { sendAlert } from '../../actions/utils';
import { ROLE_IDS } from "../../config/ability";

const ProjectWorkHourReport = () => {
  const [projectWorkHours, setProjectWorkHours] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const { user } = useSelector(state => state.auth);
  const userIsOnlyMechanic = user.roleIds?.includes(ROLE_IDS.mechanic) && !user.roleIds?.includes(ROLE_IDS.admin);
  const [state, setState] = useState({
    approved: '',
    endDate: '',
    startDate: '',
    user: '',
    page: 0
  });

  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const approvedHoursOptions = [
    { label: 'Todas las Horas', value: '' },
    { label: 'Aprobadas', value: 'true' },
    { label: 'No Aprobadas', value: 'false' }
  ];

  const customStyles = {
    control: styles => ({ ...styles, borderRadius: '1.5rem', cursor: 'pointer' })
  };

  const fetchUsers = () => {
    indexUsersRequest({
      dispatch,
      params: {
        for_selector: true,
        sort_user_by_fullname: 'ASC',
        display_length: 100,
        active_users: true
      },
      successCallback: response => setUsers([{ label: 'Todos los Usuarios', value: '' }, ...response.data.data])
    });
  };

  useEffect(fetchUsers, []);
  useEffect(()=>handleChange(user.id, 'user'), [users, userIsOnlyMechanic]);

  const handleSuccessDownload = response => downloadFile(response);

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const downloadIndex = (format, params) => {
    const { startDate, endDate, user } = state;

    const sendParams = {
      ...params,
      display_length: 100000,
      date_from: startDate,
      date_to: endDate,
      filter_accountable_user: user,
      report: true
    };
    indexProjectWorkHoursRequest({
      dispatch,
      params: sendParams,
      format,
      successCallback: handleSuccessDownload,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessRequest = response => {
    setProjectWorkHours(response.data.data);
    setOnRequest(false);
  };

  const handleSearchRequest = () => {
    const { approved, endDate, startDate, user } = state;
    const params = {
      display_length: 100000,
      date_from: startDate,
      date_to: endDate,
      filter_accountable_user: user,
      filter_approved: approved
    };
    setOnRequest(true);
    debounceIndexProjectWorksRequest({
      dispatch,
      params,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleChange = (value, name) => {
    setState(prevState => ({
      ...prevState,
      [name]: value || '',
      page: 0
    }));
    // this.setRequest();
  };

  return (
    <>
      <Row className="justify-content-end mt-2">
        <Col md={2}>
          <Button variant="secondary" block onClick={() => downloadIndex('xlsx', {})}>
            Exportar
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <Row className="mt-4">
            <Col xs={2}>
              <Form.Group>
                <DatePicker
                  placeholderText="Desde"
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                  showYearDropdown
                  selected={state.startDate}
                  onChange={date => handleChange(date, 'startDate')}
                  locale={es}
                />
              </Form.Group>
            </Col>
            <Col xs={2}>
              <Form.Group>
                <DatePicker
                  placeholderText="Hasta"
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                  showYearDropdown
                  selected={state.endDate}
                  onChange={date => handleChange(date, 'endDate')}
                  locale={es}
                />
              </Form.Group>
            </Col>
            {!userIsOnlyMechanic && <Col md={3}>
              <Select
                placeholder="Usuario"
                options={users}
                defaultValue={{ label: 'Todos los Usuarios', value: '' }}
                onChange={data => handleChange(data.value, 'user')}
                styles={customStyles}
              />
            </Col>}
            <Col md={2}>
              <p className="project-work-hour-report-label">Horas</p>
              <Select
                placeholder="Todos"
                options={approvedHoursOptions}
                defaultValue={{ label: 'Todos las Horas', value: '' }}
                onChange={data => handleChange(data.value, 'approved')}
                styles={customStyles}
              />
            </Col>
            <Col md={2}>
              <Button onClick={handleSearchRequest} className="btn">
                Buscar
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ProjectWorkHourReportShow data={camelCaseEmptyStringRecursive(projectWorkHours)} onRequest={onRequest} />
    </>
  );
};

export default ProjectWorkHourReport;
