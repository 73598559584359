import React, { useState, useEffect } from 'react';
import {Route, Redirect, useLocation, useParams, useHistory} from 'react-router-dom';
import { Container, NavDropdown, Alert } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import { isMobile } from '../services/utils';

import API_CONFIG from './configurations';
import { requestSignOut } from '../actions/auth';
import { NavbarTop, NavbarLogged, Footer, PrincipalNavbar, Footerlogged } from '../components';
import GcFooter from '../components/Utils/GcFooter';

export const LoggedLayout = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const [toggled, setToggled] = useState(false);
  const [debtAlert, setDebtAlert] = useState({alert: false, button: false});
  ReactGA.initialize(API_CONFIG.googleAnalytics);
  const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: pathname });
  }, [pathname]);
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <>
            <NavbarLogged sidebar={{ toggled, setToggled }} setDebtAlert={setDebtAlert}>
              <NavDropdown.Item onClick={() => dispatch(requestSignOut())}>Cerrar Sesión</NavDropdown.Item>
            </NavbarLogged>
            { debtAlert.alert &&
              <Alert
                variant="danger"
                className="position-fixed top-0 start-0 w-100 mb-0 py-2 text-center"
                style={{ zIndex: 1050 }}
              >
                <button className="btn btn-outline-danger float-right" onClick={() => setDebtAlert({alert: false, button: false})}>
                  X
                </button>
                <h2 style={{ fontWeight: 'bold' }}>Tienes facturas pendientes por pagar.</h2>
                <h2 className="d-inline-block">Por favor, regularizar lo antes posible para evitar suspensión de la suscripción.</h2>
                { debtAlert.button &&
                  <button className="btn btn-light rounded-pill d-inline-block ml-3" onClick={() => history.push('/subscription')}>
                    Ir a Pagar
                  </button>
                }
              </Alert>
            }
            <Container fluid className={isMobile() ? 'pb-1' : ''}>
              {/* <GcSidebar toggled={toggled} setToggled={ setToggled } /> */}
              <div className="inter-space">
                <Component {...props} />
              </div>
            </Container>
            {isMobile() ? <GcFooter /> : <></>}
            {/* <Footerlogged /> */}
          </>
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

export const WideViewLayout = ({ component: Component, ...rest }) => {
  ReactGA.initialize(API_CONFIG.googleAnalytics);
  const { pathname } = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: pathname });
  }, [pathname]);
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <>
            <PrincipalNavbar />
            <Container fluid>
              <Component {...props} />
            </Container>
          </>
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

export const NonLoggedLayout = ({ component: Component, ...rest }) => {
  ReactGA.initialize(API_CONFIG.googleAnalytics);
  const { pathname } = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: pathname });
  }, [pathname]);
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
        ) : (
          <>
            <NavbarTop shortNavbar={pathname === '/login'} />
            <Container fluid>
              <Component {...props} />
            </Container>
            <Footer />
          </>
        )
      }
    />
  );
};

export const PublicLayout = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  ReactGA.initialize(API_CONFIG.googleAnalytics);
  const { pathname } = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: pathname });
  }, [pathname]);
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <>
            <NavbarLogged>
              <NavDropdown.Item onClick={() => dispatch(requestSignOut())}>Cerrar Sesión</NavDropdown.Item>
            </NavbarLogged>
            <Container fluid>
              <Component {...props} />
            </Container>
            <Footer />
          </>
        ) : (
          <>
            <NavbarTop shortNavbar={pathname === '/contact'} />
            <Container fluid>
              <Component {...props} />
            </Container>
            <Footer />
          </>
        )
      }
    />
  );
};

export const PublicProjectLayout = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const { companyCode } = rest.computedMatch.params;
  ReactGA.initialize(API_CONFIG.googleAnalytics);
  const { pathname } = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: pathname });
  }, [pathname]);
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <>
            <NavbarLogged>
              <NavDropdown.Item onClick={() => dispatch(requestSignOut())}>Cerrar Sesión</NavDropdown.Item>
            </NavbarLogged>
            <Container fluid>
              <Component {...props} />
            </Container>
            <Footerlogged />
          </>
        ) : (
          <>
            <PrincipalNavbar isPublicView companyCode={companyCode}/>
            <Container fluid>
              <Component {...props} />
            </Container>
            <Footerlogged />
          </>
        )
      }
    />
  );
};
