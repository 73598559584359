import React, { useState } from 'react';
import ReactSelect from 'react-select';
import './styles.scss';

const customStyles = {
  control: styles => ({ ...styles, borderRadius: '1.5rem', cursor: 'pointer' }),
  option: (provided, { data }) => {
    if (data.custom) {
      const opacity = 0.5;
      const fontStyle = 'italic';
      return { ...provided, opacity, fontStyle };
    }
    return { ...provided };
  }
};

const Select = ({ options, value, onChange, placeholder, disabled, isClearable }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = selectedOption => {
    onChange(selectedOption?.value || null, selectedOption);
  };
  return (
    <ReactSelect
      styles={customStyles}
      className={`${isOpen ? 'border-on ' : ''}`}
      placeholder={placeholder}
      onMenuOpen={() => setIsOpen(true)}
      onMenuClose={() => setIsOpen(false)}
      loadingMessage={() => 'Cargando...'}
      noOptionsMessage={() => 'No hay opciones'}
      options={options}
      isSearchable
      isClearable={isClearable}
      defaultValue={options.filter(e => e.value === value)}
      onChange={handleChange}
      isDisabled={disabled}
    />
  );
};
export default Select;