import React from 'react';
import { Container } from 'react-bootstrap';
import { Can } from '../../config/abilityContext';
import { useGetProject } from '../../hooks/useProjects';
import QuoteShow from './QuoteShow';
import ProjectOT from './ProjectOT';

const ProjectShow = props => {
  const { match, projectVersion } = props;
  const { id, version } = match.params;
  const { project, isLoading } = useGetProject(id, 'general');
  const { isQuote } = project;

  if (isLoading) return null;

  return (
    <Can I="manage" a="Project">
      <Container>
        {isQuote ? (
          <QuoteShow projectId={id} projectVersion={projectVersion} version={version} />
        ) : (
          <ProjectOT projectId={id} projectVersion={projectVersion} version={version} />
        )}
      </Container>
    </Can>
  );
};

export default ProjectShow;
