import React from 'react';
import { useGetProjectPayment, useUpdateProjectPayment } from '../../hooks/useProjectPayments';
import ProjectPaymentForm from './ProjectPaymentForm';

const ProjectPaymentFormEdit = ({ id, projectId, onSuccess }) => {
  const { projectPayment, isLoading } = useGetProjectPayment(id, 'edit');
  const updateProjectPayment = useUpdateProjectPayment(id);

  const handleUpdateRequest = values => {
    updateProjectPayment(values, {
      onSuccess: () => {
        console.log('onSuccess')
        onSuccess();
      },
      onError: error => {
        console.log(error);
      }
    });
  };
  if (isLoading) return null;

  return (
    <ProjectPaymentForm
      projectPayment={projectPayment}
      action="edit"
      projectId={projectId}
      formRequest={handleUpdateRequest}
    />
  );
};
export default ProjectPaymentFormEdit;
