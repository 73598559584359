import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InputRemoteSelect, DefaultModal } from '../../components';
import ComponentDataTable from '../../components/Utils/DataTable';
import { debounceIndexProductsRequest, deleteProductRequest, indexProductsRequest } from '../../requests/products';
import { debounceIndexProductCategoriesRequest, indexProductCategoriesRequest } from '../../requests/productCategories';
import { sendAlert } from '../../actions/utils';
import downloadFile, { unitSanitazer } from '../../services/utils';

const ServiceDataTable = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [amount, setAmount] = useState(0);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [products, setProducts] = useState([]);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const { columns } = props;
  const [queries, setQueries] = useState({});

  const handleSuccessRequest = response => {
    const rawProducts = response.data.data;
    const responseProducts = rawProducts.map(product => unitSanitazer(product));
    const { amount: responseAmount } = response.data.metadata;
    setProducts(responseProducts);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleFailureRequest = error => {
    const {
      data: { message }
    } = error.response;
    dispatch(sendAlert({ kind: 'error', message }));
    setOnRequest(false);
  };

  const handleIndexRequest = prevParams => {
    setOnRequest(true);
    const params = {
      ...prevParams,
      category_id: categoryId
    };
    debounceIndexProductsRequest({
      dispatch,
      params,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'show':
        history.push(`/products/${item.id}`);
        break;
      case 'edit':
        history.push(`/products/${item.id}/edit`);
        break;
      case 'destroy':
        setModalShow(true);
        setModalItem(item);
        setModalBody('¿Estás seguro que deseas eliminar este ítem?');
        break;
      case 'refresh':
        setMoreData(!moreData);
        break;
      default:
        // eslint-disable-next-line no-alert
        console.warn(`Unexpected action passed: ${action}`);
        alert('Error: Action not found');
    }
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSuccessRemove = () => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Ítem eliminado con éxito' }));
    setMoreData(!moreData);
  };

  const removeProduct = productId => {
    deleteProductRequest(productId, {
      dispatch,
      successCallback: () => handleSuccessRemove(productId)
    });
  };

  const handleModalConfirm = () => {
    removeProduct(modalItem.id);
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'category_name':
        return { sort_category_name: name };
      case 'product_category_name':
        return { sort_sub_category: name };
      case 'translated_unit':
        return { sort_column: 'unit' };
      default:
        return { sort_column: name };
    }
  };

  const handleSuccessDownload = response => {
    downloadFile(response);
  };

  const downloadIndex = (format, params) => {
    indexProductsRequest({
      dispatch,
      params: { ...params, display_length: 100000 },
      format,
      successCallback: handleSuccessDownload
    });
  };

  const resultFetchData = response => {
    return response.data.data;
  };

  const fetchCategories = (inputValue, callback) => {
    debounceIndexProductCategoriesRequest({
      dispatch,
      params: {
        query: inputValue,
        filter_parents: true,
        for_selector: true,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 20
      },
      successCallback: data => callback(resultFetchData(data))
    });
  };

  useEffect(() => {
    indexProductCategoriesRequest({
      dispatch,
      params: {
        filter_parents: true,
        for_selector: true,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 100
      },
      successCallback: data => setCategories(resultFetchData(data))
    });
  }, [dispatch]);

  return (
    <>
      <Row className="justify-content-end mt-2">
        <Col md={2}>
          <Button variant="secondary" block onClick={() => downloadIndex('xlsx', queries)}>
            Exportar
          </Button>
        </Col>
      </Row>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        handleSortCase={sortColumnCase}
        data={products}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          setQueries(response);
          if (!onRequest) handleIndexRequest(response);
        }}
        customSearchLong={
          <InputRemoteSelect
            isClearable
            defaultOptions={categories}
            placeholder="Filtro categorías"
            onChange={data => {
              setCategoryId(data ? data.value : '');
              setMoreData(!moreData);
            }}
            request={fetchCategories}
          />
        }
      />
      <DefaultModal
        title="Eliminar Item"
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={handleModalConfirm}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default ServiceDataTable;