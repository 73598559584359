import React from 'react';
import * as Yup from "yup";
import {withFormik, Field, Form, getIn} from 'formik';
import {Button, Row, Col} from 'react-bootstrap';
import {FormikInput, FormikNumberFormat} from "../../components";

const NationalIdentificationForm = ({errors, touched, setFieldValue, onHide, countryFactory, client}) => {
  const {isCompany, isForeign} = client;
  return (
    <Form>
      <Row>
        <Col md="12">
          <Field name="client[nationalIdentification]">
            {({field}) => (
              <>
                {countryFactory.getLabelForPersonalIdentification() === 'RUT' ? (
                  <FormikInput
                    {...field}
                    abbr
                    label={isCompany ? countryFactory.getLabelForCompanyIdentification() : countryFactory.getLabelForPersonalIdentification()}
                    onChange={e => countryFactory.handleNationalIdentificationFormat(e, setFieldValue, isForeign)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    rut={true}
                  />
                ) : (
                  <FormikNumberFormat
                    {...field}
                    abbr
                    label={isCompany ? countryFactory.getLabelForCompanyIdentification() : countryFactory.getLabelForPersonalIdentification()}
                    format={isCompany ? countryFactory.getFormatNationalIdentificationCompany() : countryFactory.getFormatNationalIdentification()}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </>
            )}
          </Field>
        </Col>
        <Col xs={12} className="mb-1 d-flex justify-content-end">
          <Button onClick={onHide} variant="light-darker" className="mr-3">
            Cancelar
          </Button>
          <Button type="submit" variant="success">
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const {client} = props;
  return {client};
}

const nationalIdentification = (country) => {
  return Yup.string().required('Debes ingresar un RUT').rut('El RUT es inválido', country);
};

const fullClient = (country) => {
  return Yup.object().shape({
    nationalIdentification: nationalIdentification(country)
  });
};

const validationSchema = ({country}) => {
  return Yup.object().shape({
    client: fullClient(country)
  });
};

const handleSubmit = (values, {props}) => {
  const {formRequest, onHide} = props;
  formRequest(values);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: true
})(NationalIdentificationForm);