import camelCaseRecursive from 'camelcase-keys-recursive';
import api_v2 from '../services/api_v2';
import useQuery, { useInvalidateQuery } from './useQuery';

const listProviders = (params) => {
  return api_v2.get('/providers.json', { params }).then(res => res.data);
};

export const useReFetchProviders = () => {
  const invalidateQueries = useInvalidateQuery();
  return () => {
    invalidateQueries(['providers']);
  };
};

export const useListProviders = (params = {}) => {
  const defaultParams = {
    for_selector: true,
    sort_column: 'name',
    sort_direction: 'asc',
    display_length: 100,
    ...params
  };

  const { data = [], isLoading } = useQuery(
    ['providers', defaultParams],
    () => listProviders(defaultParams),
    {
      enabled: true
    }
  );
  const reFetch = useReFetchProviders();
  const providers = camelCaseRecursive(data);
  return { providers, reFetch, isLoading };
};