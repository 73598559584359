import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { sendAlert } from '../../../actions/utils';
import { SimpleCenteredModal, DefaultModal } from '../../../components';
import { debounceIndexClientsRequest } from '../../../requests/clients';
import { createProjectInvoiceRequest, getDocumentRequest } from '../../../requests/projectInvoices';
import ProjectInvoiceForm from './ProjectInvoiceForm';
import downloadFile from '../../../services/utils';
import ConfirmRequest from './ConfirmRequest';
import { useCreateProjectInvoice } from '../../../hooks/useProjectInvoices';

const AddProjectInvoice = ({ project, onReloadProjectInvoices, version }) => {
  const { id: projectId, clientId, net, projectPaymentsAttributes: projectPayments, total, taxRateFormatted } = project;
  const [clients, setClients] = useState([]);
  const [currentClient, setCurrentClient] = useState({});
  const [defaultModalBody, setDefaultModalBody] = useState('');
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [originalValues, setOriginalValues] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const { create: createProjectInvoice } = useCreateProjectInvoice(projectId);

  const basicProjectInvoice = {
    id: '',
    amount: parseFloat(net),
    clientId,
    issueDate: new Date(),
    expirationDate: new Date(),
    description: '',
    total,
    companyTaxRate: parseFloat(taxRateFormatted)
  };

  const handleModalClose = () => {
    setModalBody('');
    setModalShow(false);
    setCurrentClient({});
    setOriginalValues({});
  };

  const handleSuccessDownload = response => {
    downloadFile(response);
    setOnRequest(false);
    dispatch(sendAlert({ kind: 'success', message: 'Documento emitido con éxito' }));
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleDownloadDocument = (invoiceId, format) => {
    getDocumentRequest(projectId, invoiceId, {
      format,
      dispatch,
      successCallback: handleSuccessDownload,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessCreate = response => {
    const { id, invoice_number: invoiceNumber, json_response: jsonResponse } = response;
    onReloadProjectInvoices();
    handleModalClose();
    if (invoiceNumber) {
      handleDownloadDocument(id, 'pdf');
    } else {
      const errorMessages = {
        'does not have numbers available, check sii caf':
          'No hay números de folio disponibles, por favor contacte a soporte.',
        'The sii caf used is expired, you must request and upload a new one':
          'El SII CAF utilizado está vencido, debe solicitar y subir uno nuevo.',
        'This document type has the following client attributes required: city,code,municipality/district,address':
          'El cliente seleccionado no tiene registrado RUT, ciudad, comuna o dirección.'
      };
      dispatch(
        sendAlert({
          kind: 'error',
          message: errorMessages[jsonResponse.error] || jsonResponse.error
        })
      );
    }
    setOnRequest(false);
  };

  const handleCreateRequest = values => {
    setOnRequest(true);
    createProjectInvoice(values, {
      onSuccess: handleSuccessCreate,
      onError: handleFailureRequest
    });
  };

  const handleConfirmRequest = values => {
    setModalShow(false);
    setModalBody(<ConfirmRequest confirm={() => handleCreateRequest(values)} cancel={handleModalClose} />);
    setModalShow(true);
  };

  const resultFetchData = response => {
    const result = response.data.data;
    const tempArray = result.map(element => {
      return {
        ...element,
        label: element.is_company ? element.legal_name : element.full_name,
        value: element.id
      };
    });
    setClients(camelCaseRecursive(tempArray));
    return tempArray;
  };

  const fetchClients = (inputValue, callback) => {
    debounceIndexClientsRequest({
      dispatch,
      params: {
        query: inputValue || clientId,
        sort_column: 'name',
        display_length: 50
      },
      successCallback: response => callback(resultFetchData(response)),
      failureCallback: handleFailureRequest
    });
  };

  const handleOnClickDefaultModal = () => {
    setModalShow(false);
    setDefaultModalShow(true);
    setDefaultModalBody(
      <>
        <p>Al seleccionar Editar Cliente, abandonarás esta vista y todos los datos que no han sido guardados.</p>
        <br />
        <p>Confirmar para continuar.</p>
      </>
    );
  };

  const handleDefaultModalConfirm = () => {
    if (currentClient && Object.keys(currentClient).length > 0) {
      history.push(`/clients/${currentClient.id}/edit`);
    }
  };

  const handleDefaultModalClose = () => {
    setDefaultModalShow(false);
    setModalShow(true);
  };

  const handleModalBody = () => {
    setModalBody(
      <>
        {onRequest ? (
          <>
            <h3 className="text-center">Generando Documento</h3>
            <p className="text-center lead mb-5">Espere un momento mientras se genera el documento</p>
            <div className="d-flex justify-content-around w-25 mx-auto mb-3">
              <Spinner animation="grow" variant="success" />
              <Spinner animation="grow" variant="success" />
              <Spinner animation="grow" variant="success" />
            </div>
          </>
        ) : (
          <ProjectInvoiceForm
            action="new"
            clients={clients}
            projectInvoice={basicProjectInvoice}
            fetchClients={fetchClients}
            formRequest={handleConfirmRequest}
            handleModalClose={handleModalClose}
            onClickEditClient={handleOnClickDefaultModal}
            currentClient={currentClient}
            setCurrentClient={setCurrentClient}
            originalValues={originalValues}
            setOriginalValues={setOriginalValues}
            initialClientId={clientId}
            projectPayments={projectPayments}
            projectId={projectId}
            showIsDetailed={project.priceDiscount === 0}
          />
        )}
      </>
    );
  };

  useEffect(handleModalBody, [originalValues, clients, currentClient, onRequest]);

  const handleClick = () => {
    handleModalBody();
    setModalShow(true);
  };

  return (
    <>
      <Button hidden={version} variant="secondary" className="add-payment-btn" block onClick={handleClick}>
        Emitir Documento
      </Button>
      <SimpleCenteredModal title="Emitir Nuevo Documento" body={modalBody} show={modalShow} onHide={handleModalClose} />
      <DefaultModal
        title="Confirmar"
        body={defaultModalBody}
        show={defaultModalShow}
        handleClose={handleDefaultModalClose}
        handleConfirm={handleDefaultModalConfirm}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default AddProjectInvoice;
