/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {Spinner} from 'react-bootstrap';
import ProjectAutopartsOfferCategory from './ProjectAutopartsOfferCategory';

const ProjectAutopartsOffer = ({
  offeredCategories,
  onRequest,
  addProductToSelectedProducts
}) => {
  const [categoryOpened, setCategoryOpened] = useState({});

  useEffect(() => {
    offeredCategories.forEach(category => {
      categoryOpened[category.categoryId] = category.products.length <= 12;
    });
    setCategoryOpened(categoryOpened);
  }, [categoryOpened, offeredCategories]);

  return (
    <>
    <strong className='h2'>Productos disponibles</strong>
    {onRequest && (
      <div className="containerSpinnerLoad">
        <Spinner animation="border" variant="primary" />
      </div>
    )}
    {offeredCategories.map(category => {
      return (
        <ProjectAutopartsOfferCategory
          category={category}
          addProductToSelectedProducts={addProductToSelectedProducts}
        />
      );
    })}
    </>
  );
};

export default ProjectAutopartsOffer;
