import React from 'react';
import { Form } from 'react-bootstrap';
import { useField } from 'formik';
import Select from './Select';
import * as validations from '../../../utils/validations';

const SelectInputField = ({ label, placeholder, options, required, disabled, onChange, parentClass, ...props }) => {
  const validate = required && validations.required();
  const [field, meta] = useField({ validate, ...props });
  const { error, touched } = meta;

  const changeValue = (value, selectedOption) => {
    field.onChange({
      target: {
        name: props.name,
        value
      }
    });
    if (onChange) onChange(selectedOption);
  };

  return (
    <Form.Group className={`${disabled ? 'disabled' : ''} ${error && touched ? 'is-invalid' : ''} ${parentClass}`}>
      {label && (
        <Form.Label className="font-weight-bolder">
          {label} {required && <abbr className="text-danger ml-1">*</abbr>}
        </Form.Label>
      )}
      <Select
        {...field}
        disabled={disabled}
        isClearable
        placeholder={placeholder}
        options={options}
        onChange={changeValue}
      />
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    </Form.Group>
  );
};

export default SelectInputField;