import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { sendAlert } from '../../actions/utils';
import { EXTERNAL_API_CONFIG } from '../../config/configurations';
import { piriodSessionKey } from '../../requests/companies';

const Subscription = () => {
  const { customerBaseId } = EXTERNAL_API_CONFIG;
  const dispatch = useDispatch();
  const [sessionKey, setSessionKey] = useState(null);
  
  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const fetchSessionKey = () => {
    piriodSessionKey({
      dispatch,
      successCallback: response => setSessionKey(response.data.session_key),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchSessionKey, []);

  useEffect(() => {
    if (sessionKey) {
      window.piriodSettings = {
        service: "customerBase",
        customerBaseId: customerBaseId,
        sessionKey: sessionKey
      };

      const script = document.createElement('script');
      script.src = "https://js.piriod.com/embed/piriod-embed.umd.min.js";
      script.async = true;
      script.type = "text/javascript";
      
      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(script, firstScript);

      return () => {
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
        
        delete window.piriodSettings;
      };
    }
  }, [customerBaseId, sessionKey]);

  return (
    <div id="piriod-widget"></div>
  );
};

export default Subscription;
