import ApiService from '../services/apiService';

export const createProjectChecklistRequest = (
  projectId,
  { dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.requestV2('post', `/projects/${projectId}/project_checklists.json`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });
