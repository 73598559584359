export const mix =
  (...args) =>
    (value = null, values = null) =>
      args.reduce((a, b) => (b && b(value, values)) || a, undefined)

export const required =
  (message = 'Campo requerido.') =>
    value => {
      if (typeof value === 'string') {
        value = value.replace(/\s+/g, '')
      }
      return value || value === 0 ? undefined : message
    }

export const requiredCellphone =
  (message = 'Campo requerido.') =>
    value => {
      if (typeof value === 'string') {
        value = value.replace(/\s+/g, '')
      }
      return value?.length > 9 ? undefined : message
    }