import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Toast } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { Copy, Edit, ChevronDown, ChevronUp, RefreshCcw } from 'react-feather';
import ProjectVehicleDetails from './ProjectVehicleDetails';
import { SimpleCenteredModal } from '../../components/Utils/Modal';
import VehicleForm from '../Vehicle/VehicleForm';
import { sendAlert } from '../../actions/utils';
import VehicleSelect from '../Vehicle/VehicleSelect';
import { useGetVehicle, useUpdateVehicle } from '../../hooks/useVehicles';
import { useReFetchProject, useUpdateProject } from '../../hooks/useProjects';

const ProjectVehicle = ({
  companyRequiredFieldAttributes,
  project,
  fromProjectEdit,
  version,
  toggleVehicleExpanded,
  collapsible
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { vehicleId, clientId, secondClientId, rememberMaintenanceFormated, vehicle: projectVehicle } = project;
  const { vehicle, isLoading } = useGetVehicle(vehicleId, 'show');
  const { vehicle: vehicleEdit, reFetch: reFetchVehicle } = useGetVehicle(vehicleId, 'edit');
  const { update: updateVehicle } = useUpdateVehicle(vehicleId);
  const { update: updateProject } = useUpdateProject(project?.id);
  const reFetchProject = useReFetchProject(project?.id.toString());
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const [expanded, setExpanded] = useState(false);

  if (isLoading) return null;

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
  };

  const handleUpdateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    updateVehicle(sendParams, {
      onSuccess: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Vehículo actualizado con éxito' }));
        reFetchVehicle();
        handleModalClose();
      },
      onError: error => {
        const { response } = error;
        dispatch(sendAlert({ kind: 'error', message: response.data.message }));
      }
    });
  };

  const handleEditVehicle = () => {
    setModalBody(
      <VehicleForm
        companyRequiredFieldAttributes={companyRequiredFieldAttributes}
        vehicle={vehicleEdit}
        action="edit"
        formRequest={handleUpdateRequest}
      />
    );
    setModalShow(true);
  };

  const handleRedirectToVehicle = () => {
    history.push(`/clients/${secondClientId || clientId}/vehicles/${vehicleId}`);
  };

  const formatVehicleInfo = () => {
    return `Patente: ${vehicle.plate}\nMarca: ${vehicle.brand?.name || ''}\nModelo: ${vehicle.model?.name ||
      ''}\nAño: ${vehicle.year}\nVin: ${vehicle.vin}\nTamaño Motor: ${vehicle.engineCapacity &&
      `${vehicle.engineCapacity} [CC]`}\nTipo de combustible: ${vehicle.fuelTypeName}`;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(formatVehicleInfo()).then(() => {
      setShow(true);
    });
  };

  const toggle = () => {
    setExpanded(!expanded);
    toggleVehicleExpanded();
  };

  const handleUpdateVehicleRequest = values => {
    const data = { project: { vehicle_id: values.vehicle_id } };
    updateProject(data, {
      onSuccess: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Vehículo actualizado con éxito' }));
        reFetchProject();
        handleModalClose();
      },
      onError: error => {
        const { response } = error;
        dispatch(sendAlert({ kind: 'error', message: response.data.message }));
      }
    });
  };

  const handleChangeVehicle = () => {
    setModalBody(
      <VehicleSelect
        vehicleDefault={projectVehicle}
        clientId={secondClientId || clientId}
        formRequest={handleUpdateVehicleRequest}
      />
    );
    setModalShow(true);
  };

  return (
    <>
      <Row className="justify-content-between">
        <Col md="auto">
          <strong className="mr-1">Vehículo</strong>
          <Copy size={15} onClick={handleCopy} role="button" />
        </Col>
        <Col md="auto">
          <Toast className="bg-info text-white" onClose={() => setShow(false)} show={show} delay={1500} autohide>
            <Toast.Body>Mensaje Copiado</Toast.Body>
          </Toast>
        </Col>
        {!expanded && (
          <Col md="auto">
            <strong>{vehicle.name}</strong>
          </Col>
        )}
        {!fromProjectEdit && !version && (
          <div className="d-flex">
            <Button variant="link" className="btn-sm text-dark float-right px-0" onClick={handleEditVehicle}>
              <Edit width="15px" height="15px" />
            </Button>
            <Button variant="link" className="btn-sm text-dark float-right pr-3" onClick={handleChangeVehicle}>
              <RefreshCcw width="15px" height="15px" />
            </Button>
          </div>
        )}
      </Row>
      {(expanded || !collapsible) && (
        <div role="button" onClick={handleRedirectToVehicle} onKeyPress={handleRedirectToVehicle} tabIndex={0}>
          <ProjectVehicleDetails vehicle={vehicle} rememberMaintenanceFormated={rememberMaintenanceFormated} />
        </div>
      )}
      <SimpleCenteredModal
        closeButton
        title="EDITAR VEHÍCULO"
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
      />
      {collapsible && (
        <Row className="mt-auto">
          <button className="w-100 btn btn-light" onClick={toggle}>
            {expanded ? <ChevronUp size={15} /> : <ChevronDown size={15} />}
          </button>
        </Row>
      )}
    </>
  );
};

export default ProjectVehicle;
