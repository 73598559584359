import React from 'react';
import { Eye } from 'react-feather';
import memoize from 'memoize-one';
import moment from 'moment/moment';
import { useDispatch } from 'react-redux';
import { ButtonTooltip } from '../../../components';
import { sendAlert } from '../../../actions/utils';

const columns = memoize(() => {
  const dispatch = useDispatch();

  const handleFailureRequest = () => {
    dispatch(
      sendAlert({
        kind: 'error',
        message:
          'No se puede visualizar el pdf porque el proveedor no tiene actualizada la casilla de recepción de documentos. Por favor, contactar y pedirle que actualice a recepcion@dte.bsale.app'
      })
    );
  };

  return [
    { name: 'TIPO', selector: 'document_type_name', sortable: true, grow: '2' },
    { name: 'N° DE DOCUMENTO', selector: 'number', sortable: true, grow: '1' },
    {
      name: 'FECHA DE EMISIÓN',
      selector: 'emission_date',
      cell: item => moment(item.emission_date, 'YYYY-MM-DD').format('DD/MM/YY'),
      sortable: true,
      grow: '1'
    },
    {
      name: 'RUT PROVEEDOR',
      selector: 'provider.national_identification',
      sortable: true,
      grow: '1'
    },
    {
      name: 'PROVEEDOR',
      selector: 'provider.legal_name',
      sortable: true,
      grow: '2'
    },
    {
      name: 'NETO',
      selector: 'parsed_net',
      sortable: true,
      grow: '1'
    },
    {
      name: 'IVA',
      selector: 'parsed_tax',
      sortable: true,
      grow: '1'
    },
    {
      name: 'TOTAL',
      selector: 'parsed_total',
      sortable: false,
      grow: '1'
    },
    {
      name: '',
      selector: 'id',
      cell: item =>
        !item.url_pdf ? (
          <ButtonTooltip
            variant="outline-info"
            className="btn-circle table-actions-btn"
            size="sm"
            text="Ver Documento"
            onClick={handleFailureRequest}
          >
            <Eye width="15px" height="15px" />
          </ButtonTooltip>
        ) : (
          <ButtonTooltip
            href={`${item.url_pdf}`}
            variant="outline-info"
            className="btn-circle table-actions-btn"
            size="sm"
            text="Ver Documento"
            target="_blank"
            as="a"
          >
            <Eye width="15px" height="15px" />
          </ButtonTooltip>
        )
    }
  ];
});

export default columns;
