import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ProjectImages from './ProjectImages';
import { useListProjectChecklistsByProject } from '../../hooks/useProjectChecklists';
import getImage from '../../utils/getImage';

const ProjectImagesShow = ({ project, projectVersion }) => {
  const { id: projectCode, deskSell } = project;
  const { projectChecklists, isLoading } = useListProjectChecklistsByProject(projectCode, 'list_images');

  if (isLoading) return null;

  const images = (imagesInfo = []) => {
    if (imagesInfo.length) {
      return imagesInfo.map(image => ({
        original: image.file_url,
        thumbnail: getImage(image.key, 100, 100, 'inside')
      }));
    }
    return [];
  };

  const getImages = (attributes = []) => {
    return attributes
      .map(projectReview => {
        return projectReview.reduce((result, image) => {
          result.push({
            original: image.file_url,
            thumbnail: getImage(image.key, 200, 200, 'inside')
          });
          return result;
        }, []);
      })
      .flat();
  };

  const mapProjectImages = projectChecklists.map(projectChecklist => {
    return (
      <Col className="gallery-small-box">
        <ProjectImages
          images={
            projectVersion ? images(projectChecklist.reviewImagesInfo) : getImages(projectChecklist.reviewImagesInfo)
          }
          title={projectChecklist.sectionTitle}
        />
      </Col>
    );
  });

  return (
    <>
      {!deskSell && (
        <Col className="project-section ml-2 pt-3">
          <Row>
            <Col md={12}>
              <strong>Imágenes</strong>
            </Col>
            {mapProjectImages}
          </Row>
        </Col>
      )}
    </>
  );
};

export default ProjectImagesShow;
