import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Field, FieldArray, getIn, useFormikContext } from 'formik';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { sendAlert, getClientType, saveCurrentClient, saveSecondCurrentClient } from '../../actions/utils';
import { FormikSelect, InputRemoteSelect } from '../Utils/Select';
import { debounceIndexClientsRequest, createClientRequest, indexClientsRequest } from '../../requests/clients';
import ClientForm from '../../screens/Client/ClientForm';
import { Can } from "../../config/abilityContext";
import { capitalizeFirstLetter } from "../../services/utils";

const clientForm = {
  active: true,
  address: '',
  addressNumber: '',
  businessActivity: '',
  cellphone: '',
  communeId: '',
  contactsAttributes: [],
  email: '',
  firstLastName: '',
  isCompany: false,
  legalName: '',
  name: '',
  nationalIdentification: '',
  notes: '',
  phone: '',
  regionId: '',
  secondLastName: '',
  sellerId: ''
};

const ClientTab = props => {
  const {
    abbr,
    clientDisabled,
    createClient,
    companyRequiredFieldAttributes,
    formClassName,
    formSize,
    handleClientChange,
    modelKey,
    newClient,
    newVehicle,
    resetVehicle,
    setNewClient,
    setVehicleDisabled
  } = props;
  const { user } = useSelector(state => state.auth);
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const [contactableOptions, setContactableOptions] = useState([]);
  const currentValues = getIn(values, modelKey);
  const {
    clientId: currentValueClientId,
    secondClientId: currentValueSecondClientId,
    projectContactsAttributes
  } = currentValues;
  const [clients, setClients] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const dispatch = useDispatch();
  const { clientType, currentClient, currentSecondClient } = useSelector(state => state.utils);
  const placeholderText = onRequest ? 'Cargando...' : 'Seleccionar Cliente';

  const handleContactableOptions = () => {
    if (currentClient?.contactsAttributes?.length > 0) {
      let options = [{
        id: currentClient.id,
        label: currentClient.projectContactableLabel,
        value: currentClient.id,
        contactableType: 'Client'
      }];
      currentClient.contactsAttributes.forEach(contact => {
        options = [...options, {
          id: contact.id,
          label: contact.projectContactableLabel,
          value: contact.id,
          contactableType: 'Contact'
        }];
      });
      setContactableOptions(options);
    } else {
      setContactableOptions([]);
    }
  }
  useEffect(handleContactableOptions, [currentClient]);

  useEffect(() => {
    if (!createClient) {
      if (currentValues.clientId) dispatch(saveCurrentClient(currentValues.client));
      if (currentValues.secondClientId) dispatch(saveSecondCurrentClient(currentValues.secondClient));
    }
  }, []);

  const formattedValueInput = result => {
    return result.map(element => ({
      ...element,
      label: element.full_name,
      value: element.id
    }));
  };

  const handleSuccessRequest = response => {
    resetVehicle();
    dispatch(sendAlert({ kind: 'success', message: 'Cliente creado con éxito' }));
    const data = camelCaseRecursive(response.data);

    if (clientType === 'client1') {
      setFieldValue(`${modelKey}[clientId]`, data.id);
      dispatch(saveCurrentClient(data));
    }
    if (clientType === 'client2') {
      setFieldValue(`${modelKey}[secondClientId]`, data.id);
      dispatch(saveSecondCurrentClient(data));
    }
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleCreateClientRequest = params => {
    createClientRequest({
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const resultFetchData = (response, clientValues = null) => {
    const result = response.data.data;
    const tempArray = formattedValueInput(result);
    const filteredClients = camelCaseRecursive([...tempArray, ...formattedValueInput(clientValues)]);
    setClients(prev => [...prev, ...filteredClients]);
    return filteredClients;
  };

  const inputRequestClients = (inputValue, callback) => {
    debounceIndexClientsRequest({
      dispatch,
      params: {
        query: inputValue,
        sort_column: 'name',
        display_length: 50
      },
      successCallback: response => callback(resultFetchData(response, [])),
      failureCallback: handleFailureRequest
    });
  };

  const fetchAllClients = val => {
    indexClientsRequest({
      dispatch,
      params: {
        sort_column: 'name',
        display_length: 50
      },
      successCallback: res => {
        resultFetchData(res, val);
        setOnRequest(false);
      },
      failureCallback: handleFailureRequest
    });
  };

  const fetchCurrentClient = () => {
    setOnRequest(true);
    indexClientsRequest({
      dispatch,
      params: {
        filter_by_ids: [currentValueSecondClientId, currentValueClientId],
        sort_column: 'name'
      },
      successCallback: res => fetchAllClients(res.data.data),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchCurrentClient, [currentValueSecondClientId, currentValueClientId]);

  const btnCreateClient = type => {
    dispatch(getClientType(type));
    setNewClient(true);
    setVehicleDisabled(true);
    resetVehicle();
  };

  const btnOnHide = () => setNewClient(false);

  const noDuplicateClients = arr => arr.filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i);

  const selectedContactable = (index, data) => {
    setFieldValue(`${modelKey}[projectContactsAttributes][${index}][contactableType]`, capitalizeFirstLetter(data.contactableType));
  };

  return (
    <>
      {createClient ? (
        <ClientForm
          clientTab
          modelKey={modelKey}
          client={clientForm}
          companyRequiredFieldAttributes={companyRequiredFieldAttributes}
          action="new"
          formSize={formSize}
          formClassName={formClassName}
          formRequest={handleCreateClientRequest}
          onHide={btnOnHide}
          country={user.companyCountry}
        />
      ) : (
        !newClient && (
          <Row>
            <Col md={12}>
              {!newVehicle && (
                <Col md={{ span: 4, offset: 8 }} className="modal-new-form-btn text-right">
                  <Button className="btn mb-n4" variant="primary" onClick={() => btnCreateClient('client1')}>
                    Crear Cliente
                  </Button>
                </Col>
              )}
              <Field name={`${modelKey}[clientId]`}>
                {({ field }) => (
                  <InputRemoteSelect
                    {...field}
                    isClearable
                    label="Cliente"
                    placeholder={placeholderText}
                    isDisabled={clientDisabled}
                    defaultOptions={noDuplicateClients(clients)}
                    value={clients.find(client => client.id === currentValues.clientId)}
                    onChange={data => {
                      if (currentValues.orderType !== 'reception_secure') resetVehicle();
                      dispatch(saveCurrentClient(camelCaseRecursive(data)));
                      handleClientChange(data ? data.id : '');
                    }}
                    request={inputRequestClients}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            {currentClient && Object.keys(currentClient).length !== 0 && (
              <>
                <Col md={12}>
                  <p>{currentClient.nationalIdentification}</p>
                </Col>
                <Col md={12}>
                  <p>{currentClient.cellphone}</p>
                </Col>
                <Col md={12}>
                  <p>{currentClient.email}</p>
                </Col>
                <Col md={12}>
                  <p>{currentClient.address}</p>
                </Col>
                <Can I="update" a="Client">
                  <Col md={12} className="d-flex justify-content-end mt-n2">
                    <Button
                      as={Link}
                      className="mr-n2"
                      to={`/clients/${currentValues.clientId}/edit`}
                      variant="secondary"
                    >
                      Editar Cliente
                    </Button>
                  </Col>
                </Can>
              </>
            )}
            {contactableOptions.length > 0 && (
                <>
                  <Col md={12}>
                    <h4>Notificar a:</h4>
                  </Col>
                  <Col md={12}>
                    <FieldArray name={`${modelKey}[projectContactsAttributes]`}>
                      {({ push }) => (
                        <>
                          {projectContactsAttributes?.map((contact, index) => {
                            return !contact._destroy && (
                              <Row key={`contact${index}`}>
                                <Col xs={10}>
                                  <Field name={`${modelKey}[projectContactsAttributes][${index}][contactableId]`}>
                                    {({ field }) => (
                                      <FormikSelect
                                        {...field}
                                        abbr
                                        placeholder="Seleccione contacto"
                                        options={contactableOptions}
                                        defaultValue={projectContactsAttributes[index].value}
                                        onChange={(data) => {
                                          selectedContactable(index, data);
                                          setFieldValue(field.name, data ? data.value : '');
                                        }}
                                        error={getIn(errors, field.name)}
                                        touched={getIn(touched, field.name)}
                                      />
                                    )}
                                  </Field>
                                </Col>
                                <Col xs={2} className="d-flex justify-content-end">
                                  <Button
                                    variant="link"
                                    onClick={() => {
                                      setFieldValue(`${modelKey}[projectContactsAttributes][${index}][_destroy]`, true);
                                    }}
                                  >
                                    Eliminar
                                  </Button>
                                </Col>
                              </Row>
                            );
                          })}
                          <Button onClick={() => push({ contactableId: '', contactableType: '' })}>
                            Agregar notificado
                          </Button>
                        </>
                      )}
                    </FieldArray>
                  </Col>
                </>
              )
            }
            {currentValues.orderType === 'reception_secure' && (
              <>
                <Col md={12} className={currentClient && Object.keys(currentClient).length !== 0 ? 'mt-2rem' : 'mt-1'}>
                  {!newVehicle && (
                    <Col md={{ span: 4, offset: 8 }} className="modal-new-form-btn text-right">
                      <Button className="btn mt-2" variant="primary" onClick={() => btnCreateClient('client2')}>
                        Crear Cliente
                      </Button>
                    </Col>
                  )}
                  <Field name={`${modelKey}[secondClientId]`}>
                    {({ field }) => (
                      <InputRemoteSelect
                        {...field}
                        isClearable
                        label="Cliente N°2"
                        placeholder={placeholderText}
                        isDisabled={clientDisabled}
                        defaultOptions={noDuplicateClients(clients)}
                        value={clients.find(client => client.id === currentValues.secondClientId)}
                        onChange={data => {
                          dispatch(saveSecondCurrentClient(camelCaseRecursive(data)));
                          setFieldValue(`${modelKey}[secondClientId]`, data ? data.id : '');
                          if (currentValues.orderType === 'reception_secure') {
                            resetVehicle();
                            handleClientChange(data ? data.id : '', false);
                          }
                        }}
                        request={inputRequestClients}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                </Col>
                {currentSecondClient && Object.keys(currentSecondClient).length !== 0 && (
                  <>
                    <Col md={12}>
                      <p>{currentSecondClient.nationalIdentification}</p>
                    </Col>
                    <Col md={12}>
                      <p>{currentSecondClient.cellphone}</p>
                    </Col>
                    <Col md={12}>
                      <p>{currentSecondClient.email}</p>
                    </Col>
                    <Col md={12}>
                      <p>{currentSecondClient.address}</p>
                    </Col>
                    <Col md={12} className="d-flex justify-content-end mt-n2">
                      <Button
                        as={Link}
                        className="mr-n2"
                        to={`/clients/${currentValues.secondClientId}/edit`}
                        variant="secondary"
                      >
                        Editar Cliente
                      </Button>
                    </Col>
                  </>
                )}
              </>
            )}
          </Row>
        )
      )}
    </>
  );
};

export default ClientTab;
