import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { MoreVertical } from 'react-feather';
import { sendAlert } from '../../actions/utils';
import { BasicDropdown, DefaultModal, SimpleCenteredModal } from '../../components';
import { createProjectVersionRequest, createProjectRequest } from '../../requests/projects';
import ProjectSendQuoteForm from './ProjectSendQuoteForm';

const orderTypesOptions = [
  { label: 'Orden con Recepción', value: 'reception' },
  { label: 'Orden con Recepción y Seguro', value: 'reception_secure' }
];

const defaultOrderTypeOption = orderTypesOptions[0];

const ProjectNavbarInfo = ({
  editProjectShow,
  fromMechanicCard,
  fromProjectEdit,
  project,
  setMoreData,
  version,
  handleUpdateRequest,
  onRequest
}) => {
  const {
    id,
    promiseDateFormatted,
    mileageFormatted,
    preInspectionDateFormatted,
    projectDateFormatted,
    projectVersions,
    reviewDateFormatted,
    otStateName,
    deliveredDateFormatted,
    receptionDateFormatted,
    isDeskSale
  } = project;

  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [actionModalConfirm, setActionModalConfirm] = useState(null);
  const [dropdownVersionItems, setDropdownVersionItems] = useState([]);
  const [orderType, setOrderType] = useState(defaultOrderTypeOption);
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessCreateNewOt = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Orden de trabajo creada correctamente' }));
    setDefaultModalShow(false);
    setMoreData(m => !m);
    setOrderType(defaultOrderTypeOption);
  };

  const handleCreateOT = () => {
    const sendParams = {
      created_from_quote: true,
      order_type: orderType.value,
      project: {
        quote_id: id
      }
    };
    createProjectRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessCreateNewOt,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Versión generada correctamente' }));
    setDefaultModalShow(false);
    setMoreData(m => !m);
  };

  const handleNewVersion = () => {
    createProjectVersionRequest(id, {
      dispatch,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureRequest
    });
  };

  const execFunction = action => {
    const functions = {
      handleNewVersion,
      handleCreateOT
    };
    functions[action]();
  };

  const dropdownProjectVersions = () => {
    let versions = [];
    if (projectVersions.length > 0) {
      versions = projectVersions.map(pv => ({
        key: `nn-pv-${pv.versionNumber}`,
        title: `Versión ${pv.versionNumber}`,
        onClick: () => history.push(`/projects/${id}/versions/${pv.versionNumber}`)
      }));
    }
    versions = [
      ...versions,
      {
        key: '1',
        title: `Versión ${projectVersions.length + 1} (actual)`,
        onClick: () => history.push(`/projects/${id}`),
        active: true
      }
    ];
    setDropdownVersionItems(versions);
  };

  const handleCloseDefaultModal = () => {
    setModalTitle('');
    setModalBody('');
    setDefaultModalShow(false);
  };

  const handleClickGenerateVersion = () => {
    setActionModalConfirm('handleNewVersion');
    setModalTitle('Generar Versión');
    setModalBody('¿Estás seguro que deseas generar una nueva versión de esta orden?');
    setDefaultModalShow(true);
  };

  useEffect(dropdownProjectVersions, [projectVersions]);

  return (
    <Row className="mb-3 pt-3 project-section project-show-nav small d-flex align-items-center">
      <Col md={fromMechanicCard || version ? 12 : ''}>
        <Row className="nav-info-section text-uppercase">
          <Col md={6} className="project-info-division">
            <Row>
              {project.orderType === 'reception_secure' && (
                <Col>
                  <strong>Fecha Pre inspección</strong>
                  <p>{preInspectionDateFormatted}</p>
                </Col>
              )}
              <Col>
                <strong>Fecha Ingreso</strong>
                <p>{projectDateFormatted}</p>
              </Col>
              <Col>
                <strong>Fecha de recepción</strong>
                <p>{receptionDateFormatted}</p>
              </Col>
              <Col>
                <strong>Fecha Revisión</strong>
                <p>{reviewDateFormatted}</p>
              </Col>
            </Row>
          </Col>
          <Col md={6} className={`${!version && !fromMechanicCard && !fromProjectEdit ? 'project-info-division' : ''}`}>
            <Row>
              <Col>
                <strong>Fecha estimada de entrega</strong>
                <p>{promiseDateFormatted}</p>
              </Col>
              <Col>
                <strong>Fecha de entrega</strong>
                <p>{deliveredDateFormatted}</p>
              </Col>
              <Col>
                <strong className="d-flex">Kilometraje</strong>
                <p>{mileageFormatted}</p>
              </Col>
              <Col>
                <strong>Estado:</strong> {otStateName}
                <Button
                  variant="success"
                  className="project-show-nav-btn mt-2"
                  onClick={editProjectShow}
                  disabled={project.id === '' || fromProjectEdit || version || fromMechanicCard}
                >
                  Cambiar estado
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      {!fromMechanicCard && !fromProjectEdit && !version && (
        <Col xs={6} md={4}>
          <Row className="d-flex align-items-center">
            <ProjectSendQuoteForm
              id={id}
              version={!!version}
              versionNum={version}
              setMoreData={setMoreData}
              isDeskSale={isDeskSale}
              handleUpdateRequest={handleUpdateRequest}
              promiseDateFormatted={project?.promiseDateFormatted}
              onRequest={onRequest}
            />
            <Col>
              <Button
                block
                disabled={version}
                variant="light-darker"
                className="project-show-nav-btn"
                onClick={handleClickGenerateVersion}
              >
                Generar versión
              </Button>
              <DefaultModal
                title={modalTitle}
                body={modalBody}
                show={defaultModalShow}
                handleClose={handleCloseDefaultModal}
                handleConfirm={() => execFunction(actionModalConfirm)}
                titleBtnClose="Cancelar"
                titleBtnSave="Confirmar"
              />
              <SimpleCenteredModal
                closeButton
                title={modalTitle}
                body={modalBody}
                show={modalShow}
                onHide={() => setModalShow(false)}
                centered={false}
                size="sm"
              />
            </Col>
            <Col xs={1} style={{ paddingRight: 0 }}>
              <BasicDropdown
                small
                right
                noArrow
                variant="link"
                classColor="text-dark"
                titleDrop={<MoreVertical />}
                items={dropdownVersionItems}
              />
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default ProjectNavbarInfo;
