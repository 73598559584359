import React, { useEffect, useState, useContext } from 'react';
import { Nav } from 'react-bootstrap';
import { SimpleCenteredModal } from "../../components/Utils/Modal";
import { ShoppingCart } from 'react-feather';
import { useDispatch } from 'react-redux';
import Cart from './CartIndex';
import { quotationCartProducts } from '../../requests/autoparts';
import { CartContext } from '../../config/cartContext';

const CartButton = ({ hashid }) => {
  const dispatch = useDispatch();
  const { cart, cartEmpty, refreshCart } = useContext(CartContext);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');

  const openCart = () => {
    setModalTitle('Carrito');
    setModalShow(true);
    setModalBody(
        <Cart
          cart={cart}
          hashid = {hashid}
          setModalTitle={setModalTitle}
          setModalBody={setModalBody}
        />
    );
  };
  
  const cartOnHide = () => {
    if (cart) {
      Object.values(cart.quotations).forEach(quotation => {
        if (quotation?.products) {
          const categories = {};
          quotation.products.forEach(p => {
            const categoryId = p.categoryId;
            if (!(categoryId in categories)) categories[categoryId] = [];
            categories[categoryId].push({id: p.id, quantity: p.quantity});
          });
        
          quotationCartProducts(hashid, quotation.ot, {
            dispatch,
            params: { categories },
            failureCallback: () => {}
          });
        }
      });
      // update products quantity
      refreshCart();
    }
    setModalShow(false);
  };
  
  useEffect(refreshCart, []);

  return (
    <>
      <Nav.Link alignRight onClick={openCart} className="ml-lg-4">
        <ShoppingCart />
        {!cartEmpty &&
          <span class="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle" />
        }
      </Nav.Link>
      <SimpleCenteredModal
        title={modalTitle}
        show={modalShow}
        onHide={cartOnHide}
        size="lg"
        body={modalBody}
      />
    </>
  );
};

export default CartButton;
