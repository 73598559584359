import React from 'react';
import DatePicker from 'react-datepicker';
import { es } from 'date-fns/locale';
import { format, parseISO } from 'date-fns';

const InputDate = ({ placeholderText, value, onChange, disabled }) => {
  return (
    <DatePicker
      placeholderText={placeholderText}
      dateFormat="dd/MM/yyyy"
      className="form-control"
      showYearDropdown
      disabled={disabled}
      selected={value ? parseISO(value) : ''}
      onChange={date => {
        const formattedDate = date ? format(date, 'yyyy-MM-dd') : '';
        onChange(formattedDate);
      }}
      locale={es}
    />
  );
};
export default InputDate;
