import React, { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Link, useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { DefaultModal, SimpleCenteredModal } from '../../components';
import { ProgressBar } from '../../components/Utils/Charts/index';
import { sendEmailRequest } from '../../requests/projects';
import { sendAlert } from '../../actions/utils';
import ProjectReceptionForm from './Form/ProjectReceptionForm';
import ProjectSelectChecklistForm from './Form/ProjectSelectChecklistForm';
import { createProjectChecklistRequest } from '../../requests/projectChecklists';
import { reviewTypes } from '../ReviewGroup/Constants';
import { destroyProjectChecklistRequest, updateProjectChecklistRequest } from '../../requests/checklists';
import { camelCase } from '../../services/utils';
import { Can } from '../../config/abilityContext';

const PublicProjectProgressBars = ({
  project,
  id,
  version,
  setMoreData,
  setShowMechanicChecklistAtStart,
  setShowReceptionChecklistAtStart,
  setShowPreInspectionChecklistAtStart,
  showMechanicChecklistAtStart,
  showReceptionChecklistAtStart,
  showPreInspectionChecklistAtStart,
  toggleProgressExpanded,
  fromProjectEdit
}) => {
  const history = useHistory();
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [checklistSelected, setChecklistSelected] = useState({});
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  const { workProgressPercentage, checklistsAttributes, hideChecklistInPublicView = {} } = project;
  const bgcolor = '#5cb85c';
  let reviewType = '';
  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleModalClose = () => {
    reviewType = '';
    setCenterModalShow(false);
    setShowConfirmModal(false);
    setModalBody('');
    setModalTitle('');
  };

  const handleSuccessSendProgressEmail = res => {
    if (res.data?.message) {
      dispatch(
        sendAlert({
          kind: 'success',
          message: res.data?.message
        })
      );
    }
    handleModalClose();
  };

  const handleSendProgressEmail = type => {
    sendEmailRequest({
      id,
      type,
      dispatch,
      successCallback: handleSuccessSendProgressEmail,
      failureCallback: () => {
        handleFailureRequest();
        handleModalClose();
      }
    });
    setModalBody(
      <>
        <h3 className="text-center">Guardando progreso de OT</h3>
        <p className="text-center lead mb-5">
          Espere un momento mientras se notifica al cliente del progreso de su vehículo
        </p>
        <div className="d-flex justify-content-around w-25 mx-auto mb-3">
          <Spinner animation="grow" variant="success" />
          <Spinner animation="grow" variant="success" />
          <Spinner animation="grow" variant="success" />
        </div>
      </>
    );
  };

  const handleSuccessUpdate = (response, oldProjectProgress) => {
    const { projectProgress, progressEmailSent } = camelCaseRecursive(response.data);
    const updateType = oldProjectProgress === projectProgress ? 'actualizado' : 'confirmado';
    dispatch(sendAlert({ kind: 'success', message: `${reviewTypes[reviewType]} ${updateType} con éxito` }));
    setMoreData(m => !m);
    if (projectProgress === 'received') {
      if (progressEmailSent !== 'reception') {
        handleSendProgressEmail('reception_progress');
      } else {
        handleModalClose();
      }
    }
    if (projectProgress === 'reviewed') {
      if (progressEmailSent !== 'mechanic') {
        handleSendProgressEmail('mechanic_progress');
      } else {
        handleModalClose();
      }
    }
  };

  const handleUpdateRequest = async (values, checklist) => {
    const { projectProgress, projectReviewsAttributes, checklistsAttributes } = values.project;
    const params = {};
    params.checklist = { ...checklist };
    params.checklist.promiseDate = values.project.promiseDate;
    params.checklist.mileage = values.project.mileage;
    params.checklist.notes = values.project.notes;
    params.checklist.projectReviewsAttributes = projectReviewsAttributes;
    params.checklist.projectId = id;
    if (params.checklist.checklistType === 'reception') {
      params.checklist.signature = checklistsAttributes[0].signature;
      params.checklist.signatory = checklistsAttributes[0].signatory;
    }
    await updateProjectChecklistRequest(checklist.id, {
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: response => handleSuccessUpdate(response, projectProgress),
      failureCallback: handleFailureRequest
    });
  };

  const handleModalCreate = checkList => {
    reviewType = checkList.checklistType;
    setModalTitle(checkList.checklistName);
    setCenterModalShow(true);
    setModalBody(
      <ProjectReceptionForm
        workOrderModal={version === undefined}
        fromReviewType={checkList.checklistType}
        fromProjectProgressBar
        project={project}
        action="edit"
        submitVariant="success"
        formRequest={values => handleUpdateRequest(values, checkList)}
        isModal
        version={version}
      />
    );
  };

  const handleSuccessCreateProjectChecklist = checklist => {
    setMoreData(m => !m);
    dispatch(sendAlert({ kind: 'success', message: `Se seleccionó checklist con éxito` }));
    handleModalClose();
    history.push(`/projects/${id}/${checklist.checklistType}`);
  };

  const handleCreateProjectChecklistRequest = values => {
    createProjectChecklistRequest(id, {
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: response => handleSuccessCreateProjectChecklist(camelCaseRecursive(response.data))
    });
  };

  const handleModalSelectChecklist = checklistType => {
    setModalTitle(`SELECCIONAR ${reviewTypes[checklistType]}`);
    setCenterModalShow(true);
    setModalBody(
      <ProjectSelectChecklistForm checklistTypeCode={checklistType} formRequest={handleCreateProjectChecklistRequest} />
    );
  };

  const toggle = () => {
    setExpanded(!expanded);
    toggleProgressExpanded();
  };

  const handleStarterModals = () => {
    if (Object.keys(project).length !== 0) {
      if (showMechanicChecklistAtStart) {
        handleModalCreate('mechanic');
        setShowMechanicChecklistAtStart(false);
      } else if (showReceptionChecklistAtStart) {
        handleModalCreate('reception');
        setShowReceptionChecklistAtStart(false);
      } else if (showPreInspectionChecklistAtStart) {
        handleModalCreate('pre_inspection');
        setShowPreInspectionChecklistAtStart(false);
      }
    }
  };

  const handleConfirmDelete = () => {
    destroyProjectChecklistRequest(checklistSelected.id, {
      dispatch,
      successCallback: () => {
        setMoreData(m => !m);
        dispatch(sendAlert({ kind: 'success', message: 'Checklist eliminado con éxito' }));
        setShowConfirmModal(false);
      },
      failureCallback: handleFailureRequest
    });
  };

  const openModalConfirm = data => {
    setChecklistSelected(data);
    setShowConfirmModal(true);
  };

  useLayoutEffect(handleStarterModals, [project]);

  return (
    <>
      <Row>
        {fromProjectEdit && (
          <Col md={12}>
            <strong>Progreso</strong>
          </Col>
        )}
        {(expanded || !fromProjectEdit) && (
          <>
            {checklistsAttributes?.map(checklistType => {
              const isHidden = hideChecklistInPublicView[camelCase(checklistType.checklistType)];

              if (isHidden) return null;

              return (
                <Col md={12} className="mt-3" key={checklistType.checklistId}>
                  <strong className="d-inline-block mr-2 mb-2">Checklist {checklistType.name}</strong>
                  {checklistType.checklistId ? (
                    <Button variant="link" onClick={() => handleModalCreate(checklistType)}>
                      Ver Checklist
                    </Button>
                  ) : (
                    <></>
                  )}
                  <ProgressBar bgcolor={bgcolor} completed={`${checklistType.progress}%`} />
                </Col>
              );
            })}

            {!project.isQuote && (
              <Col md={12} className="my-3">
                <strong className="d-inline-block mr-2 mb-2">Completitud de Trabajos</strong>
                <ProgressBar bgcolor={bgcolor} completed={workProgressPercentage} />
              </Col>
            )}
          </>
        )}
      </Row>
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={centerModalShow}
        onHide={handleModalClose}
        closeButton
      />
      <DefaultModal
        title="¿Está seguro que quiere eliminar este checklist?"
        body="Si confirma, se limpian los datos del checklist y no podrá recuperarlos"
        show={showConfirmModal}
        handleClose={handleModalClose}
        handleConfirm={handleConfirmDelete}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
      {fromProjectEdit && (
        <Row>
          <button className="w-100 btn btn-light" onClick={toggle}>
            {expanded ? <ChevronUp size={15} /> : <ChevronDown size={15} />}
          </button>
        </Row>
      )}
    </>
  );
};

export default PublicProjectProgressBars;
