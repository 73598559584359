import React from 'react';
import { useField } from 'formik';
import { Form, InputGroup } from 'react-bootstrap';
import InputNumber from './InputNumber';
import * as validations from '../../../utils/validations';

const NumberInputField = ({
  label,
  required,
  disabled,
  decimalScale = 0,
  leftAddon,
  className,
  parentClass,
  onChange,
  ...props
}) => {
  const validate = required && validations.required();
  const [field, meta] = useField({ validate, ...props });
  const { error, touched } = meta;

  const handleChange = value => {
    field.onChange({
      target: {
        name: props.name,
        value
      }
    });
    if (onChange) onChange(value);
  };

  return (
    <Form.Group className={`${disabled ? 'disabled' : ''} ${error && touched ? 'is-invalid' : ''} ${parentClass}`}>
      {label && (
        <Form.Label className="font-weight-bolder">
          {label} {required && <abbr className="text-danger ml-1">*</abbr>}
        </Form.Label>
      )}
      <InputGroup>
        {leftAddon && (
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">{leftAddon}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <InputNumber
          {...field}
          decimalScale={decimalScale}
          disabled={disabled}
          onChange={handleChange}
          className={className}
        />
      </InputGroup>
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    </Form.Group>
  );
};

export default NumberInputField;
