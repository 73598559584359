import React from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Minus } from 'react-feather';
import { getIn } from 'formik';

const PercentageReviewButtons = ({ isDisabled, field, handleIsOpen, handleButtonsAction, errors, touched }) => {
  const handleClick = value => {
    if (isDisabled) return;
    if (field.value === value) {
      handleButtonsAction('');
    } else {
      handleButtonsAction(value);
    }
    handleIsOpen(false);
  };

  return (
    <ToggleButtonGroup
      {...field}
      onChange={value => handleButtonsAction(value)}
      disabled={isDisabled}
      error={getIn(errors, field.name)}
      touched={getIn(touched, field.name)}
    >
      <ToggleButton
        disabled={isDisabled}
        className="btn"
        value="not_apply"
        variant="outline-dark"
        style={{ marginLeft: '0.5rem', borderRadius: '3px' }}
        onClick={() => handleClick('not_apply')}
      >
        <Minus /> No aplica
      </ToggleButton>
      <ToggleButton
        disabled={isDisabled}
        className="btn"
        value="percentage_0"
        variant="outline-danger"
        style={{ marginLeft: '0.5rem', borderRadius: '3px' }}
        onClick={() => handleClick('percentage_0')}
      >
        <Minus /> 0%
      </ToggleButton>
      <ToggleButton
        disabled={isDisabled}
        className="btn"
        value="percentage_25"
        variant="outline-danger"
        style={{ marginLeft: '0.5rem', borderRadius: '3px' }}
        onClick={() => handleClick('percentage_25')}
      >
        <Minus /> 25%
      </ToggleButton>
      <ToggleButton
        disabled={isDisabled}
        className="btn"
        value="percentage_50"
        variant="outline-secondary"
        style={{ marginLeft: '0.5rem', borderRadius: '3px' }}
        onClick={() => handleClick('percentage_50')}
      >
        <Minus /> 50%
      </ToggleButton>
      <ToggleButton
        disabled={isDisabled}
        className="btn"
        value="percentage_75"
        variant="outline-success"
        style={{ marginLeft: '0.5rem', borderRadius: '3px' }}
        onClick={() => handleClick('percentage_75')}
      >
        <Minus /> 75%
      </ToggleButton>
      <ToggleButton
        disabled={isDisabled}
        className="btn"
        variant="outline-success"
        value="percentage_100"
        style={{ marginLeft: '0.5rem', borderRadius: '3px' }}
        onClick={() => handleClick('percentage_100')}
      >
        <Minus /> 100%
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default PercentageReviewButtons;
