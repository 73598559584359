import React from 'react';
import { Form, Formik } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import { useGetProject } from '../../../hooks/useProjects';
import { useGetVehicle } from '../../../hooks/useVehicles';
import DateTimeInputField from '../../../components/Utils/Input/DateTimeInputField';
import NumberInputField from '../../../components/Utils/Input/NumberInputField';
import SelectInputField from '../../../components/Utils/Input/SelectInputField';
import MaintenanceReminder from './MaintenanceReminder';
import { otQuoteStateTypes, otStateTypes } from './FormOptions';

const ProjectChangeStatusForm = ({ projectId, onSave, isQuote }) => {
  const { project, isLoading: isLoadingProject } = useGetProject(projectId, 'edit');
  const { vehicle, isLoading: isLoadingVehicle } = useGetVehicle(project?.vehicleId, 'edit');

  const handleChangeOnMaintenanceReminder = (e, setFieldValue) => {
    if (e.target.checked) {
      setFieldValue('project[vehicleAttributes][maintenanceReminderDate]', project?.nextMaintenanceReminderDate);
    } else {
      setFieldValue('project[vehicleAttributes][maintenanceReminderDate]', vehicle?.maintenanceReminderDate);
    }
    setFieldValue('project[rememberMaintenance]', e.target.checked);
  };

  if (isLoadingProject || isLoadingVehicle) return null;

  const initialValues = {
    project: {
      promiseDate: project.promiseDate,
      mileage: project.mileage,
      otState: project.otState,
      rememberMaintenance: project.rememberMaintenance,
      vehicleAttributes: vehicle?.id
        ? {
            id: vehicle.id,
            maintenanceReminderDate: vehicle.maintenanceReminderDate
          }
        : null
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSave}>
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <Row>
            <Col md={12}>
              <DateTimeInputField name="project[promiseDate]" label="Fecha estimada de entrega" required={!isQuote} />
            </Col>
            <Col md={12}>
              <NumberInputField
                name="project[mileage]"
                required={!isQuote && !project.isDeskSell}
                label={`Kilometraje${project.isDeskSell ? ' (opcional)' : ''}`}
              />
            </Col>
            <Col md={12}>
              <SelectInputField
                name="project[otState]"
                label="Estado OT"
                placeholder="Seleccionar Estado OT"
                options={project?.isQuote ? otQuoteStateTypes : otStateTypes}
                required
              />
            </Col>
            {values.project.vehicleAttributes && (
              <MaintenanceReminder
                values={values}
                project={project}
                handleChangeOnMaintenanceReminder={handleChangeOnMaintenanceReminder}
                setFieldValue={setFieldValue}
              />
            )}
            <Col md={12}>
              <Button block disabled={isSubmitting} type="submit" variant="success">
                {isSubmitting ? 'Guardando...' : 'Guardar'}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default ProjectChangeStatusForm;
