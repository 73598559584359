import React, { useEffect, useState } from 'react';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { sendAlert } from '../../actions/utils';
import { InputRemoteSelect } from '../../components';
import { indexVehiclesRequest } from '../../requests/vehicles';

const VehicleSelect = ({ vehicleDefault, clientId, formRequest }) => {
  const [vehicles, setVehicles] = useState([]);
  const [vehicle, setVehicle] = useState({ label: '', value: '' });
  const dispatch = useDispatch();
  console.log(vehicleDefault);

  const resultFetchData = response => {
    const result = response.data.data;
    const tempArray = result.map(element => {
      return {
        ...element,
        value: element.id
      };
    });
    setVehicles(camelCaseRecursive(tempArray));
    setVehicle({ label: vehicleDefault?.label, value: vehicleDefault?.id });
    return tempArray;
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const fetchVehicles = (inputValue, callback) => {
    indexVehiclesRequest(clientId, {
      dispatch,
      params: {
        query: inputValue,
        for_selector: true,
        sort_column: 'plate',
        sort_direction: 'asc',
        display_length: 50
      },
      successCallback: response => callback(resultFetchData(response)),
      failureCallback: handleFailureRequest
    });
  };

  const handleChange = value => {
    setVehicle(value);
  };

  const saveVehicle = () => {
    formRequest({ vehicle_id: vehicle.value });
  };

  useEffect(() => {
    if (vehicleDefault) {
      setVehicle({ label: vehicleDefault.name, value: vehicleDefault.id });
    }
  }, [vehicleDefault]);

  return (
    <div>
      <InputRemoteSelect
        label="Vihículo"
        placeholder="Seleccionar Vehículo"
        options={vehicles}
        value={vehicles.find(data => data.id === vehicle?.value)}
        request={fetchVehicles}
        onChange={data => handleChange(data)}
      />
      <Button variant="success" className="mt-3" onClick={saveVehicle}>
        Guardar
      </Button>
    </div>
  );
};

export default VehicleSelect;
