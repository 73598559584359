import React, { useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import ComponentDataTable from '../../components/Utils/DataTable';
import { indexProjectsPaymentsRequest, debounceIndexProjectsPaymentsRequest } from '../../requests/projectPayments';
import { sendAlert } from '../../actions/utils';
import downloadFile from '../../services/utils';
import {FormikSelect} from "../../components";

const ProjectPaymentDataTable = ({ columns, moreData, setMoreData }) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [projectPayments, setProjectPayments] = useState([]);
  const [onRequest, setOnRequest] = useState(false);
  const [queries, setQueries] = useState({});
  const [optionPaymentGateway, setOptionPaymentGateway] = useState('');
  const {
    user: { companyAttributes }
  } = useSelector(state => state.auth);

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
  };

  const handleSuccessRequest = response => {
    const responseProjectPayments = response.data.data;
    const { amount: responseAmount } = response.data.metadata;
    setProjectPayments(responseProjectPayments);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    debounceIndexProjectsPaymentsRequest({
      dispatch,
      params: {
        ...params,
        with_payment_gateway: optionPaymentGateway
      },
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'project_code':
        return { sort_column: 'project_id' };
      default:
        return { sort_column: 'project_id' };
    }
  };

  const handleSuccessDownload = response => downloadFile(response);

  const downloadIndex = (format, params) => {
    indexProjectsPaymentsRequest({
      dispatch,
      params: { ...params, display_length: 100000 },
      format,
      successCallback: handleSuccessDownload,
      failureCallback: handleFailureRequest
    });
  };

  const requestPaymentGateway = [
    { value: true, label: 'Con pasarela' },
    { value: false, label: 'Sin pasarela' }
  ];

  return (
    <>
      <Row className="justify-content-end">
        <Col md={2}>
          <Button variant="primary" block onClick={() => downloadIndex('xlsx', queries)}>
            Exportar
          </Button>
        </Col>
      </Row>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(companyAttributes)}
        handleSortCase={sortColumnCase}
        data={projectPayments}
        totalRows={amount}
        moreData={moreData}
        withDate
        resourceRequest={response => {
          setQueries({ ...response, with_payment_gateway: optionPaymentGateway });
          if (!onRequest) handleIndexRequest(response);
        }}
        firstCustomMediumSearch={<div></div>}
        secondCustomMediumSearch={
          <FormikSelect
            isClearable
            placeholder="Pago con pasarela"
            options={requestPaymentGateway}
            defaultValue={requestPaymentGateway.find(option => option.value === optionPaymentGateway)}
            onChange={data => {
              setOptionPaymentGateway(data ? data.value : '');
              setMoreData(!moreData);
            }}
          />
        }
      />
    </>
  );
};

export default ProjectPaymentDataTable;
