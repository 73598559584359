import AwesomeDebouncePromise from 'awesome-debounce-promise';
import AutopartsService from '../services/AutopartsService';

export const autopartsCategories = ({ dispatch, failureCallback, successCallback }) => {
  AutopartsService.request('get', `/categories`, {
    dispatch,
    failureCallback,
    successCallback
  });
};

export const quotation = (customerId, ot, { dispatch, failureCallback, successCallback }) => {
  AutopartsService.request('get', `/quotation/customer/${customerId}/ot/${ot}`, {
    dispatch,
    failureCallback,
    successCallback
  });
};

export const quotationCartProducts = (customerId, ot, { dispatch, params, failureCallback, successCallback }) => {
  AutopartsService.request('put', `/quotation/customer/${customerId}/ot/${ot}/quotationCart`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const getCart = (customerId, { dispatch, failureCallback, successCallback }) => {
  AutopartsService.request('get', `/cart/customer/${customerId}`, {
    dispatch,
    failureCallback,
    successCallback
  });
};

export const addProductsToCart = (customerId, { dispatch, params, failureCallback, successCallback }) => {
  AutopartsService.request('post', `/cart/customer/${customerId}/products`, {
    params,
    dispatch,
    failureCallback,
    successCallback
  });
};

export const addQuotationToCart = (quotationId, { dispatch, failureCallback, successCallback }) => {
  AutopartsService.request('put', `/quotation/${quotationId}/addToCart`, {
    dispatch,
    failureCallback,
    successCallback
  });
};

export const removeQuotationFromCart = (quotationId, { dispatch, failureCallback, successCallback }) => {
  AutopartsService.request('put', `/quotation/${quotationId}/removeFromCart`, {
    dispatch,
    failureCallback,
    successCallback
  });
};

export const deleteProductFromCart = (customerId, productId, { dispatch, failureCallback, successCallback }) => {
  AutopartsService.request('put', `/cart/customer/${customerId}/product/${productId}/delete`, {
    dispatch,
    failureCallback,
    successCallback
  });
};

export const emptyCart = (customerId, { dispatch, failureCallback, successCallback }) => {
  AutopartsService.request('put', `/cart/customer/${customerId}/empty`, {
    dispatch,
    failureCallback,
    successCallback
  });
};

export const newOrder = (customerId, { dispatch, failureCallback, successCallback }) => {
  AutopartsService.request('post', `/order/cart/customer/${customerId}`, {
    dispatch,
    failureCallback,
    successCallback
  });
};

export const debounceIndexAutopartsRequest = AwesomeDebouncePromise(autopartsCategories, 300);
