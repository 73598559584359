import React from 'react';
import { withFormik, Form, Field, getIn } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import { FormikInput, FormikPassword } from '../../components';
import * as Yup from 'yup';

const ProjectPasswordToDelete = props => {
  const { errors, touched, isSubmitting, handleModalClose, projectId } = props;

  return (
    <Form>
      <Row>
        <Col md={12}>
          <Field name="id">
            {({ field }) => (
              <FormikInput
                {...field}
                value={projectId}
                inputType="hidden"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          <Field name="passwordToDelete">
            {({ field }) => (
              <FormikPassword
                {...field}
                abbr
                label="Contraseña"
                placeholder="Contraseña"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mb-2">
        <Col md={{ span: 3, offset: 6 }}>
          <Button className="btn" variant="primary" block onClick={handleModalClose} disabled={isSubmitting}>
            Cancelar
          </Button>
        </Col>
        <Col md={3}>
          <Button type="submit" variant="success" className="float-right mb-2" block disabled={isSubmitting}>
            {isSubmitting ? 'Enviando...' : 'Confirmar'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  return { id: props.projectId, passwordToDelete: '' };
};

const validationSchema = Yup.object().shape({
  passwordToDelete: Yup.string().required('Debes ingresar la contraseña')
});

const handleSubmit = (values, { props, setSubmitting }) => {
  setSubmitting(true);
  const { formRequest } = props;
  formRequest(values, setSubmitting);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: false
})(ProjectPasswordToDelete);
