import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import ProjectImages from '../ProjectImages';
import { LinkBtn } from '../../../components';
import getImage from '../../../utils/getImage';
import PublicProjectNavbarInfo from '../../PublicProject/PublicProjectNavbarInfo';
import PublicProductShow from '../../PublicProject/PublicProductShow';
import PublicProjectProgressBars from '../PublicProjectProgressBars';
import PublicProjectClient from '../PublicProjectClient';
import PublicProjectClient2 from '../PublicProjectClient2';
import PublicProjectVehicle from '../PublicProjectVehicle';

const ProjectPublicContent = ({
  project,
  userId,
  currentVersion,
  version,
  setMoreData,
  setShowMechanicChecklistAtStart,
  setShowReceptionChecklistAtStart,
  showMechanicChecklistAtStart,
  showReceptionChecklistAtStart,
  receptionImages,
  mechanicImages,
  preInspectionImages,
  quoteImages,
  privateProject,
  token,
  preview = false
}) => {
  const { id } = project;

  const images = (imagesInfo = []) => {
    if (imagesInfo.length) {
      return imagesInfo.map(image => ({
        original: image.fileUrl,
        thumbnail: getImage(image.key, 100, 100, 'inside')
      }));
    }
    return [];
  };

  return (
    <Container className="pb-5">
      <Row className="my-4">
        <Col>
          <h2 className="text-uppercase">Orden de Trabajo</h2>
        </Col>
        {!!userId && (
          <Col md={2}>
            <LinkBtn variant="light-darker" block to={`/projects/${id}/versions/${version}`} className="cancel">
              Volver
            </LinkBtn>
          </Col>
        )}
      </Row>
      <Row>
        <Col className="project-section public">
          <PublicProjectNavbarInfo project={project} currentVersion={currentVersion} />
        </Col>
      </Row>
      <Row>
        <Col className="project-section public">
          <PublicProjectClient version project={project} />
          {project.secondClient && <PublicProjectClient2 client={project.secondClient} />}
        </Col>
        <Col className="project-section public">
          <PublicProjectVehicle version project={project} />
        </Col>
      </Row>
      <Row>
        <Col md={3} className="project-section public">
          <PublicProjectProgressBars
            project={privateProject}
            id={id}
            version={version}
            versionNum={project ? project.versionNumber : ''}
            setMoreData={setMoreData}
            setShowMechanicChecklistAtStart={setShowMechanicChecklistAtStart}
            setShowReceptionChecklistAtStart={setShowReceptionChecklistAtStart}
            showMechanicChecklistAtStart={showMechanicChecklistAtStart}
            showReceptionChecklistAtStart={showReceptionChecklistAtStart}
          />
        </Col>
        <Col md={3} className="project-section public">
          <Row>
            <Col md={10}>
              <strong>Notas</strong>
            </Col>
            <Col md={12} className="pt-2">
              <pre>{project.notes}</pre>
            </Col>
          </Row>
        </Col>
        <Col md={6} className="project-section public">
          <Row>
            <Col md={12}>
              <strong>Imágenes</strong>
            </Col>
            {(project.quoteId || project.isQuote) && (
              <Col className="gallery-small-box">
                <ProjectImages images={images(quoteImages)} title="Fotos Presupuesto" />
              </Col>
            )}
            {project.orderType === 'reception_secure' && (
              <Col className="gallery-small-box">
                <ProjectImages images={images(preInspectionImages)} title="Checklist de Pre Inspección" />
              </Col>
            )}
            <Col className="gallery-small-box">
              <ProjectImages images={images(receptionImages)} title="Checklist recepción" />
            </Col>
            <Col className="gallery-small-box">
              <ProjectImages images={images(mechanicImages)} title="Checklist mecánico" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="project-section public">
          <PublicProductShow
            project={project}
            preview={preview}
            token={token}
            version={version}
            setMoreData={setMoreData}
          />
        </Col>
      </Row>
    </Container>
  );
};
export default ProjectPublicContent;
