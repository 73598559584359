import React from 'react';
import { Field, Form, Formik, getIn } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import { BasicTextArea } from '../../../components';
import { useGetProject } from '../../../hooks/useProjects';

const ProjectNotesForm = ({projectId, onSave}) => {
  const { project, isLoading } = useGetProject(projectId, 'edit');
  const initialValues = {
    project: {
      notes: project.notes
    }
  }
  if(isLoading) return null;
  return (
    <Formik initialValues={initialValues} onSubmit={onSave}>
      {({ isSubmitting }) => (
        <Form>
          <Row>
            <Col md={12}>
              <Field name="project[notes]">
                {({ field, form }) => (
                  <BasicTextArea
                    {...field}
                    row="6"
                    label="Motivo / Notas"
                    error={getIn(form.errors, field.name)}
                    touched={getIn(form.touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Button block disabled={isSubmitting} type="submit" variant="success">
                {isSubmitting ? 'Guardando...' : 'Guardar'}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
export default ProjectNotesForm;