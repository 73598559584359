import React, { useEffect, useState } from 'react';
import { format, parseISO, isValid, parse } from 'date-fns';
import { useField } from 'formik';
import { Form } from 'react-bootstrap';
import InputDate from './InputDate';
import InputTime from './InputTime';
import * as validations from '../../../utils/validations';

const DateTimeInputField = ({ label, required, disabled, placeholderText, ...props }) => {
  const validate = required && validations.required();
  const [field, meta] = useField({ validate, ...props });
  const { error, touched } = meta;

  const parseDate = field.value ? isValid(parseISO(field.value)) ? parseISO(field.value) : parseISO(parse(field.value, 'yyyy-MM-dd HH:mm:ss XX', new Date()).toISOString()) : null;

  const [date, setDate] = useState(field.value ? format(parseDate, 'yyyy-MM-dd') : '');
  const [time, setTime] = useState(field.value ? format(parseDate, 'HH:mm') : '');

  const onChange = () => {
    let formattedDateTime = null;
    if (date && time) {
      formattedDateTime = new Date(`${date}T${time}`).toISOString();
    }
    field.onChange({
      target: {
        name: props.name,
        value: formattedDateTime
      }
    });
  };

  const changeDate = value => {
    setDate(value);
    if (!time) {
      setTime('00:00');
    }
  };

  const changeTime = value => {
    setTime(value);
  };

  useEffect(() => {
    onChange();
  }, [date, time]);

  return (
    <Form.Group className={`${disabled ? 'disabled' : ''} ${error && touched ? 'is-invalid' : ''}`}>
      {label && (
        <Form.Label className="font-weight-bolder">
          {label} {required && <abbr className="text-danger ml-1">*</abbr>}
        </Form.Label>
      )}
      <div style={{ display: 'flex' }}>
        <InputDate
          disabled={disabled}
          placeholderText="Fecha"
          onChange={changeDate}
          value={date}
        />
        <InputTime
          disabled={disabled}
          placeholderText="Hora"
          onChange={changeTime}
          value={time}
        />
      </div>
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    </Form.Group>
  );
};

export default DateTimeInputField;
