import React from 'react';
import {useField} from "formik";
import { Form } from 'react-bootstrap';
import InputDate from './InputDate';
import * as validations from '../../../utils/validations';

const DateInputField = ({ label, required, disabled, placeholderText, ...props }) => {
  const validate = required && validations.required()
  const [field, meta] = useField({ validate, ...props });
  const { error, touched } = meta;

  const changeValue = value => {
    field.onChange({
      target: {
        name: props.name,
        value
      }
    });
  };

  return (
    <Form.Group className={`${disabled ? 'disabled' : ''} ${error && touched ? 'is-invalid' : ''}`}>
      {label && (
        <Form.Label className="font-weight-bolder">
          {label} {required && <abbr className="text-danger ml-1">*</abbr>}
        </Form.Label>
      )}
      <InputDate
        {...field}
        {...props}
        disabled={disabled}
        placeholderText={placeholderText}
        onChange={changeValue}
      />
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    </Form.Group>
  );
}

export default DateInputField;
