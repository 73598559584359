/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {Col, Container} from 'react-bootstrap';
import ProjectAutopartsProductCard from './ProjectAutopartsProductCard';

const ProjectAutopartsOfferCategory = ({
  category,
  addProductToSelectedProducts
}) => {
  const [categoryOpened, setCategoryOpened] = useState(true);

  useEffect(() => setCategoryOpened(category.products.length <= 12), []);

  return (
    <>
      <Col
        md={12}
        className='p-2'
        style={{ backgroundColor: '#dee2e6', fontWeight: 'bold' }}
        onClick={() => setCategoryOpened(!categoryOpened)}
      >
        {category.categoryName + ' (' + category.products.length + ')'}
      </Col>
      {categoryOpened &&
        <Container className='d-flex justify-content-center flex-wrap'>
          {category.products.length ? category.products.map(product => {
            if (!product) return undefined;
            return (
              <Col md={4} className="px-1">
                <ProjectAutopartsProductCard
                  product={product}
                  formRequest={(values) => addProductToSelectedProducts(category.categoryId, product, values)}
                />
              </Col>
            )
          }) : (<span className="pt-2" style={{ color: 'red' }}>No se han encontrado productos aún.</span>)}
        </Container>
      }
    </>
  );
};

export default ProjectAutopartsOfferCategory;
