import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RefreshCcw } from 'react-feather';
import { useHistory } from 'react-router-dom';
import snakecaseKeys from 'snakecase-keys';
import { updateUserRequest } from '../../requests/user';
import { sendAlert } from '../../actions/utils';
import { validateToken } from '../../actions/auth';

const BranchOfficesSelect = ({setBranchOfficeSelect}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(state => state.auth);
  const { id, branchOffices, currentBranchOffice, roleIds } = user;
  const noCurrentBranchOffices = currentBranchOffice
    ? branchOffices.filter(branchOffice => branchOffice.value !== currentBranchOffice.value)
    : branchOffices;
  const selectedBranchOfficeLabel = currentBranchOffice?.label || 'Seleccionar Sucursal';
  const [showNoCurrentBranchOffices, setShowNoCurrentBranchOffices] = useState(false);

  const handleSuccessUpdate = () => {
    dispatch(validateToken());
    dispatch(sendAlert({ kind: 'success', message: 'Sucursal actualizada con éxito' }));
    setShowNoCurrentBranchOffices(false);
    if (roleIds.includes(1) || roleIds.includes(7)) {
      history.push('/dashboard');
    } else {
      history.push('/home');
    }
  };

  const handleFailureUpdate = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setShowNoCurrentBranchOffices(false);
  };

  const handleClick = (branchOfficeId) => {
    const params = { user: { id, currentBranchOfficeId: branchOfficeId } };
    const sendParams = snakecaseKeys(params);
    updateUserRequest(id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureUpdate
    });
  };

  return (
    <>
      <div
        style={{ fontWeight: 'bold' }}
        onClick={() => {
          setBranchOfficeSelect(true);
          setShowNoCurrentBranchOffices(!showNoCurrentBranchOffices);
        }}
      >
        {selectedBranchOfficeLabel}
        {noCurrentBranchOffices.length ? 
          <RefreshCcw className='ml-2' size={12}/> : undefined
        }
      </div>
      {noCurrentBranchOffices.length && showNoCurrentBranchOffices ?
        <div className='bg-white p-2 border' style={{ width: '150px', position: 'absolute', left: '-140px', top: '30px' }}>
          {noCurrentBranchOffices.map(branchOffice => (
            <span className='d-block mt-1 cursor-pointer' onClick={() => handleClick(branchOffice.value)}>
              {branchOffice.label}
            </span>
          ))}
        </div> : undefined
      }
    </>
  );
};

export default BranchOfficesSelect;
