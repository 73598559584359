import { format } from 'date-fns';
import ArCountryFactory from "./ArCountryFactory";
import ClCountryFactory from "./ClCountryFactory";
import CrCountryFactory from "./CrCountryFactory";
import EcCountryFactory from "./EcCountryFactory";
import PeCountryFactory from "./PeCountryFactory";

export const uuid = () => Math.floor(Math.random() * Date.now()).toString();

export function debounce(func, delay) {
  let debounceTimer;
  return function(...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
}

export const getCountryFactory = (country) => {
  if (country === 'ar') {
    return new ArCountryFactory()
  }
  if (country === 'cr') {
    return new CrCountryFactory()
  }
  if (country === 'ec') {
    return new EcCountryFactory()
  }
  if (country === 'pe') {
    return new PeCountryFactory()
  }
  return new ClCountryFactory()
}

export const formatAmount = (amount) =>
  Number(amount).toLocaleString('es-CL', { maximumFractionDigits: 0 });

export const dateFormat = (date) => {
  if (!date) return '';
  return format(new Date(date), 'dd/MM/yyyy');
}

