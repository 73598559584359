import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { Edit, MoreVertical } from 'react-feather';
import moment from 'moment';
import ReactSelect from 'react-select';
import { sendAlert } from '../../actions/utils';
import { BasicDropdown, DefaultModal, SimpleCenteredModal } from '../../components';
import { createProjectVersionRequest, createProjectRequest, updateProjectRequest } from '../../requests/projects';
import CalendarEventForm from './Form/CalendarEventForm';
import { createCalendarEventsRequest } from '../../requests/calendarEvents';
import ProjectSendQuoteForm from './ProjectSendQuoteForm';
import ProjectMileage from './ProjectMileage';

const orderTypesOptions = [
  { label: 'Orden con Recepción', value: 'reception' },
  { label: 'Orden con Recepción y Seguro', value: 'reception_secure' }
];

const defaultOrderTypeOption = orderTypesOptions[0];

const QuoteNavbarInfo = ({
  fromMechanicCard,
  fromProjectEdit,
  project,
  setMoreData,
  version,
  handleUpdateRequest,
  onRequest
}) => {
  const {
    id,
    promiseDateFormatted,
    mileageFormatted,
    preInspectionDateFormatted,
    projectDateFormatted,
    projectVersions,
    reviewDateFormatted,
    deliveredDateFormatted,
    receptionDateFormatted
  } = project;

  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [actionModalConfirm, setActionModalConfirm] = useState(null);
  const [dropdownVersionItems, setDropdownVersionItems] = useState([]);
  const [orderType, setOrderType] = useState(defaultOrderTypeOption);
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessCreateNewOt = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Orden de trabajo creada correctamente' }));
    setDefaultModalShow(false);
    setMoreData(m => !m);
    setOrderType(defaultOrderTypeOption);
  };

  const handleCreateOT = () => {
    const sendParams = {
      created_from_quote: true,
      order_type: orderType.value,
      project: {
        quote_id: id
      }
    };
    createProjectRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessCreateNewOt,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessCreate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Versión generada correctamente' }));
    setDefaultModalShow(false);
    setMoreData(m => !m);
  };

  const handleNewVersion = () => {
    createProjectVersionRequest(id, {
      dispatch,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureRequest
    });
  };

  const execFunction = action => {
    const functions = {
      handleNewVersion,
      handleCreateOT
    };
    functions[action]();
  };

  const dropdownProjectVersions = () => {
    let versions = [];
    if (projectVersions.length > 0) {
      versions = projectVersions.map(pv => ({
        key: `nn-pv-${pv.versionNumber}`,
        title: `Versión ${pv.versionNumber}`,
        onClick: () => history.push(`/projects/${id}/versions/${pv.versionNumber}`)
      }));
    }
    versions = [
      ...versions,
      {
        key: '1',
        title: `Versión ${projectVersions.length + 1} (actual)`,
        onClick: () => history.push(`/projects/${id}`),
        active: true
      }
    ];
    setDropdownVersionItems(versions);
  };

  const handleCloseDefaultModal = () => {
    setModalTitle('');
    setModalBody('');
    setDefaultModalShow(false);
  };

  const handleClickGenerateVersion = () => {
    setActionModalConfirm('handleNewVersion');
    setModalTitle('Generar Versión');
    setModalBody('¿Estás seguro que deseas generar una nueva versión de esta orden?');
    setDefaultModalShow(true);
  };

  const handleClickCreateOtFromQuote = () => {
    setActionModalConfirm('handleCreateOT');
    setModalTitle('Crear OT a partir de presupuesto');
    setModalBody(() => (
      <div>
        <Row>
          <Col md={12}>
            <label>
              <b>Tipo de Orden</b>
            </label>
            <ReactSelect
              options={orderTypesOptions}
              placeholder="Tipo de Orden"
              defaultValue={orderType}
              name="order-type"
              onChange={option => setOrderType(option)}
              isSearchable
            />
          </Col>
        </Row>
        <p className="mt-3">¿Estás seguro que deseas generar una Orden de Trabajo a partir de éste presupuesto?</p>
      </div>
    ));
    setDefaultModalShow(true);
  };

  const handleCreateCalendarEventRequest = params => {
    const baseDate = moment(params.calendarEvent.dateSelected, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const startDate = moment(
      `${baseDate}T${moment(params.calendarEvent.startDate).format('HH:mm')}`,
      'YYYY-MM-DDTHH:mm'
    ).toDate();
    const endDate = moment(
      `${baseDate}T${moment(params.calendarEvent.endDate).format('HH:mm')}`,
      'YYYY-MM-DDTHH:mm'
    ).toDate();
    const calendar_event = {
      order_type: params.calendarEvent.orderType,
      start_date: startDate,
      end_date: endDate,
      quote_id: id,
      client_id: project.clientId,
      notes: project.notes,
      vehicle_id: project.vehicleId,
      promise_date: project.promiseDate,
      seller_id: project.seller?.id,
      price_discount: project.priceDiscount,
      percentage_discount: project.percentageDiscount
    };
    createCalendarEventsRequest({
      dispatch,
      params: { calendar_event },
      formData: true,
      successCallback: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Cita agendada con éxito' }));
        setModalShow(false);
        setMoreData(m => !m);
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleClickCreateCalendarEventFromQuote = () => {
    setModalTitle('Crear cita a partir de presupuesto');
    setModalBody(() => (
      <>
        {project.quoteHasOt && (
          <div className="text-danger" style={{ fontSize: '11px' }}>
            Ya existe una cita asociada a este presupuesto
          </div>
        )}
        <CalendarEventForm
          calendarEvent={{ startDate: moment().toDate() }}
          formRequest={handleCreateCalendarEventRequest}
          submitVariant="success"
          handleClose={handleCloseDefaultModal}
        />
      </>
    ));
    setModalShow(true);
  };

  const handleFailureUpdate = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response.data.message }));
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Dato actualizado con éxito' }));
    setModalShow(false);
    setMoreData(m => !m);
  };

  const saveMileage = values => {
    const data = { project: { ...values } };
    updateProjectRequest(project.id, {
      dispatch,
      params: data,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureUpdate
    });
  };

  const handleEditMileage = () => {
    setModalTitle('Editar Kilometraje');
    setModalBody(() => <ProjectMileage project={project} formRequest={saveMileage} />);
    setModalShow(true);
  };

  useEffect(dropdownProjectVersions, [projectVersions]);

  return (
    <Row className="mb-3 pt-3 project-section project-show-nav small d-flex align-items-center">
      <Col md={fromMechanicCard || version ? 12 : ''}>
        <Row className="nav-info-section text-uppercase">
          <Col md={6} className="project-info-division">
            <Row>
              {project.orderType === 'reception_secure' && (
                <Col>
                  <strong>Fecha Pre inspección</strong>
                  <p>{preInspectionDateFormatted}</p>
                </Col>
              )}
              <Col>
                <strong>Fecha Ingreso</strong>
                <p>{projectDateFormatted}</p>
              </Col>
              <Col>
                <strong>Fecha de recepción</strong>
                <p>{receptionDateFormatted}</p>
              </Col>
              <Col>
                <strong>Fecha Revisión</strong>
                <p>{reviewDateFormatted}</p>
              </Col>
            </Row>
          </Col>
          <Col md={6} className={`${!version && !fromMechanicCard && !fromProjectEdit ? 'project-info-division' : ''}`}>
            <Row>
              <Col>
                <strong>Fecha estimada de entrega</strong>
                <p>{promiseDateFormatted}</p>
              </Col>
              <Col>
                <strong>Fecha de entrega</strong>
                <p>{deliveredDateFormatted}</p>
              </Col>
              <Col>
                <strong className="d-flex">
                  Kilometraje{' '}
                  <Edit className="ml-1" width="15px" height="15px" role="button" onClick={handleEditMileage} />
                </strong>
                <p>{mileageFormatted}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      {!fromMechanicCard && !fromProjectEdit && !version && (
        <Col xs={6} md={4}>
          <Row className="d-flex align-items-center">
            <ProjectSendQuoteForm
              id={id}
              version={!!version}
              versionNum={version}
              setMoreData={setMoreData}
              handleUpdateRequest={handleUpdateRequest}
              promiseDateFormatted={project?.promiseDateFormatted}
              onRequest={onRequest}
              isQuote
            />
            <Col>
              <Button
                block
                disabled={version}
                variant="light-darker"
                className="project-show-nav-btn"
                onClick={handleClickCreateOtFromQuote}
              >
                Generar OT
              </Button>
              <Button
                block
                disabled={version}
                variant="light-darker"
                className="project-show-nav-btn"
                onClick={handleClickCreateCalendarEventFromQuote}
              >
                Crear Cita
              </Button>
              <Button
                block
                disabled={version}
                variant="light-darker"
                className="project-show-nav-btn"
                onClick={handleClickGenerateVersion}
              >
                Generar versión
              </Button>
              <DefaultModal
                title={modalTitle}
                body={modalBody}
                show={defaultModalShow}
                handleClose={handleCloseDefaultModal}
                handleConfirm={() => execFunction(actionModalConfirm)}
                titleBtnClose="Cancelar"
                titleBtnSave="Confirmar"
              />
              <SimpleCenteredModal
                closeButton
                title={modalTitle}
                body={modalBody}
                show={modalShow}
                onHide={() => setModalShow(false)}
                centered={false}
                size="sm"
              />
            </Col>
            <Col xs={1} style={{ paddingRight: 0 }}>
              <BasicDropdown
                small
                right
                noArrow
                variant="link"
                classColor="text-dark"
                titleDrop={<MoreVertical />}
                items={dropdownVersionItems}
              />
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default QuoteNavbarInfo;
