/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import memoize from 'memoize-one';
import { Link } from 'react-router-dom';
import { BasicActionBtns } from '../../components';
import { SimpleCenteredModal } from '../../components';
import { updateProductRequest } from '../../requests/products';
import { useDispatch } from 'react-redux';
import { sendAlert } from '../../actions/utils';

const columns = memoize(clickHandler => {
  const CostUnitCell = (row) => {
    const [modalShow, setModalShow] = useState(false);
    const [newPrice, setNewPrice] = useState('');
    const dispatch = useDispatch();

    const handleClick = () => {
      setNewPrice(row.sale_price ? Math.round(row.sale_price).toString() : '');
      setModalShow(true);
    };

    const handleSave = () => {
      const productData = {
        product: {
          sale_price: parseInt(newPrice, 10)
        }
      };

      updateProductRequest(row.id, {
        dispatch,
        params: productData,
        formData: true,
        successCallback: () => {
          dispatch(sendAlert({ kind: 'success', message: 'Precio actualizado con éxito' }));
          setModalShow(false);
          if (clickHandler) {
            clickHandler(row, 'refresh');
          }
        },
        failureCallback: (error) => {
          console.error('Update failed:', error?.response?.data?.message);
        }
      });
    };

    const modalBody = (
      <div className="p-3">
        <div className="form-group mb-4 text-center">
          <label htmlFor="price-input" className="w-100">Nuevo Precio</label>
          <div className="d-flex justify-content-center">
            <input
              id="price-input"
              type="number"
              className="form-control text-center"
              style={{ maxWidth: '150px' }}
              value={newPrice}
              onChange={(e) => setNewPrice(e.target.value)}
              placeholder="Ingrese el nuevo precio"
              step="1"
              min="0"
              onKeyDown={(e) => {
                if (e.key === '.' || e.key === ',') {
                  e.preventDefault();
                }
              }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center" style={{ gap: '1rem' }}>
          <button className="btn btn-secondary px-4" onClick={() => setModalShow(false)}>
            Cancelar
          </button>
          <button className="btn btn-primary px-4" onClick={handleSave}>
            Guardar
          </button>
        </div>
      </div>
    );

    return (
      <>
        <div 
          className="text-primary" 
          role="button" 
          onClick={handleClick}
          style={{ cursor: 'pointer' }}
        >
          {row.parsed_sale_price || `$${(parseFloat(row.sale_price) || 0).toLocaleString('es-CL')}`}
        </div>
        <SimpleCenteredModal
          size="sm"
          title="Cambiar Precio"
          body={modalBody}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </>
    );
  };

  return [
    { name: 'NOMBRE VENTA', selector: 'sale_name', sortable: true, grow: '2' },
    {
      name: 'CATEGORÍA',
      selector: 'category_name',
      sortable: true,
      grow: '2'
    },
    {
      name: 'SUBCATEGORÍA',
      selector: 'product_category_name',
      sortable: true,
      grow: '2'
    },
    { name: 'UNIDAD', selector: 'translated_unit', sortable: true, grow: '1' },
    {
      name: 'PRECIO',
      selector: 'sale_price',
      cell: (row) => <CostUnitCell {...row} />,
      sortable: true,
      grow: '1'
    },
    {
      cell: item => (
        <>
          <BasicActionBtns item={item} clickHandler={clickHandler} showClick editClick />
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
      grow: '1',
      minWidth: '140px'
    }
  ];
});

export default columns;
