import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { sendAlert } from '../../actions/utils';
import { showProjectVersionRequest } from '../../requests/projects';
import ProjectShow from '../Project/ProjectShow';
import ProjectVersionShow from '../Project/ProjectVersionShow';

const basicProjectVersion = {
  object: {},
  versionNumber: ''
};

const ProjectVersionIndex = ({ match }) => {
  const { id: projectId, version } = match.params;
  const [projectVersion, setProjectVersion] = useState(basicProjectVersion);
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessShow = response => {
    setProjectVersion(camelCaseRecursive(response.data));
  };

  const fetchProjectVersion = () => {
    showProjectVersionRequest(projectId, version, {
      dispatch,
      successCallback: handleSuccessShow,
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchProjectVersion, []);

  return <ProjectVersionShow match={match} projectVersion={projectVersion} />;
};

export default ProjectVersionIndex;
