import { Table } from 'react-bootstrap';
import React, { Fragment, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ShoppingCart } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { publicUpdateProjectWorksApprovalStatusRequest } from '../../../requests/projects';
import { SimpleCenteredModal } from '../../../components';
import { sendAlert } from '../../../actions/utils';
import { useListProjectWorksByProject } from '../../../hooks/useProjectWorks';
import PurchaseReceivedModalForm from '../../ProjectDetail/PurchaseReceivedModalForm';

const ProjectQuoteTable = ({ project, handleAdvancedConfiguration, token, version }) => {
  const { projectWorks, isLoading } = useListProjectWorksByProject(project.id, 'item_list');
  const [modalShow, setModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const approvementRef = useRef(null);

  if (isLoading) return null;

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const projectDetailBody = vProjectDetail => {
    const {
      category,
      costFormatted,
      totalCostFormatted,
      marginFormatted,
      unitPriceFormatted,
      priceFormatted,
      productCategory,
      productId,
      productName,
      quantity,
      priceDiscountFormatted,
      unitPrice
    } = vProjectDetail;

    const openModal = () => {
      setModalBody(
        <PurchaseReceivedModalForm
          onHide={handleModalClose}
          submitVariant="success"
          projectDetailId={vProjectDetail?.id}
          projectId={project?.id}
        />
      );
      setModalShow(true);
    };
    return (
      <>
        <td>
          {vProjectDetail?.hasPurchaseReceived && (
            <>
              <ShoppingCart
                data-tip
                data-for={`purchase-received-${vProjectDetail?.id}`}
                className="mr-1"
                width="15px"
                height="15px"
                color={vProjectDetail?.purchaseReceivedDate ? '#5CC689' : 'black'}
                onClick={openModal}
              />
              <ReactTooltip id={`purchase-received-${vProjectDetail?.id}`} place="top">
                {vProjectDetail?.purchaseReceivedDate ? 'Compra recibida' : 'Compra no recibida'}
              </ReactTooltip>
            </>
          )}
          {category?.name}
        </td>
        {!project?.company?.hideSubcategory && <td>{productCategory?.name}</td>}
        <td className={productName ? '' : 'font-italic text-muted'}>
          <Link className="text-uppercase" to={`/products/${productId || ''}`}>
            {productName || 'Ítem sin Nombre'}
          </Link>
        </td>
        <td>
          <div className="text-primary" role="button" onClick={() => handleAdvancedConfiguration(vProjectDetail)}>
            {costFormatted}
          </div>
        </td>
        <td>{quantity || 0}</td>
        <td>{totalCostFormatted}</td>
        <td>{unitPriceFormatted}</td>
        <td className="text-warning">{priceDiscountFormatted}</td>
        <td>{priceFormatted}</td>
        <td>{unitPrice > 0 ? marginFormatted : 'No aplica'}</td>
      </>
    );
  };

  const projectWorksBody = () =>
    projectWorks.map((projectWork, indexWork) => {
      const projectDetails = projectWork.projectDetails.filter(projectDetail => !projectDetail.hidden);
      return (
        <Fragment key={`project-work-${indexWork.toString()}`}>
          {projectDetails.map((projectDetail, indexDetail) =>
            indexDetail === 0 ? (
              <>
                <tr>
                  <td className="font-weight-bold" style={{ color: '#5e99b8' }}>
                    {projectWork.name}
                  </td>
                </tr>
                <tr key={`project-detail-${indexDetail.toString()}`}>{projectDetailBody(projectDetail)}</tr>
              </>
            ) : (
              <tr key={`project-detail-${indexDetail.toString()}`}>{projectDetailBody(projectDetail)}</tr>
            )
          )}
        </Fragment>
      );
    });

  const handleSubmit = values => {
    if (values.projectWorksApprovals.filter(v => v.value === 'pending').length) {
      dispatch(sendAlert({ kind: 'error', message: 'Se deben seleccionar todos.' }));
    } else {
      const updatedValues = values.projectWorksApprovals.filter(v => !v.disabled);
      updatedValues.forEach(uv => {
        if (uv.value === 'doubt' && (!uv.notes || uv.notes === '') && values.all.notes && values.all.notes !== '') {
          uv.notes = values.all.notes;
        }
      });
      publicUpdateProjectWorksApprovalStatusRequest(token, version, {
        dispatch,
        params: { project: { project_works_approval_status: updatedValues } },
        successCallback: response => {
          dispatch(sendAlert({ kind: 'success', message: 'Enviado con éxito' }));
          history.push(response.data.path);
        },
        failureCallback: handleFailureRequest
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={{}}
        onSubmit={values => {
          handleSubmit(values);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form ref={approvementRef}>
            <Table responsive borderless hover>
              <thead className="border-top-borderless-table">
                <tr className="header-border-bottom">
                  <th>Categoría</th>
                  {!project.company.hideSubcategory && <th>Subcategoría</th>}
                  <th>Ítem</th>
                  <th>Costo Neto Unit</th>
                  <th>Cantidad</th>
                  <th>Costo Neto Total</th>
                  <th>P. Venta Unit</th>
                  <th>Descuento</th>
                  <th>P. Venta Total</th>
                  <th>Margen</th>
                </tr>
              </thead>
              <tbody>{projectWorksBody(values, setFieldValue)}</tbody>
            </Table>
          </Form>
        )}
      </Formik>
      <SimpleCenteredModal
        size="lg"
        title="Registro de compra recibida"
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
        closeButton
      />
    </>
  );
};
export default ProjectQuoteTable;
