import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';

import AutopartSelect from '../../../components/SharedFields/AutopartSelect';
import ProjectAutopartInput from '../ProjectAutoparts/ProjectAutopartInput';
import { uuid } from '../../../utils/utils';

const ProjectAutopartTab = ({ projectAutopartsAttributes, modelKey, indexProjectReview, onHide, setMoreData }) => {
  const { setFieldValue } = useFormikContext();

  const handleAddAutopartClick = ({ autopart = null }) => {
    const newValue = projectAutopartsAttributes.filter(x => {return x.categoryId === autopart.value});
    if (newValue.length === 0) {
      const newValues = [...projectAutopartsAttributes, { 
        name: autopart.label,
        categoryId: autopart.value,
        parentCategoryId: autopart.parentId,
        quantity: 1,
        key: uuid() 
      }];
      setFieldValue(modelKey, newValues);
    }
  };

  const addProjectAutopartInputs = () => {
    const mapResults = projectAutopartsAttributes.map((projectAutopart, index) => {
      if (projectAutopart._destroy) {
        return undefined;
      }
      return (
        <ProjectAutopartInput
          key={`project-autopart-input-${indexProjectReview.toString()}-${index.toString()}`}
          projectAutopartsAttributes={projectAutopartsAttributes}
          projectAutopart={projectAutopart}
          index={index}
          modelKey={modelKey}
          firstMapIndex={indexProjectReview}
        />
      );
    });

    return mapResults;
  };

  return (
    <Row>
      <Col md={12} className="mb-1">
        <Row>
          <Field>
            {({ field }) => (
              <AutopartSelect
                {...field}
                onAutopartChange={data => {
                  handleAddAutopartClick({ autopart: camelCaseRecursive(data) });
                }}
              />
            )}
          </Field>
        </Row>
      </Col>
      <Col md={12} style={{ padding: '0 30px' }}>
        {addProjectAutopartInputs()}
      </Col>
      <Col md={12}>
        <Button variant="success" onClick={onHide} block className="mb-3">
          Listo
        </Button>
      </Col>
    </Row>
  );
};

export default ProjectAutopartTab;
