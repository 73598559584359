import axios from "axios";
import qs from 'qs';
import snakeCaseKeys from 'snakecase-keys';
import API_CONFIG from '../config/configurations';

const { url } = API_CONFIG;

export const headers = formData => {
  if (localStorage.jwt) {
    if (formData) {
      return {
        Authorization: `Bearer ${localStorage.jwt}`
      };
    }
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.jwt}`
    };
  }
  if (formData) {
    return {};
  }
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
};

export default class API {
  static genericErrorMessage(status) {
    return status === 404 ? 'Recurso no encontrado' : 'Intentelo más tarde';
  }

  static get(route, params, baseUrl = url('v2')) {
    let url = baseUrl + route;
    if (params) {
      const paramsSnakeCase = snakeCaseKeys(params, { exclude: ['_destroy'] });
      const queryParams = qs.stringify(paramsSnakeCase, {arrayFormat: 'brackets', encode: false });
      url += `?${queryParams}`;
    }
    return axios({
      method: 'get',
      url,
      headers: headers()
    });
  }

  static post(route, params = {}, baseUrl = url('v2')) {
    return axios({
      method: 'post',
      url: baseUrl + route,
      cache: 'no-cache',
      data: JSON.stringify(snakeCaseKeys(params, { exclude: ['_destroy'] })),
      headers: headers()
    });
  }

  static async put(route, params = {}, baseUrl = url('v2')) {
    return axios({
      method: 'put',
      url: baseUrl + route,
      cache: 'no-cache',
      data: JSON.stringify(snakeCaseKeys(params, { exclude: ['_destroy'] })),
      headers: headers()
    });
  }

  static delete(route, baseUrl = url('v2')) {
    return axios({
      method: 'delete',
      url: baseUrl + route,
      headers: headers()
    });
  }
}
