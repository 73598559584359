import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import { Button, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import '../../services/yupCustomMethods';
import {
  FormikInput,
  FormikCheckBox,
  BasicTextArea,
  FormikSelect,
  BrandModelSelect,
  CategoryProductCategorySelect,
  InputRemoteSelect
} from '../../components';
import { debounceIndexProvidersRequest } from '../../requests/providers';
import productUnits from './ProductUnits';

const ProductForm = ({ action, errors, onHide, product, setFieldValue, submitVariant, touched, values }) => {
  const dispatch = useDispatch();
  const btnMessage = action === 'new' ? 'Crear' : 'Actualizar';
  const { unit } = product;
  const [isInventaryable, setIsInventaryable] = useState(true);

  const fetchProviders = (inputValue, callback) => {
    debounceIndexProvidersRequest({
      dispatch,
      params: {
        query: inputValue,
        for_selector: true,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 20
      },
      successCallback: response => callback(response.data.data)
    });
  };

  useEffect(() => {
    setIsInventaryable(values.product.inventoryable);
  }, [values]);

  return (
    <Form>
      <Row>
        <CategoryProductCategorySelect
          categoryAbbr
          productCategoryAbbr
          modelKey="product"
          currentModel={product}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          isModal
        />
      </Row>
      <Row>
        <Col md="6">
          <Field name="product[code]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre / Código"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md="6">
          <Field name="product[saleName]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Nombre Venta"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Field name="product[referenceCodes]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Códigos de Referencia"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          {isInventaryable && (
            <Row>
              <Col md="12">
                <Field name="product[sku]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      label="Sku"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </Row>
          )}
        </Col>
        <Col md="6" className="d-flex flex-column"> 
          <div className="d-flex justify-content-between"> 
            <Col md="4" className="checkbox-align-center">
              <Field name="product[inventoryable]">
                {({ field }) => (
                  <FormikCheckBox {...field} field={field} label="Inventariable" checked={isInventaryable} custom />
                )}
              </Field>
            </Col>
            <Col md="4" className="checkbox-align-center">
              <Field name="product[workforce]">
                {({ field }) => <FormikCheckBox {...field} field={field} label="Mano de Obra" custom />}
              </Field>
            </Col>
            <Col md="4" className="checkbox-align-center">
              <Field name="product[active]">
                {({ field }) => <FormikCheckBox {...field} field={field} label="Activo" custom />}
              </Field>
            </Col>
          </div>

          
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Field name="product[providerId]">
            {({ field }) => (
              <InputRemoteSelect
                {...field}
                isClearable
                label="Proveedor"
                values={values.product}
                model={[product, 'provider']}
                placeholder="Seleccionar Proveedor"
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                request={fetchProviders}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name="product[providerCode]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Código Proveedor"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <BrandModelSelect
          modelKey="product"
          dispatch={dispatch}
          currentModel={product}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
      </Row>
      <hr />
      <Row>
        <Col>
          <Field name="product[unit]">
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Unidad de medida"
                placeholder="Seleccionar Unidad de medida"
                options={productUnits}
                defaultValue={unit}
                onChange={data => setFieldValue(field.name, data ? data.value : '')}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name="product[defaultQty]">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="number"
                label={getIn(values, "product[unit]") !== "piece" ? "Cantidad por defecto" : "N° piezas"}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name="product[cost]">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="number"
                label="Costo Unitario"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name="product[salePrice]">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="number"
                label="Precio Venta"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name="product[maxSalePrice]">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="number"
                label="Precio Máximo de Venta"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col>
          <Field name="product[description]">
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Descripción"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mb-3">
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { product } = props;
  return {
    product
  };
};

const validationSchema = Yup.object().shape({
  product: Yup.object().shape({
    categoryId: Yup.string().required('Debes seleccionar una categoría'),
    code: Yup.string().required('Debes ingresar un código'),
    cost: Yup.number().nullable(),
    description: Yup.string().nullable(),
    referenceCodes: Yup.string().nullable(),
    inventoryable: Yup.boolean(),
    providerCode: Yup.string().nullable(),
    salePrice: Yup.number().nullable(),
    maxSalePrice: Yup.number().nullable(),
    sku: Yup.string().when('inventoryable', {
      is: true,
      then: Yup.string().required('Ítem debe tener SKU')
    }),
    productCategoryId: Yup.string().required('Debes seleccionar una subcategoría'),
    providerId: Yup.string().nullable(),
    vehicleBrandId: Yup.string().nullable(),
    vehicleModelId: Yup.string().nullable()
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ProductForm);
