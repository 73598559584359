import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { Edit, RefreshCcw } from 'react-feather';
import ProjectClientDetails from './ProjectClientDetails';
import { SimpleCenteredModal } from '../../components/Utils/Modal';
import ClientForm from '../Client/ClientForm';
import { sendAlert } from '../../actions/utils';
import { Can } from '../../config/abilityContext';
import ClientSelect from '../Client/ClientSelect';
import { useGetClient, useUpdateClient } from '../../hooks/useClients';
import { useReFetchProject, useUpdateProject } from '../../hooks/useProjects';

const ProjectClient = ({ companyRequiredFieldAttributes, project, version }) => {
  const { id: projectId, clientId, client: projectClient } = project;
  const { user } = useSelector(state => state.auth);
  const { client: clientEdit, reFetch: reFetchClient } = useGetClient(clientId, 'edit');
  const { update: updateClient } = useUpdateClient(clientId);
  const { update: updateProject } = useUpdateProject(projectId);
  const reFetchProject = useReFetchProject(projectId?.toString());
  const dispatch = useDispatch();
  const history = useHistory();

  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
  };

  const handleUpdateClienteRequest = values => {
    const sendParams = snakeCaseKeys(values, { exclude: ['_destroy'] });
    updateClient(sendParams, {
      onSuccess: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Cliente actualizado con éxito' }));
        reFetchClient();
        handleModalClose();
      },
      onError: error => {
        const { response } = error;
        dispatch(sendAlert({ kind: 'error', message: response.data.message }));
      }
    });
  };

  const handleChangeClienteRequest = values => {
    const data = { project: { client_id: values.client_id } };
    updateProject(data, {
      onSuccess: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Cliente actualizado con éxito' }));
        reFetchProject();
        handleModalClose();
      },
      onError: error => {
        const { response } = error;
        dispatch(sendAlert({ kind: 'error', message: response.data.message }));
      }
    });
  };

  const handleEditClient = () => {
    setModalBody(
      <ClientForm
        client={clientEdit}
        action="edit"
        companyRequiredFieldAttributes={companyRequiredFieldAttributes}
        formRequest={handleUpdateClienteRequest}
        editFromProject
        country={user.companyCountry}
      />
    );
    setModalShow(true);
  };

  const handleChangeClient = () => {
    setModalBody(<ClientSelect clientDefault={projectClient} formRequest={handleChangeClienteRequest} />);
    setModalShow(true);
  };

  const handleRedirectToClient = () => {
    history.push(`/clients/${clientId}`);
  };

  return (
    <>
      <Row>
        <Col md="11" className="justify-content-end">
          <Row>
            <Col md={6}>
              <strong>Cliente</strong>
            </Col>
            <Col md={6}>
              <strong>Datos de Contacto</strong>
            </Col>
          </Row>
        </Col>
        <Can I="update" a="Client">
          {!version && (
            <div className="d-flex">
              {clientEdit && Object.keys(clientEdit).length !== 0 ? (
                <Button variant="link" className="btn-sm text-dark float-right px-0" onClick={handleEditClient}>
                  <Edit width="15px" height="15px" />
                </Button>
              ) : (
                <Button
                  variant="link"
                  className="btn-sm text-dark float-right px-0"
                  disabled
                  title="No client data available"
                >
                  <Edit width="15px" height="15px" />
                </Button>
              )}
              <Button variant="link" className="btn-sm text-dark float-right pr-0" onClick={handleChangeClient}>
                <RefreshCcw width="15px" height="15px" />
              </Button>
            </div>
          )}
        </Can>
      </Row>
      <div role="button" onClick={handleRedirectToClient} onKeyPress={handleRedirectToClient} tabIndex={0}>
        <ProjectClientDetails clientId={clientId} />
      </div>
      <SimpleCenteredModal
        closeButton
        title="EDITAR CLIENTE"
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
      />
    </>
  );
};

export default ProjectClient;
