import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Table, Image } from 'react-bootstrap';
import { Trash, CheckCircle } from 'react-feather';
import { withFormik, Form, Field, FieldArray, getIn, useFormikContext } from 'formik';
import { FormikInput } from '../../../components';
import ImageGallery from 'react-image-gallery';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { SimpleCenteredModal } from '../../../components/Utils/Modal';
import { uuid } from '../../../utils/utils';
import getImage from '../../../utils/getImage';
import AutopartSelect from '../../../components/SharedFields/AutopartSelect';
import SelectedAutopartsModal from './SelectedAutopartsModal'

const ProjectAutopartsListEdit = ({ project, offeredCategories, inCart, addToCart, removeFromCart }) => {
  const {errors, setFieldValue, touched, values} = useFormikContext();
  const { projectAutopartsAttributes } = project;
  const modelKey = 'project[projectAutopartsAttributes]';
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);

  const handleModalClose = () => {
    setModalTitle('');
    setModalBody('');
    setModalShow(false);
  };

  const updateSelectedProducts = (index, autopart) => {
    setFieldValue(`${modelKey}[${index}][selectedProducts]`, autopart.selectedProducts);
    recalculatePrice(autopart);
    handleModalClose();
  };

  const handleModalOpen = (autopart, index) => {
    if (autopart.selectedProducts.length) {
      setModalTitle(autopart.name);
      setModalBody(
        <SelectedAutopartsModal
          autopart={autopart}
          onSubmit={() => updateSelectedProducts(index, autopart)}
        />
      );
      setModalShow(true);
    }
  };

  const handleImgClick = (imagesInfo, name) => {   
    const images = imagesInfo.map(image => {
      return {
        original: getImage(image.key, 800, 800, 'inside'),
        thumbnail: getImage(image.key, 100, 100, 'inside')
      };
    }); 
    setModalBody(<ImageGallery items={images} showPlayButton={false} showFullscreenButton={false} lazyLoad />);
    setModalShow(true);
    setModalTitle(`IMÁGENES DE ${name.toUpperCase()}`);
  };

  const handleRemoveBtn = (autopart, index) => {
    autopart._destroy = true;
    setFieldValue(`${modelKey}[${index}]._destroy`, autopart._destroy);
    recalculateTotalPrice();
  };
  
  const handleAddAutopartClick = ({ autopart = null }) => {
    const projectAutoparts = getIn(values, modelKey);
    const newValue = projectAutoparts.filter(x => {return x.categoryId === autopart.value && !x._destroy});
    if (newValue.length === 0) {
      projectAutoparts.push({
        name: autopart.label,
        categoryId: autopart.value,
        parentCategoryId: autopart.parentId,
        quantity: 1,
        purchasedQuantity: 0,
        receivedQuantity: 0,
        selectedProducts: [],
        imagesInfo: [],
        key: uuid(),
        new: true
      });
      setFieldValue(modelKey, projectAutoparts);
    }
  };

  const recalculateTotalPrice = () => {
    const recalculatedPrice = getIn(values, modelKey).reduce((total, autopart) => {
      return total + (!autopart._destroy ? autopart.total : 0);
    }, 0);
    setTotalPrice(recalculatedPrice);
  };

  const recalculatePrice = (autopart) => {
    autopart.total = autopart.selectedProducts.reduce((acc, curr) => {
      return acc + (curr?.inCart ? curr.inCart * (curr?.price ?? 0) : 0);
    }, 0);
    recalculateTotalPrice();
  };

  useEffect(() => {
    const fetchSelectedProducts = async () => {
      let totalCounter = 0;
      await offeredCategories.forEach(cat => {
        const projectAutopartsAttribute = projectAutopartsAttributes.find(paa => paa.categoryId === cat.categoryId);
        const autopartTotal = cat.products.reduce((acc, curr) => {
          return acc + (curr?.inCart ? curr.inCart * (curr?.price ?? 0) : 0);
        }, 0);
        if (projectAutopartsAttribute) {
          projectAutopartsAttribute.selectedProducts = cat.products ?? [];
          projectAutopartsAttribute.total = autopartTotal;
        } else if (autopartTotal > 0) {
          projectAutopartsAttributes.push({
            name: cat.categoryName,
            categoryId: cat.categoryId,
            parentCategoryId: cat.parentCategoryId,
            quantity: 1,
            purchasedQuantity: 0,
            receivedQuantity: 0,
            selectedProducts: cat.products ?? [],
            total: autopartTotal,
            imagesInfo: [],
            key: uuid(),
            new: true
          });
        }
        totalCounter += autopartTotal;
      });
      setTotalPrice(totalCounter);
      setFieldValue(modelKey, projectAutopartsAttributes);
    };
    fetchSelectedProducts();
  }, [offeredCategories, projectAutopartsAttributes, setFieldValue]);

  return (
    <Form className="w-100">
      <strong className='h2'>Lista de repuestos</strong>
      <Button className="px-5 float-right" type="submit">
        Guardar
      </Button>
      {inCart ? (
        <Button variant='danger' className="float-right mr-3" onClick={removeFromCart}>
          Quitar OT del carrito
        </Button>
      ) : (getIn(values, modelKey).length ? (
        <Button className="float-right mr-3" onClick={addToCart}>
          Agregar OT al carrito
        </Button>
      ) : undefined)}
      {totalPrice > 0 && <strong className='h2 float-right mr-3'>Total: ${totalPrice.toLocaleString('es-CL')}</strong>}
      {getIn(values, modelKey).length ? (
      <Table responsive className="mt-2 mb-0">
        <thead>
          <tr>
            <th className='col-2'>Repuesto</th>
            <th className='col-5'>Notas</th>
            <th className='col-1'>Imágenes</th>
            <th className='col-1'>Cantidad</th>
            <th className='col-1'>Cantidad comprada</th>
            <th className='col-1'>Cantidad recibida</th>
            <th className='col-1'>Seleccionados</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <FieldArray name={modelKey}>
            {({ push }) => (
              <>
                {getIn(values, modelKey).map((autopart, index) => {
                  if (autopart._destroy) {
                    return undefined;
                  }
                  return (<tr key={`project-autopart-input-${index.toString()}`}>
                    <td>
                      {autopart.name}                      
                    </td>
                    <td>
                      <Field name={`${modelKey}[${index}][notes]`}>
                        {({field}) => (
                          <FormikInput
                            {...field}
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                            margin='mb-0'
                          />
                        )}
                      </Field>
                    </td>
                    <td className='text-center'>
                      {autopart.imagesInfo.length ? (
                          <Image 
                            src={autopart.imagesInfo[0].fileUrl}
                            style={{ maxHeight: '50px', cursor: 'pointer' }}
                            onClick={() => handleImgClick(autopart.imagesInfo, autopart.name)}
                            fluid 
                          />
                      ) : undefined}
                    </td>
                    <td>
                      <Field name={`${modelKey}[${index}][quantity]`}>
                        {({field}) => (
                          <FormikInput
                            {...field}
                            inputType="number"
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                            margin='mb-0'
                          />
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name={`${modelKey}[${index}][purchasedQuantity]`}>
                        {({field}) => (
                          <FormikInput
                            {...field}
                            inputType="number"
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                            margin='mb-0'
                          />
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name={`${modelKey}[${index}][receivedQuantity]`}>
                        {({field}) => (
                          <FormikInput
                            {...field}
                            inputType="number"
                            error={getIn(errors, field.name)}
                            touched={getIn(touched, field.name)}
                            margin='mb-0'
                          />
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name={`${modelKey}[${index}][selectedProducts]`}>
                        {({field}) => {
                          const count = field.value?.reduce((acc, curr) => {
                            return acc + (curr?.inCart ?? 0);
                          }, 0);
                          return (
                            <>
                              <Button
                                variant={count > 0 ? 'success' : 'outline-dark'}
                                onClick={() => {if (count > 0) handleModalOpen(autopart, index)}}
                              >
                                {count ?? 0}
                              </Button>
                              {count > 0 && 
                                <strong className='ml-1'>
                                  ${field.value?.reduce((acc, curr) => {
                                    return acc + (curr?.inCart ? curr.inCart * (curr?.price ?? 0) : 0);
                                  }, 0).toLocaleString('es-CL')}
                                </strong>
                              }
                            </>
                          )
                        }}
                      </Field>
                    </td>
                    <td>
                      {autopart.purchasedQuantity === autopart.receivedQuantity &&
                        autopart.purchasedQuantity > 0 &&
                        <CheckCircle
                          color="green"
                          size={16}
                        />
                      }
                      {(autopart.purchasedQuantity !== autopart.receivedQuantity ||
                        autopart.purchasedQuantity === 0) &&
                        <Trash
                          color="red"
                          style={{float: 'right'}}
                          className="clickable"
                          size={16}
                          onClick={() => handleRemoveBtn(autopart, index)}
                        />
                      }
                    </td>
                  </tr>);
                })}
              </>
            )}
          </FieldArray>
        </tbody>
      </Table>
      ) : undefined}
      <Row className='mt-2 justify-content-center'>
        <Col md={1}>
          <strong style={{ color: '#00517B' }}>AGREGAR:</strong>
        </Col>
        <Col md={8} className="d-flex">
          <AutopartSelect
            onAutopartChange={data => {
              handleAddAutopartClick({ autopart: camelCaseRecursive(data) });
            }}
          />      
        </Col>
      </Row>
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
        closeButton
      />
    </Form>
  );
};

const setInitialValues = ({ project }) => {
  return { project };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  enableReinitialize: true,
  validateOnChange: false
})(ProjectAutopartsListEdit);
