import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Dropdown, Row, Table } from 'react-bootstrap';
import { Download, Trash } from 'react-feather';
import snakeCaseKeys from 'snakecase-keys';
import { DefaultModal, SimpleCenteredModal } from '../../../components';
import AddProjectInvoice from './AddProjectInvoice';
import AssociateProjectInvoice from './AssociateProjectInvoice';
import { sendAlert } from '../../../actions/utils';
import { deleteProjectInvoiceRequest, getDocumentRequest } from '../../../requests/projectInvoices';
import downloadFile from '../../../services/utils';
import CreditNoteToDelete from './CreditNoteToDelete';
import ConfirmRequest from './ConfirmRequest';
import { useListProjectInvoicesByProject } from '../../../hooks/useProjectInvoices';
import { useReFetchProjectPayments } from '../../../hooks/useProjectPayments';
import { useReFetchProject } from '../../../hooks/useProjects';

const ProjectInvoicesIndex = ({ project, version }) => {
  const { id: projectId } = project;
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const { projectInvoices, reFetch: reFetchProjectInvoices, isLoading } = useListProjectInvoicesByProject(projectId);
  const reFetchProjectPayments = useReFetchProjectPayments(projectId);
  const reFetchProject = useReFetchProject(projectId);
  const dispatch = useDispatch();

  const handleModalClose = () => {
    setModalBody('');
    setModalShow(false);
    setModalTitle('');
    setDefaultModalShow(false);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessDownload = response => {
    downloadFile(response);
  };

  const handleDownloadDocument = (invoiceId, format) => {
    getDocumentRequest(projectId, invoiceId, {
      format,
      dispatch,
      successCallback: handleSuccessDownload,
      failureCallback: handleFailureRequest
    });
  };

  const handleReFetch = () => {
    reFetchProjectInvoices();
    reFetchProjectPayments();
    reFetchProject();
  };

  const handleSuccessDelete = response => {
    const { id, invoice_number: invoiceNumber, project_code: projectCode, json_response: jsonResponse } = response.data;
    handleReFetch();
    handleModalClose();
    if (invoiceNumber) {
      dispatch(
        sendAlert({
          kind: 'success',
          message: 'Documento eliminado con éxito'
        })
      );
      handleDownloadDocument(id, 'pdf');
    } else {
      const errorMessages = {
        'does not have numbers available, check sii caf':
          'No hay números de folio disponibles, por favor contacte a soporte.',
        'The sii caf used is expired, you must request and upload a new one':
          'El SII CAF utilizado está vencido, debe solicitar y subir uno nuevo.',
        'This document type has the following client attributes required: city,code,municipality/district,address':
          'El cliente seleccionado no tiene registrado RUT, ciudad, comuna o dirección.'
      };
      dispatch(
        sendAlert({
          kind: 'error',
          message: errorMessages[jsonResponse.error] || jsonResponse.error
        })
      );
    }
  };

  const handleDeleteRequest = (params = {}) => {
    const idToDelete = params.id || modalItem.id;
    deleteProjectInvoiceRequest(projectId, idToDelete, {
      dispatch,
      params: snakeCaseKeys(params),
      successCallback: handleSuccessDelete,
      failureCallback: handleFailureRequest
    });
  };

  const handleConfirmRequest = values => {
    setModalShow(false);
    setModalBody(<ConfirmRequest confirm={() => handleDeleteRequest(values)} cancel={handleModalClose} />);
    setModalShow(true);
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'downloadPdf':
        handleDownloadDocument(item.id, 'pdf');
        break;
      case 'downloadXml':
        handleDownloadDocument(item.id, 'xml');
        break;
      case 'destroy':
        if (item.canDelete) {
          setDefaultModalShow(true);
          setModalBody('¿Estás seguro que deseas eliminar este abono?');
        } else {
          setModalTitle('EMITIR NOTA DE CRÉDITO');
          setModalShow(true);
          setModalBody(
            <CreditNoteToDelete
              projectInvoiceToDelete={{ id: item.id, motive: '', projectCode: item.projectCode, issueDate: new Date() }}
              formRequest={handleConfirmRequest}
              handleModalClose={handleModalClose}
              dispatch={dispatch}
            />
          );
        }
        setModalItem(item);
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  if (isLoading) return null;

  const mapProjectInvoices = projectInvoices.map(projectInvoice => {
    const {
      id: projectInvoiceId,
      client,
      invoiceDateFormatted,
      amountFormatted,
      statusName,
      canDelete,
      totalFormatted,
      state,
      shortName,
      balanceFormatted,
      type,
      paymentsTypes
    } = projectInvoice;
    return (
      <tr key={`project-payment-${projectInvoiceId.toString()}`}>
        <td>{shortName}</td>
        <td>{client?.fullName}</td>
        <td>{invoiceDateFormatted}</td>
        <td>{amountFormatted}</td>
        <td>{totalFormatted}</td>
        <td>{balanceFormatted}</td>
        <td>{paymentsTypes}</td>
        <td>{statusName}</td>
        <td className="text-center d-flex align-items-center justify-content-center">
          <>
            {canDelete ? (
              <Trash
                width="18px"
                height="18px"
                className="clickable delete-item text-danger"
                onClick={() => handleButtonClick(projectInvoice, 'destroy')}
              />
            ) : (
              <>
                <Dropdown>
                  <Dropdown.Toggle className="btn-circle mr-2" variant="outline-success">
                    <Download size={16} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleButtonClick(projectInvoice, 'downloadPdf')}>
                      Descargar PDF
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleButtonClick(projectInvoice, 'downloadXml')}>
                      Descargar XML
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {type !== 'CreditNoteDocument' &&
                  state !== 'deleted' &&
                  (canDelete ? (
                    <Trash
                      width="18px"
                      height="18px"
                      className="clickable delete-item text-danger"
                      onClick={() => handleButtonClick(projectInvoice, 'destroy')}
                    />
                  ) : (
                    <Button className="p-0" variant="link" onClick={() => handleButtonClick(projectInvoice, 'destroy')}>
                      NC
                    </Button>
                  ))}
              </>
            )}
          </>
        </td>
      </tr>
    );
  });

  return (
    <>
      <Row className="mb-3">
        <Col className="section-header d-flex justify-content-between">
          <strong>DOCUMENTOS</strong>
          <Col md={6} className="justify-content-end d-flex">
            <AssociateProjectInvoice project={project} version={version} onReloadProjectInvoices={handleReFetch} />
            {project?.company.billingConfigured && (
              <AddProjectInvoice project={project} version={version} onReloadProjectInvoices={handleReFetch} />
            )}
          </Col>
        </Col>
      </Row>
      <Table striped bordered hover size="sm" responsive>
        <thead>
          <tr className="text-uppercase fit-content">
            <th>Doc.</th>
            <th>Cliente</th>
            <th>Fecha Ingreso</th>
            <th>Neto</th>
            <th>Total</th>
            <th>Saldo</th>
            <th>Tipo de abono</th>
            <th>Estado</th>
            <th style={{ width: '100px' }} />
          </tr>
        </thead>
        <tbody>{mapProjectInvoices}</tbody>
      </Table>
      {defaultModalShow && (
        <DefaultModal
          title="Eliminar abono"
          body={modalBody}
          show={defaultModalShow}
          handleClose={handleModalClose}
          handleConfirm={() => handleDeleteRequest()}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />
      )}
      <SimpleCenteredModal
        size="md"
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
        closeButton
      />
    </>
  );
};

export default ProjectInvoicesIndex;
