import camelCaseRecursive from 'camelcase-keys-recursive';
import api_v2 from '../services/api_v2';
import useQuery, { useInvalidateQuery } from './useQuery';

const listProjectWorksByProject = (projectId, view) =>
  api_v2.get(`/projects/${projectId}/project_works.json?view=${view}`).then(res => res.data);

export const useListProjectWorksByProject = (projectId, view = '') => {
  const { data = [], isLoading } = useQuery(['project_works', projectId, view], listProjectWorksByProject, {
    enabled: !!projectId
  });
  const invalidateQueries = useInvalidateQuery();
  const reFetch = () => {
    invalidateQueries(['project_works', projectId]);
  };
  const projectWorks = camelCaseRecursive(data);
  return { projectWorks, reFetch, isLoading };
};
