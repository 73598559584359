import camelCaseRecursive from 'camelcase-keys-recursive';
import api_v2 from '../services/api_v2';
import useQuery, { useInvalidateQuery } from './useQuery';

const getVehicle = (id, view) => api_v2.get(`/vehicles/${id}.json?view=${view}`).then(res => res.data);

const updateVehicle = (id, params) => api_v2.put(`/vehicles/${id}.json`, params).then(res => res.data);

export const useGetVehicle = (id, view) => {
  const { data = {}, isLoading } = useQuery(['vehicle', id, view], getVehicle, { enabled: !!id });
  const invalidateQueries = useInvalidateQuery();
  const reFetch = () => {
    invalidateQueries(['vehicle', id]);
  };
  const vehicle = camelCaseRecursive(data);
  return { vehicle, reFetch, isLoading };
};

export const useUpdateVehicle = id => {
  const update = async (values, options = {}) => {
    try {
      const data = await updateVehicle(id, values);
      options.onSuccess && options.onSuccess(data);
    } catch (error) {
      options.onError && options.onError(error);
    }
  };
  return { update };
};
