import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getIn, useFormikContext } from 'formik';
import { Button, Col, Container, Row, Accordion, Card } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { Trash } from 'react-feather';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  BasicTextArea,
  Dropzone,
  FormikCheckBox,
  FormikInput,
  FormikNumberFormat,
  FormikSelect,
  SimpleCenteredModal
} from '../../components';
import { sendAlert } from '../../actions/utils';
import { deleteProjectWorkImageRequest, updateSpecificAttributesRequest } from '../../requests/projects';
import { indexUsersRequest, userExceededProjectWorkHours } from '../../requests/user';
import ProjectDetailInput from '../ProjectDetail/ProjectDetailInput';
import ProjectWorkHoursAlertExceededModal from '../ProjectWorkHours/ProjectWorkHoursAlertExceededModal';
import ProjectWorkHoursModal from '../ProjectWorkHours/ProjectWorkHoursModal';
import { createDropZoneFileRequest, deleteDropZoneFileRequest } from '../../requests/dropzone';
import { uuid } from "../../utils/utils";
import { textCapitalize, capitalizeFirstLetter } from "../../services/utils";
import { ROLE_IDS } from "../../config/ability";
import {updateProjectWorkRequest} from "../../requests/projectWorks";
import { indexProductCategoriesRequest } from "../../requests/productCategories";
import useCountryFactory from "../../hooks/useCountryFactory";

const defaultProjectDetail = {
  cost: '',
  categoryId: '',
  freeQuotedCost: '',
  hidden: false,
  price: '',
  productCategoryId: '',
  productId: '',
  productNameCustom: '',
  quantity: '',
  unitPrice: ''
};

const ProjectWorkInput = ({
                            calculatedPrice,
                            fromMechanicCard,
                            fromProjectReview,
                            fromProject,
                            index,
                            modelKey,
                            project,
                            projectWork,
                            projectWorksAttributes,
                            setChangeSubtotal,
                            setMoreData,
                            providedProjectWork,
                            work
                          }) => {
  const {errors, setFieldValue, touched, values} = useFormikContext();
  const {done, imagesInfo, projectDetailsAttributes, workHours, workMinutes, userId, doneById} = projectWork;
  const inputName = `${modelKey}[${index}]`;
  const projectId = getIn(values, `project[id]`);
  const [changePrice, setChangePrice] = useState(false);
  const [subtotalPrice, setSubtotalPrice] = useState(0);
  const [users, setUsers] = useState([]);
  const [modalUsers, setModalUsers] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalAlertShow, setAlertModalShow] = useState(false);
  const [modalAssignUserShow, setModalAssignUserShow] = useState(false);
  const [updateComplete, setUpdateComplete] = useState(false);
  const [selectedUser, setSelectedUser] = useState({value: null, label: ''});
  const [modalBody, setModalBody] = useState('');
  const [totalPieces, setTotalPieces] = useState(0);
  const {user: currentUser} = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const countryFactory = useCountryFactory();

  // Dropzone Variables
  const maxAvatarSize = 20971520;
  const [documentCode, setDocumentCode] = useState(Date.now());
  const [documentKeys, setDocumentKeys] = useState([]);
  const modelKeyProjectWork = `${modelKey}[${index}]`;
  const [imageCounter, setImageCounter] = useState(imagesInfo?.length || 0);
  const [projectWorkExtended, setProjectWorkExtended] = useState({
    ...projectWork,
    persistedImages: imagesInfo ?? []
  });

  const checkAllDone = array => {
    return array.every(item => item.done);
  };
  const allDone = checkAllDone(projectDetailsAttributes);
  const isAdminUser = currentUser?.roleIds?.includes(ROLE_IDS.admin) || false;

  const handleResponseRequest = (kind, message) => {
    dispatch(sendAlert({kind, message}));
    setMoreData(m => !m);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setModalAssignUserShow(false);
  }
  const handleAlertModalClose = () => setAlertModalShow(false);

  const handleUpdateRequest = () => {
    const originalDone = project?.projectWorksAttributes[index]?.done;
    const originalWorkHours = project?.projectWorksAttributes[index]?.workHours;
    const originalWorkMinutes = project?.projectWorksAttributes[index]?.workMinutes;
    const originalUserId = project?.projectWorksAttributes[index]?.userId;
    if (
      fromMechanicCard &&
      (done !== originalDone ||
        workHours !== originalWorkHours ||
        userId !== originalUserId ||
        workMinutes !== originalWorkMinutes)
    ) {
      const sendParams = {project: {projectWorksAttributes: getIn(values, modelKey)}};
      updateSpecificAttributesRequest(projectId, {
        dispatch,
        params: snakeCaseKeys(sendParams),
        successCallback: () => handleResponseRequest('success', 'Trabajo actualizado con éxito'),
        failureCallback: error => handleResponseRequest('error', error?.response?.data?.message)
      });
    }
  };

  const successAlertRequest = response => {
    setAlertModalShow(response.data.exceeded_work_hours);
  };

  const hanldeUserExceededProjectWorkHoursRequest = () => {
    userExceededProjectWorkHours(currentUser.id, {
      dispatch,
      successCallback: response => successAlertRequest(response)
    });
  };

  const assignUser = (e) => {
    setSelectedUser({value: e.value, label: e.label});
    projectDetailsAttributes.forEach((projectDetail, currentIndex) => {
      if (!projectDetail.done) {
        setFieldValue(`${inputName}[projectDetailsAttributes][${currentIndex}][done]`, true);
        setFieldValue(`${inputName}[projectDetailsAttributes][${currentIndex}][doneById]`, e.value);
        setFieldValue(`${inputName}[projectDetailsAttributes][${currentIndex}][doneByName]`, e.label.split(' ')[0]);
        setFieldValue(`${inputName}[projectDetailsAttributes][${currentIndex}][doneAt]`, new Date());
      }
    });
    setUpdateComplete(true);
  };

  const handleChangeWorkerResponsible = e => {
    if (e.target.checked) {
      if (isAdminUser) {
        setModalAssignUserShow(true);
        setModalBody(
          <FormikSelect
            options={users}
            label="Asignar Personal"
            placeholder="Seleccionar"
            onChange={assignUser}
          />
        );
      } else {
        setFieldValue(`${inputName}[done]`, true);
        setFieldValue(`${inputName}[doneById]`, currentUser.id);
        setFieldValue(`${inputName}[doneByName]`, currentUser.name);
      }
    } else {
      setFieldValue(`${inputName}[done]`, false);
      setFieldValue(`${inputName}[doneById]`, '');
      setFieldValue(`${inputName}[doneByName]`, '');
    }
  }

  useEffect(handleUpdateRequest, [doneById, userId]);
  useEffect(() => {
    if (updateComplete) {
      setFieldValue(`${inputName}[done]`, true);
      setFieldValue(`${inputName}[doneById]`, selectedUser.value);
      setFieldValue(`${inputName}[doneByName]`, selectedUser.label.split(' ')[0]);
      setModalAssignUserShow(false);
      setUpdateComplete(false);
    }
  }, [updateComplete, selectedUser]);

  const handleAddBtn = () => {
    const newProjectDetail = {...defaultProjectDetail, key: uuid()};
    if (['DESABOLLADURA', 'PINTURA', 'DESMONTAR / MONTAR', 'CAMBIO', 'REPUESTOS SEGURO'].includes(projectWork.name)) {
      indexProductCategoriesRequest({
        dispatch,
        params: {
          filter_parents: true,
          display_length: 100
        },
        successCallback: (response) => {
          const category = response.data.data.filter(cat => cat.name === projectWork.name);
          if (category.length) {
            newProjectDetail.categoryId = category[0].id;
            newProjectDetail.categoryName = category[0].name;
            const subcategoryName = project.client.insuranceCompany.subcategoryName ? project.client.insuranceCompany.subcategoryName : 'PARTICULAR';
            const subcategory = category[0].sub_categories_attributes?.filter(subcat => subcat.name === subcategoryName);
            if (subcategory.length) {
              newProjectDetail.productCategoryId = subcategory[0].id;
              newProjectDetail.productCategoryName = subcategory[0].name;
            }
          }
          const newValues = [...projectDetailsAttributes, newProjectDetail];
          setFieldValue(`${inputName}[projectDetailsAttributes]`, newValues);
        }
      });
    } else {
      const newValues = [...projectDetailsAttributes, newProjectDetail];
      setFieldValue(`${inputName}[projectDetailsAttributes]`, newValues);
    }
  };

  const handleCreateDetailFirstTime = () => {
    if (projectDetailsAttributes.length === 0) handleAddBtn();
  };
  useEffect(handleCreateDetailFirstTime, []);

  const handleRemoveBtn = () => {
    const arrayValuesLength = projectWorksAttributes.length;
    const deleteValue = projectWork;
    const newValues = [
      ...projectWorksAttributes.slice(0, index),
      ...projectWorksAttributes.slice(index + 1, arrayValuesLength)
    ];
    if (deleteValue && deleteValue.id) {
      deleteValue._destroy = 'true';
      deleteValue.projectDetailsAttributes.map(projectDetail => (projectDetail._destroy = 'true'));
      newValues.push(deleteValue);
    }
    setFieldValue(modelKey, newValues);
    if (fromProject) setChangeSubtotal(s => !s);
  };

  const handleSetUsers = response => {
    const responseUsers = response.data.data;
    setModalUsers(responseUsers);
    return setUsers(responseUsers);
  };

  const fetchUsers = () => {
    indexUsersRequest({
      dispatch,
      params: {
        for_selector: true,
        sort_column: 'email',
        display_length: 100,
        active_users: true
      },
      successCallback: handleSetUsers
    });
  };

  useEffect(fetchUsers, []);

  const setPosition = () => {
    setFieldValue(`${inputName}[position]`, index);
  };

  useEffect(setPosition, [index]);

  const addProjectDetailInputs = () => {
    return projectDetailsAttributes.map((projectDetail, currentIndex) => {
      const recognizer = projectDetail?.id ? projectDetail.id : projectDetail.key;
      if (projectDetail._destroy) {
        return undefined;
      }
      return (
        <Draggable key={recognizer} draggableId={String(recognizer)} index={currentIndex}
                   isDragDisabled={fromMechanicCard}>
          {provided => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <ProjectDetailInput
                key={`projectDetail-${index.toString()}-${currentIndex.toString()}-${recognizer}`}
                index={currentIndex}
                projectDetail={projectDetail}
                projectWork={projectWork}
                modelKey={`${inputName}[projectDetailsAttributes]`}
                setChangePrice={setChangePrice}
                fromProjectReview={fromProjectReview}
                fromMechanicCard={fromMechanicCard}
                fromProject={fromProject}
                users={users}
                countryFactory={countryFactory}
                work={work}
              />
            </div>
          )}
        </Draggable>
      );
    });
  };

  const handleSubtotalPrice = () => {
    if (fromProject) {
      const projectDetails = projectDetailsAttributes.filter(pd => !pd._destroy);
      const totalProjectWorkPrice = calculatedPrice(projectDetails);
      setSubtotalPrice(totalProjectWorkPrice);
      setChangeSubtotal(s => !s);
    }
  };

  useEffect(handleSubtotalPrice, [changePrice]);

  // Dropzone methods handler
  const handleSuccessDeleteImage = response => {
    const documentInfo = JSON.parse(response.config.data);
    const {persistedImages} = projectWorkExtended;
    const updatedPersistedImages = persistedImages.filter(image => image.id !== documentInfo.document_id);
    const updatedState = {
      ...projectWorkExtended,
      persistedImages: updatedPersistedImages
    };
    setProjectWorkExtended(updatedState);
    handleResponseRequest('success', 'Trabajo actualizado con éxito');
  };

  const handleDeleteProjectWorkImageRequest = params => {
    deleteProjectWorkImageRequest(projectId, {
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: handleSuccessDeleteImage
    });
  };

  const handleOnDrop = (code, documents) => {
    const dropzoneFiles = {
      drop_zone_file: {
        code,
        document_type: 'project_work_image',
        documents
      }
    };
    return createDropZoneFileRequest({
      dispatch,
      params: dropzoneFiles,
      formData: true,
      successCallback: response => {
        const {data} = response;
        const newDocumentKeys = documentKeys;
        data.map(element => newDocumentKeys.push(element.document_key));
        setDocumentKeys(newDocumentKeys);
        return response;
      }
    });
  };

  const updateProjectWorkExtended = () => {
    setImageCounter(imagesInfo?.length);
    const updatedState = {
      ...projectWorkExtended,
      persistedImages: imagesInfo ?? []
    };
    setProjectWorkExtended(updatedState);
  };

  const handleConfirmImages = () => {
    if (projectWork.documentKeys?.length) {
      const sendParams = {project: {projectWorksAttributes: getIn(values, modelKey)}};
      updateSpecificAttributesRequest(projectId, {
        dispatch,
        params: snakeCaseKeys(sendParams),
        successCallback: () => handleResponseRequest('success', 'Trabajo actualizado con éxito'),
        failureCallback: error => handleResponseRequest('error', error?.response?.data?.message)
      });
    }
  };

  const handleUpdateImages = () => {
    const {persistedImages} = projectWorkExtended;
    const updatedState = {
      ...projectWorkExtended,
      persistedImages: [...persistedImages]
    };

    setFieldValue(`${modelKeyProjectWork}[documentKeys]`, documentKeys);

    setImageCounter(persistedImages.length);
    setProjectWorkExtended(updatedState);
    setDocumentCode(Date.now());
  };

  const handleOnDelete = image => {
    deleteDropZoneFileRequest(image.id, {
      dispatch,
      params: {dropZoneFile: {documentKey: image.code}},
      successCallback: () => {
        const newDocumentKeys = documentKeys.filter(element => element !== image.code);
        setDocumentKeys([...newDocumentKeys]);
        setImageCounter(imageCounter - 1);
      }
    });
  };

  const handleOnDeletePersistedImages = document => {
    setImageCounter(imageCounter - 1);
    const destroyImage = {
      project_work_id: projectWork.id,
      document_type: 'project_work_image',
      document_id: document.id
    };
    return handleDeleteProjectWorkImageRequest(destroyImage);
  };

  useEffect(updateProjectWorkExtended, [projectWork.imagesInfo]);
  useEffect(handleConfirmImages, [projectWork.documentKeys]);

  const handleOnDragEnd = result => {
    if (!result.destination) return;

    const items = Array.from(projectDetailsAttributes);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFieldValue(`${inputName}[projectDetailsAttributes]`, items);
  };

  const saveNote = () => {
    const data = {projectWork: {note: getIn(values, `${inputName}[note]`)}};
    const sendParams = snakeCaseKeys(data, { exclude: ['_destroy'] });
    updateProjectWorkRequest(projectWork.id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: () => handleResponseRequest('success', 'Observación actualizada con éxito'),
      failureCallback: () => handleResponseRequest('error', 'Error al actualizar Observación')
    });
  }

  const handleSetFieldValueWithParsed = (index, attribute, value) => {
    setFieldValue(`${inputName}[projectDetailsAttributes][${index}][${attribute}]`, value);
    setFieldValue(`${inputName}[projectDetailsAttributes][${index}][parsed${capitalizeFirstLetter(attribute)}]`, value);
  };

  const handleChangePiecePrice = price => {
    const formattedValue = parseFloat(price.replace(/\./g, '').replace(/,/g, '.'));
    projectDetailsAttributes.forEach((detail, index) => {
      const detailProduct = getIn(values, `${inputName}[projectDetailsAttributes][${index}][product]`)
      if (detailProduct.maxSalePrice && formattedValue > detailProduct.maxSalePrice) {
        dispatch(sendAlert({kind: 'warning', message: 'El precio ingresado es mayor al precio máximo establecido.'}));
      }
      detail.unitPrice = formattedValue;
      handleSetFieldValueWithParsed(index, 'unitPrice', formattedValue);
      setFieldValue(`${inputName}[projectDetailsAttributes][${index}][price]`, Math.round(formattedValue * parseFloat(detail.quantity, 10)));
    });
  };

  const handleChangeWorkDiscount = discount => {
    const formattedValue = discount.replace(/\./g, '');
    projectDetailsAttributes.forEach((detail, index) => {
      const detailPrice = Math.round(detail.unitPrice * parseFloat(detail.quantity, 10));
      const calculatedDiscount = detail.unitPrice && detail.quantity ? Math.round((formattedValue / 100) * detailPrice) : 0;
      setFieldValue(`${inputName}[projectDetailsAttributes][${index}][discountType]`, 'percentage');
      setFieldValue(`${inputName}[projectDetailsAttributes][${index}][percentageDiscount]`, formattedValue);
      setFieldValue(`${inputName}[projectDetailsAttributes][${index}][priceDiscount]`, calculatedDiscount);
      setFieldValue(`${inputName}[projectDetailsAttributes][${index}][price]`, detailPrice - calculatedDiscount);
    });
  };

  useEffect(() => {
    const sumPieces = projectDetailsAttributes.reduce((a, b) => a + (b.quantity ? parseFloat(b.quantity) : 0), 0);
    setTotalPieces(Math.round(sumPieces * 10) / 10);
  }, [projectDetailsAttributes]);

  return (
    <Row className="work-box mb-4 shadow border border-dark">
      <Col md={12}>
        <Row className="work-header pt-3 px-2" {...providedProjectWork?.dragHandleProps}>
          <Col md={fromProject ? 2 : 3} xs={fromProjectReview ? 10 : 12}>
            <Field name={`${inputName}[name]`}>
              {({field}) => (
                <FormikInput
                  {...field}
                  abbr
                  placeholder="Nombre de Trabajo"
                  label="Trabajo"
                  disabled={fromMechanicCard}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          {fromProject && (
            <Col md={3}>
              <Field name={`${inputName}[userId]`}>
                {({field}) => (
                  <FormikSelect
                    {...field}
                    label="Personal asignado"
                    placeholder={
                      // eslint-disable-next-line no-nested-ternary
                      !fromMechanicCard
                        ? 'Seleccionar'
                        : userId !== currentUser.id && userId
                          ? 'Responsable Seleccionado'
                          : 'Seleccionar'
                    }
                    options={users}
                    defaultValue={userId}
                    isDisabled={fromMechanicCard}
                    onChange={data => {
                      setFieldValue(field.name, data ? data.value : '');
                      const details = getIn(values, `${inputName}[projectDetailsAttributes]`) || [];
                      details.forEach((detail, idx) => {
                        setFieldValue(`${inputName}[projectDetailsAttributes][${idx}][assignedUserId]`, data ? data.value : '');
                        setFieldValue(`${inputName}[projectDetailsAttributes][${idx}][assignedUserName]`, data ? data.label : '');
                        setFieldValue(`${inputName}[projectDetailsAttributes][${idx}][assignedAt]`, data ? new Date() : '');
                        setFieldValue(`${inputName}[projectDetailsAttributes][${idx}][hasAssignedUser]`, !!data);
                      });
                    }}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          )}
          {(fromMechanicCard || fromProject) && (
            <Col md={2}>
              <Field name={`${inputName}[workTime]`}>
                {({field}) => (
                  <FormikInput
                    {...field}
                    disabled
                    inputType="number"
                    label="Horas de trabajo mecánico"
                    onBlur={handleUpdateRequest}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          )}
          {fromMechanicCard && (
            <>
              <Col md={2}>
                <Dropzone
                  modalPreview
                  id={documentCode}
                  fileAccept="image/*"
                  documentCounter={imageCounter}
                  maxSize={maxAvatarSize} // 20MB
                  btnOrientation="align-self-end"
                  btnText="Imágenes"
                  files={[]}
                  persistedFiles={projectWorkExtended.persistedImages}
                  onlyPersistedFiles
                  onDrop={handleOnDrop}
                  onDropUploaded={handleUpdateImages}
                  onDelete={image => handleOnDelete(image)}
                  onDeletePersistedFiles={handleOnDeletePersistedImages}
                  name={`${modelKeyProjectWork}[documentKeys]`}
                  error={getIn(errors, `${modelKeyProjectWork}[documentKeys]`)}
                  touched={getIn(touched, `${modelKeyProjectWork}[documentKeys]`)}
                />
              </Col>
              <Col md={2}>
                <Button variant="primary" block onClick={() => setModalShow(true)}>
                  Agregar Horas
                </Button>
              </Col>
            </>
          )}
          {!fromMechanicCard && fromProject && (
            <Col md={2}>
              <Field name={`${inputName}[subtotal]`}>
                {({field}) => (
                  <FormikNumberFormat
                    {...field}
                    disabled
                    decimalScale={2}
                    leftAddon={countryFactory.getSymbol()}
                    label="Subtotal"
                    value={subtotalPrice}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          )}
          {fromProject && (
            <Col md={1} className="mt-2">
              <Field name={`${inputName}[done]`}>
                {({field}) => (
                  <FormikCheckBox {...field} margin="float-left" type="switch" field={field} custom
                                  label={`Realizado ${textCapitalize(projectWork?.doneByName || '')}`}
                                  disabled={isAdminUser ? false : !allDone || (projectWork.done && projectWork.doneById !== currentUser.id)}
                                  onChange={handleChangeWorkerResponsible}/>
                )}
              </Field>
              <Field name={`${inputName}[doneById]`}>
                {({field}) => (
                  <FormikInput
                    {...field}
                    inputType="hidden"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          )}
          {/* <Col md={fromProject ? 1 : { span: 1, offset: 8 }} xs={fromProjectReview ? 2 : 6}>
              <BasicDropdown right items={projectWorkDropdownItems} titleDrop="Acciones" className="work-dropdown" />
            </Col> */}
          {!fromMechanicCard && (
            <Col>
              <Row>
                <Col md={8}>
                  <Button variant="primary" block onClick={() => handleAddBtn()}>
                    Agregar Detalle
                  </Button>
                </Col>
                <Col className="d-flex align-items-center">
                  <Trash
                    color="red"
                    className="clickable"
                    style={{display: 'block'}}
                    onClick={() => handleRemoveBtn()}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col md={getIn(values, `${inputName}[name]`) === 'PINTURA' ? 6 : 
            (getIn(values, `${inputName}[name]`) === 'DESABOLLADURA' ? 8 : 10)}>
            <Accordion>
              <Card>
                <Card.Header className="p-0">
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Observaciones
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    {fromMechanicCard && <Button className="float-right" variant="link" onClick={saveNote}>
                      Guardar Observación
                    </Button>}
                    <Field name={`${inputName}[note]`}>
                      {({field}) => (
                        <BasicTextArea
                          {...field}
                          row="1"
                          label="Nota / Observación"
                          error={getIn(errors, field.name)}
                          touched={getIn(touched, field.name)}
                        />
                      )}
                    </Field>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
          {['PINTURA', 'DESABOLLADURA'].includes(getIn(values, `${inputName}[name]`)) &&
          <>
            <Col md={2}>
              <h3 className='text-center'>Piezas Totales: {totalPieces}</h3>
            </Col>
            {getIn(values, `${inputName}[name]`) === 'PINTURA' &&
            <Col md={2}>
              <Field name={`${inputName}[piecePrice]`}>
                {({field}) => (
                  <FormikNumberFormat
                    {...field}
                    decimalScale={2}
                    leftAddon={countryFactory.getSymbol()}
                    label="Precio pieza general"
                    onValueChange={(value) => handleChangePiecePrice(value.value)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            }
          </>
          }
          {!fromMechanicCard && <Col md={2}>
            <Field name={`${inputName}[workDiscount]`}>
              {({field}) => (
                <FormikNumberFormat
                  {...field}
                  decimalScale={2}
                  leftAddon="%"
                  label="Descuento General Trabajo"
                  onValueChange={(value) => handleChangeWorkDiscount(value.value)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>}
        </Row>
        <Row className="bg-white pb-3 work-details-container">
          <Col md={12}>
            <Container>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="projectDetails">
                  {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {addProjectDetailInputs()}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Container>
          </Col>
          {!fromMechanicCard && (
            <Col md={12} className="mt-2">
              <Button variant="primary" block onClick={() => handleAddBtn()}>
                Agregar Detalle
              </Button>
            </Col>
          )}
        </Row>
      </Col>
      {modalShow && (
        <ProjectWorkHoursModal
          handleClose={handleModalClose}
          modalShow={modalShow}
          projectWork={projectWork}
          users={modalUsers}
          setMoreData={setMoreData}
          alertHoursExceeded={hanldeUserExceededProjectWorkHoursRequest}
        />
      )}
      {modalAlertShow && (
        <ProjectWorkHoursAlertExceededModal
          handleClose={handleAlertModalClose}
          modalShow={modalAlertShow}
          setModalShow={setAlertModalShow}
        />
      )}
      <SimpleCenteredModal
        title="Asignar Usuario"
        body={modalBody}
        show={modalAssignUserShow}
        onHide={handleModalClose}
        closeButton/>
    </Row>
  );
};

export default ProjectWorkInput;