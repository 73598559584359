import React from 'react';
import { useField } from 'formik';
import { Form } from 'react-bootstrap';
import * as validations from '../../../utils/validations';

const TextAreaInputField = ({ label, placeholder, required, row, disabled, ...props }) => {
  const validate = required && validations.required();
  const [field, meta] = useField({ validate, ...props });
  const { error, touched } = meta;

  const handleChange = e => {
    field.onChange({
      target: {
        name: props.name,
        value: e.target.value
      }
    });
  };

  return (
    <Form.Group className={`${disabled ? 'disabled' : ''} ${error && touched ? 'is-invalid' : ''}`}>
      {label && (
        <Form.Label className="font-weight-bolder">
          {label} {required && <abbr className="text-danger ml-1">*</abbr>}
        </Form.Label>
      )}
      <Form.Control
        {...field}
        as="textarea"
        rows={row}
        placeholder={placeholder}
        className={`${error && touched ? 'is-invalid' : ''}`}
        onChange={handleChange}
      />
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
    </Form.Group>
  );
};

export default TextAreaInputField;
