import React, { useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { Edit } from 'react-feather';
import { sendAlert } from '../../actions/utils';
import { SimpleCenteredModal } from '../../components';
import { updateProjectRequest } from '../../requests/projects';
import ProductShow from './Show/ProductShow';
import ProjectClient from './ProjectClient';
import ProjectNavbarBudget from './ProjectNavbarBudget';
import ProjectProgressBars from './ProjectProgressBars';
import ProjectVehicle from './ProjectVehicle';
import ProjectWorkShow from './Show/ProjectWorkShow';
import ProjectComments from './ProjectComments';
import ProjectClient2 from './ProjectClient2';
import { useGetProject, useUpdateProject } from '../../hooks/useProjects';
import ProjectImagesShow from './ProjectImagesShow';
import ProjectNotesForm from './Form/ProjectNotesFrom';
import ProjectNotesReceptionSecureFrom from './Form/ProjectNotesReceptionSecureFrom';
import ProjectDiscountForm from './Form/ProjectDiscountFrom';
import ProjectChangeStatusForm from './Form/ProjectChangeStatusForm';
import ProjectInvoiceStateForm from './Form/ProjectInvoiceStateForm';
import QuoteTitle from './QuoteTitle';
import QuoteLinkButtons from './QuoteLinkButtons';
import QuoteNavbarInfo from './QuoteNavbarInfo';

const QuoteShow = ({ projectId, projectVersion, version }) => {
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [moreData, setMoreData] = useState(false);
  const { project, reFetch: reFetchProject, isLoading } = useGetProject(projectId, 'general');
  const { update: updateProject } = useUpdateProject(projectId);
  const {
    deskSell: isDeskSale,
    token,
    orderType,
    sinisterNumber,
    ocNumber,
    deliveryDateFormatted,
    orderNumber
  } = project;
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessRequest = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Orden de trabajo actualizada con éxito' }));
    setMoreData(!moreData);
    setModalShow(false);
    setModalBody('');
    setModalTitle('');
  };

  const handleUpdateRequest = values => {
    updateProjectRequest(projectId, {
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleUpdateProjectRequest = values => {
    updateProject(values, {
      onSuccess: () => {
        dispatch(sendAlert({ kind: 'success', message: 'Actualización correcta' }));
        reFetchProject();
        setModalShow(false);
      },
      onError: error => {
        const { response } = error;
        dispatch(sendAlert({ kind: 'error', message: response.data.message }));
      }
    });
  };

  const showEditProjectForm = (form = 'notes') => {
    const forms = {
      notes: { title: 'Editar Notas', el: ProjectNotesForm },
      notesReceptionSecure: { title: 'Editar Orden de Trabajo', el: ProjectNotesReceptionSecureFrom },
      discount: { title: 'Editar Descuento General', el: ProjectDiscountForm },
      invoiceState: { title: 'Editar Descuento General', el: ProjectInvoiceStateForm },
      changeStatus: { title: 'Editar Orden de Trabajo', el: ProjectChangeStatusForm }
    };
    const El = forms[form].el;
    setModalTitle(forms[form].title);
    setModalBody(<El projectId={projectId} onSave={handleUpdateProjectRequest} />);
    setModalShow(true);
  };

  if (isLoading) return null;

  return (
    <>
      <QuoteTitle version={version} projectVersion={projectVersion} id={projectId} />
      <QuoteLinkButtons
        showActive
        id={projectId}
        version={version}
        token={token}
        clientNumber={project?.client?.cellphone}
      />
      <QuoteNavbarInfo
        project={project}
        version={version}
        editProjectShow={() => showEditProjectForm('changeStatus')}
        setMoreData={setMoreData}
        handleUpdateRequest={handleUpdateRequest}
      />
      <Row className="mb-3">
        <Col className="project-section mr-2 pt-3">
          <ProjectClient
            companyRequiredFieldAttributes={project?.company?.companyRequiredFieldAttributes}
            project={project}
            version={version}
          />
          {project.secondClientId && <ProjectClient2 clientId={project.secondClientId} />}
        </Col>
        {project.vehicleId && (
          <Col className="project-section ml-2 pt-3">
            <ProjectVehicle
              companyRequiredFieldAttributes={project?.company?.companyRequiredFieldAttributes}
              project={project}
              version={version}
              setMoreData={setMoreData}
            />
          </Col>
        )}
      </Row>
      <Row className="mb-3">
        <Col md={6} className="pl-0 pr-0 pr-md-2">
          <div className="project-section pt-3 h-100">
            {orderType === 'reception_secure' ? (
              <Row>
                <strong className="ml-3">Orden con Recepción y Seguro</strong>
                <Col md={10} className="mb-2 mt-3">
                  <strong className="mr-1">Número de siniestro:</strong>
                  {sinisterNumber}
                </Col>
                {!version && (
                  <Col md={2}>
                    <Button
                      variant="link"
                      className="btn-sm text-dark float-right"
                      onClick={() => showEditProjectForm('notesReceptionSecure')}
                    >
                      <Edit width="15px" height="15px" />
                    </Button>
                  </Col>
                )}
                {ocNumber && (
                  <Col md={12} className="mb-2">
                    <strong className="mr-1">Número OC:</strong>
                    {ocNumber}
                  </Col>
                )}
                {deliveryDateFormatted && (
                  <Col md={12} className="mb-2">
                    <strong className="mr-1">Fecha de entrega:</strong>
                    {deliveryDateFormatted}
                  </Col>
                )}
                {orderNumber && (
                  <Col md={12} className="mb-2">
                    <strong className="mr-1">Número de pedido:</strong>
                    {orderNumber}
                  </Col>
                )}
                <Col md={12} className="mb-4">
                  <strong>Motivo / Notas</strong>
                  <div>{project.notes}</div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col md={10}>
                  <strong>Motivo / Notas</strong>
                </Col>
                {!version && (
                  <Col md={2}>
                    <Button
                      variant="link"
                      className="btn-sm text-dark float-right"
                      onClick={() => showEditProjectForm('notes')}
                      disabled={project.id === ''}
                    >
                      <Edit width="15px" height="15px" />
                    </Button>
                  </Col>
                )}
                <Col md={12} className="pt-2">
                  {project.notes}
                </Col>
              </Row>
            )}
            {projectId && <ProjectComments projectCode={projectId} />}
          </div>
        </Col>
        <ProjectImagesShow
          project={project}
          isDeskSale={isDeskSale}
          projectId={projectId}
          projectVersion={projectVersion}
        />
      </Row>
      <Row className="mb-3">
        {!isDeskSale && (
          <Col md={4} className="project-section mr-2 pt-3">
            <ProjectProgressBars
              project={project}
              id={projectId}
              version={version}
              versionNum={projectVersion ? projectVersion.versionNumber : ''}
              setMoreData={setMoreData}
            />
          </Col>
        )}
        <Col className="project-section pt-3">
          <ProjectWorkShow project={project} />
        </Col>
      </Row>
      <ProjectNavbarBudget
        project={project}
        version={version}
        versionNum={projectVersion ? projectVersion.versionNumber : ''}
        onClick={() => showEditProjectForm('invoiceState')}
        handleChangeDiscount={() => showEditProjectForm('discount')}
        setMoreData={setMoreData}
        isDeskSale={isDeskSale}
        handleUpdateRequest={handleUpdateRequest}
      />
      <Row className="mb-3">
        <Col className="project-section pt-3">
          <ProductShow project={project} version={version} />
        </Col>
      </Row>

      <SimpleCenteredModal
        size="sm"
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default QuoteShow;
