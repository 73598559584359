import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Dropdown, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import { Trash } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useHistory } from 'react-router-dom';
import snakecaseKeys from 'snakecase-keys';
import { format } from 'date-fns';
import {
  CategoryProductCategorySelect,
  DefaultModal,
  FormikCheckBox,
  FormikInput,
  FormikNumberFormat,
  FormikSelect,
  ProductCustomSelect,
  SimpleCenteredModal
} from '../../components';
import { indexProductsRequest } from '../../requests/products';
import { capitalizeFirstLetter, isMobile, textCapitalize, toDecimal, toInteger } from '../../services/utils';
import { showProductCategoryRequest } from '../../requests/productCategories';
import { sendAlert } from '../../actions/utils';
import { updateAdvancedConfigurationRequest } from '../../requests/projects';
import { ROLE_IDS } from '../../config/ability';
import { discountTypes } from '../Project/Form/FormOptions';
import ProjectDetailAdvancedConfigurationModal from './ProjectAdvancedConfiguration';

const ProjectDetailInput = ({
  fromMechanicCard,
  fromProject,
  fromProjectReview,
  index,
  modelKey,
  projectDetail,
  projectWork,
  setChangePrice,
  users,
  countryFactory,
  work
}) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [defaultModalBody, setDefaultModalBody] = useState('');
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [subcategoryMargin, setSubcategoryMargin] = useState('');
  const [inverseMargin, setInverseMargin] = useState(false);
  const [parsedSubcategoryMargin, setParsedSubcategoryMargin] = useState('');
  const [originalValues, setOriginalValues] = useState({});
  const [prevHidden, setPrevHidden] = useState(false);
  const [products, setProducts] = useState([]);
  const [modalAssignUserShow, setModalAssignUserShow] = useState(false);
  const [modalAssignUserBody, setModalAssignUserBody] = useState('');
  const [discountType, setDiscountType] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPrice, setCurrentPrice] = useState(0);
  const { user: currentUser } = useSelector(state => state.auth);
  const inputName = `${modelKey}[${index}]`;
  const { projectDetailsAttributes } = projectWork;
  const vProjectDetail = getIn(values, inputName) || {};
  const {
    hidden,
    price,
    productId,
    productNameCustom,
    projectId,
    doneById,
    discountType: selectedDiscountType
  } = vProjectDetail;
  const [currentDetailUnitPrice, setCurrentDetailUnitPrice] = useState(projectDetail?.unitPrice);
  const [currentDetailDiscount, setCurrentDetailDiscount] = useState(projectDetail?.priceDiscount);
  const [currentDetailQuantity, setCurrentDetailQuantity] = useState(projectDetail?.quantity);
  const isAdminUser = currentUser?.roleIds?.includes(ROLE_IDS.admin) || false;
  const setFirstProductsOption = array => {
    array.unshift({ label: 'Ítem libre', salePrice: '', value: 0, custom: true });
    return camelCaseRecursive(array);
  };

  const handleRemoveBtn = () => {
    const arrayValuesLength = projectDetailsAttributes.length;
    const deleteValue = projectDetail;
    const newValues = [
      ...projectDetailsAttributes.slice(0, index),
      ...projectDetailsAttributes.slice(index + 1, arrayValuesLength)
    ];
    if (deleteValue && deleteValue.id) {
      deleteValue._destroy = 'true';
      newValues.push(deleteValue);
    }
    setFieldValue(modelKey, newValues);
    setChangePrice(p => !p);
  };

  const fetchSubcategory = subcategoryId => {
    showProductCategoryRequest(subcategoryId, {
      dispatch,
      params: {},
      successCallback: response => {
        if (response.data.margin) {
          setSubcategoryMargin(response.data.margin);
          setInverseMargin(response.data.inverse);
          setParsedSubcategoryMargin(response.data.parsed_margin);
        }
      }
    });
  };

  const fetchInitialProducts = params => {
    if (projectDetail.productCategoryId || params?.productCategoryId) {
      indexProductsRequest({
        dispatch,
        params: {
          productCategoryId: projectDetail.productCategoryId,
          active: true,
          ...params,
          for_selector: true,
          sort_column: 'code',
          sort_direction: 'asc',
          display_length: 100
        },
        successCallback: response => {
          setProducts(setFirstProductsOption(response.data.data));
        }
      });
    }
  };

  const handleSetFieldValueWithParsed = (attribute, value) => {
    setFieldValue(`${inputName}[${attribute}]`, value);
    setFieldValue(`${inputName}[parsed${capitalizeFirstLetter(attribute)}]`, value);
  };

  const clearSelectedProduct = () => {
    setFieldValue(`${inputName}[productId]`, '');
    setFieldValue(`${inputName}[productName]`, '');
    setFieldValue(`${inputName}[productNameCustom]`, '');
    setCurrentDetailQuantity(0);
    setCurrentDetailUnitPrice('');
  };

  const handleSubCategoryChange = value => {
    clearSelectedProduct();
    setSubcategoryMargin('');
    setInverseMargin(false);
    if (value) {
      fetchInitialProducts({ productCategoryId: value });
      fetchSubcategory(value);
    }
  };

  const handleParentCategoryChange = () => {
    clearSelectedProduct();
    setProducts([]);
  };

  const handleChangeUnitPrice = e => {
    const formattedValue = parseFloat(e.target.value.replace(/\./g, '').replace(/,/g, '.'));
    if (productNameCustom && subcategoryMargin !== '' && inverseMargin) {
      const cost = ((formattedValue * subcategoryMargin) / 100).toFixed(2);
      handleSetFieldValueWithParsed('freeQuotedCost', parseFloat(cost));
      setFieldValue(`${inputName}[cost]`, cost);
    } else if (vProjectDetail.product.maxSalePrice && formattedValue > vProjectDetail.product.maxSalePrice) {
      dispatch(sendAlert({ kind: 'warning', message: 'El precio ingresado es mayor al precio máximo establecido.' }));
    }
    setCurrentDetailUnitPrice(formattedValue);
    handleSetFieldValueWithParsed('unitPrice', formattedValue);
  };

  const handleChangeQuantity = e => {
    setCurrentDetailQuantity(e.target.value);
    setFieldValue(e.target.name, e.target.value);
  };

  const calculatePrice = () => {
    const currentParsedUnitPrice = currentDetailUnitPrice || 0;
    const parsedQuantity = parseFloat(currentDetailQuantity) || 0;
    return parsedQuantity * currentParsedUnitPrice;
  };

  const recalculateTotalPrice = () => {
    let currentParsedDiscount = currentDetailDiscount || 0;
    const calculatedPrice = calculatePrice();
    if (discountType === 'percentage') {
      const discount_percentage = Number(getIn(values, `${inputName}[percentageDiscount]`) || 0);
      currentParsedDiscount = (discount_percentage / 100) * calculatedPrice;
    }
    setCurrentDetailDiscount(currentParsedDiscount);
    const newCalculatedPrice = calculatedPrice - currentParsedDiscount;
    setFieldValue(`${inputName}[priceDiscount]`, currentParsedDiscount);
    setFieldValue(`${inputName}[price]`, newCalculatedPrice);
    setCurrentPrice(newCalculatedPrice);
  };

  const recalculateAfterChangeFreeQuotedCost = value => {
    let newValue;
    if (inverseMargin) {
      newValue = toInteger(Math.round((value * 100) / subcategoryMargin));
      setFieldValue(`${inputName}[cost]`, value);
    } else {
      newValue = toInteger(Math.round(value * (1 + subcategoryMargin / 100)));
    }
    handleSetFieldValueWithParsed('unitPrice', newValue);
    setCurrentDetailUnitPrice(newValue);
    recalculateTotalPrice();
    setChangePrice(p => !p);
  };

  const handleChangeFreeQuotedCost = e => {
    const formattedValue = e.target.value.replace(/\./g, '');
    if (productNameCustom && subcategoryMargin !== '') {
      const value = parseFloat(formattedValue);
      recalculateAfterChangeFreeQuotedCost(value);
    }
    setFieldValue(e.target.name, formattedValue);
    handleSetFieldValueWithParsed('freeQuotedCost', formattedValue);
  };

  const cleanPrice = () => {
    handleSetFieldValueWithParsed('unitPrice', '');
    handleSetFieldValueWithParsed('freeQuotedCost', '');
    setFieldValue(`${inputName}[price]`, '');
    setCurrentPrice('');
  };

  const handleProductPrice = () => {
    if (productId || productNameCustom) recalculateTotalPrice();
    else cleanPrice();
  };

  const handleOriginalValues = () => {
    setOriginalValues(vProjectDetail);
  };

  const handleDefaultModalConfirm = () => {
    history.push(`/products/${productId}/edit`);
  };

  const handleDefaultModalClose = () => {
    setModalShow(true);
    setDefaultModalShow(false);
    setDefaultModalBody('');
  };

  const handleAdvancedConfiguration = () => {
    setModalBody(
      <ProjectDetailAdvancedConfigurationModal
        projectDetailId={vProjectDetail.id}
        onClose={(didSave, updatedDetail) => {
          if (didSave && updatedDetail?.projectDetail) {
            const updatedValues = {
              ...vProjectDetail,
              ...updatedDetail.projectDetail,
              cost: updatedDetail.projectDetail.cost,
              unitPrice: updatedDetail.projectDetail.unitPrice || vProjectDetail.unitPrice,
              product: {
                ...vProjectDetail.product,
                ...updatedDetail.projectDetail.product
              }
            };
            setFieldValue(inputName, updatedValues);
            setOriginalValues(updatedValues);
            setCurrentDetailUnitPrice(updatedDetail.projectDetail.unitPrice || vProjectDetail.unitPrice);
            setCurrentDetailQuantity(updatedDetail.projectDetail.quantity || vProjectDetail.quantity);
            
            const newPrice = (updatedDetail.projectDetail.quantity || vProjectDetail.quantity) * 
                            (updatedDetail.projectDetail.unitPrice || vProjectDetail.unitPrice);
            setCurrentPrice(newPrice);
            setFieldValue(`${inputName}[price]`, newPrice);
            
            const newMargin = productNameCustom
              ? newPrice - (updatedValues.quantity || 0) * updatedValues.freeQuotedCost
              : newPrice - (updatedValues.quantity || 0) * updatedValues.cost;
            
            handleSetFieldValueWithParsed('profitMargin', newMargin);
          }
          setModalShow(false);
        }}
      />
    );
    setModalShow(true);
  };

  const hideProjectDetail = () => {
    if (hidden) {
      handleSetFieldValueWithParsed('unitPrice', 0);
      setFieldValue(`${inputName}[price]`, 0);
      setCurrentPrice(0);
      setPrevHidden(true);
    } else if (prevHidden) {
      if (originalValues.unitPrice !== 0) {
        handleSetFieldValueWithParsed('unitPrice', originalValues.unitPrice);
        setFieldValue(`${inputName}[price]`, originalValues.unitPrice);
        setCurrentDetailUnitPrice(originalValues.unitPrice);
        recalculateTotalPrice();
      } else {
        handleSetFieldValueWithParsed('unitPrice', vProjectDetail?.product?.salePrice);
        setFieldValue(`${inputName}[price]`, vProjectDetail?.product?.salePrice);
        setCurrentDetailUnitPrice(vProjectDetail?.product?.salePrice);
        recalculateTotalPrice();
      }
    }
  };

  const handleResponseRequest = (kind, message) => {
    dispatch(sendAlert({ kind, message }));
  };

  const handleUpdateRequest = () => {
    if (fromMechanicCard && originalValues?.done !== undefined && projectWork.done !== true) {
      updateAdvancedConfigurationRequest(projectId, {
        dispatch,
        params: snakecaseKeys(values),
        formData: true,
        successCallback: () => handleResponseRequest('success', 'Detalle actualizado con éxito'),
        failureCallback: error => handleResponseRequest('error', error?.response?.data?.message)
      });
    }
  };

  const handleReloadTotalPrice = () => {
    setChangePrice(p => !p);
    if (fromProject && !fromMechanicCard) {
      if (productNameCustom) {
        handleSetFieldValueWithParsed(
          'profitMargin',
          currentPrice - (vProjectDetail?.quantity || 0) * vProjectDetail.freeQuotedCost
        );
      } else {
        handleSetFieldValueWithParsed(
          'profitMargin',
          currentPrice - (vProjectDetail?.quantity || 0) * vProjectDetail.cost
        );
      }
    }
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const assignResponsible = e => {
    setFieldValue(`${inputName}[done]`, true);
    setFieldValue(`${inputName}[doneById]`, e.value);
    setFieldValue(`${inputName}[doneByName]`, e.label);
    setFieldValue(`${inputName}[doneAt]`, new Date());
    setModalAssignUserShow(false);
  };

  const assignUser = e => {
    setFieldValue(`${inputName}[hasAssignedUser]`, true);
    setFieldValue(`${inputName}[assignedUserId]`, e.value);
    setFieldValue(`${inputName}[assignedUserName]`, e.label);
    setFieldValue(`${inputName}[assignedAt]`, new Date());
    setModalAssignUserShow(false);
  };

  const handleChangeResponsible = e => {
    if (e.target.checked) {
      if (isAdminUser) {
        setModalAssignUserShow(true);
        setModalAssignUserBody(
          <FormikSelect
            options={users}
            label="Asignar Personal"
            placeholder="Seleccionar"
            onChange={assignResponsible}
          />
        );
      } else {
        setFieldValue(`${inputName}[done]`, true);
        setFieldValue(`${inputName}[doneById]`, currentUser.id);
        setFieldValue(`${inputName}[doneByName]`, currentUser.name);
        setFieldValue(`${inputName}[doneAt]`, new Date());
      }
    } else {
      setFieldValue(`${inputName}[done]`, false);
      setFieldValue(`${inputName}[doneById]`, '');
      setFieldValue(`${inputName}[doneByName]`, '');
      setFieldValue(`${inputName}[doneAt]`, '');
    }
  };

  const handleChangeAssignedUser = e => {
    if (e.target.checked) {
      if (isAdminUser) {
        setModalAssignUserShow(true);
        setModalAssignUserBody(
          <FormikSelect options={users} label="Asignar Personal" placeholder="Seleccionar" onChange={assignUser} />
        );
      } else {
        setFieldValue(`${inputName}[hasAssignedUser]`, true);
        setFieldValue(`${inputName}[assignedUserId]`, currentUser.id);
        setFieldValue(`${inputName}[assignedUserName]`, currentUser.name);
        setFieldValue(`${inputName}[assignedAt]`, new Date());
      }
    } else {
      setFieldValue(`${inputName}[hasAssignedUser]`, false);
      setFieldValue(`${inputName}[assignedUserId]`, '');
      setFieldValue(`${inputName}[assignedUserName]`, '');
      setFieldValue(`${inputName}[assignedAt]`, '');
    }
  };

  useEffect(() => {
    setParsedSubcategoryMargin(projectDetail?.parsedProductCategoryMargin);
    setSubcategoryMargin(projectDetail?.productCategoryMargin);
    setInverseMargin(projectDetail?.productCategoryInverse);
  }, []);

  useEffect(fetchInitialProducts, []);
  useEffect(hideProjectDetail, [hidden]);
  useEffect(handleUpdateRequest, [doneById]);
  useEffect(handleProductPrice, [productId, productNameCustom]);
  useEffect(recalculateTotalPrice, [currentDetailUnitPrice, currentDetailQuantity, currentDetailDiscount]);
  useEffect(handleOriginalValues, []);
  useEffect(handleReloadTotalPrice, [currentPrice]);

  const setPosition = () => {
    setFieldValue(`${inputName}[position]`, index);
  };

  const handleChangeOfDiscountType = data => {
    setFieldValue(`${inputName}[discountType]`, data?.value);
    setFieldValue(`${inputName}[priceDiscount]`, '');
    setFieldValue(`${inputName}[percentageDiscount]`, '');
    setFieldValue(`${inputName}[price]`, calculatePrice());
    setDiscountType(data?.value);
  };

  const handleFormattingPercentage = e => {
    const formattedValue = e.target.value.replace(/\./g, '');
    const calculatedPrice = calculatePrice();
    const discount = (formattedValue / 100) * calculatedPrice;
    setCurrentDetailDiscount(discount);
    setFieldValue(e.target.name, formattedValue);
  };

  const handleFormattingPrice = e => {
    const formattedValue = e.target.value.replace(/\./g, '');
    setCurrentDetailDiscount(formattedValue);
    setFieldValue(e.target.name, formattedValue);
  };

  const formatDate = date => {
    return date ? format(new Date(date), 'dd/MM/yyyy') : '';
  };

  const handleCheckRemoveIva = e => {
    let newValue;
    setFieldValue(e.target.name, e.target.checked);
    if (vProjectDetail.freeQuotedCost !== '' && vProjectDetail.freeQuotedCost !== 0) {
      if (e.target.checked) {
        newValue = vProjectDetail.freeQuotedCost / 1.19;
      } else {
        newValue = vProjectDetail.freeQuotedCost * 1.19;
      }
      if (productNameCustom && subcategoryMargin !== '') {
        recalculateAfterChangeFreeQuotedCost(newValue);
      }
      handleSetFieldValueWithParsed('freeQuotedCost', newValue);
    }
  };

  useEffect(setPosition, [index]);
  useEffect(() => {
    if (selectedDiscountType) setDiscountType(selectedDiscountType);
  }, []);

  useEffect(() => {
    if (fromProject && !fromMechanicCard) {
      let margin;
  
      if (productNameCustom) {
        margin = currentPrice - (vProjectDetail?.quantity || 0) * vProjectDetail.freeQuotedCost;
      } else {
        margin = currentPrice - (vProjectDetail?.quantity || 0) * vProjectDetail.cost;
      }
      setFieldValue(`${inputName}[parsedProfitMargin]`, margin);
    }
  }, [currentPrice, vProjectDetail.quantity, vProjectDetail.cost, vProjectDetail.freeQuotedCost]);
  

  const classColumnMobile = isMobile() ? 'col-12' : '';

  return (
    <Col className="px-2">
      <Row
        className={`item-background p-0 mt-2 ${productNameCustom ? 'free-item' : ''}`}
        style={!isMobile() && !work ? { overflow: 'auto hidden', flexWrap: 'nowrap' } : {}}
      >
        <Col md={fromMechanicCard || fromProjectReview ? 6 : 4} className="work-detail-col">
          <Row className="work-detail-row">
            <CategoryProductCategorySelect
              categoryAbbr
              currentModel={projectDetail || {}}
              disabled={fromMechanicCard}
              errors={errors}
              fromProjectDetailInput
              modelKey={inputName}
              onParentCategoryChange={handleParentCategoryChange}
              onSubCategoryChange={handleSubCategoryChange}
              subcategoryMargin={productNameCustom && subcategoryMargin !== '' ? parsedSubcategoryMargin : null}
              setFieldValue={setFieldValue}
              touched={touched}
              values={values}
              isModal={work}
            />
          </Row>
        </Col>

        <Col className="work-detail-col">
          <Row
            className="work-detail-row"
            style={!isMobile() ? (work ? { flexWrap: 'nowrap' } : { overflow: 'auto hidden', flexWrap: 'nowrap' }) : {}}
          >
            <Col className={`work-detail-col`} style={{ minWidth: '150px' }}>
              <ProductCustomSelect
                clearItemHidden
                fromProject={fromProject}
                fromProjectDetailInput
                products={products}
                modelKey={inputName}
                modelDetail={projectDetail}
                setFirstProductsOption={setFirstProductsOption}
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
                touched={touched}
                disabled={fromMechanicCard}
                setCurrentDetailQuantity={setCurrentDetailQuantity}
                setCurrentDetailUnitPrice={setCurrentDetailUnitPrice}
                isModal={work}
              />
            </Col>
            {fromProject && !fromMechanicCard && (
              <>
                <Col
                  data-tip
                  data-for={`btn-settings-${vProjectDetail?.id}`}
                  className="project-config-btn"
                  style={{ left: '50px' }}
                >
                  <Button
                    className="btn"
                    disabled={!vProjectDetail?.id}
                    variant="link"
                    onClick={handleAdvancedConfiguration}
                  >
                    Configuración
                  </Button>
                  {!vProjectDetail?.id && (
                    <ReactTooltip id={`btn-settings-${vProjectDetail?.id}`} place="top">
                      Ítem debe ser guardado antes de acceder a Configuración
                    </ReactTooltip>
                  )}
                </Col>
                <SimpleCenteredModal
                  size="md"
                  title="Configuración Avanzada"
                  body={modalBody}
                  show={modalShow}
                  onHide={handleModalClose}
                />
                {defaultModalShow && (
                  <DefaultModal
                    title="Confirmar Ir a Editar Ítem"
                    body={defaultModalBody}
                    show={defaultModalShow}
                    handleClose={handleDefaultModalClose}
                    handleConfirm={handleDefaultModalConfirm}
                    titleBtnClose="Cancelar"
                    titleBtnSave="Confirmar"
                  />
                )}
              </>
            )}
            {fromProject && productNameCustom && !fromMechanicCard && (
              <div className={`work-detail-col p-0 ${classColumnMobile}`} style={{ minWidth: '120px', width: '120px' }}>
                <Field name={`${inputName}[freeQuotedCost]`}>
                  {({ field }) => (
                    <FormikNumberFormat
                      {...field}
                      decimalScale={2}
                      disabled={hidden}
                      value={parseFloat(field.value)}
                      leftAddon={countryFactory.getSymbol()}
                      label="Cotizado"
                      onChange={handleChangeFreeQuotedCost}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
                <Col className="project-config-btn" style={{ left: '55%', top: '0' }}>
                  <Field name={`${inputName}[quotedWithTax]`}>
                    {({ field }) => (
                      <FormikCheckBox
                        {...field}
                        field={field}
                        label="Quitar IVA"
                        onChange={handleCheckRemoveIva}
                        custom
                      />
                    )}
                  </Field>
                </Col>
              </div>
            )}
            <div
              className={`work-detail-col ${productNameCustom && !isMobile() ? 'pl-4' : ''} ${
                isMobile() ? 'pt-2' : ''
              } ${classColumnMobile}`}
              style={{ minWidth: '95px', width: '95px' }}
            >
              <Field name={`${inputName}[quantity]`}>
                {({ field }) => (
                  <FormikInput
                    {...field}
                    inputType="number"
                    label="Cantidad"
                    onChange={e => {
                      const value = parseFloat(e.target.value);
                      if (value >= 0) {
                        handleChangeQuantity(e);
                      } else {
                        handleChangeQuantity({ target: { value: 0, name: field.name } });
                      }
                    }}
                    disabled={fromMechanicCard}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </div>
          </Row>
        </Col>
        {fromProject && !fromMechanicCard && (
          <>
            <div
              className={`work-detail-col pt-3 pr-0 ${classColumnMobile}`}
              style={{ minWidth: '120px', width: '120px' }}
            >
              <Field name={`${inputName}[unitPrice]`} style={{ marginBottom: 0 }}>
                {({ field }) => (
                  <FormikNumberFormat
                    {...field}
                    decimalScale={2}
                    disabled={hidden}
                    leftAddon={countryFactory.getSymbol()}
                    value={parseFloat(field.value)}
                    label="Valor Unitario"
                    onChange={handleChangeUnitPrice}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </div>
            <div
              className={`work-detail-col pr-1 ${!isMobile() ? 'pt-4' : ''} ${classColumnMobile}`}
              style={{ minWidth: '120px', width: '120px' }}
            >
              <Row className="work-detail-row">
                <Col md={12} xs={12} className="work-detail-col pt-1 pl-0">
                  <Field name={`${inputName}[price]`}>
                    {({ field }) => (
                      <FormikNumberFormat
                        {...field}
                        disabled
                        decimalScale={2}
                        leftAddon={countryFactory.getSymbol()}
                        label="Valor Neto"
                        value={toDecimal(price)}
                        error={getIn(errors, field.name)}
                        touched={getIn(touched, field.name)}
                      />
                    )}
                  </Field>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" className={`dropdown-basic-${inputName} pl-0`}>
                      Agregar descuento
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ width: '350px' }}>
                      <div className="d-flex">
                        <Col md={6} xs={12}>
                          <Field name={`${inputName}[discountType]`}>
                            {({ field }) => (
                              <FormikSelect
                                {...field}
                                isClearable
                                label="Tipo de descuento"
                                placeholder="Seleccionar"
                                options={discountTypes}
                                defaultValue={selectedDiscountType}
                                onChange={handleChangeOfDiscountType}
                                menuPortalTarget={document.body}
                              />
                            )}
                          </Field>
                        </Col>
                        <Col md={6} xs={12}>
                          {discountType === 'price' && (
                            <Field name={`${inputName}[priceDiscount]`}>
                              {({ field }) => (
                                <FormikNumberFormat
                                  {...field}
                                  leftAddon={countryFactory.getSymbol()}
                                  label="Descuento [en pesos]"
                                  onChange={handleFormattingPrice}
                                  error={getIn(errors, field.name)}
                                  touched={getIn(touched, field.name)}
                                />
                              )}
                            </Field>
                          )}
                          {discountType === 'percentage' && (
                            <Field name={`${inputName}[percentageDiscount]`}>
                              {({ field }) => (
                                <FormikNumberFormat
                                  {...field}
                                  leftAddon="%"
                                  label="Descuento [porcentaje]"
                                  onChange={handleFormattingPercentage}
                                  error={getIn(errors, field.name)}
                                  touched={getIn(touched, field.name)}
                                />
                              )}
                            </Field>
                          )}
                        </Col>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </div>
            {fromProject && !fromMechanicCard && (
              <div
                className={`work-detail-col ${isMobile() ? 'pt-2' : 'pt-3'} pr-0 ${classColumnMobile}`}
                style={{ minWidth: '120px', width: '120px' }}
              >
                <Field name={`${inputName}[parsedProfitMargin]`}>
                  {({ field }) => (
                    <FormikNumberFormat
                      {...field}
                      disabled
                      leftAddon={countryFactory.getSymbol()}
                      label="Margen"
                      className={field.value < 0 ? 'text-danger' : 'text-success'}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </div>
            )}
            <Col md={1} className={`${!isMobile() ? 'mt-4' : ''}`}>
              <Field name={`${inputName}[hidden]`}>
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    custom
                    label="Oculto"
                    margin={`float-left ${isMobile() ? 'col-6' : ''}`}
                    type="switch"
                    field={field}
                  />
                )}
              </Field>
              <Field name={`${inputName}[hasAssignedUser]`}>
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    custom
                    disabled={
                      isAdminUser
                        ? projectWork.done
                        : projectWork.done ||
                          (projectDetail.hasAssignedUser && projectDetail.assignedUserId !== currentUser.id)
                    }
                    checked={projectWork.done ? true : projectDetail.hasAssignedUser}
                    label={`Asignado ${textCapitalize(projectDetail?.assignedUserName || '')} ${formatDate(
                      projectDetail?.assignedAt
                    )}`}
                    margin="float-left"
                    type="switch"
                    onChange={handleChangeAssignedUser}
                    field={field}
                  />
                )}
              </Field>
            </Col>
          </>
        )}
        {!fromMechanicCard && (
          <div className="trash-container">
            <Trash onClick={handleRemoveBtn} className="clickable delete-item" />
          </div>
        )}
        {fromMechanicCard && (
          <Col md={1} className="mt-4">
            <Field name={`${inputName}[hasAssignedUser]`}>
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  disabled
                  checked={projectWork.done ? true : projectDetail.hasAssignedUser}
                  label={`Asignado ${textCapitalize(projectDetail?.assignedUserName || '')} ${formatDate(
                    projectDetail?.assignedAt
                  )}`}
                  margin="float-left"
                  type="switch"
                  onChange={handleChangeAssignedUser}
                  field={field}
                />
              )}
            </Field>
            <Field name={`${inputName}[done]`}>
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  disabled={
                    isAdminUser
                      ? projectWork.done
                      : projectWork.done || (projectDetail.done && projectDetail.doneById !== currentUser.id)
                  }
                  checked={projectDetail.done}
                  label={`Realizado ${textCapitalize(projectDetail?.doneByName || '')} ${formatDate(
                    projectDetail?.doneAt
                  )}`}
                  margin="float-left"
                  type="switch"
                  onChange={handleChangeResponsible}
                  field={field}
                />
              )}
            </Field>
          </Col>
        )}
        <Field name={`${inputName}[doneById]`}>
          {({ field }) => (
            <FormikInput
              {...field}
              inputType="hidden"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Row>
      <SimpleCenteredModal
        title="Asignar Usuario"
        body={modalAssignUserBody}
        show={modalAssignUserShow}
        onHide={handleModalClose}
        closeButton
      />
    </Col>
  );
};

export default ProjectDetailInput;