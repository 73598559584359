import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { Edit, RefreshCcw } from 'react-feather';
import { updateClientRequest } from '../../requests/clients';
import { SimpleCenteredModal } from '../../components/Utils/Modal';
import ClientForm from '../Client/ClientForm';
import { sendAlert } from '../../actions/utils';
import { Can } from '../../config/abilityContext';
import ClientSelect from '../Client/ClientSelect';
import { updateProjectRequest } from '../../requests/projects';
import PublicProjectClientDetails from './PublicProjectClientDetails';

const PublicProjectClient = ({ companyRequiredFieldAttributes, project, setMoreData, version }) => {
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const { client: projectClient, clientId: id } = project;

  const [client, setClient] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    setClient(projectClient);
  }, [projectClient]);

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
  };

  const handleFailureUpdate = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response.data.message }));
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Cliente actualizado con éxito' }));
    handleModalClose();
    setMoreData(m => !m);
  };

  const handleUpdateRequest = values => {
    const sendParams = snakeCaseKeys(values, { exclude: ['_destroy'] });
    updateClientRequest(id, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureUpdate
    });
  };

  const handleUpdateClientRequest = values => {
    const data = { project: { client_id: values.client_id } };
    updateProjectRequest(project.id, {
      dispatch,
      params: data,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureUpdate
    });
  };

  const handleEditClient = () => {
    setModalBody(
      <ClientForm
        client={client}
        action="edit"
        companyRequiredFieldAttributes={companyRequiredFieldAttributes}
        formRequest={handleUpdateRequest}
        editFromProject
        country={user.companyCountry}
      />
    );
    setModalShow(true);
  };

  const handleChangeClient = () => {
    setModalBody(<ClientSelect clientDefault={project?.client} formRequest={handleUpdateClientRequest} />);
    setModalShow(true);
  };

  const handleRedirectToClient = () => {
    history.push(`/clients/${id}`);
  };

  return (
    <>
      <Row>
        <Col md="11" className="justify-content-end">
          <Row>
            <Col md={6}>
              <strong>Cliente</strong>
            </Col>
            <Col md={6}>
              <strong>Datos de Contacto</strong>
            </Col>
          </Row>
        </Col>
        <Can I="update" a="Client">
          {!version && (
            <div className="d-flex">
              <Button variant="link" className="btn-sm text-dark float-right px-0" onClick={handleEditClient}>
                <Edit width="15px" height="15px" />
              </Button>
              <Button variant="link" className="btn-sm text-dark float-right pr-0" onClick={handleChangeClient}>
                <RefreshCcw width="15px" height="15px" />
              </Button>
            </div>
          )}
        </Can>
      </Row>
      <div role="button" onClick={handleRedirectToClient} onKeyPress={handleRedirectToClient} tabIndex={0}>
        <PublicProjectClientDetails client={client} />
      </div>
      <SimpleCenteredModal
        closeButton
        title="EDITAR CLIENTE"
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
      />
    </>
  );
};

export default PublicProjectClient;
