import React, { useState, useEffect, useMemo } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { Form, Formik, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import { FormikInput, FormikCheckBox } from '../../components';
import useCountryFactory from '../../hooks/useCountryFactory';
import NumberInputField from '../../components/Utils/Input/NumberInputField';
import TextAreaInputField from '../../components/Utils/Input/TextAreaInputField';
import SelectInputField from '../../components/Utils/Input/SelectInputField';
import CheckboxInputField from '../../components/Utils/Input/CheckboxInputField';
import { documentTypeOptions } from '../Project/Form/FormOptions';
import { useListProviders } from '../../hooks/useProviders';

const ProjectShowAdvancedConfiguration = ({
  onCancel,
  onSave,
  projectDetail,
  isFreeItem = false
}) => {
  const {
    product,
    requestPurchase,
    purchaseMade,
    hasOnePurchase,
    quantity,
    documentType,
    providerId
  } = projectDetail;

  const [allDisabled, setAllDisabled] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);

  const countryFactory = useCountryFactory();
  const history = useHistory();

  const { providers, isLoading } = useListProviders();

  const providerOptions = useMemo(() => 
    providers?.map(provider => ({
      value: provider.id,
      label: provider.legalName
    })) || []
  , [providers]);

  useEffect(() => {
    if (providers?.length > 0 && providerId && !selectedProvider) {
      const initialProvider = providers.find(p => p.id === providerId);
      if (initialProvider) {
        setSelectedProvider({
          value: initialProvider.id,
          label: initialProvider.legalName
        });
      }
    }
  }, [providers, providerId]);

  useEffect(() => {
    if (hasOnePurchase && purchaseMade && requestPurchase) {
      setAllDisabled(true);
    }
  }, [hasOnePurchase, purchaseMade, requestPurchase]);

  if (isLoading) {
    return <p>Loading Providers...</p>;
  }

  const handleProviderChange = (selectedOption, setFieldValue) => {
    setSelectedProvider(selectedOption);
    setFieldValue('projectDetail.provider_id', selectedOption?.value || null);
  };

  const handleChangeCost = (value, setFieldValue) => {
    setFieldValue('projectDetail.cost', value);
    setFieldValue('projectDetail.costQuotation', value);
    setFieldValue('projectDetail.totalCost', value * quantity);
  };

  const handleCheckRemoveIva = (checked, setFieldValue, values) => {
    let newValue;
    const cost = parseFloat(values.projectDetail.cost);
    if (cost && cost !== 0) {
      if (checked) {
        newValue = cost / 1.19;
      } else {
        newValue = cost * 1.19;
      }
      setFieldValue('projectDetail.cost', newValue);
    }
  };

  return (
    <Formik
      initialValues={{
        projectDetail: {
          ...projectDetail,
          provider_id: providerId
        }
      }}
      onSubmit={(values, { setSubmitting }) => {
        try {
          const { provider_id, product, ...rest } = values.projectDetail;

          const updatedProjectDetail = {
            ...rest,
            provider_id: provider_id,
            product: product ? {
              ...product,
              providerName: selectedProvider?.label || ''
            } : null
          };
          onSave({ ...values, projectDetail: updatedProjectDetail });
        } catch (error) {
          console.error('Error in form submission:', error);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, setFieldValue, values }) => {
        return (
          <Form>
            {isFreeItem && (
              <>
                <Row>
                  <Col md={3}>
                    <FormikInput
                      disabled
                      label="Nombre/Código"
                      value={projectDetail.productNameCustom}
                    />
                  </Col>
                  <Col md={5}>
                    <FormikInput
                      disabled
                      label="Nombre Venta"
                      value={projectDetail.productNameCustom}
                    />
                  </Col>
                  <Col md={4}>
                    <div className="mt-4 pt-2">
                      <CheckboxInputField
                        name="projectDetail[costWithTax]"
                        label="Quitar IVA"
                        onChange={checked => handleCheckRemoveIva(checked, setFieldValue, values)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <SelectInputField
                      name="projectDetail[documentType]"
                      label="Tipo de Documento"
                      placeholder="Seleccionar..."
                      options={documentTypeOptions.filter(opt => opt.value !== '')}
                      value={documentType || ''}
                      onChange={selectedOption =>
                        setFieldValue(
                          'projectDetail.documentType',
                          selectedOption?.value || ''
                        )
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <Field name="projectDetail[folio]">
                      {({ field }) => (
                        <FormikInput
                          {...field}
                          label="Folio"
                          placeholder="Ingrese folio"
                          onChange={e => {
                            field.onChange(e);
                            setFieldValue('projectDetail[folio]', e.target.value);
                          }}
                        />
                      )}
                    </Field>
                  </Col>
                  <Col md={4}>
                    <NumberInputField
                      leftAddon={countryFactory.getSymbol()}
                      name="projectDetail.cost"
                      label="Costo Unitario"
                      decimalScale={2}
                      value={values.projectDetail.cost}
                      onChange={value => handleChangeCost(value, setFieldValue)}
                    />
                  </Col>
                </Row>
              </>
            )}
            {!isFreeItem && (
              <>
                <Row>
                  <Col md={4}>
                    <FormikInput
                      disabled
                      label="Nombre/Código"
                      value={product?.code}
                    />
                  </Col>
                  <Col md={8}>
                    <FormikInput
                      disabled
                      label="Nombre Venta"
                      value={product?.name}
                    />
                  </Col>
                </Row>
                {(!product?.inventoryable) && (
                  <Row className="mt-3">
                    <Col md={4}>
                      <NumberInputField
                        leftAddon={countryFactory.getSymbol()}
                        name="projectDetail.cost"
                        label="Costo Unitario"
                        decimalScale={2}
                        value={values.projectDetail.cost}
                        onChange={value => handleChangeCost(value, setFieldValue)}
                      />
                    </Col>
                    <Col md={4}>
                      <NumberInputField
                        leftAddon={countryFactory.getSymbol()}
                        name="projectDetail.costQuotation"
                        label="Costo Cotización"
                        decimalScale={2}
                        value={values.projectDetail.costQuotation}
                        disabled
                      />
                    </Col>
                    <Col md={4}>
                      <NumberInputField
                        leftAddon={countryFactory.getSymbol()}
                        name="projectDetail.totalCost"
                        label="Costo Total"
                        decimalScale={2}
                        value={values.projectDetail.totalCost}
                        disabled
                      />
                    </Col>
                  </Row>
                )}
                <Row className="mt-3">
                  <Col md={6}>
                    <SelectInputField
                      name="projectDetail[documentType]"
                      label="Tipo de Documento"
                      placeholder="Seleccionar..."
                      options={documentTypeOptions.filter(opt => opt.value !== '')}
                      value={documentType || ''}
                      onChange={selectedOption =>
                        setFieldValue('projectDetail.documentType', selectedOption?.value || '')
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <Field name="projectDetail[folio]">
                      {({ field }) => (
                        <FormikInput
                          {...field}
                          label="Folio"
                          placeholder="Ingrese folio"
                          onChange={e => {
                            field.onChange(e);
                            setFieldValue('projectDetail[folio]', e.target.value);
                          }}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </>
            )}
            <Row className="mt-3">
              <Col md={12}>
                <TextAreaInputField
                  name="projectDetail[notes]"
                  row="3"
                  label="Notas"
                  placeholder="Agregar nota"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={2} />
              <Col md={4} className="text-center">
                <Field name="projectDetail[requestPurchase]">
                  {({ field }) => (
                    <FormikCheckBox
                      {...field}
                      field={field}
                      label="Solicitar compra"
                      customClass="pl-2"
                      disabled={allDisabled || hasOnePurchase}
                    />
                  )}
                </Field>
              </Col>
              <Col md={4} className="text-center">
                <Field name="projectDetail[purchaseMade]">
                  {({ field }) => (
                    <FormikCheckBox
                      {...field}
                      field={field}
                      label="Compra realizada"
                      customClass="pl-2"
                      disabled={
                        allDisabled ||
                        !values.projectDetail.requestPurchase ||
                        (hasOnePurchase && purchaseMade)
                      }
                      onChange={e => {
                        field.onChange(e);
                        if (e.target.checked) {
                          setFieldValue(
                            'projectDetail[purchaseDate]',
                            new Date().toLocaleDateString('es-ES')
                          );
                        } else {
                          setFieldValue('projectDetail[purchaseDate]', '');
                        }
                      }}
                    />
                  )}
                </Field>
              </Col>
              <Col md={2} />
            </Row>
            {!isFreeItem && product?.movementDetails?.length > 0 && (
              <Row className="mt-4">
                <Col md={12}>
                  <h4 className="text-uppercase text-center my-3">Historial</h4>
                  <Table responsive borderless hover>
                    <thead className="border-top-borderless-table">
                      <tr className="header-border-bottom">
                        <th>Fecha de Movimiento</th>
                        <th>Tipo</th>
                        <th>Cantidad</th>
                        <th>Valor Unitario</th>
                      </tr>
                    </thead>
                    <tbody>
                      {product.movementDetails.map((movementDetail, index) => (
                        <tr key={`movement-detail-${index}`}>
                          <td>{movementDetail?.dateFormatted}</td>
                          <td>{movementDetail?.translatedMovementType}</td>
                          <td>{movementDetail?.quantity || 0}</td>
                          <td>{movementDetail?.salePriceFormatted}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
            <Row className="mt-3">
              <Col md={12}>
                <div className="mb-1">
                  <strong>Proveedor</strong>
                </div>
                <SelectInputField
                  name="projectDetail[provider_id]"
                  placeholder="Seleccionar Proveedor"
                  options={providerOptions}
                  value={selectedProvider}
                  onChange={option => handleProviderChange(option, setFieldValue)}
                />
                <Button
                  block
                  variant="secondary"
                  onClick={() => {
                    history.push('/providers/new', { 
                      from: 'ProjectShowAdvancedConfiguration',
                      projectDetailId: projectDetail.id 
                    });
                  }}
                  className="mt-2"
                >
                  Crear Proveedor
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              {isFreeItem ? (
                <>
                  <Col md={6}>
                    <Button
                      block
                      variant="outline-dark"
                      onClick={onCancel}
                      disabled={isSubmitting}
                    >
                      Cancelar
                    </Button>
                  </Col>
                  <Col md={6}>
                    {!allDisabled && (
                      <Button
                        block
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Guardar
                      </Button>
                    )}
                  </Col>
                </>
              ) : (
                <>
                  <Col md={4}>
                    <Button
                      block
                      variant="outline-dark"
                      onClick={onCancel}
                      disabled={isSubmitting}
                    >
                      Cancelar
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button
                      block
                      variant="secondary"
                      disabled={isSubmitting}
                      onClick={() => history.push(`/products/${product?.id}/edit`)}
                    >
                      Editar ítem
                    </Button>
                  </Col>
                  <Col md={4}>
                    {!allDisabled && (
                      <Button
                        block
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Guardar
                      </Button>
                    )}
                  </Col>
                </>
              )}
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProjectShowAdvancedConfiguration;
