const workshopManagerAbility = (can, cannot) => {
  can('manage', 'all');
  cannot('create', 'BranchOffice');
  cannot('create', 'Company');
  cannot('create', 'User');
  cannot('destroy', 'BranchOffice');
  cannot('destroy', 'Company');
  cannot('destroy', 'User');
  cannot('update', 'BranchOffice');
  cannot('update', 'Company');
  cannot('read', 'Dashboard');
  cannot('destroy', 'ProjectChecklist');
};

export default workshopManagerAbility;
