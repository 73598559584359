import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Row, Col, Button, Toast } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';

import { Copy, Edit, ChevronDown, ChevronUp, RefreshCcw } from 'react-feather';
import { updateVehicleRequest } from '../../requests/vehicles';
import ProjectVehicleDetails from './ProjectVehicleDetails';
import { SimpleCenteredModal } from '../../components/Utils/Modal';
import VehicleForm from '../Vehicle/VehicleForm';
import { sendAlert } from '../../actions/utils';
import VehicleSelect from '../Vehicle/VehicleSelect';
import { updateProjectRequest } from '../../requests/projects';

const PublicProjectVehicle = ({
  companyRequiredFieldAttributes,
  project,
  setMoreData,
  fromProjectEdit,
  version,
  toggleVehicleExpanded,
  collapsible
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { vehicle: projectVehicle, clientId, rememberMaintenanceFormated } = project;
  const { id: vehicleId } = projectVehicle;

  const [vehicle, setVehicle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setVehicle(projectVehicle);
  }, [projectVehicle]);

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
  };

  const handleFailureUpdate = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: 'error', message: response.data.message }));
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Vehículo actualizado con éxito' }));
    handleModalClose();
    setMoreData(m => !m);
  };

  const handleUpdateRequest = values => {
    const sendParams = snakeCaseKeys(values);
    updateVehicleRequest(clientId, vehicleId, {
      dispatch,
      params: sendParams,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureUpdate
    });
  };

  const handleEditVehicle = () => {
    setModalBody(
      <VehicleForm
        companyRequiredFieldAttributes={companyRequiredFieldAttributes}
        vehicle={vehicle}
        action="edit"
        formRequest={handleUpdateRequest}
      />
    );
    setModalShow(true);
  };

  const handleRedirectToVehicle = () => {
    history.push(`/clients/${clientId}/vehicles/${vehicleId}`);
  };

  const formatVehicleInfo = () => {
    return `Patente: ${vehicle.plate}\nMarca: ${vehicle.vehicleBrandName}\nModelo: ${vehicle.vehicleModelName}\nAño: ${
      vehicle.year
    }\nVin: ${vehicle.vin}\nTamaño Motor: ${vehicle.engineCapacity &&
      `${vehicle.engineCapacity} [CC]`}\nTipo de combustible: ${vehicle.translatedFuelType}`;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(formatVehicleInfo()).then(() => {
      setShow(true);
    });
  };

  const toggle = () => {
    setExpanded(!expanded);
    toggleVehicleExpanded();
  };

  const handleUpdateVehicleRequest = values => {
    const data = { project: { ...values } };
    updateProjectRequest(project.id, {
      dispatch,
      params: data,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureUpdate
    });
  };

  const handleChangeVehicle = () => {
    setModalBody(
      <VehicleSelect vehicleDefault={projectVehicle} clientId={clientId} formRequest={handleUpdateVehicleRequest} />
    );
    setModalShow(true);
  };

  return (
    <>
      <Row className="justify-content-between">
        <Col md="auto">
          <strong className="mr-1">Vehículo</strong>
          <Copy size={15} onClick={handleCopy} role="button" />
        </Col>
        <Col md="auto">
          <Toast className="bg-info text-white" onClose={() => setShow(false)} show={show} delay={1500} autohide>
            <Toast.Body>Mensaje Copiado</Toast.Body>
          </Toast>
        </Col>
        {!expanded && (
          <Col md="auto">
            <strong>
              {vehicle.vehicleBrandName + ' ' + vehicle.vehicleModelName + ' ' + vehicle.year + ' - ' + vehicle.plate}
            </strong>
          </Col>
        )}
        {!fromProjectEdit && !version && (
          <div className="d-flex">
            <Button variant="link" className="btn-sm text-dark float-right px-0" onClick={handleEditVehicle}>
              <Edit width="15px" height="15px" />
            </Button>
            <Button variant="link" className="btn-sm text-dark float-right pr-3" onClick={handleChangeVehicle}>
              <RefreshCcw width="15px" height="15px" />
            </Button>
          </div>
        )}
      </Row>
      {(expanded || !collapsible) && (
        <div role="button" onClick={handleRedirectToVehicle} onKeyPress={handleRedirectToVehicle} tabIndex={0}>
          <ProjectVehicleDetails vehicle={vehicle} rememberMaintenanceFormated={rememberMaintenanceFormated} />
        </div>
      )}
      <SimpleCenteredModal
        closeButton
        title="EDITAR VEHÍCULO"
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
      />
      {collapsible && (
        <Row className="mt-auto">
          <button className="w-100 btn btn-light" onClick={toggle}>
            {expanded ? <ChevronUp size={15} /> : <ChevronDown size={15} />}
          </button>
        </Row>
      )}
    </>
  );
};

export default PublicProjectVehicle;
