import React from 'react';
import { Switch } from 'react-router-dom';
import { LoggedLayout, NonLoggedLayout, PublicLayout, PublicProjectLayout } from './layouts';
import {
  AssignedTaskIndex,
  AssociatePurchaseIndex,
  BulkUploadIndex,
  BranchOfficeIndex,
  CalendarIndex,
  ChecklistEdit,
  ChecklistIndex,
  ChecklistNew,
  ClientEdit,
  ClientIndex,
  ClientNew,
  ClientShow,
  CollectionIndex,
  CompanyEdit,
  CompanyLogsIndex,
  Components,
  DashboardIndex,
  Home,
  HomeIndex,
  HomeContact,
  IncidentIndex,
  IncidentTypeIndex,
  Login,
  NpsIndex,
  NpsAnswerIndex,
  Page,
  PlanIndex,
  ProductCategoryIndex,
  ProductEdit,
  ProductIndex,
  ProductNew,
  ProductShow,
  CreditNotesIndex,
  ProfileEdit,
  ProductInventoryIndex,
  ProductInventoryCompanyIndex,
  ProductMovementCompanyIndex,
  ProductMovementIndex,
  ProductMovementNew,
  ProductMovementShow,
  ProfileIndex,
  ProjectEdit,
  ProjectAutoparts,
  ProjectIndex,
  ProjectQuoteIndex,
  ProjectInvoiceIndex,
  ProjectLogs,
  ProjectMechanicList,
  ProjectMechanicReviewEdit,
  ProjectPaymentIndex,
  ProjectReceptionMechanic,
  ProjectShow,
  ProjectShowPublic,
  ProjectVehicleReviewIndex,
  ProjectVersionIndex,
  ProjectWorkHourIndex,
  ProjectWorkHourReport,
  ProviderEdit,
  ProviderIndex,
  ProviderNew,
  ProviderShow,
  PurchaseBookIndex,
  PurchaseManagementAdminIndex,
  PurchaseManagementEdit,
  PurchaseManagementIndex,
  PurchaseManagementNew,
  PurchaseManagementShow,
  RecoverPassword,
  ResetPassword,
  SalesIncomeIndex,
  StockTakeNew,
  Subscription,
  TemplateIndex,
  UserChangePassword,
  UserEdit,
  UserIndex,
  UserNew,
  VehicleCompanyIndex,
  VehicleEdit,
  VehicleIndex,
  VehicleNew,
  VehicleShow,
  WorkIndex,
  ReviewGroupEdit,
  ReviewGroupIndex,
  ServicesIndex
} from '../screens';
import Kanban from '../../lib/kanban/Kanban';
import ScheduleAppointment from '../screens/Home/ScheduleAppointment';

const routes = (
  <Switch>
    <LoggedLayout exact path="/associate_purchase" component={AssociatePurchaseIndex} />

    <LoggedLayout exact path="/branch_offices" component={BranchOfficeIndex} />

    <LoggedLayout exact path="/calendar" component={CalendarIndex} />

    <LoggedLayout exact path="/clients" component={ClientIndex} />
    <LoggedLayout exact path="/clients/new" component={ClientNew} />
    <LoggedLayout exact path="/clients/:id" component={ClientShow} />
    <LoggedLayout exact path="/clients/:id/edit" component={ClientEdit} />

    <LoggedLayout exact path="/clients/:client_id/vehicles" component={VehicleIndex} />
    <LoggedLayout exact path="/clients/:client_id/vehicles/new" component={VehicleNew} />
    <LoggedLayout exact path="/clients/:client_id/vehicles/:id" component={VehicleShow} />
    <LoggedLayout exact path="/clients/:client_id/vehicles/:id/edit" component={VehicleEdit} />

    <LoggedLayout exact path="/company_profile" component={CompanyEdit} />

    <LoggedLayout exact path="/company_logs" component={CompanyLogsIndex} />

    <LoggedLayout exact path="/dashboard" component={DashboardIndex} />

    <LoggedLayout exact path="/home" component={HomeIndex} />

    <LoggedLayout exact path="/incidents" component={IncidentIndex} />
    <LoggedLayout exact path="/incident_types" component={IncidentTypeIndex} />

    <LoggedLayout exact path="/inventories" component={ProductInventoryCompanyIndex} />

    <LoggedLayout exact path="/inventory" component={ProductInventoryIndex} />

    <LoggedLayout exact path="/movements" component={ProductMovementCompanyIndex} />

    <LoggedLayout exact path="/movement" component={ProductMovementIndex} />
    <LoggedLayout exact path="/movements/new" component={ProductMovementNew} />
    <LoggedLayout exact path="/movements/stocktake" component={StockTakeNew} />
    <LoggedLayout exact path="/movements/:id" component={ProductMovementShow} />

    <LoggedLayout exact path="/products" component={ProductIndex} />
    <LoggedLayout exact path="/products/new" component={ProductNew} />
    <LoggedLayout exact path="/products/:id" component={ProductShow} />
    <LoggedLayout exact path="/products/:id/edit" component={ProductEdit} />

    <LoggedLayout exact path="/services" component={ServicesIndex} />

    <LoggedLayout exact path="/product_categories" component={ProductCategoryIndex} />

    <LoggedLayout exact path="/profile" component={ProfileIndex} />
    <LoggedLayout exact path="/profile/edit" component={ProfileEdit} />

    <LoggedLayout exact path="/projects" component={ProjectIndex} />
    <LoggedLayout exact path="/projects_quotes" component={ProjectQuoteIndex} />
    <LoggedLayout exact path="/projects/:id" component={ProjectShow} />
    <LoggedLayout exact path="/projects/:id/edit" component={ProjectEdit} />
    <LoggedLayout exact path="/projects/:id/logs" component={ProjectLogs} />
    <LoggedLayout exact path="/projects/:id/reception" component={ProjectReceptionMechanic} />
    <LoggedLayout exact path="/projects/:id/mechanic" component={ProjectReceptionMechanic} />
    <LoggedLayout exact path="/projects/:id/output" component={ProjectReceptionMechanic} />
    <LoggedLayout exact path="/projects/:id/pre_inspection" component={ProjectReceptionMechanic} />
    <LoggedLayout exact path="/projects/:id/autoparts" component={ProjectAutoparts} />
    <LoggedLayout exact path="/projects/:id/quote" component={ProjectReceptionMechanic} />
    <LoggedLayout exact path="/projects/:id/versions/:version" component={ProjectVersionIndex} />
    <LoggedLayout exact path="/project_vehicle_reviews" component={ProjectVehicleReviewIndex} />
    <LoggedLayout exact path="/project_mechanic_reviews" component={Kanban} />
    <LoggedLayout exact path="/project_mechanic_reviews/:id" component={ProjectMechanicReviewEdit} />
    <LoggedLayout exact path="/project_mechanic_list" component={ProjectMechanicList} />

    <LoggedLayout exact path="/project_payments" component={ProjectPaymentIndex} />
    <LoggedLayout exact path="/project_invoices" component={ProjectInvoiceIndex} />
    <LoggedLayout exact path="/sales_income" component={SalesIncomeIndex} />
    <LoggedLayout exact path="/credit_notes" component={CreditNotesIndex} />
    <LoggedLayout exact path="/collections" component={CollectionIndex} />
    <LoggedLayout exact path="/purchase_books" component={PurchaseBookIndex} />

    <LoggedLayout exact path="/project_work_hours" component={ProjectWorkHourIndex} />
    <LoggedLayout exact path="/project_work_hour_reports" component={ProjectWorkHourReport} />

    <LoggedLayout exact path="/providers" component={ProviderIndex} />
    <LoggedLayout exact path="/providers/new" component={ProviderNew} />
    <LoggedLayout exact path="/providers/:id" component={ProviderShow} />
    <LoggedLayout exact path="/providers/:id/edit" component={ProviderEdit} />

    <LoggedLayout exact path="/purchase_managements" component={PurchaseManagementAdminIndex} />
    <LoggedLayout exact path="/purchase_management" component={PurchaseManagementIndex} />
    <LoggedLayout exact path="/purchase_managements/new" component={PurchaseManagementNew} />
    <LoggedLayout exact path="/purchase_managements/:id" component={PurchaseManagementShow} />
    <LoggedLayout exact path="/purchase_managements/:id/edit" component={PurchaseManagementEdit} />

    <LoggedLayout exact path="/checklists" component={ChecklistIndex} />
    <LoggedLayout exact path="/checklists/new" component={ChecklistNew} />
    <LoggedLayout exact path="/checklists/:id/edit" component={ChecklistEdit} />

    <LoggedLayout exact path="/subscription" component={Subscription} />

    <LoggedLayout exact path="/users" component={UserIndex} />
    <LoggedLayout exact path="/users/new" component={UserNew} />
    <LoggedLayout exact path="/users/:id/edit" component={UserEdit} />
    <LoggedLayout exact path="/users/password/change_password" component={UserChangePassword} />

    <LoggedLayout exact path="/vehicles" component={VehicleCompanyIndex} />

    <LoggedLayout exact path="/works" component={WorkIndex} />

    <LoggedLayout exact path="/templates" component={TemplateIndex} />

    <LoggedLayout exact path="/bulk_uploads" component={BulkUploadIndex} />
    <LoggedLayout exact path="/nps_answers" component={NpsAnswerIndex} />
    <LoggedLayout exact path="/assigned_tasks" component={AssignedTaskIndex} />

    <NonLoggedLayout exact path="/login" component={Login} />
    <NonLoggedLayout exact path="/recover_password" component={RecoverPassword} />
    <NonLoggedLayout exact path="/reset_password" component={ResetPassword} />

    <PublicProjectLayout exact path="/public/projects/:token/:version" component={ProjectShowPublic} />
    <PublicProjectLayout exact path="/public/nps/:token" component={NpsIndex} />
    <PublicProjectLayout exact path="/public/appointment/:companyCode" component={ScheduleAppointment} />

    <PublicLayout exact path="/" component={Home} />
    <PublicLayout exact path="/components" component={Components} />
    <PublicLayout exact path="/contact" component={HomeContact} />
    <PublicLayout path="/plans" component={PlanIndex} />
    <PublicLayout path="/:page" component={Page} />
  </Switch>
);

export default routes;
