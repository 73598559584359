import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import downloadFile, { camelCaseEmptyStringRecursive } from '../../services/utils';
import ComponentDataTable from '../../components/Utils/DataTable';
import { DefaultModal, InputRemoteSelect, SimpleCenteredModal } from '../../components';
import {
  indexProjectWorkHoursRequest,
  debounceIndexProjectWorksRequest,
  deleteProjectWorkHourRequest,
  updateProjectWorkHourRequest
} from '../../requests/projectWorkHours';
import { indexUsersRequest } from '../../requests/user';
import { sendAlert } from '../../actions/utils';
import ProjectWorkHourAdminForm from './ProjectWorkHourAdminForm';

const ProjectWorkHourDataTable = props => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);
  const [centeredModalShow, setCenteredModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [onRequest, setOnRequest] = useState(false);
  const [projectWorkHours, setProjectWorkHours] = useState([]);
  const [user, setUser] = useState([]);
  const [users, setUsers] = useState('');
  const [queries, setQueries] = useState({});
  const { columns, moreData, setMoreData } = props;

  const customStyles = {
    control: styles => ({ ...styles, borderRadius: '1.5rem', cursor: 'pointer' })
  };

  const handleModalClose = () => {
    setCenteredModalShow(false);
    setModalBody('');
    setModalShow(false);
    setModalTitle('');
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
    setOnRequest(false);
    handleModalClose();
  };

  const handleSuccessRequest = response => {
    const responseProjectWorkHours = response.data.data;
    const { amount: responseAmount } = response.data.metadata;
    setProjectWorkHours(responseProjectWorkHours);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    const sendParams = {
      ...params,
      sort_direction: 'desc',
      sort_column: 'date',
      filter_accountable_user: user
    };
    debounceIndexProjectWorksRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessRequest,
      failureCallback: handleFailureRequest
    });
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Hora de trabajo actualizada con éxito' }));
    setMoreData(!moreData);
    handleModalClose();
  };

  const handleUpdateRequest = values => {
    const {
      projectWorkHour: { id }
    } = values;
    updateProjectWorkHourRequest(id, {
      dispatch,
      params: snakeCaseKeys(values, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'edit':
        setModalBody(
          <ProjectWorkHourAdminForm
            action={action}
            formRequest={handleUpdateRequest}
            handleModalClose={handleModalClose}
            submitVariant="success"
            projectWorkHour={camelCaseEmptyStringRecursive(item)}
          />
        );
        setCenteredModalShow(true);
        setModalTitle('Editar Hora de Trabajo');
        break;
      case 'destroy':
        setModalShow(true);
        setModalItem(item);
        setModalTitle('Eliminar Hora de Trabajo');
        setModalBody('¿Estás seguro que deseas eliminar esta Hora de Trabajo?');
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const handleSuccessRemove = () => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: 'Hora de trabajo eliminada con éxito' }));
    setMoreData(!moreData);
  };

  const handleModalConfirm = () => {
    const { id: projectWorkHourId } = modalItem;

    deleteProjectWorkHourRequest(projectWorkHourId, {
      dispatch,
      successCallback: handleSuccessRemove,
      failureCallback: handleFailureRequest
    });
  };

  const handleChange = value => {
    setUser(value ? value.value : '');
  };

  const fetchUsers = () => {
    indexUsersRequest({
      dispatch,
      params: {
        active_users: true,
        for_selector: true,
        sort_user_by_fullname: 'ASC',
        display_length: 100,
        active_users: true
      },
      successCallback: response => setUsers(response.data.data)
    });
  };

  const handleSuccessDownload = response => downloadFile(response);

  const downloadIndex = (format, params) => {
    const sendParams = {
      ...params,
      display_length: 100000,
      filter_accountable_user: user
    };
    indexProjectWorkHoursRequest({
      dispatch,
      params: sendParams,
      format,
      successCallback: handleSuccessDownload,
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchUsers, []);

  const sortColumnCase = name => {
    switch (name) {
      case 'accountable_user':
        return { sort_accountable_user: 'full_name' };
      case 'project_work_name':
        return { sort_project_work_name: name };
      case 'project':
        return { sort_project: 'code' };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <Row className="justify-content-end mt-2">
        <Col md={2}>
          <Button variant="secondary" block onClick={() => downloadIndex('xlsx', queries)}>
            Exportar
          </Button>
        </Col>
      </Row>

      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        handleSortCase={sortColumnCase}
        data={projectWorkHours}
        withDate
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          setQueries(response);
          if (!onRequest) handleIndexRequest(response);
        }}
        firstCustomMediumSearch={
          <InputRemoteSelect
            isClearable
            placeholder="Usuario"
            defaultOptions={users}
            onChange={data => {
              handleChange(data);
              setMoreData(!moreData);
            }}
            styles={customStyles}
            request={fetchUsers}
          />
        }
      />
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={centeredModalShow}
        onHide={handleModalClose}
        centered={false}
        closeButton={false}
      />
      {modalShow && (
        <DefaultModal
          title={modalTitle}
          body={modalBody}
          show={modalShow}
          handleClose={handleModalClose}
          handleConfirm={handleModalConfirm}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />
      )}
    </>
  );
};

export default ProjectWorkHourDataTable;
