import React, { useEffect, useState, useContext } from 'react';
import {useDispatch} from 'react-redux';
import { Container, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { sendAlert } from '../../actions/utils';
import snakeCaseKeys from 'snakecase-keys';
import { showProjectRequest, updateProjectRequest } from '../../requests/projects';
import {
  quotation,
  quotationCartProducts,
  addQuotationToCart,
  removeQuotationFromCart
} from '../../requests/autoparts';
import { camelCaseEmptyStringRecursive } from '../../services/utils';
import basicProject from './Show/basicProject';
import ProjectLinkButtons from './ProjectLinkButtons';
import ProjectTitle from './ProjectTitle';
import ProjectAutopartsListEdit from './ProjectAutoparts/ProjectAutopartsListEdit'
import ProjectAutopartsOffer from './ProjectAutoparts/ProjectAutopartsOffer'
import {Can} from "../../config/abilityContext";
import {CartContext} from "../../config/cartContext";

const ProjectAutoparts = ({ match }) => {
  const [moreData, setMoreData] = useState(false);
  const [project, setProject] = useState(basicProject);
  const [offeredCategories, setOfferedCategories] = useState([]);
  const [quotationId, setQuotationId] = useState('');
  const [inCart, setInCart] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const { deskSell: isDeskSale, isQuote, orderType, vehicle, checklistsAttributes } = project;
  const mechanicChecklist = checklistsAttributes.find(checklist => checklist.checklistType === 'mechanic');
  const dispatch = useDispatch();
  const { addQuotation, removeQuotation } = useContext(CartContext);
  const { id } = match.params;

  const addProductsToSelectedProducts = (products, alert=false, moreData=false) => {
    quotationCartProducts(project.company.hashid, id, {
      dispatch,
      params: {categories: products},
      successCallback: () => {
        if (alert) dispatch(sendAlert({ kind: 'success', message: `Producto agregado con éxito` }));
        if (moreData) setMoreData(m => !m);
      },
      failureCallback: () => {}
    });
  }

  const addProductToSelectedProducts = (category_id, product, values) => {
    if (values.qtyInput) {
      const categories = {};
      const quantity = parseInt(values.qtyInput) + (product.inCart ?? 0)
      categories[category_id] = [{
        id: product.id,
        quantity: quantity
      }];
      addProductsToSelectedProducts(categories, true, true);
    }
  }

  const handleUpdateRequest = (values) => {
    const { projectAutopartsAttributes } = values.project;
    const params = { project: {} }
    params.project.projectAutopartsAttributes = { ...projectAutopartsAttributes };
    updateProjectRequest(id, {
      dispatch,
      params: snakeCaseKeys(params, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });

    const categories = {};
    projectAutopartsAttributes.forEach(cat => {
      categories[cat.categoryId] = cat.selectedProducts.map(prod => {
        return {id: prod.id, quantity: prod.inCart && !cat._destroy ? prod.inCart : 0};
      });
    });
    addProductsToSelectedProducts(categories);
  };

  const addToCart = () => {
    const categories = {};
    const products = [];
    offeredCategories.forEach(cat => {
      categories[cat.categoryId] = cat.products.map(prod => {
        if (prod.inCart > 0) products.push({...prod, quantity: prod.inCart});
        return {id: prod.id, quantity: prod.inCart || 0};
      });
    });
    quotationCartProducts(project.company.hashid, id, {
      dispatch,
      params: {categories: categories},
      successCallback: () => {
        addQuotationToCart(quotationId, {
          dispatch,
          successCallback: () => {
            setInCart(true);
            dispatch(sendAlert({ kind: 'success', message: `Agregada con éxito` }));
            addQuotation(quotationId, {ot: id, products, title: `OT ${id} - ${project.vehicle.name}`});
          },
          failureCallback: handleFailureRequest
        });
      },
      failureCallback: handleFailureRequest
    });
  };

  const removeFromCart = () => {
    removeQuotationFromCart(quotationId, {
      dispatch,
      successCallback: () => {
        setInCart(false);
        dispatch(sendAlert({ kind: 'success', message: `Quitada con éxito` }));
        removeQuotation(quotationId);
      },
      failureCallback: handleFailureRequest
    });
  };
  
  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: `Lista de repuestos actualizada con éxito` }));
    setMoreData(m => !m);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessShow = response => {
    setProject(camelCaseEmptyStringRecursive(response.data));
  };

  const fetchProject = () => {
    showProjectRequest(id, {
      dispatch,
      params: { screen: 'ProjectEdit' },
      successCallback: handleSuccessShow,
      failureCallback: handleFailureRequest
    });
  };

  const handleFailureQuote = response => {
    console.log(response);
    setOnRequest(false);
  }

  const handleSuccessQuote = async (response) => {
    setQuotationId(response.quotationId);
    setInCart(response.inCart);
    // const autopartsCategories = await Promise.all(project.projectAutopartsAttributes.map(a => a.categoryId));
    // const categories = await Promise.all(response.products.map(p => {
    //   if (p.offeredProducts.length) {
    //     if (autopartsCategories.includes(p.categoryId)) {
    //       return {...p, pos: 0};
    //     } else {
    //       return {...p, pos: 1};
    //     }
    //   } else {
    //     if (autopartsCategories.includes(p.categoryId)) {
    //       return {...p, pos: 2};
    //     } else {
    //       return {...p, pos: 3};
    //     }
    //   }
    // }));
    // categories.sort((a, b) => {
    //   if (a.pos !== b.pos) return a.pos - b.pos;
    //   return a.categoryName.localeCompare(b.categoryName);
    // });
    setOfferedCategories(response.products);
    setOnRequest(false);
  };
    
  const quote = () => {
    setOnRequest(true);
  
    quotation(project.company.hashid, id, {
      dispatch,
      failureCallback: response => handleFailureQuote(response),
      successCallback: response => handleSuccessQuote(response.data)
    });
  };

  useEffect(fetchProject, [moreData]);
  useEffect(quote, [project.projectAutopartsAttributes]);

  return (
    <Can I="manage" a="Project">
      <Container className='pb-3'>
        <ProjectTitle isQuote={isQuote} orderType={orderType} id={id}/>
        <ProjectLinkButtons id={id} isDeskSale={isDeskSale} isQuote={isQuote} clientNumber={project.client.cellphone} fromProjectAutoparts/>
        <Row className="mb-3">
          <Col md={2} className="project-section py-3 pb-0">
            <p className='mb-0'>PATENTE/CÓDIGO</p>
            <span className="font-weight-bold data-title">{vehicle.plate}</span>          
          </Col>
          <Col md={2} className="project-section py-3 pb-0">
            <p className='mb-0'>MARCA</p>
            <span className="font-weight-bold data-title">{vehicle.vehicleBrandName}</span>          
          </Col>
          <Col md={2} className="project-section py-3 pb-0">
            <p className='mb-0'>MODELO</p>
            <span className="font-weight-bold data-title">{vehicle.vehicleModelName}</span>          
          </Col>
          <Col md={2} className="project-section py-3 pb-0">
            <p className='mb-0'>AÑO</p>
            <span className="font-weight-bold data-title">{vehicle.year}</span>          
          </Col>
          <Col md={2} className="project-section py-3 pb-0">
            <p className='mb-0'>VIN</p>
            <span className="font-weight-bold data-title">{vehicle.vin}</span>          
          </Col>
          <Col className="project-section ml-2 py-3 pb-0">
            {mechanicChecklist && mechanicChecklist.checklistId !== '' && mechanicChecklist.checklistId !== null && (
              <Link className="fw-bolder" to={`/projects/${id}/mechanic`}>Ver Checklist de Inspección Técnica</Link>
            )}       
          </Col>
        </Row>
        <Row className='project-section pb-0 mb-3'>
          <ProjectAutopartsListEdit 
            project={project}
            setMoreData={setMoreData}
            offeredCategories={offeredCategories}
            inCart={inCart}
            action="edit"
            submitVariant="success"
            addToCart={addToCart}
            removeFromCart={removeFromCart}
            formRequest={(values) => handleUpdateRequest(values)}
          />
        </Row>
        <Row className="project-section pt-3">
          <ProjectAutopartsOffer 
            project={project}
            offeredCategories={offeredCategories}
            onRequest={onRequest}
            addProductToSelectedProducts={addProductToSelectedProducts}
          />
        </Row>
      </Container>
    </Can>
  );
};

export default ProjectAutoparts;
