import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { AlertTriangle } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LinkBtn } from '../../components';
import { updateQuoteRequest } from '../../requests/projects';
import { sendAlert } from '../../actions/utils';
import GoBackButton from '../../components/GoBackButton';

const QuoteTitle = ({ version, projectVersion, id }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const updateQuote = () => {
    updateQuoteRequest(id, {
      dispatch,
      params: { version_number: projectVersion.versionNumber },
      formData: true,
      successCallback: () => {
        history.push(`/projects/${id}`);
        dispatch(sendAlert({ kind: 'success', message: 'Presupuesto Aprobado' }));
      },
      failureCallback: error => {
        const {
          data: { message }
        } = error.response;
        dispatch(sendAlert({ kind: 'error', message }));
      }
    });
  };
  return (
    <Row className="mt-4 mb-4">
      <Col md={10}>
        <h2 className="text-uppercase">Presupuesto</h2>
        <h2 className="font-italic mt-3">N° de presupuesto {id.toLocaleString('es-CL')}</h2>
      </Col>
      <Col md={2}>
        <GoBackButton />
      </Col>
      {version && (
        <Col md={5} className="project-section mt-2">
          <Row>
            <Col md={1}>
              <AlertTriangle width="20px" height="20px" className="text-warning" />
            </Col>
            <Col md={8}>
              <p className="mb-1">Estás viendo una versión antigua de esta orden.</p>
              <strong>
                Versión #{projectVersion.versionNumber}: {projectVersion.createdAt}
              </strong>
            </Col>
            <Col className="pl-0">
              <LinkBtn block variant="primary" to={`/projects/${id}`}>
                Ver versión actual
              </LinkBtn>
              <Button block variant="secondary" onClick={updateQuote}>
                Aprobar presupuesto
              </Button>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default QuoteTitle;
