import camelCaseRecursive from 'camelcase-keys-recursive';
import api_v2 from '../services/api_v2';
import useQuery, { useInvalidateQuery } from './useQuery';

const createProjectInvoice = (projectId, params) =>
  api_v2.post(`/projects/${projectId}/project_invoices.json`, params).then(res => res.data);

const listProjectInvoicesByProject = (projectId, view, filters) => {
  const params = { view, q: filters };
  return api_v2.get(`/projects/${projectId}/project_invoices.json`, params).then(res => res.data);
};

export const useReFetchProjectInvoices = projectId => {
  const invalidateQueries = useInvalidateQuery();
  return () => {
    invalidateQueries(['project_invoices', projectId]);
  };
};

export const useListProjectInvoicesByProject = (projectId, view = 'list', filters) => {
  const { data = [], isLoading } = useQuery(
    ['project_invoices', projectId, view, filters],
    listProjectInvoicesByProject,
    {
      enabled: !!projectId
    }
  );
  const reFetch = useReFetchProjectInvoices(projectId);
  const projectInvoices = camelCaseRecursive(data);
  return { projectInvoices, reFetch, isLoading };
};

export const useCreateProjectInvoice = projectId => {
  const create = async (values, options = {}) => {
    try {
      const data = await createProjectInvoice(projectId, values);
      options.onSuccess && options.onSuccess(data);
    } catch (error) {
      options.onError && options.onError(error);
    }
  };
  return { create };
};
