import camelCaseRecursive from 'camelcase-keys-recursive';
import api_v2 from '../services/api_v2';
import useQuery, { useInvalidateQuery } from './useQuery';

const getProjects = () => api_v2.get(`/projects.json`).then(res => res.data);

const getProject = (projectCode, view) => {
  return api_v2.get(`/projects/${projectCode}.json?view=${view}`).then(res => res.data);
};

const updateProject = (projectCode, params) =>
  api_v2.put(`/projects/${projectCode}.json`, params).then(res => res.data);

export const useReFetchProject = projectId => {
  const invalidateQueries = useInvalidateQuery();
  return () => {
    invalidateQueries(['project', projectId?.toString()]);
  };
};

export const useGetProject = (projectCode, view) => {
  const { data = {}, isLoading } = useQuery(['project', projectCode?.toString(), view], getProject, {
    enabled: !!projectCode
  });
  const invalidateQueries = useReFetchProject(projectCode);
  const project = camelCaseRecursive(data);
  return { project, reFetch: invalidateQueries, isLoading };
};

export const useUpdateProject = projectCode => {
  const update = async (values, options = {}) => {
    try {
      const data = await updateProject(projectCode, values);
      options.onSuccess && options.onSuccess(data);
    } catch (error) {
      options.onError && options.onError(error);
    }
  };
  return { update };
};

export const useListProjects = () => {
  const { data = [] } = useQuery(['projects'], getProjects);
  const invalidateQueries = useInvalidateQuery();
  const reFetch = () => {
    invalidateQueries(['projects']);
  };
  let projects = camelCaseRecursive(data);
  return { projects, reFetch };
};
