/* eslint-disable react/button-has-type */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faHome, faClipboard, faCheckCircle, faPlus } from '@fortawesome/fontawesome-free-solid';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import { createCalendarEventsRequest } from '../../../requests/calendarEvents';
import { createProjectRequest } from '../../../requests/projectsV2';
import { sendAlert, saveCurrentClient, saveSecondCurrentClient } from '../../../actions/utils';
import { SimpleCenteredModal } from '../Modal';
import CalendarEventForm from '../../../screens/Calendar/Form/CalendarEventForm';
import { useHistory } from 'react-router-dom';
import { camelCaseEmptyStringRecursive } from '../../../services/utils';
import { createProjectChecklistRequest } from '../../../requests/projectChecklists';
import { reviewTypes } from '../../../screens/ReviewGroup/Constants';
import ProjectSelectChecklistForm from '../../../screens/Project/Form/ProjectSelectChecklistForm';

// const themes = {
//   light: {
//     sidebar: {
//       backgroundColor: '#ffffff',
//       color: '#607489',
//     },
//     menu: {
//       menuContent: '#fbfcfd',
//       icon: '#0098e5',
//       hover: {
//         backgroundColor: '#c5e4ff',
//         color: '#44596e',
//       },
//       disabled: {
//         color: '#9fb6cf',
//       },
//     },
//   }
// };

const GcFooter = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [otModalShow, setOtModalShow] = React.useState(false);
  const [otModalTitle, setOtModalTitle] = React.useState('');
  const [otModalBody, setOtModalBody] = React.useState('');
  const [otClassModalBody, setOtClassModalBody] = React.useState('');
  const [otModalSize, setOtModalSize] = React.useState('');
  const [otModalCentered, setOtModalCentered] = React.useState(false);

  const handleSuccessAction = message => {
    dispatch(sendAlert({ kind: 'success', message }));
    setOtClassModalBody('');
    setOtModalShow(false);
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

    
  const setOtModal = (title, body, size = null, centered = false) => {
    dispatch(saveCurrentClient({}));
    dispatch(saveSecondCurrentClient({}));
    setOtClassModalBody('');
    setOtModalTitle(title);
    setOtModalBody(body);
    setOtModalSize(size);
    setOtModalCentered(centered);
    setOtModalShow(true);
  };

  const handleCreateProjectChecklistRequest = (code, values, checklistTypeCode) => {
    createProjectChecklistRequest(code, {
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: () => history.push(`/projects/${code}/${checklistTypeCode}`)
    });
  };

  const handleShowChecklist = (project, checklistTypeCode) => {
    const code = project.otCode || project.id;
    const checklistData = project.checklistAttributes?.find(item => item?.checklistType === checklistTypeCode);
    if (checklistData?.checklistId !== '') {
      history.push(`/projects/${code}/${checklistTypeCode}`);
    } else {
      const ttlle = `SELECCIONAR ${  reviewTypes[checklistTypeCode]}`;
      const body = (
        <ProjectSelectChecklistForm
          checklistTypeCode={checklistTypeCode}
          formRequest={values => handleCreateProjectChecklistRequest(code, values, checklistTypeCode)}
        />
      );
      setOtModal(ttlle, body, 'lg', true);
    }
  };

  const createProject = (eventId) => {
    const nextCheckList = {
      reception_secure: 'pre_inspection',
      reception: 'reception'
    };
    createProjectRequest({
      dispatch,
      params: {project: {calendar_event_id: eventId}},
      formData: true,
      successCallback: response => {
        handleShowChecklist(camelCaseEmptyStringRecursive(response.data), nextCheckList[response.data.order_type]);
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleCreateRequest = params => {
    createCalendarEventsRequest({
      dispatch,
      params: snakeCaseKeys(params, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: params.calendarEvent.orderType !== 'desk_sell' ? (
        (response) => {
          handleSuccessAction('Cita agendada con éxito');
          createProject(response.data.id);
        }
      ) : (
        (response) => {
          handleSuccessAction('Venta de Mesón creada con éxito');
          history.push(`/projects/${response.data.code}/edit`);
        }
      ),
      failureCallback: handleFailureRequest
    });
  };

  const handleOpenOtModal = () => {
    const startDate = new Date();
    const endDate = new Date(startDate.getTime() + 90 * 60 * 1000);
    const event = {
      id: '',
      clientId: '',
      secondClientId: null,
      endDate,
      notes: '',
      promiseDate: '',
      startDate,
      vehicleId: '',
      orderType: 'reception',
      mileage: '',
      sinisterNumber: null,
      ocNumber: null,
      deliveryDate: null,
      orderNumber: null
    };
    const body = (
      <CalendarEventForm
        calendarEvent={event}
        action="new"
        formRequest={handleCreateRequest}
        submitVariant="success"
        handleClose={() => setOtModalShow(false)}
        setClassModalBody={setOtClassModalBody}
      />
    );
    setOtModal('Crear Cita', body, '', true);
  };

  const isActive = path => {
    if (path === pathname.replace('/', '')) {
      return 'active';
    }
    return '';
  };

  return (
    <>
      <Navbar className="navbar-footer-area" fixed="bottom" bg="dark">
        <Link className={`nav-item flex-sm-fill text-sm-center nav-link ${isActive('dashboard')}`} to="/dashboard">
          <FontAwesomeIcon icon={faHome} />
        </Link>
        <Link className={`nav-item flex-sm-fill text-sm-center nav-link ${isActive('calendar')}`} to="/calendar">
          <FontAwesomeIcon icon={faCalendar} />
        </Link>
        <div className="nav-item flex-sm-fill text-sm-center nav-link create-appointment-btn" onClick={handleOpenOtModal}>
          <div className="create-btn-circle">
            +OT
          </div>
        </div>
        <Link
          className={`nav-item flex-sm-fill text-sm-center nav-link ${isActive('project_mechanic_reviews')}`}
          to="/project_mechanic_reviews"
        >
          <FontAwesomeIcon icon={faClipboard} />
        </Link>
        <Link
          className={`nav-item flex-sm-fill text-sm-center nav-link ${isActive('project_vehicle_reviews')}`}
          to="/project_vehicle_reviews"
        >
          <FontAwesomeIcon icon={faCheckCircle} />
        </Link>
      </Navbar>
      <SimpleCenteredModal
        dialogClassName="modal-90w"
        size={otModalSize}
        classNameBody={otClassModalBody}
        title={otModalTitle}
        body={otModalBody}
        show={otModalShow}
        onHide={() => setOtModalShow(false)}
        centered={otModalCentered}
      />
    </>
  );
};

export default GcFooter;
