import React, { useRef } from 'react';
import { useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import { BasicTooltip } from '../Tooltips';

const FormikInput = ({
                       abbr,
                       additionalIconLabel,
                       error,
                       helpText,
                       inputType,
                       label,
                       labelColor,
                       margin,
                       placeholder,
                       size,
                       tooltipText,
                       touched,
                       value,
                       allowNegative = false,
                       onChange,
                       rut,
                       ...props
                     }) => {
  const { setFieldValue = () => null } = useFormikContext() || {};
  const inputRef = useRef(null);

  const ignoreUppercaseTypes = ['number', 'password'];

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (typeof newValue === 'string' && !ignoreUppercaseTypes.includes(inputType) && !rut) {
      const cursorPosition = e.target.selectionStart;

      const transformedValue = newValue.toUpperCase();

      setFieldValue(props.name, transformedValue);

      setTimeout(() => {
        if (inputRef.current && inputType !== 'email') {
          inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        }
      }, 0);
    }

    if (onChange) {
      onChange(e);
    }
  };

  const displayValue = (typeof value === 'string' && !ignoreUppercaseTypes.includes(inputType))
    ? value.toUpperCase()
    : value;

  return (
    <Form.Group className={margin}>
      {label && (
        <>
          {tooltipText ? (
            <BasicTooltip text={tooltipText}>
              <Form.Label className={labelColor || ''}>
                {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
                {additionalIconLabel && additionalIconLabel}
              </Form.Label>
            </BasicTooltip>
          ) : (
            <Form.Label className={labelColor || ''}>
              {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
              {additionalIconLabel && additionalIconLabel}
            </Form.Label>
          )}
        </>
      )}
      <Form.Control
        ref={inputRef}
        size={size}
        placeholder={placeholder}
        type={inputType}
        className={error && touched ? 'is-invalid' : ''}
        value={displayValue}
        onChange={handleChange}
        {...props}
      />
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

export default FormikInput;