import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { indexUsersRequest, debounceIndexUsersRequest } from '../../requests/user';
import ComponentDataTable from '../../components/Utils/DataTable';
import downloadFile from '../../services/utils';
import {FormikSelect} from "../../components";

const UserDataTable = props => {
  const [amount, setAmount] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [users, setUsers] = useState([]);
  const [active, setActive] = useState(true);
  const dispatch = useDispatch();
  const { columns } = props;
  const [queries, setQueries] = useState({});

  const handleSuccessRequest = response => {
    const {
      data: responseUsers,
      metadata: { amount: responseAmount }
    } = response.data;
    setUsers(responseUsers);
    setAmount(responseAmount);
    setMoreData(!moreData);
    setOnRequest(false);
  };

  const handleIndexRequest = params => {
    setOnRequest(true);
    const sendParams = {
      ...params,
      active_users: active
    }
    debounceIndexUsersRequest({
      dispatch,
      params: sendParams,
      successCallback: handleSuccessRequest
    });
  };

  const handleSuccessDownload = response => {
    downloadFile(response);
  };

  const downloadIndex = (format, params) => {
    indexUsersRequest({
      dispatch,
      params: { ...params, display_length: 100000 },
      format,
      successCallback: handleSuccessDownload
    });
  };

  const defaultStates = [
    { label: 'Usuarios Activos', value: true },
    { label: 'Usuario Inactivos', value: false }
  ];

  return (
    <>
      <Row className="justify-content-end mt-2">
        <Col md={2}>
          <Button variant="secondary" block onClick={() => downloadIndex('xlsx', queries)}>
            Exportar
          </Button>
        </Col>
      </Row>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns()}
        data={users}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={response => {
          setQueries({...response, active_users: active});
          if (!onRequest) handleIndexRequest(response);
        }}
        firstCustomMediumSearch={
          <FormikSelect
            placeholder="Usuarios Activos"
            options={defaultStates}
            defaultValue={defaultStates.find(state => state.value === active)}
            onChange={data => {
              setActive(data ? data.value : '');
              setMoreData(!moreData);
            }}
          />
        }
      />
    </>
  );
};

export default UserDataTable;
