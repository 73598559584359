import camelCaseRecursive from 'camelcase-keys-recursive';
import api_v2 from '../services/api_v2';
import useQuery, { useInvalidateQuery } from './useQuery';
import { useReFetchProjectInvoices } from './useProjectInvoices';

const listProjectPaymentsByProject = (projectId, view) =>
  api_v2.get(`/projects/${projectId}/project_payments.json?view=${view}`).then(res => res.data);

const createProjectPayment = (projectId, params) =>
  api_v2.post(`/projects/${projectId}/project_payments.json`, params).then(res => res.data);

const getProjectPayment = (id, view) => api_v2.get(`/project_payments/${id}.json?view=${view}`).then(res => res.data);

const updateProjectPayment = (id, params) => api_v2.put(`/project_payments/${id}.json`, params).then(res => res.data);

export const useReFetchProjectPayments = projectId => {
  const invalidateQueries = useInvalidateQuery();
  return () => {
    invalidateQueries(['project_payments', projectId]);
  };
};

export const useListProjectPaymentsByProject = (projectId, view = '') => {
  const { data = [], isLoading } = useQuery(['project_payments', projectId, view], listProjectPaymentsByProject, {
    enabled: !!projectId
  });
  const reFetch = useReFetchProjectPayments(projectId);
  const projectPayments = camelCaseRecursive(data);
  return { projectPayments, reFetch, isLoading };
};

export const useCreateProjectPayment = projectId => {
  const create = async (values, options = {}) => {
    try {
      const data = await createProjectPayment(projectId, values);
      options.onSuccess && options.onSuccess(data);
    } catch (error) {
      options.onError && options.onError(error);
    }
  };
  return { create };
};

export const useUpdateProjectPayment = id => {
  return async (values, options = {}) => {
    try {
      const data = await updateProjectPayment(id, values);
      options.onSuccess && options.onSuccess(data);
    } catch (error) {
      options.onError && options.onError(error);
    }
  };
};

export const useGetProjectPayment = (id, view) => {
  const { data = {}, isLoading } = useQuery(['project_payment', id, view], getProjectPayment, {
    enabled: !!id
  });
  const projectPayment = camelCaseRecursive(data);
  return { projectPayment, isLoading };
};
