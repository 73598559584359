import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, getIn, useFormikContext } from 'formik';
import { Col, Row } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { Trash } from 'react-feather';
import {
  DropzoneChecklist,
  FormikInput
} from '../../../components';
import { deleteProjectAutopartImageRequest } from '../../../requests/projects';
import { createDropZoneFileRequest, deleteDropZoneFileRequest } from '../../../requests/dropzone';

const ProjectAutopartInput = ({
                            index,
                            modelKey,
                            projectAutopart,
                            projectAutopartsAttributes
                          }) => {
  const {errors, setFieldValue, touched, values} = useFormikContext();
  const {imagesInfo} = projectAutopart;
  const inputName = `${modelKey}[${index}]`;
  const projectId = getIn(values, `project[id]`);
  const dispatch = useDispatch();

  const maxAvatarSize = 20971520;
  const [documentCode, setDocumentCode] = useState(Date.now());
  const [documentKeys, setDocumentKeys] = useState([]);
  const modelKeyProjectAutopart = `${modelKey}[${index}]`;
  const [imageCounter, setImageCounter] = useState(imagesInfo?.length || 0);
  const [projectAutopartExtended, setProjectAutopartExtended] = useState({
    ...projectAutopart,
    currentImages: [],
    persistedImages: imagesInfo ?? []
  });

  const handleRemoveBtn = () => {
    const arrayValuesLength = projectAutopartsAttributes.length;
    const deleteValue = projectAutopart;
    const newValues = [
      ...projectAutopartsAttributes.slice(0, index),
      ...projectAutopartsAttributes.slice(index + 1, arrayValuesLength)
    ];
    if (deleteValue && deleteValue.id) {
      deleteValue._destroy = 'true';
      newValues.push(deleteValue);
    }
    setFieldValue(modelKey, newValues);
  };

  const setPosition = () => {
    setFieldValue(`${inputName}[position]`, index);
  };

  useEffect(setPosition, [index]);

  const handleSuccessDeleteImage = response => {
    const documentInfo = JSON.parse(response.config.data);
    const { persistedImages } = projectAutopartExtended;
    const updatedPersistedImages = persistedImages.filter(image => image.id !== documentInfo.document_id);
    const updatedState = {
      ...projectAutopartExtended,
      persistedImages: updatedPersistedImages
    };
    setProjectAutopartExtended(updatedState);
  };

  const handleDeleteProjectAutopartImageRequest = params => {
    deleteProjectAutopartImageRequest(projectId, {
      dispatch,
      params: snakeCaseKeys(params),
      formData: true,
      successCallback: handleSuccessDeleteImage
    });
  };

  const handleOnDrop = (code, documents) => {
    const dropzoneFiles = {
      drop_zone_file: {
        code,
        document_type: 'project_autopart_image',
        documents
      }
    };
    return createDropZoneFileRequest({
      dispatch,
      params: dropzoneFiles,
      formData: true,
      successCallback: response => {
        const {data} = response;
        const newDocumentKeys = documentKeys;
        data.map(element => newDocumentKeys.push(element.document_key));
        setDocumentKeys(newDocumentKeys);
        return response;
      }
    });
  };

  const handleUpdateImages = images => {
    const { persistedImages } = projectAutopartExtended;
    const updatedState = {
      ...projectAutopartExtended,
      currentImages: [...images],
      persistedImages: [...persistedImages]
    };

    setFieldValue(`${modelKeyProjectAutopart}[documentKeys]`, documentKeys);
    setImageCounter(persistedImages.length + images.length);
    setProjectAutopartExtended(updatedState);
    setDocumentCode(Date.now());
  };

  const handleOnDelete = image => {
    deleteDropZoneFileRequest(image.id, {
      dispatch,
      params: {dropZoneFile: {documentKey: image.code}},
      successCallback: () => {
        const newDocumentKeys = documentKeys.filter(element => element !== image.code);
        setDocumentKeys([...newDocumentKeys]);
        setImageCounter(imageCounter - 1);
      }
    });
  };

  const handleOnDeletePersistedImages = document => {
    setImageCounter(imageCounter - 1);
    const destroyImage = {
      project_autopart_id: projectAutopart.id,
      document_type: 'project_autopart_image',
      document_id: document.id
    };
    return handleDeleteProjectAutopartImageRequest(destroyImage);
  };

  return (
    <Row className="mb-2 pt-2" style={{ border: 'solid', borderWidth: '1px', borderColor: 'black', borderRadius: '5px'}}>
      <Col md={8}>
        <strong>{projectAutopart.name}</strong>
        <Field name={`${inputName}[id]`}>
          {({field}) => (
            <FormikInput
              {...field}
              inputType="hidden"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={3} className='d-flex'>
        <span className='mr-2 fw-bold'>Cantidad</span>
        <Field name={`${inputName}[quantity]`}>
          {({field}) => (
            <FormikInput
              {...field}
              inputType="number"
              // label="Cantidad"
              defaultValue={projectAutopart.quantity}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={1}>
        <Trash
          color="red"
          className="clickable"
          onClick={() => handleRemoveBtn()}
        />
      </Col>
      <Col md={4}>
        <DropzoneChecklist
          modalPreview={false}
          id={documentCode}
          fileAccept="image/*"
          documentCounter={imageCounter}
          maxSize={maxAvatarSize} // 20MB
          persistedFiles={projectAutopartExtended.persistedImages}
          files={projectAutopartExtended.currentImages}
          onDrop={handleOnDrop}
          onDropUploaded={images => handleUpdateImages(images)}
          onDelete={image => handleOnDelete(image)}
          onDeletePersistedFiles={handleOnDeletePersistedImages}
          name={`${modelKeyProjectAutopart}[documentKeys]`}
          error={getIn(errors, `${modelKeyProjectAutopart}[documentKeys]`)}
          touched={getIn(touched, `${modelKeyProjectAutopart}[documentKeys]`)}
          fullWidth
        />
      </Col>
      <Col md={8}>
        <Field name={`${inputName}[notes]`}>
          {({field}) => (
            <FormikInput
              {...field}
              placeholder="Nota / Observación"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
    </Row>
  );
};

export default ProjectAutopartInput;
