import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexChecklistRequest = ({ dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.requestV2('get', `/checklists${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showChecklistRequest = (id, { dispatch, params = {}, failureCallback, successCallback }) =>
  ApiService.requestV2('get', `/checklists/${id}.json`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const updateChecklistRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.requestV2('put', `/checklists/${id}.json`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const createChecklistRequest = ({ dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.requestV2('post', `/checklists.json`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const updateProjectChecklistRequest = (id, { dispatch, params, formData, failureCallback, successCallback }) =>
  ApiService.request('put', `/project_checklists/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const destroyProjectChecklistRequest = (id, { dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('delete', `/project_checklists/${id}`, {
    dispatch,
    params,
    successCallback,
    failureCallback
  });

export const debounceIndexChecklistRequest = AwesomeDebouncePromise(indexChecklistRequest, 300);
