import React from 'react';
import { Field, Form, Formik, getIn } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import { BasicTextArea, FormikInput } from '../../../components';
import DateInputField from '../../../components/Utils/Input/DateInputField';
import { useGetProject } from '../../../hooks/useProjects';
import * as validations from '../../../utils/validations';

const ProjectNotesReceptionSecureFrom = ({ projectId, onSave }) => {
  const { project, isLoading } = useGetProject(projectId, 'edit');
  const initialValues = {
    project: {
      sinisterNumber: project.sinisterNumber,
      ocNumber: project.ocNumber,
      deliveryDate: project.deliveryDate,
      orderNumber: project.orderNumber,
      notes: project.notes
    }
  };
  if (isLoading) return null;
  return (
    <Formik initialValues={initialValues} onSubmit={onSave}>
      {({ isSubmitting }) => (
        <Form>
          <Row>
            <Col md={12}>
              <Field name="project[sinisterNumber]" validate={validations.required()}>
                {({ field, form }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Número de Siniestro"
                    error={getIn(form.errors, field.name)}
                    touched={getIn(form.touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={12}>
              <Field name="project[ocNumber]">
                {({ field, form }) => (
                  <FormikInput
                    {...field}
                    label="Número OC"
                    error={getIn(form.errors, field.name)}
                    touched={getIn(form.touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={12}>
              <DateInputField label="Fecha de Entrega" name="project[deliveryDate]" />
            </Col>
            <Col md={12}>
              <Field name="project[orderNumber]">
                {({ field, form }) => (
                  <FormikInput
                    {...field}
                    label="Número Pedido"
                    error={getIn(form.errors, field.name)}
                    touched={getIn(form.touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={12}>
              <Field name="project[notes]">
                {({ field, form }) => (
                  <BasicTextArea
                    {...field}
                    row="6"
                    label="Motivo / Notas"
                    error={getIn(form.errors, field.name)}
                    touched={getIn(form.touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Button block disabled={isSubmitting} type="submit" variant="success">
                {isSubmitting ? 'Guardando...' : 'Guardar'}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
export default ProjectNotesReceptionSecureFrom;