import React, { Fragment, useEffect, useState } from 'react';
import { Field, FieldArray, Form, getIn, withFormik } from 'formik';
import { Button, Col, Row, Table, Spinner, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import QRCode from 'react-qr-code';
import { Copy } from 'react-feather';
import { parse } from 'date-fns';
import {
  FormikCheckBox,
  FormikDatePicker,
  FormikInput,
  FormikPassword,
  UploadImage,
  BasicTextArea,
  FormikSelect,
  RichEditor,
  SimpleCenteredModal
} from '../../components';
import manteinancePeriodOptions from './companyPeriodOptions';
import { destroyDocumentCompanyRequest, status2Chat, qr2Chat } from '../../requests/companies';
import { getGoogleAuthRequest } from '../../requests/googleAuth';
import { sendAlert } from '../../actions/utils';
import WhatsappConnection from './WhatsappConnection';
import { debounceIndexTemplateNotificationsRequest } from '../../requests/templateNotifications';
import { indexInsuranceCompaniesRequest } from '../../requests/insuranceCompanies';
import useCountryFactory from '../../hooks/useCountryFactory';
import budgetFormatOptions from './companyBudgetFormatOptions';
import PhoneInputField from '../../components/Utils/Input/PhoneInputField';
import TimeInputField from '../../components/Utils/Input/TimeInputField';
import { camelCase } from '../../services/utils';

const CompanyForm = ({ setCompany, company, errors, onHide, setFieldValue, touched, submitVariant, values }) => {
  const countryFactory = useCountryFactory();
  const [endTime, setEndTime] = useState('');
  const [startTime, setStartTime] = useState('');
  const [selectedTab, setSelectedTab] = useState('general');
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  let qrCode;
  const [defaultMessages, setDefaultMessages] = useState({});
  const [insuranceCompanies, setInsuranceCompanies] = useState([]);
  const { appointmentsSchedule } = values.company;
  const { checklistTypes } = company;

  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const getDefaultMessages = () => {
    debounceIndexTemplateNotificationsRequest({
      dispatch,
      failureCallback: handleFailureRequest,
      successCallback: response => {
        const notifications = {
          notificar_nueva_cita: 'newAppointment',
          notificar_recordatorio_cita: 'appointmentReminder',
          notificar_progreso: 'progress',
          notificar_emision_de_factura: 'invoiceIssuance',
          notificar_cobranza: 'paymentCollection',
          send_nps_survey: 'npsSurvey',
          recordatorio_mantencion: 'maintenanceReminder',
          recordatorio_revision_tecnica: 'technicalReviewReminder',
          notificar_presupuesto_enviado: 'budgetSent'
        };
        response.data
          .map(template => {
            defaultMessages[notifications[template.name]] = template.body.replace('{{telefono}}', '');
            if (template.body_with_payment) {
              defaultMessages[notifications[template.name] + 'WithPayment'] = template.body_with_payment;
            }
            return template;
          })
          .then(() => setDefaultMessages(defaultMessages));
      }
    });
  };

  useEffect(getDefaultMessages, []);

  const handleOnDelete = document => {
    destroyDocumentCompanyRequest({
      dispatch,
      params: {
        document: { document_type: document.document_type, document_id: document.document_id }
      }
    });
  };

  const getGoogleAuth = () => {
    getGoogleAuthRequest({
      dispatch,
      successCallback: response => {
        window.location.href = response.data.url;
      }
    });
  };

  const handleModalClose = () => {
    setCenterModalShow(false);
  };

  const handleSuccessConnect = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Whatsapp conectado con éxito' }));
    handleModalClose();
  };

  const handleMessage = status => {
    setModalBody(
      <WhatsappConnection
        submitVariant="success"
        connectRequest={handleConnectRequest}
        handleModalClose={handleModalClose}
      />
    );
    const msg = {
      'channel-creation-failed': {
        kind: 'error',
        message: `Error al crear el canal para el número ${company.whatsappPhoneNumber}`
      },
      'failure-state': { kind: 'error', message: 'Error' },
      'already-connected': { kind: 'success', message: 'Ya estás conectado.' },
      'connection-failed': { kind: 'error', message: 'Conexión fallida.' }
    };
    dispatch(sendAlert({ kind: msg[status].kind, message: msg[status].message }));
  };

  const renderQr = qr => {
    setModalBody(
      <WhatsappConnection
        submitVariant="success"
        connectRequest={handleConnectRequest}
        handleModalClose={handleModalClose}
        content={
          <div style={{ height: 'auto', margin: '0 auto', maxWidth: 256, width: '100%' }}>
            <b>Escanea el código de abajo usando WhatsApp</b>
            <ol>
              <li>Abre Configuración (iPhone) o Menú (Android)</li>
              <li>Selecciona Dispositivos Vinculados</li>
              <li>Toca en 'Vincular un dispositivo'</li>
            </ol>
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={qr}
              viewBox="0 0 256 256"
            />
          </div>
        }
      />
    );
  };

  const getQr = () => {
    qr2Chat({
      dispatch,
      successCallback: response => {
        if (response.data.status === 'disconnected') {
          if (response.data.qr) {
            if (response.data.qr !== qrCode) {
              qrCode = response.data.qr;
              renderQr(response.data.qr);
            }
          }
          setTimeout(getQr, 1000);
        } else if (response.data.status === 'connected') {
          handleSuccessConnect();
        } else {
          handleMessage(response.data.status);
        }
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleConnectRequest = () => {
    setModalBody(
      <WhatsappConnection
        submitVariant="success"
        connectRequest={handleConnectRequest}
        handleModalClose={handleModalClose}
        content={
          <Col>
            <Row className="justify-content-center">
              <b>Obteniendo código, puede tardar unos segundos.</b>
            </Row>
            <Row>
              <div className="containerSpinnerLoad">
                <Spinner animation="border" variant="primary" />
              </div>
            </Row>
          </Col>
        }
      />
    );
    status2Chat({
      dispatch,
      successCallback: response => {
        if (response.data.status === 'disconnected') {
          renderQr(response.data.qr);
        } else if (response.data.status === 'not-suscribed') {
          notSuscribedMessage();
        } else if (response.data.status === 'waiting-qr') {
          setTimeout(getQr, 10000);
        } else {
          handleMessage(response.data.status);
        }
      },
      failureCallback: handleFailureRequest
    });
  };

  const notSuscribedMessage = () => {
    setModalBody(
      <WhatsappConnection
        submitVariant="success"
        connectRequest={handleConnectRequest}
        handleModalClose={handleModalClose}
        content={
          <div>
            <b>
              No tienes contratado el servicio de mensajería directo. Para contratarlo, ponte en contacto con nosotros.
            </b>
          </div>
        }
      />
    );
  };

  const handleModalCreate = () => {
    setCenterModalShow(true);
    setModalBody(
      <WhatsappConnection
        submitVariant="success"
        connectRequest={handleConnectRequest}
        handleModalClose={handleModalClose}
      />
    );
  };

  const fetchInsuranceCompanies = () => {
    indexInsuranceCompaniesRequest({
      dispatch,
      params: {
        sort_column: 'name'
      },
      successCallback: response => setInsuranceCompanies(response.data.data.filter(ic => ic.label !== 'PARTICULAR'))
    });
  };

  useEffect(fetchInsuranceCompanies, []);

  const handleSelectorIsMulti = (data, field) => {
    const newData = data.map(element => element.value);
    if (!newData.length) {
      newData.push('');
    }
    setFieldValue(field, newData);
  };

  const urlSchedule = `${window.location.origin}/public/appointment/${company.hashid}`;
  const copyUrlSchedule = async () => {
    await navigator.clipboard.writeText(urlSchedule);
    dispatch(sendAlert({ kind: 'success', message: 'Url copiada al portapapeles' }));
  };

  const urlAgendapro = `https://api.gestioncar.cl/api/v1/public/agendapro/${company.hashid}`;
  const copyUrlAgendapro = async () => {
    await navigator.clipboard.writeText(urlAgendapro);
    dispatch(sendAlert({ kind: 'success', message: 'Url copiada al portapapeles' }));
  };

  return (
    <Form>
      <Row className="mb-3 project-show-menu-btn">
        <Col md={2}>
          <Button
            style={{ fontWeight: '700' }}
            className={selectedTab === 'general' ? 'active-btn' : 'inactive-btn'}
            onClick={() => setSelectedTab('general')}
          >
            Configuración General
          </Button>
        </Col>

        <Col md={2}>
          <Button
            style={{ fontWeight: '700' }}
            className={selectedTab === 'mailers' ? 'active-btn' : 'inactive-btn'}
            onClick={() => setSelectedTab('mailers')}
          >
            Configuración de notificaciones
          </Button>
        </Col>

        <Col md={2}>
          <Button
            style={{ fontWeight: '700' }}
            className={selectedTab === 'optionalFields' ? 'active-btn' : 'inactive-btn'}
            onClick={() => setSelectedTab('optionalFields')}
          >
            Campos opcionales
          </Button>
        </Col>
      </Row>

      <section style={{ display: selectedTab === 'general' ? 'inline' : 'none' }}>
        <Row className="mt-3 mb-3">
          <Col md={6} className="mt-3">
            <Field name="company[nationalIdentification]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  disabled
                  onChange={e => countryFactory.handleNationalIdentificationFormat(e, setFieldValue)}
                  label={countryFactory.getLabelForCompanyIdentification()}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6} className="mb-3">
            <Field name="company[logo]">
              {({ field }) => (
                <UploadImage
                  {...field}
                  label="Logo de Empresa"
                  name={company.businessLogo?.filename}
                  imageUrl={getIn(company.businessLogo, 'fileUrl')}
                  onChange={_avatar => setFieldValue(field.name, _avatar)}
                  helpText="Formato sugerido 640x200px (.jpg o .png) de máximo 1mb."
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="company[name]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre de Fantasía"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="company[legalName]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  disabled
                  label="Razón Social"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="company[businessActivity]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Giro"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="company[address]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Dirección"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="company[email]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Email"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="company[website]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Sitio Web"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <PhoneInputField required name="company[phone]" label="Celular" />
          </Col>
          <Col md={2}>
            <Field name="company[taxRate]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  inputType="number"
                  label="IVA (%)"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={2}>
            <Field name="company[hourPrice]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  inputType="float"
                  label="Precio CLP"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="company[maintenanceRecordatoryPeriod]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  abbr
                  label="Periodo Recordatorio Mantención"
                  placeholder="Seleccionar Periodo"
                  options={manteinancePeriodOptions}
                  defaultValue={company.maintenanceRecordatoryPeriod}
                  onChange={data => setFieldValue(field.name, data?.value)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="company[maxWorkHours]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  inputType="float"
                  label="Máximo de Horas diarias de trabajador por Trabajo"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={3}>
            <Field name="company[dyp]">
              {({ field }) => (
                <FormikCheckBox {...field} custom label="Hacemos Desabolladura y Pintura" type="switch" field={field} />
              )}
            </Field>
          </Col>
          <Col md={3}>
            <Field name="company[worksWithInsuranceCompanies]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  label="Trabajamos con Compañías de Seguros"
                  type="switch"
                  field={field}
                />
              )}
            </Field>
          </Col>
          {getIn(values, 'company[worksWithInsuranceCompanies]') && (
            <Col md={6}>
              <Field name="company[insuranceCompanyIds]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    isMulti
                    label="Compañías con las que trabajamos"
                    placeholder="Seleccionar Compañías"
                    options={insuranceCompanies}
                    defaultValue={company.insuranceCompanyIds}
                    defaultMultiValues={company.insuranceCompanies}
                    onChange={data => handleSelectorIsMulti(data || [], field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          )}
          <Col md={12} className="mt-3">
            <hr />
            <h4>
              <strong>CONFIGURACIÓN DE OT</strong>
            </h4>
          </Col>
          <Col md={3}>
            <p>
              <strong>Configura una contraseña de confirmación para eliminar OT.</strong>
            </p>
            <Field name="company[passwordToDeleteOt]">
              {({ field }) => (
                <FormikPassword
                  {...field}
                  label="Contraseña"
                  placeholder="Contraseña"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={3}>
            <p>
              <strong>Configura el PDF que se enviará en el email y whatsapp de presupuesto.</strong>
            </p>
            <Field name="company[budgetFormat]">
              {({ field }) => (
                <FormikSelect
                  {...field}
                  label="Selecionar formato de presupuesto"
                  placeholder="Selecionar formato"
                  options={budgetFormatOptions}
                  defaultValue={company.budgetFormat}
                  onChange={data => setFieldValue(field.name, data?.value)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6} />
          <Col md={6} sm={12} className="mt-3">
            <hr />
            <h4>
              <strong>CONFIGURACIÓN DE SUBCATEGORIA</strong>
            </h4>
            <p>Habilitar la opción para ocultar la subcategoría en los reportes de pdf y en la vista pública</p>
            <Field name="company[hideSubcategory]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  label="Ocultar subcategoría"
                  margin="float-left"
                  type="switch"
                  field={field}
                />
              )}
            </Field>
          </Col>
          <Col md={6} sm={12} className="mt-3">
            <hr />
            <h4>
              <strong>CONFIGURACIÓN DE COMPRAS</strong>
            </h4>
            <p>Habilitar la opción para que el checkbox de solicitar compra esté marcado por defecto</p>
            <Field name="company[requestedPurchaseByDefault]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  label="Solicitar Compra por defecto"
                  margin="float-left"
                  type="switch"
                  field={field}
                />
              )}
            </Field>
          </Col>
          <Col md={12} sm={12} className="mt-3">
            <hr />
            <h4>
              <strong>CONFIGURACIÓN DE CHECKLIST EN VISTA PÚBLICA</strong>
            </h4>
            <p>Habilitar la opción para ocultar el ver checklist en la vista pública</p>
            {checklistTypes?.map(checklist => {
              return (
                <Row className="ml-0">
                  <Field
                    key={checklist.checklistType}
                    name={`company[settings][configurations][hideChecklistInPublicView][${camelCase(
                      checklist.checklistType
                    )}]`}
                  >
                    {({ field }) => (
                      <FormikCheckBox
                        {...field}
                        custom
                        label={checklist.name}
                        margin="float-left"
                        type="switch"
                        field={field}
                      />
                    )}
                  </Field>
                </Row>
              );
            })}
          </Col>
          <Col md={12} className="mb-3">
            <hr />
            <h4>
              <strong>CONFIGURACIÓN DE CALENDARIO</strong>
            </h4>
            <p>Selecciona el rango de horario y los días en los que se podrán fijar citas en el calendario.</p>
          </Col>
          <Col md={3}>
            <Field name="company[startTime]">
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  dateFormat="H:mm"
                  showTimeSelect
                  showTimeSelectOnly
                  label="Hora Inicial"
                  timeIntervals={30}
                  selected={startTime}
                  onChange={date => {
                    setStartTime(date);
                    setFieldValue(field.name, date);
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={3}>
            <Field name="company[endTime]">
              {({ field }) => (
                <FormikDatePicker
                  {...field}
                  dateFormat="H:mm"
                  showTimeSelect
                  showTimeSelectOnly
                  label="Hora Final"
                  timeIntervals={30}
                  timeCaption="Time"
                  selected={endTime}
                  onChange={date => {
                    setEndTime(date);
                    setFieldValue(field.name, date);
                  }}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <strong>Días habilitados para recibir citas</strong>
            </p>
          </Col>
        </Row>
        <Row className="weekday-selector-box mb-3">
          <Col>
            <Field name="company[worksMonday]">
              {({ field }) => <FormikCheckBox {...field} field={field} label="Lunes" custom />}
            </Field>
            <Field name="company[worksTuesday]">
              {({ field }) => <FormikCheckBox {...field} field={field} label="Martes" custom />}
            </Field>
            <Field name="company[worksWednesday]">
              {({ field }) => <FormikCheckBox {...field} field={field} label="Miércoles" custom />}
            </Field>
            <Field name="company[worksThursday]">
              {({ field }) => <FormikCheckBox {...field} field={field} label="Jueves" custom />}
            </Field>
            <Field name="company[worksFriday]">
              {({ field }) => <FormikCheckBox {...field} field={field} label="Viernes" custom />}
            </Field>
            <Field name="company[worksSaturday]">
              {({ field }) => <FormikCheckBox {...field} field={field} label="Sábado" custom />}
            </Field>
            <Field name="company[worksSunday]">
              {({ field }) => <FormikCheckBox {...field} field={field} label="Domingo" custom />}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <strong>Vincular con google calendar</strong>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {company.calendarAccess && Object.keys(company.calendarAccess).length ? (
              <div>Google calendar vinculado</div>
            ) : (
              <Button onClick={getGoogleAuth}>Realizar vinculación</Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-3 mb-3">
            <hr />
            <h4>
              <strong>CONFIGURACIÓN DE HORARIOS PARA AGENDAR CITA</strong>
            </h4>
            <p>Selecciona el rango de horario que se podrán agendar citas.</p>
          </Col>
          <Col md={12}>
            <Field name="company[appointmentDescription]">
              {({ field }) => (
                <BasicTextArea
                  {...field}
                  label="Descripción de la cita"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Row>
              <FieldArray name="company[appointmentsSchedule]">
                {({ push, remove }) => (
                  <Col md={12} className="row">
                    {appointmentsSchedule?.map((appointment, index) => {
                      return (
                        <Fragment key={`appointments-Schedule-${index}`}>
                          <Col md={5}>
                            <TimeInputField
                              name={`company[appointmentsSchedule][${index}][startTime]`}
                              label="Hora Inicial"
                            />
                          </Col>
                          <Col md={5}>
                            <TimeInputField
                              name={`company[appointmentsSchedule][${index}][endTime]`}
                              label="Hora Final"
                            />
                          </Col>
                          <Col xs={2} className="d-flex">
                            <Button variant="link" onClick={() => remove(index)}>
                              Eliminar
                            </Button>
                          </Col>
                        </Fragment>
                      );
                    })}
                    <Col md={4}>
                      <Button onClick={() => push({ startTime: '', endTime: '' })}>Agregar horario</Button>
                    </Col>
                  </Col>
                )}
              </FieldArray>
            </Row>
          </Col>
          <Col md={2}>
            <Field name="company[maxAppointmentsPerDay]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  inputType="float"
                  label="Cantidad máxima de citas por día"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <strong className="mr-2">Url para agendar cita</strong>
            <Copy size={15} onClick={copyUrlSchedule} role="button" />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <a href={urlSchedule} target="_blank" rel="noreferrer" className="text-big">
              Ir a la página de agendar cita
            </a>
          </Col>
        </Row>
        {company.agendapro && (
          <>
            <Row className="mt-4">
              <Col>
                <strong>Url para webhook de AgendaPro</strong>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <p onClick={copyUrlAgendapro} role="button">
                  {urlAgendapro}
                  <Copy size={15} className="ml-2" />
                </p>
              </Col>
            </Row>
          </>
        )}
      </section>

      <section style={{ display: selectedTab === 'mailers' ? 'inline' : 'none' }}>
        <Row className="mt-3">
          <Col>
            <h2>Notificaciones por Whatsapp</h2>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col md={12}>
            <Row>
              <Col md={4}>
                <PhoneInputField name="company[whatsappPhoneNumber]" label="Número de teléfono de whatsapp" />
              </Col>
              {company.messengerService && (
                <>
                  <Col md={3} className="text-center">
                    <div className="d-block">Enviar desde el número del:</div>
                    <Field name="company[messengerServiceSettings][sender]">
                      {({ field }) => (
                        <ToggleButtonGroup
                          {...field}
                          type="radio"
                          style={{ width: '245px' }}
                          onChange={value => setFieldValue('company[messengerServiceSettings][sender]', value)}
                        >
                          <ToggleButton
                            className="btn"
                            variant="outline-primary"
                            value="company"
                            style={{ padding: '0.5rem', width: '50%' }}
                          >
                            Taller
                          </ToggleButton>
                          <ToggleButton
                            className="btn"
                            variant="outline-primary"
                            value="seller"
                            style={{ padding: '0.5rem', width: '50%' }}
                          >
                            Vendedor asignado
                          </ToggleButton>
                        </ToggleButtonGroup>
                      )}
                    </Field>
                  </Col>
                  {getIn(values, 'company[messengerServiceSettings][sender]') === 'seller' && (
                    <Col>
                      <Field name="company[messengerServiceSettings][fromCompanyIfSellerNotConnected]">
                        {({ field }) => (
                          <FormikCheckBox
                            {...field}
                            field={field}
                            label="Si el Whatsapp del vendedor no está conectado, enviar del número del taller"
                            custom
                          />
                        )}
                      </Field>
                    </Col>
                  )}
                </>
              )}
              <Col>
                <Button
                  style={{ fontWeight: '700', backgroundColor: '#25d366', float: 'inline-end' }}
                  className="connect-wsp-btn"
                  onClick={() => handleModalCreate()}
                >
                  Conectar Whatsapp
                </Button>
              </Col>
            </Row>
            <SimpleCenteredModal
              title="Conectar Whatsapp"
              body={modalBody}
              show={centerModalShow}
              onHide={handleModalClose}
              closeButton
            />
            <Table striped bordered>
              <thead>
              <tr>
                <th>Cliente</th>
                <th>Cliente 2</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <Field name="company[settings][notifications][whatsapp][newAppointment][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Nueva cita" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][appointmentReminder][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Recordatorio de cita" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][receptionProgress][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Recepción" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][mechanicProgress][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Inspección técnica" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][budgetSentProgress][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Presupuesto enviado" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][budgetApprovedProgress][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Presupuesto aprobado" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][otCompletedProgress][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="OT Finalizada" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][deliveredProgress][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Entregado" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][invoiceIssuance][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Emisión de factura" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][collection][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Cobranza" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][sendNpsSurvey][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Enviar encuesta NPS" custom />}
                  </Field>
                </td>
                <td>
                  <Field name="company[settings][notifications][whatsapp][newAppointment][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Nueva cita" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][appointmentReminder][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Recordatorio de cita" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][receptionProgress][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Recepción" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][mechanicProgress][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Inspección técnica" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][budgetSentProgress][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Presupuesto enviado" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][budgetApprovedProgress][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Presupuesto aprobado" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][otCompletedProgress][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="OT Finalizada" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][deliveredProgress][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Entregado" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][invoiceIssuance][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Emisión de factura" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][collection][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Cobranza" custom />}
                  </Field>
                  <Field name="company[settings][notifications][whatsapp][sendNpsSurvey][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Enviar encuesta NPS" custom />}
                  </Field>
                </td>
              </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <h2>Notificaciones por email</h2>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col md={12}>
            <Table striped bordered>
              <thead>
              <tr>
                <th>Cliente</th>
                <th>Cliente 2</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <Field name="company[settings][notifications][email][preInspectionProgress][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Pre inspeccióm" custom />}
                  </Field>
                  <Field name="company[settings][notifications][email][receptionProgress][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Recepción" custom />}
                  </Field>
                  <Field name="company[settings][notifications][email][mechanicProgress][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Inspección técnica" custom />}
                  </Field>
                  <Field name="company[settings][notifications][email][budgetSentProgress][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Presupuesto enviado" custom />}
                  </Field>
                  <Field name="company[settings][notifications][email][budgetApprovedProgress][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Presupuesto aprobado" custom />}
                  </Field>
                  <Field name="company[settings][notifications][email][otCompletedProgress][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="OT Finalizada" custom />}
                  </Field>
                  <Field name="company[settings][notifications][email][deliveredProgress][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Entregado" custom />}
                  </Field>
                  <Field name="company[settings][notifications][email][invoiceIssuance][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Emisión de factura" custom />}
                  </Field>
                  <Field name="company[settings][notifications][email][collection][client]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Cobranza" custom />}
                  </Field>
                </td>
                <td>
                  <Field name="company[settings][notifications][email][preInspectionProgress][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Pre inspeccióm" custom />}
                  </Field>
                  <Field name="company[settings][notifications][email][receptionProgress][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Recepción" custom />}
                  </Field>
                  <Field name="company[settings][notifications][email][mechanicProgress][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Inspección técnica" custom />}
                  </Field>
                  <Field name="company[settings][notifications][email][budgetSentProgress][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Presupuesto enviado" custom />}
                  </Field>
                  <Field name="company[settings][notifications][email][budgetApprovedProgress][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Presupuesto aprobado" custom />}
                  </Field>
                  <Field name="company[settings][notifications][email][otCompletedProgress][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="OT Finalizada" custom />}
                  </Field>
                  <Field name="company[settings][notifications][email][deliveredProgress][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Entregado" custom />}
                  </Field>
                  <Field name="company[settings][notifications][email][invoiceIssuance][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Emisión de factura" custom />}
                  </Field>
                  <Field name="company[settings][notifications][email][collection][secondClient]">
                    {({ field }) => <FormikCheckBox {...field} field={field} label="Cobranza" custom />}
                  </Field>
                </td>
              </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        {company.messengerService && (
          <Row className="mt-3">
            <Col md={12}>
              <h2>Notificaciones personalizadas por Whatsapp</h2>
            </Col>
            <Col md={8}>
              <strong>Puedes usar los siguientes parametros (dentro de doble llave):</strong>
            </Col>
            <Col md={4}>
              <strong>Puedes darle estilo al texto de la siguiente manera:</strong>
            </Col>
            <Col md={4}>
              <br />
              <span>
                <strong>{'{{taller}}'}:</strong> Nombre del taller.
              </span>
              <br />
              <span>
                <strong>{'{{cliente}}'}:</strong> Nombre del cliente.
              </span>
              <br />
              <span>
                <strong>{'{{fecha_cita}}'}:</strong> Fecha de la cita.
              </span>
              <br />
              <span>
                <strong>{'{{hora_cita}}'}:</strong> Hora de la cita.
              </span>
              <br />
              <span>
                <strong>{'{{direccion}}'}:</strong> Dirección del taller.
              </span>
              <br />
              <span>
                <strong>{'{{telefono}}'}:</strong> Teléfono del taller.
              </span>
              <br />
              <span>
                <strong>{'{{monto_pendiente}}'}:</strong> Monto pendiente de pago.
              </span>
            </Col>
            <Col md={4}>
              <br />
              <span>
                <strong>{'{{auto}}'}:</strong> Marca, modelo y versión del vehículo.
              </span>
              <br />
              <span>
                <strong>{'{{patente}}'}:</strong> Patente del vehículo.
              </span>
              <br />
              <span>
                <strong>{'{{estado}}'}:</strong> Estado de la OT.
              </span>
              <br />
              <span>
                <strong>{'{{link}}'}:</strong> Link de seguimiento de la OT.
              </span>
              <br />
              <span>
                <strong>{'{{nps}}'}:</strong> Link de la encuesta NPS.
              </span>
              <br />
              <span>
                <strong>{'{{link_agendamiento}}'}:</strong> Link de agendamiento.
              </span>
              {company.paymentServiceEnabled && (
                <>
                  <br />
                  <span>
                    <strong>{'{{link_pago}}'}:</strong> Link de pago.
                  </span>
                </>
              )}
            </Col>
            <Col md={4}>
              <br />
              <span>
                <strong>*texto*:</strong> negrita.
              </span>
              <br />
              <span>
                <strong>_texto_:</strong> cursiva.
              </span>
              <br />
              <span>
                <strong>~texto~:</strong> tachado.
              </span>
            </Col>
            <Col md={6} className="mt-3">
              <Field name="company[messengerServiceSettings][messages][newAppointment]">
                {({ field }) => (
                  <BasicTextArea
                    {...field}
                    row="5"
                    customClass="h-100"
                    label="Nueva cita"
                    value={field.value && !field.value.empty ? field.value : defaultMessages.newAppointment}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6} className="mt-3">
              <Field name="company[messengerServiceSettings][messages][appointmentReminder]">
                {({ field }) => (
                  <BasicTextArea
                    {...field}
                    row="5"
                    customClass="h-100"
                    label="Recordatorio de cita"
                    value={field.value && !field.value.empty ? field.value : defaultMessages.appointmentReminder}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6} className="mt-3">
              <Field name="company[messengerServiceSettings][messages][progress]">
                {({ field }) => (
                  <BasicTextArea
                    {...field}
                    row="5"
                    customClass="h-100"
                    label="Progreso"
                    value={field.value && !field.value.empty ? field.value : defaultMessages.progress}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6} className="mt-3">
              <Field name="company[messengerServiceSettings][messages][budgetSent]">
                {({ field }) => (
                  <BasicTextArea
                    {...field}
                    row="5"
                    customClass="h-100"
                    label="Presupuesto enviado"
                    value={
                      field.value && !field.value.empty
                        ? field.value
                        : company.paymentServiceEnabled
                          ? defaultMessages.budgetSentWithPayment
                          : defaultMessages.budgetSent
                    }
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6} className="mt-3">
              <Field name="company[messengerServiceSettings][messages][invoiceIssuance]">
                {({ field }) => (
                  <BasicTextArea
                    {...field}
                    row="5"
                    customClass="h-100"
                    label="Emisión de factura"
                    value={field.value && !field.value.empty ? field.value : defaultMessages.invoiceIssuance}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6} className="mt-3">
              <Field name="company[messengerServiceSettings][messages][paymentCollection]">
                {({ field }) => (
                  <BasicTextArea
                    {...field}
                    row="5"
                    customClass="h-100"
                    label="Cobranza"
                    value={
                      field.value && !field.value.empty
                        ? field.value
                        : company.paymentServiceEnabled
                          ? defaultMessages.paymentCollectionWithPayment
                          : defaultMessages.paymentCollection
                    }
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6} className="mt-3">
              <Field name="company[messengerServiceSettings][messages][npsSurvey]">
                {({ field }) => (
                  <BasicTextArea
                    {...field}
                    row="5"
                    customClass="h-100"
                    label="Encuesta NPS"
                    value={field.value && !field.value.empty ? field.value : defaultMessages.npsSurvey}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6} className="mt-3">
              <Field name="company[messengerServiceSettings][messages][maintenanceReminder]">
                {({ field }) => (
                  <BasicTextArea
                    {...field}
                    row="5"
                    customClass="h-100"
                    label="Recordatorio de mantención"
                    value={field.value && !field.value.empty ? field.value : defaultMessages.maintenanceReminder}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={6} className="mt-3">
              <Field name="company[messengerServiceSettings][messages][technicalReviewReminder]">
                {({ field }) => (
                  <BasicTextArea
                    {...field}
                    row="5"
                    customClass="h-100"
                    label="Recordatorio de revisión técnica"
                    value={field.value && !field.value.empty ? field.value : defaultMessages.technicalReviewReminder}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
        )}
        <Row className="mt-3">
          <Col>
            <h2>Configuración de notificaciones</h2>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Field name="company[paymentCondition]">
              {({ field }) => (
                <BasicTextArea
                  {...field}
                  row="5"
                  customClass="h-100"
                  label="Condiciones"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="company[collectionEmailBodyText]">
              {({ field }) => (
                <RichEditor
                  {...field}
                  label="Email Cobranza: Cuerpo parte 1"
                  data={getIn(values, field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  setFieldValue={setFieldValue}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="company[collectionEmailSecondaryBodyText]">
              {({ field }) => (
                <RichEditor
                  {...field}
                  label="Email Cobranza: Cuerpo parte 2"
                  data={getIn(values, field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  setFieldValue={setFieldValue}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="company[progressOtStateEmailBody]">
              {({ field }) => (
                <RichEditor
                  {...field}
                  label="Email Progreso de Estados OT"
                  data={getIn(values, field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  setFieldValue={setFieldValue}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="company[technicalReviewReminder]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  custom
                  label="Enviar recordatorios de revisión técnica"
                  type="switch"
                  field={field}
                />
              )}
            </Field>
          </Col>
          {/* Formulario en etapa intermedia, en etapa superada se deberia borrar */}
          {/* <Col md={12}>
            <Field name="company[technicalReviewEmailBody]">
              {({ field }) => (
                <RichEditor
                  {...field}
                  label="Email Recordatorio de Revisión Técnica"
                  data={getIn(values, field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  setFieldValue={setFieldValue}
                />
              )}
            </Field>
          </Col>
          <Col md={12}>
            <Field name="company[maintenanceEmailBody]">
              {({ field }) => (
                <RichEditor
                  {...field}
                  label="Email Recordatorio de Mantención"
                  data={getIn(values, field.name)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  setFieldValue={setFieldValue}
                />
              )}
            </Field>
          </Col> */}
        </Row>
        <Row className="mt-3">
          <Col md={6}>
            <Field name="company[maintenanceFlyer]">
              {({ field }) => (
                <UploadImage
                  {...field}
                  clearable
                  clearableText="Eliminar Flyer"
                  onLeft
                  label="Flyer de Mantención"
                  name={company.maintenanceFlyerImage?.filename}
                  imageUrl={getIn(company.maintenanceFlyerImage, 'fileUrl')}
                  destroyRequestData={{ imageCode: company.maintenanceFlyerImage?.id, imageType: 'maintenance_flyer' }}
                  deleteField={() => setCompany({ ...company, maintenanceFlyerImage: {} })}
                  destroyRequest={handleOnDelete}
                  onChange={_avatar => setFieldValue(field.name, _avatar)}
                  helpText="Formato sugerido 200x640px (.jpg o .png)."
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="company[technicalReviewFlyer]">
              {({ field }) => (
                <UploadImage
                  {...field}
                  clearable
                  clearableText="Eliminar Flyer"
                  onLeft
                  label="Flyer de Recordatorio de Revisión Técnica"
                  name={company.technicalReviewFlyerImage?.filename}
                  imageUrl={getIn(company.technicalReviewFlyerImage, 'fileUrl')}
                  destroyRequestData={{
                    imageCode: company.technicalReviewFlyerImage?.id,
                    imageType: 'technical_review_flyer'
                  }}
                  deleteField={() => setCompany({ ...company, technicalReviewFlyerImage: {} })}
                  destroyRequest={handleOnDelete}
                  onChange={_avatar => setFieldValue(field.name, _avatar)}
                  helpText="Formato sugerido 200x640px (.jpg o .png)."
                />
              )}
            </Field>
          </Col>
        </Row>
      </section>

      <section style={{ display: selectedTab === 'optionalFields' ? 'inline' : 'none' }}>
        <Row className="mt-3">
          <Col className="mb-4">
            <h1>Campos que serán requeridos en formularios</h1>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col className="mb-4">
            <h2>Clientes</h2>
          </Col>
        </Row>
        <Field name="company[companyRequiredFieldAttributes][clientAddress]">
          {({ field }) => <FormikCheckBox {...field} field={field} label="Dirección de Cliente" custom />}
        </Field>
        <Field name="company[companyRequiredFieldAttributes][clientCellphone]">
          {({ field }) => <FormikCheckBox {...field} field={field} label="Número de teléfono de Cliente" custom />}
        </Field>
        <Field name="company[companyRequiredFieldAttributes][clientEmail]">
          {({ field }) => <FormikCheckBox {...field} field={field} label="Email de Cliente" custom />}
        </Field>
        <Field name="company[companyRequiredFieldAttributes][regionAndCommune]">
          {({ field }) => <FormikCheckBox {...field} field={field} label="Región y Comuna del Cliente" custom />}
        </Field>
        <Field name="company[companyRequiredFieldAttributes][clientNationalIdentification]">
          {({ field }) => <FormikCheckBox {...field} field={field} label="RUT del Cliente" custom />}
        </Field>

        <Row className="mt-3">
          <Col className="mb-4">
            <h2>Vehículos</h2>
          </Col>
        </Row>
        <Field name="company[companyRequiredFieldAttributes][vehicleVin]">
          {({ field }) => <FormikCheckBox {...field} field={field} label="VIN de vehículo" custom />}
        </Field>
      </section>

      <Row className="d-flex justify-content-end mt-4 mb-4">
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            Actualizar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ company }) => {
  return { company };
};

const websiteRegex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gim;

const isTimeRangeOverlapping = ranges => {
  for (let i = 0; i < ranges.length; i++) {
    for (let j = i + 1; j < ranges.length; j++) {
      const start1 = parse(ranges[i].startTime, 'H:mm', new Date());
      const end1 = parse(ranges[i].endTime, 'H:mm', new Date());
      const start2 = parse(ranges[j].startTime, 'H:mm', new Date());
      const end2 = parse(ranges[j].endTime, 'H:mm', new Date());
      if (start1 < end2 && start2 < end1) {
        return true;
      }
    }
  }
  return false;
};

const validationSchema = ({ company }) => {
  return Yup.object().shape({
    company: Yup.object().shape({
      active: Yup.boolean(),
      address: Yup.string().required('Debes ingresar una dirección'),
      businessActivity: Yup.string().required('Debes ingresar un nombre'),
      collectionEmailBodyText: Yup.string().nullable(),
      collectionEmailSecondaryBodyText: Yup.string().nullable(),
      email: Yup.string()
        .required('Debes ingresar un email')
        .email('Debes ingresar un email válido'),
      endTime: Yup.string().nullable(),
      legalName: Yup.string().required('Debes ingresar una razón social'),
      name: Yup.string().required('Debes ingresar un nombre de fantasía'),
      nationalIdentification: Yup.string()
        .required('Debes ingresar un RUT')
        .rut('El RUT es inválido', company.country),
      paymentCondition: Yup.string().nullable(),
      phone: Yup.string().required('Debes ingresar un número de celular'),
      startTime: Yup.string().nullable(),
      taxRate: Yup.number().required('Debes ingresar un porcentaje de IVA'),
      whatsappPhoneNumber: Yup.string().nullable(),
      worksMonday: Yup.boolean(),
      worksTuesday: Yup.boolean(),
      worksWednesday: Yup.boolean(),
      worksThursday: Yup.boolean(),
      worksFriday: Yup.boolean(),
      worksSaturday: Yup.boolean(),
      worksSunday: Yup.boolean(),
      website: Yup.string().matches(websiteRegex, 'El formato no es válido'),
      appointmentsSchedule: Yup.array().of(
        Yup.object().shape({
          startTime: Yup.string()
            .required('Debes ingresar una hora de inicio')
            .test('no-overlapping', 'Los rangos de horas no deben superponerse', function() {
              const { appointmentsSchedule } = this.options.context.company;
              return !isTimeRangeOverlapping(appointmentsSchedule);
            }),
          endTime: Yup.string()
            .required('Debes ingresar una hora de fin')
            .test('no-overlapping', 'Los rangos de horas no deben superponerse', function() {
              const { appointmentsSchedule } = this.options.context.company;
              return !isTimeRangeOverlapping(appointmentsSchedule);
            })
        })
      )
    })
  });
};

const handleSubmit = (values, { props }) => {
  const { formRequest, onHide } = props;
  const formattedValues = {
    ...values,
    company: {
      ...values.company,
      appointmentsSchedule:
        values.company.appointmentsSchedule.length === 0 ? '[]' : values.company.appointmentsSchedule
    }
  };
  formRequest(formattedValues);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: true
})(CompanyForm);
