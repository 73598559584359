import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { sendAlert } from '../../actions/utils';
import { Button, Table } from 'react-bootstrap';
import { getCart, newOrder } from '../../requests/autoparts';

const Checkout = ({companyHashId}) => {
  const dispatch = useDispatch();
  const [cart, setCart] = useState({});

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const pay = () => {
    newOrder(companyHashId, {
      dispatch,
      successCallback: (response) => window.open(response.data.url, "_blank"),
      failureCallback: handleFailureRequest
    });
  };

  const fetchCart = () => {
    getCart(companyHashId, {
      dispatch,
      successCallback: response => setCart(response.data),
      failureCallback: handleFailureRequest
    });
  };

  useEffect(fetchCart, []);

  return (
    <>
      <p className='mb-1'>Si compras antes de las <strong>12:00</strong> llega el <strong>mismo día</strong>. Si compras antes de las <strong>17:00</strong> llega a la <strong>mañana siguiente</strong>.</p>
      <p className='mb-1'>(Aplica para días hábiles. Consulta por disponibilidad de despacho los sábados.)</p>
      <Table responsive size="sm" className="mt-2">
        <tbody>
          {cart.quotations && Object.values(cart.quotations).map(quotation => {
            if (!quotation) return undefined;
            return (
              <>
                <tr className='border-bottom' style={{ fontWeight: 'bold' }}>
                  <td className='border-0'></td>
                  <td className='pt-3 border-0' colspan="5">{quotation.title}</td>
                  <td className='float-right pt-3 border-0'>${quotation.total.toLocaleString('es-CL')}</td>
                </tr>
                {quotation.products.filter(p => p.quantity > 0).map(product => {
                  return (<tr>
                    <td className='float-right border-0'>{product.quantity}x</td>
                    <td className='border-0'>{product.name}</td>
                    <td className='border-0'>{product.brand}</td>
                    <td className='border-0'>{product.code}</td>
                    <td className='border-0'>{product.position ?? ''}</td>
                    {/* <td className='border-0'>{product.deliveryTime}</td> */}
                    <td className='text-black-50 border-0'>${product.price.toLocaleString('es-CL')} c/u</td>
                    <td className='float-right border-0'>${(product.quantity * product.price).toLocaleString('es-CL')}</td>
                  </tr>);
                })}
              </>
            );
          })}
          {cart.products && cart.products.filter(p => p.quantity > 0).map(product => {
            return (<tr>
              <td className='float-right border-0'>{product.quantity}x</td>
              <td className='border-0'>{product.name}</td>
              <td className='border-0'>{product.brand}</td>
              <td className='border-0'>{product.code}</td>
              <td className='border-0'>{product.position ?? ''}</td>
              {/* <td className='border-0'>{product.deliveryTime}</td> */}
              <td className='text-black-50 border-0'>${product.price.toLocaleString('es-CL')} c/u</td>
              <td className='float-right border-0'>${(product.quantity * product.price).toLocaleString('es-CL')}</td>
            </tr>);
          })}
          <tr className='border-top' style={{ fontWeight: 'bold' }}>
            <td></td>
            <td className='pb-3' colspan="5">SUBTOTAL</td>
            <td className='float-right'>${cart.total?.toLocaleString('es-CL')}</td>
          </tr>
          <tr>
            <td className='border-0'></td>
            <td className='border-0' colspan="2">Despacho (IVA inc.)</td>
            <td className='border-0' colspan="3">AM / PM</td>
            <td className='float-right border-0'>${cart.shippingPrice?.toLocaleString('es-CL')}</td>
          </tr>
          <tr className='border-top' style={{ fontWeight: 'bold' }}>
            <td></td>
            <td colspan="5">TOTAL (IVA incluido)</td>
            <td className='float-right'>${(cart.total+cart.shippingPrice).toLocaleString('es-CL')}</td>
          </tr>
        </tbody>
      </Table>
      <Button className="px-5 float-right" variant="primary" onClick={pay}>
        Pagar
      </Button>
    </>
  );
};

export default Checkout;
