import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexProjectsInvoicesRequest = ({ dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/project_invoices${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createProjectInvoiceRequest = (
  projectId,
  { dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.request('post', `/projects/${projectId}/project_invoices`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateProjectInvoiceRequest = (
  projectId,
  id,
  { dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.request('put', `/projects/${projectId}/project_invoices/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteProjectInvoiceRequest = (projectId, id, { dispatch, params, failureCallback, successCallback }) =>
  ApiService.request('delete', `/projects/${projectId}/project_invoices/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const getDocumentRequest = (
  projectId,
  id,
  { dispatch, format, params = {}, failureCallback, successCallback }
) =>
  ApiService.requestV2('getBlob', `/project_invoices/${id}/download_document.${format}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexPurchaseBookRequest = ({ dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/project_invoices${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const debounceindexProjectsInvoicesRequest = AwesomeDebouncePromise(indexProjectsInvoicesRequest, 300);
