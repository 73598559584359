/* eslint-disable no-nested-ternary */
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Button, Col, Row, Table} from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-feather';
import snakeCaseKeys from 'snakecase-keys';
import {SimpleCenteredModal, LinkBtn} from '../../../components';
import {updateProjectRequest} from '../../../requests/projects';
import {sendAlert} from '../../../actions/utils';
import ProjectAutopartListEdit from './ProjectAutopartsListEditModal';

const ProjectAutopartsList = ({
  project,
  id,
  setMoreData,
  toggleAutopartsExpanded
}) => {
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  const { projectAutopartsAttributes } = project;
  
  const handleModalClose = () => {
    setCenterModalShow(false);
    setModalBody('');
    setModalTitle('');
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: `Lista de repuestos actualizada con éxito` }));
    setMoreData(m => !m);
    handleModalClose();
  };

  const handleFailureRequest = () => {
    dispatch(sendAlert({ kind: 'error', message: `Error al actualizar Lista de repuestos` }));
  };

  const handleUpdateRequest = (values) => {
    const { projectAutopartsAttributes } = values.project;
    const params = { project: {} }
    params.project.projectAutopartsAttributes = { ...projectAutopartsAttributes };
    updateProjectRequest(id, {
      dispatch,
      params: snakeCaseKeys(params, { exclude: ['_destroy'] }),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const handleEditModalCreate = () => {
    setModalTitle('Editar lista de repuestos');
    setCenterModalShow(true);
    setModalBody(
      <ProjectAutopartListEdit
        project={project}
        setMoreData={setMoreData}
        onHide={handleModalClose}
        action="edit"
        submitVariant="success"
        formRequest={(values) => handleUpdateRequest(values)}
      />
    );
  };

  const autopartsTable = () => {
    if (projectAutopartsAttributes.length === 0) {
      return;
    }
    return (
      <Table responsive size="sm" className="mt-2">
        <thead>
          <tr>
            <th>Repuesto</th>
            <th>Cantidad</th>
            <th>Cantidad comprada</th>
            <th>Cantidad recibida</th>
          </tr>
        </thead>
        <tbody>
          {projectAutopartsAttributes.map(autopart => {
            if (autopart._destroy) {
              return undefined;
            }
            return (<tr>
              <td>{autopart.name}</td>
              <td>{autopart.quantity}</td>
              <td>{autopart.purchasedQuantity}</td>
              <td>{autopart.receivedQuantity}</td>
            </tr>);
          })}
        </tbody>
      </Table>
    );
  };

  const toggle = () => {
    setExpanded(!expanded);
    toggleAutopartsExpanded();
  }

  return (
    <>
      <Row className="px-3 d-block">
        <strong>Lista de repuestos</strong>
        { expanded &&
          <>
            {autopartsTable()}
            <Col md={12} className="text-center mb-3">
              <Button className="px-5 mr-2" variant="secondary" onClick={() => handleEditModalCreate()}>
                Editar
              </Button>
              <LinkBtn className="px-5 ml-2" variant="primary" to={`/projects/${id}/autoparts`}>
                Cotizar
              </LinkBtn>
            </Col>
          </>
        }
      </Row>
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={centerModalShow}
        onHide={handleModalClose}
        size="xl"
        closeButton
      />
      <Row>
        <button className='w-100 btn btn-light' onClick={toggle}>
          { expanded ? <ChevronUp size={15}/> : <ChevronDown size={15}/>}
        </button>
      </Row>
    </>
  );
};

export default ProjectAutopartsList;
