import React from 'react';
import { Col, Alert } from 'react-bootstrap';
import { Field } from 'formik';
import { FormikCheckBox } from '../../../components';
import { dateFormat } from '../../../utils/utils';

const MaintenanceReminder = ({ values, project, handleChangeOnMaintenanceReminder, setFieldValue }) => (
  <>
    <Col md={12}>
      <Alert variant="secondary">
        <p className="font-weight-bold mb-0">
          Fecha de recordatorio de mantención:
          <span className="font-weight-normal">
            {dateFormat(values.project.vehicleAttributes.maintenanceReminderDate) || 'No especificada'}
          </span>
        </p>
        {!values.project.rememberMaintenance && (
          <p className="font-weight-bold mb-0">
            Si activa este check la fecha cambiará a:
            <span className="font-weight-normal"> {dateFormat(project.nextMaintenanceReminderDate)}</span>
          </p>
        )}
      </Alert>
    </Col>
    <Col className="position-relative" style={{ zIndex: 0 }}>
      <Field name="project[rememberMaintenance]">
        {({ field }) => (
          <FormikCheckBox
            {...field}
            field={field}
            label="¿Recordar Mantención?"
            onClick={e => handleChangeOnMaintenanceReminder(e, setFieldValue)}
            className="ml-3 mb-2"
          />
        )}
      </Field>
    </Col>
  </>
);

export default MaintenanceReminder;
