import { useQuery as reactQuery, useQueryClient } from 'react-query';

const wrapper = axiosFn => ({ queryKey }) => axiosFn(...queryKey.slice(1));

export const isLoading = status => status === 'loading';

export const isSuccessful = status => status === 'success';

export const isError = status => status === 'error';

const useQuery = (queryKeys, queryFunction, options) => {
  const { data, status } = reactQuery(queryKeys, wrapper(queryFunction), options);
  return { data, status, isLoading: isLoading(status) };
};

export const useInvalidateQuery = () => {
  const queryClient = useQueryClient();
  const invalidateQueries = queryKey => queryClient.invalidateQueries(queryKey);
  return invalidateQueries;
};

export const useSetQueryData = () => {
  const queryClient = useQueryClient();
  const setQueryData = (queryKey, data) => queryClient.setQueryData(queryKey, data);
  return setQueryData;
};

export const useGetQueryData = () => {
  const queryClient = useQueryClient();
  const getQueryData = queryKey => queryClient.getQueryData(queryKey);
  return getQueryData;
};

export const useCancelQuery = () => {
  const queryClient = useQueryClient();
  const cancelQuery = queryKey => queryClient.cancelQueries(queryKey);
  return cancelQuery;
};

export default useQuery;
