import {Button, Row, Col, Table} from 'react-bootstrap';
import React, {Fragment, useState, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {Formik, Form, Field, getIn} from 'formik';
import {publicUpdateProjectWorksApprovalStatusRequest} from "../../requests/projects";
import {payProjectRequest} from "../../requests/projectPayments";
import {SimpleCenteredModal, BasicTextArea} from "../../components";
import {sendAlert} from "../../actions/utils";
import ApproveButtons from "../Project/Show/ApproveButtons";
import NationalIdentificationForm from "../Client/NationalIdentificationForm";
import {getCountryFactory} from "../../utils/utils";

const PublicProjectQuoteTable = ({project, token, version}) => {
  const {
    companyTaxRate,
    parsedTotalDiscount,
    parsedTotalPriceWithDiscount,
    parsedTotalPrice,
    parsedTotalPriceTax,
    parsedTotalTax,
    projectWorksAttributes,
    paymentServiceEnabled
  } = project;
  const [doubtModalShow, setDoubtModalShow] = useState(null);
  const [totalApproved, setTotalApproved] = useState('');
  const [hover, setHover] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const approvementRef = useRef(null);
  const countryFactory = getCountryFactory(project?.country);

  useEffect(() => {
    setTotalApproved(project.projectWorksAttributes.filter(w => w.approvalStatus === 'approved').reduce((acc, current) => {
      const parsedPrice = parseFloat(current.projectDetailsAttributes.reduce((accDetail, currentDetail) => {
        const parsedDetailPrice = parseFloat(currentDetail.price) || 0;
        return accDetail + parsedDetailPrice;
      }, 0));
      return acc + parsedPrice;
    }, 0));
  }, [project.projectWorksAttributes]);

  useEffect(() => {
    if (project.currentOtState === 'budget_sent' && approvementRef.current) {
      approvementRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, []);

  const updateTotalApproved = (projectWorksApprovals) => {
    const newTotalApproved = project.projectWorksAttributes.filter((w, i) => projectWorksApprovals[i].value === 'approved').reduce((acc, current) => {
      const parsedPrice = parseFloat(current.projectDetailsAttributes.reduce((accDetail, currentDetail) => {
        const parsedDetailPrice = parseFloat(currentDetail.price) || 0;
        return accDetail + parsedDetailPrice;
      }, 0));
      return acc + parsedPrice;
    }, 0);
    setTotalApproved(Math.round(newTotalApproved * 1.19));
  }

  const projectDetailBody = (vProjectDetail) => {
    const {
      categoryName,
      parsedUnitPrice,
      parsedPrice,
      productCategoryName,
      productNameShow,
      quantity,
      parsedPriceDiscount,
    } = vProjectDetail;
    const nameToShow = productNameShow;

    return (
      <>
        {project.currentOtState === 'budget_sent' && <td/>}
        <td>
          {categoryName}
        </td>
        {!project?.hideSubcategory && <td>{productCategoryName}</td>}
        <td className={`text-uppercase ${nameToShow ? '' : 'font-italic text-muted'}`}>
          {nameToShow || 'Ítem sin Nombre'}
        </td>
        <td>{quantity || 0}</td>
        <td>{parsedUnitPrice}</td>
        <td className="text-warning">{parsedPriceDiscount}</td>
        <td>{parsedPrice}</td>
      </>
    );
  };

  const calculatedPrice = projectDetailsAttributes => {
    return projectDetailsAttributes.reduce((acc, current) => {
      const parsedPrice = parseFloat(current.price) || 0;
      return acc + parsedPrice;
    }, 0);
  };

  const currencyFormat = (projectDetailsAttributes, sym = '$') => {
    const price = calculatedPrice(projectDetailsAttributes);
    const priceWithFormat = price.toLocaleString().replace(',', '.');
    return `${sym}${priceWithFormat}`;
  };

  const projectWorksBody = (values, setFieldValue) =>
    projectWorksAttributes &&
    projectWorksAttributes.map((projectWork, indexWork) => {
      const projectDetails = projectWork.projectDetailsAttributes.filter(projectDetail => !projectDetail.hidden);
      return (
        <Fragment key={`project-work-${indexWork.toString()}`}>
          {projectDetails.map((projectDetail, indexDetail) =>
            indexDetail === 0 ? (
              <>
                <tr>
                  {project.currentOtState === 'budget_sent' &&
                    <>
                      <td>
                        {getIn(values, `projectWorksApprovals[${indexWork}][disabled]`) ? (
                          projectWork.approvalStatus === 'approved' ?
                            <strong className='text-success'>APROBADO</strong> :
                            <strong className='text-danger'>RECHAZADO</strong>
                        ) : (
                          <>
                            <Field name={`projectWorksApprovals[${indexWork}][value]`}>
                              {({field}) =>
                                <ApproveButtons
                                  handleButtonsAction={(data) => {
                                    const projectWorksApprovals = getIn(values, `projectWorksApprovals`)
                                    projectWorksApprovals[indexWork].value = data;
                                    if (data === 'doubt') {
                                      setDoubtModalShow(indexWork);
                                    }
                                    updateTotalApproved(projectWorksApprovals);
                                    setFieldValue(`projectWorksApprovals`, projectWorksApprovals);
                                  }}
                                  field={field}
                                />
                              }
                            </Field>
                            <SimpleCenteredModal
                              title="Dudas"
                              body={
                                <Field name={`projectWorksApprovals[${indexWork}][notes]`}>
                                  {({field}) => (
                                    <BasicTextArea
                                      {...field}
                                    />
                                  )}
                                </Field>
                              }
                              show={doubtModalShow === indexWork}
                              onHide={() => setDoubtModalShow(null)}
                              closeButton
                            />
                          </>
                        )}
                      </td>
                    </>
                  }
                  <td className="font-weight-bold" style={{color: '#5e99b8'}}>
                    {projectWork.name}
                  </td>
                </tr>
                <tr key={`project-detail-${indexDetail.toString()}`}>
                  {projectDetailBody(projectDetail, indexWork, indexDetail)}
                </tr>
              </>
            ) : (
              <tr key={`project-detail-${indexDetail.toString()}`}>
                {projectDetailBody(projectDetail, indexWork, indexDetail)}
              </tr>
            )
          )}
          {projectDetails.length > 0 && (
            <tr>
              <th colSpan={project?.hideSubcategory ? '5' : '6'}/>
              <th>Subtotal</th>
              <th>{currencyFormat(projectDetails)}</th>
            </tr>
          )}
        </Fragment>
      );
    });

  const isDiscounted = () => {
    if (parsedTotalDiscount) {
      const discount = parseInt(parsedTotalDiscount.substring(1), 10);
      if (discount) return true;
    }
    return false;
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({kind: 'error', message: error?.response?.data?.message}));
  };

  const handleSubmit = (values) => {
    if (values.projectWorksApprovals.filter(v => v.value === 'pending').length) {
      dispatch(sendAlert({kind: 'error', message: 'Se deben seleccionar todos.'}));
    } else {
      const updatedValues = values.projectWorksApprovals.filter(v => !v.disabled);
      updatedValues.forEach(uv => {
        if (uv.value === 'doubt' && (!uv.notes || uv.notes === '') && values.all.notes && values.all.notes !== '') {
          uv.notes = values.all.notes;
        }
      });
      publicUpdateProjectWorksApprovalStatusRequest(token, version, {
        dispatch,
        params: {project: {project_works_approval_status: updatedValues}},
        successCallback: (response) => {
          dispatch(sendAlert({kind: 'success', message: 'Enviado con éxito'}));
          history.push(response.data.path);
        },
        failureCallback: handleFailureRequest
      });
    }
  }

  const handleModalClose = () => {
    setModalShow(false);
    setModalBody('');
  };

  const saveNationalIdentification = (values) => {
    if (!paymentServiceEnabled) alert('El taller no tiene habilitada la pasarela de pago.');
    payProjectRequest(token, {
      dispatch,
      params: { client: { national_identification: values.client.nationalIdentification }},
      successCallback: (response) => {
        if (response.data.link_payment) {
          window.open(response.data.link_payment, "_self");
        }
      },
      failureCallback: handleFailureRequest
    });
  };

  const handlePay = () => {
    if (project?.client?.nationalIdentification === '' || project?.client?.nationalIdentification === null) {
      setModalBody(<NationalIdentificationForm onHide={handleModalClose} formRequest={saveNationalIdentification}
                                               countryFactory={countryFactory} client={project?.client}/>);
      setModalShow(true);
    } else {
      if (!paymentServiceEnabled) alert('El taller no tiene habilitada la pasarela de pago.');
      payProjectRequest(token, {
        dispatch,
        params: {},
        successCallback: (response) => {
          if (response.data.link_payment) {
            window.open(response.data.link_payment, "_self");
          }
        },
        failureCallback: handleFailureRequest
      });
    }
  };

  return (
    <>
      {project.currentOtState === 'budget_approved' &&
        <Row className='justify-content-end fixed-bottom mb-5'>
          <Col md={3} className='mr-md-5'>
            <Button
              variant="success"
              className='w-100 py-2 rounded-pill shadow-lg bg-body rounded'
              style={{fontSize: 'x-large', backgroundColor: hover ? '#38c156' : '#3fd760'}}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              onClick={handlePay}
            >
              IR A PAGAR
            </Button>
          </Col>
        </Row>
      }
      <Formik
        initialValues={project.currentOtState === 'budget_sent' ?
          {
            projectWorksApprovals: projectWorksAttributes.map(work => {
              return {
                id: work.id,
                value: work.approvalStatus ? work.approvalStatus : 'pending',
                disabled: ['approved', 'rejected'].includes(work.approvalStatus)
              }
            })
          } : {}
        }
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({values, setFieldValue}) => (
          <Form ref={approvementRef}>
            <Table responsive borderless hover>
              <thead className="border-top-borderless-table">
              <tr className="header-border-bottom">
                {project.currentOtState === 'budget_sent' && <th>Aprobación</th>}
                <th>Categoría</th>
                {!project?.hideSubcategory && <th>Subcategoría</th>}
                <th>Ítem</th>
                <th>Cantidad</th>
                <th>P. Venta Unit</th>
                <th>Descuento</th>
                <th>P. Venta Total</th>
              </tr>
              </thead>
              <tbody>
              {projectWorksBody(values, setFieldValue)}
              <>
                <tr className="border-top-borderless-table">
                  <th colSpan={project?.hideSubcategory ? '5' : '6'}/>
                  <th>Valor Neto</th>
                  <th>{parsedTotalPrice}</th>
                </tr>
                {isDiscounted() && (
                  <>
                    <tr className="font-weight-bold">
                      <th colSpan={project?.hideSubcategory ? '5' : '6'}/>
                      <th>Descuento</th>
                      <th className="text-secondary">- {parsedTotalDiscount}</th>
                    </tr>
                    <tr className="font-weight-bold">
                      <th colSpan={project?.hideSubcategory ? '5' : '6'}/>
                      <th>Valor Total Neto</th>
                      <th>{parsedTotalPriceWithDiscount}</th>
                    </tr>
                  </>
                )}
                <tr>
                  <th colSpan={project?.hideSubcategory ? '5' : '6'}/>
                  <th>IVA ({companyTaxRate})</th>
                  <th>{parsedTotalTax}</th>
                </tr>
                <tr>
                  <th colSpan={project?.hideSubcategory ? '5' : '6'}/>
                  <th>
                    <h2>Total</h2>
                  </th>
                  <th>
                    <h2>{parsedTotalPriceTax}</h2>
                  </th>
                </tr>
                {project.currentOtState === 'budget_sent' &&
                  <tr>
                    <th colSpan={project?.hideSubcategory ? '5' : '6'}>
                      <Button variant="primary" type='submit' className='text-center'>Enviar Aprobaciones</Button>
                    </th>
                    <th>
                      <h2 className='text-success'>Total aprobado</h2>
                    </th>
                    <th>
                      <h2 className='text-success'>{`$${totalApproved.toLocaleString().replaceAll(',', '.')}`}</h2>
                    </th>
                  </tr>
                }
              </>
              </tbody>
            </Table>
          </Form>
        )}
      </Formik>
      <SimpleCenteredModal
        size="md"
        closeButton
        title={`POR FAVOR, INGRESA TU ${project?.client.nationalIdentificationText}`}
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
      />
    </>
  );
};
export default PublicProjectQuoteTable;
