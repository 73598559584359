import React from 'react';
import { Form, Formik } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
import { useGetProject } from '../../../hooks/useProjects';
import { invoiceStateTypes } from './FormOptions';
import SelectInputField from '../../../components/Utils/Input/SelectInputField';

const ProjectInvoiceStateForm = ({ projectId, onSave }) => {
  const { project, isLoading } = useGetProject(projectId, 'edit');
  const initialValues = {
    project: {
      invoiceState: project.invoiceState
    }
  };
  if (isLoading) return null;
  return (
    <Formik initialValues={initialValues} onSubmit={onSave}>
      {({ isSubmitting }) => (
        <Form>
          <Row>
            <Col md={12}>
              <SelectInputField
                name="project[invoiceState]"
                label="Estado Cobro"
                placeholder="Seleccionar Estado"
                options={invoiceStateTypes}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Button block disabled={isSubmitting} type="submit" variant="success">
                {isSubmitting ? 'Guardando...' : 'Guardar'}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
export default ProjectInvoiceStateForm;