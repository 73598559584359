import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row, Table } from 'react-bootstrap';
import { Edit, Trash } from 'react-feather';
import ProjectPaymentFormEdit from './ProjectPaymentFormEdit';
import { sendAlert } from '../../actions/utils';
import { DefaultModal, SimpleCenteredModal } from '../../components';
import { deleteProjectPaymentRequest } from '../../requests/projectPayments';
import AddProjectPayment from './AddProjectPayment';
import { useListProjectPaymentsByProject } from '../../hooks/useProjectPayments';
import { useReFetchProjectInvoices } from '../../hooks/useProjectInvoices';
import { useReFetchProject } from '../../hooks/useProjects';

const ProjectPaymentsShow = ({ project, publicVersion, version }) => {
  const { id: projectId, hasPaymentService, totalPaymentFormatted } = project;
  const dispatch = useDispatch();
  const { projectPayments, reFetch: reFetchPayment, isLoading } = useListProjectPaymentsByProject(projectId, 'list');
  const reFetchProjectInvoices = useReFetchProjectInvoices(projectId);
  const reFetchProject = useReFetchProject(projectId);
  const [defaultModalShow, setDefaultModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const handleModalClose = () => {
    setDefaultModalShow(false);
    setModalBody('');
    setModalItem(null);
    setModalShow(false);
    setModalTitle('');
  };

  const handleReFetch = () => {
    reFetchPayment();
    reFetchProjectInvoices();
    reFetchProject();
  };

  const handleSuccessAction = (response, message) => {
    dispatch(sendAlert({ kind: 'success', message }));
    handleReFetch();
    handleModalClose();
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleAfterEdit = () => {
    handleSuccessAction({}, 'Abono editado con éxito');
  };

  const handleDeleteRequest = () => {
    deleteProjectPaymentRequest(projectId, modalItem.id, {
      dispatch,
      successCallback: response => handleSuccessAction(response, 'Abono eliminado con éxito'),
      failureCallback: handleFailureRequest
    });
  };

  if (isLoading) return null;

  const handleButtonClick = (item, action) => {
    switch (action) {
      case 'edit':
        setModalBody(<ProjectPaymentFormEdit id={item.id} projectId={project.id} onSuccess={handleAfterEdit} />);
        setModalItem(item);
        setModalShow(true);
        setModalTitle('Editar Abono');
        break;
      case 'destroy':
        setDefaultModalShow(true);
        setModalBody('¿Estás seguro que deseas eliminar este abono?');
        setModalItem(item);
        break;
      default:
        dispatch(sendAlert({ kind: 'error', message: 'Acción no permitida' }));
    }
  };

  const mapProjectPayments = projectPayments.map((projectPayment, index) => {
    return (
      <tr key={`project-payment-${index.toString()}`}>
        <td>{projectPayment.paymentTypeName}</td>
        <td>{projectPayment.dateFormatted}</td>
        <td>{projectPayment.amountFormatted}</td>
        <td>{projectPayment.documentNamesText}</td>
        <td>{projectPayment.balanceFormatted}</td>
        {hasPaymentService && <td className="id-pago">{projectPayment.paymentExternalId}</td>}
        {!publicVersion && (
          <td className="d-flex justify-content-center">
            <Edit
              width="18px"
              height="18px"
              className="clickable delete-item text-warning mr-2"
              onClick={() => handleButtonClick(projectPayment, 'edit')}
            />
            <Trash
              width="18px"
              height="18px"
              className="clickable delete-item text-danger"
              onClick={() => handleButtonClick(projectPayment, 'destroy')}
            />
          </td>
        )}
      </tr>
    );
  });
  mapProjectPayments.push(
    <tr key="project-payment-total">
      <th colSpan={2}>Total</th>
      <th>{totalPaymentFormatted}</th>
      <th colSpan={hasPaymentService ? 3 : 2} />
      {!publicVersion && <th />}
    </tr>
  );

  return (
    <>
      <Row className="mb-3">
        <Col className="section-header">
          <strong>ABONOS</strong>
          <AddProjectPayment projectId={project.id} version={version} onSuccess={handleReFetch} />
        </Col>
      </Row>
      <Table striped bordered hover size="sm" responsive>
        <thead>
          <tr className="text-uppercase fit-content">
            <th>Tipo</th>
            <th>Fecha</th>
            <th>Monto</th>
            <th>Docs.</th>
            <th>Saldo</th>
            {hasPaymentService && <th>ID Pago</th>}
            {!publicVersion && <th style={{ width: '65px' }} />}
          </tr>
        </thead>
        <tbody>{mapProjectPayments}</tbody>
      </Table>
      {defaultModalShow && (
        <DefaultModal
          title="Eliminar abono"
          body={modalBody}
          show={defaultModalShow}
          handleClose={handleModalClose}
          handleConfirm={handleDeleteRequest}
          titleBtnClose="Cancelar"
          titleBtnSave="Confirmar"
        />
      )}
      <SimpleCenteredModal title={modalTitle} body={modalBody} show={modalShow} onHide={handleModalClose} closeButton />
    </>
  );
};

export default ProjectPaymentsShow;
