import camelCaseRecursive from 'camelcase-keys-recursive';
import api_v2 from '../services/api_v2';
import useQuery, { useInvalidateQuery } from './useQuery';

const getClients = () => api_v2.get(`/clients.json`).then(res => res.data);

const getClient = (clientId, view) => api_v2.get(`/clients/${clientId}.json?view=${view}`).then(res => res.data);

const updateClient = (clientId, params) => api_v2.put(`/clients/${clientId}.json`, params).then(res => res.data);

export const useGetClient = (clientId, view) => {
  const { data = [], isLoading } = useQuery(['client', clientId, view], getClient, { enabled: !!clientId });
  const invalidateQueries = useInvalidateQuery();
  const reFetch = () => {
    invalidateQueries(['client', clientId]);
  };
  const client = camelCaseRecursive(data);
  return { client, reFetch, isLoading };
};

export const useUpdateClient = clientId => {
  const update = async (values, options = {}) => {
    try {
      const data = await updateClient(clientId, values);
      options.onSuccess && options.onSuccess(data);
    } catch (error) {
      options.onError && options.onError(error);
    }
  };
  return { update };
};

export const useListClients = () => {
  const { data = [] } = useQuery(['clients'], getClients);
  const invalidateQueries = useInvalidateQuery();
  const reFetch = () => {
    invalidateQueries(['clients']);
  };
  let clients = camelCaseRecursive(data);
  return { clients, reFetch };
};
