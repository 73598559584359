import React, { useState } from 'react';
import { Col, Dropdown, Button, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { getChecklistPdfRequest, getPdfRequest } from '../../requests/projects';
import ProjectDetailAdvancedConfiguration from '../ProjectDetail/ProjectDetailAdvancedConfiguration';
import downloadFile from '../../services/utils';
import { sendAlert } from '../../actions/utils';
import { SimpleCenteredModal } from '../../components';
import PublicProjectQuoteTable from './PublicProjectQuoteTable';

const PublicProductShow = ({ project, setMoreData, preview, token, version }) => {
  const { id, checklistsAttributes, otState, paymentServiceEnabled } = project;
  const history = useHistory();
  const dispatch = useDispatch();

  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const downloadPdf = pdfType => {
    getPdfRequest({
      id,
      type: pdfType,
      dispatch,
      format: 'pdf',
      successCallback: response => downloadFile(response),
      failureCallback: error => dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }))
    });
  };

  const downloadChecklistPdf = type => {
    getChecklistPdfRequest({
      id,
      type,
      dispatch,
      format: 'pdf',
      successCallback: response => downloadFile(response)
    });
  };

  const handleAdvancedConfiguration = (vProjectDetail, inputName, productId) => {
    setModalShow(true);
    setModalBody(
      <Formik initialValues={{ project }} onSubmit={() => null}>
        <ProjectDetailAdvancedConfiguration
          inputName={inputName}
          vProjectDetail={vProjectDetail}
          onHide={() => setModalShow(false)}
          restoreOriginalValues={() => setModalShow(false)}
          originalValues={vProjectDetail}
          setOriginalValues={() => setMoreData(m => !m)}
          handleEditConfirmation={() => history.push(`/products/${productId}/edit`)}
        />
      </Formik>
    );
  };

  return (
    <Row>
      <Col md={paymentServiceEnabled ? 4 : 6} className="mb-3">
        <strong>Ítems</strong>
      </Col>
      {(otState !== 'in_process' || preview) && (
        <Col md={12}>
          <PublicProjectQuoteTable
            project={project}
            handleAdvancedConfiguration={handleAdvancedConfiguration}
            setMoreData={setMoreData}
            token={token}
            version={version}
          />
        </Col>
      )}
      <SimpleCenteredModal size="md" title="Configuración Avanzada" body={modalBody} show={modalShow} />
    </Row>
  );
};

export default PublicProductShow;
