import React from 'react';
import { Row, Col } from 'react-bootstrap';

const PublicProjectNavbarInfo = ({ currentVersion, project }) => {
  const {
    companyTaxRate,
    formatedPromiseDate,
    parsedMileage,
    parsedTotalPrice,
    parsedTotalPriceTax,
    parsedTotalTax,
    preInspectionDate,
    projectDate,
    reviewDate,
    formatedDeliveredDate,
    receptionDate,
    otState
  } = project;

  return (
    <Row className="mb-3 pt-3 project-section project-show-nav small d-flex align-items-center">
      <Col md={7}>
        <Row className="nav-info-section text-uppercase">
          <Col md={7}>
            <Row>
              {project.orderType === 'reception_secure' && (
                <Col>
                  <strong>Fecha Pre inspección</strong>
                  <p>{preInspectionDate}</p>
                </Col>
              )}
              <Col>
                <strong>Fecha Ingreso</strong>
                <p>{projectDate}</p>
              </Col>
              <Col>
                <strong>Fecha de recepción</strong>
                <p>{receptionDate}</p>
              </Col>
              <Col>
                <strong>Fecha Revisión</strong>
                <p>{reviewDate}</p>
              </Col>
            </Row>
          </Col>
          <Col md={5}>
            <Row>
              <Col>
                <strong>Fecha estimada de entrega</strong>
                <p>{formatedPromiseDate}</p>
              </Col>
              <Col>
                <strong>Fecha de entrega</strong>
                <p>{formatedDeliveredDate}</p>
              </Col>
              <Col className="project-info-division">
                <strong className="d-flex">Kilometraje</strong>
                <p>{parsedMileage}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      {otState !== 'in_process' && (
        <Col md={5} className="ml-3">
          <Row className="text-uppercase d-flex align-items-center">
            <Col md={3}>
              <p className="mb-2">
                <span className="font-weight-bold d-inline-block w-50">Valor Neto:</span> {parsedTotalPrice}
              </p>
              <p className="mb-2">
                <span className="font-weight-bold d-inline-block w-50">IVA ({companyTaxRate}):</span> {parsedTotalTax}
              </p>
            </Col>
            <Col md={3} className="text-primary">
              <h4 className="font-weight-bold mb-2">Total: {parsedTotalPriceTax}</h4>
            </Col>
            <Col md={{ span: 3, offset: 3 }} className="font-weight-bold d-flex justify-content-end">
              Versión #{currentVersion.versionNumber}: {currentVersion.createdAt}
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default PublicProjectNavbarInfo;
