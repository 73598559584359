import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withFormik, Form, Field, getIn } from 'formik';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';

import QRCode from "react-qr-code";
import { FormikCheckBox, FormikInput, FormikSelect, UploadImage, SimpleCenteredModal } from '../../components';
import { indexBranchOfficesRequest } from '../../requests/branchOffices';
import { indexRolesRequest } from '../../requests/roles';
import { sendAlert } from '../../actions/utils';
import WhatsappConnection from '../Company/WhatsappConnection';
import { status2Chat, qr2Chat } from '../../requests/user';
import PhoneInputField from "../../components/Utils/Input/PhoneInputField";

const UserForm = props => {
  const { action, errors, onHide, setFieldValue, setFieldTouched, submitVariant, touched, user } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';
  const [branchOffices, setBranchOffices] = useState([]);
  const [roles, setRoles] = useState([]);
  const {user: currentUser} = useSelector(state => state.auth);
  const [centerModalShow, setCenterModalShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const dispatch = useDispatch();
  let qrCode;

  const fetchBranchOffices = () => {
    indexBranchOfficesRequest({
      dispatch,
      params: {
        for_selector: true,
        sort_column: 'name',
        display_length: 25
      },
      successCallback: response => setBranchOffices(response.data.data)
    });
  };

  const fetchRoles = () => {
    indexRolesRequest({
      dispatch,
      params: {
        sort_column: 'name',
        display_length: 25
      },
      successCallback: response => setRoles(response.data.data)
    });
  };

  const intialFetchs = () => {
    fetchBranchOffices();
    fetchRoles();
  };

  useEffect(intialFetchs, []);

  const handleSelectorIsMulti = (data, field, allowEmpty = false) => {
    const newData = data.map(element => element.value);
    if (allowEmpty && !newData.length) {
      newData.push('');
    }
    setFieldValue(field, newData);
  };

  const getQr = () => {
    qr2Chat({
      dispatch,
      successCallback: response => {
        if (response.data.status === 'disconnected') {
          if (response.data.qr) {
            if (response.data.qr !== qrCode) {
              qrCode = response.data.qr;
              renderQr(response.data.qr);
            }
          }
          setTimeout(getQr, 1000);
        } else if (response.data.status === 'connected') {
          handleSuccessConnect();
        } else {
          handleMessage(response.data.status);
        }
      },
      failureCallback: handleFailureRequest
    });
  };

  const notSuscribedMessage = () => {
    setModalBody(
      <WhatsappConnection
        submitVariant="success"
        connectRequest={handleConnectRequest}
        handleModalClose={handleModalClose}
        content={
          <div>
            <b>No tienes contratado el servicio de mensajería directo. Para contratarlo, ponte en contacto con nosotros.</b>
          </div>
        }
      />
    );
  };

  const renderQr = qr => {
    setModalBody(
      <WhatsappConnection
        submitVariant="success"
        connectRequest={handleConnectRequest}
        handleModalClose={handleModalClose}
        content={
          <div style={{ height: "auto", margin: "0 auto", maxWidth: 256, width: "100%" }}>
            <b>Escanea el código de abajo usando WhatsApp</b>
            <ol>
              <li>Abre Configuración (iPhone) o Menú (Android)</li>
              <li>Selecciona Dispositivos Vinculados</li>
              <li>Toca en 'Vincular un dispositivo'</li>
            </ol>
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={qr}
              viewBox="0 0 256 256"
            />
          </div>
        }
      />
    );
  };

  const handleConnectRequest = () => {
    setModalBody(
      <WhatsappConnection
        submitVariant="success"
        connectRequest={handleConnectRequest}
        handleModalClose={handleModalClose}
        content={
          <Col>
            <Row className='justify-content-center'>
              <b>Obteniendo código, puede tardar unos segundos.</b>
            </Row>
            <Row>
              <div className="containerSpinnerLoad">
                <Spinner animation="border" variant="primary" />
              </div>
            </Row>
          </Col>
        }
      />
    );
    status2Chat({
      dispatch,
      successCallback: response => {
        if (response.data.status === 'disconnected') {
          renderQr(response.data.qr);
        } else if (response.data.status === 'not-suscribed') {
          notSuscribedMessage();
        } else if (response.data.status === 'waiting-qr') {
          setTimeout(getQr, 10000);
        } else {
          handleMessage(response.data.status);
        }
      },
      failureCallback: handleFailureRequest
    });
  };

  const handleModalClose = () => {
    setCenterModalShow(false);
  };

  const handleSuccessConnect = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Whatsapp conectado con éxito' }));
    handleModalClose();
  };

  const handleMessage = status => {
    setModalBody(
      <WhatsappConnection
        submitVariant="success"
        connectRequest={handleConnectRequest}
        handleModalClose={handleModalClose}
      />
    );
    const msg = {
      'channel-creation-failed': {kind: 'error', message: `Error al crear el canal para el número ${  user.whatsappPhoneNumber}`},
      'failure-state': {kind: 'error', message: 'Error'},
      'already-connected': {kind: 'success', message: 'Ya estás conectado.'},
      'connection-failed': {kind: 'error', message: 'Conexión fallida.'}
    };
    dispatch(sendAlert({ kind: msg[status].kind, message: msg[status].message }));    
  };

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleModalCreate = () => {
    setCenterModalShow(true);
    setModalBody(
      <WhatsappConnection
        submitVariant="success"
        connectRequest={handleConnectRequest}
        handleModalClose={handleModalClose}
      />
    );
  };

  return (
    <Form>
      <Row className="mt-3">
        <Col>
          <Field name="user[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name="user[lastName]">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Apellido"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Field name="user[email]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Email"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name="user[roleIds]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                isMulti
                label="Roles"
                placeholder="Seleccionar Roles"
                options={roles}
                defaultValue={user.roleIds}
                defaultMultiValues={user.roles}
                onChange={data => handleSelectorIsMulti(data || [], field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                setFieldTouched={() => setFieldTouched(field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={6}>
          <PhoneInputField
            name="user[whatsappPhoneNumber]"
            label="Número de Whatsapp"
          />
        </Col>
        {currentUser.id === user.id && user.companyAttributes.messengerService &&
          <Col>
            <Button
              style={{ fontWeight: '700', backgroundColor: '#25d366' }}
              className="connect-wsp-btn"
              onClick={() => handleModalCreate()}
            >
              Conectar Whatsapp
            </Button>
            <span className='ml-2'>Cada conexión tiene un valor de 0,4 UF + IVA mensuales</span>
          </Col>
        }
      </Row>
      <SimpleCenteredModal
        title="Conectar Whatsapp"
        body={modalBody}
        show={centerModalShow}
        onHide={handleModalClose}
        closeButton
      />
      <Row>
        <Col>
          <Field name="user[branchOfficeIds]">
            {({ field }) => (
              <FormikSelect
                {...field}
                isMulti
                label="Sucursales"
                placeholder="Seleccionar Sucursales"
                options={branchOffices}
                defaultValue={user.branchOfficeIds}
                defaultMultiValues={user.branchOffices}
                onChange={data => handleSelectorIsMulti(data || [], field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                setFieldTouched={() => setFieldTouched(field.name)}
              />
            )}
          </Field>
        </Col>
        <Col className="checkbox-align-center">
          <Field name="user[active]">
            {({ field }) => <FormikCheckBox {...field} field={field} label="Activo" custom />}
          </Field>
        </Col>
      </Row>

      {action === 'new' && (
        <Row>
          <Col>
            <Field name="user[password]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Contraseña"
                  inputType="password"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col>
            <Field name="user[passwordConfirmation]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Confirmar Contraseña"
                  inputType="password"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={6} className="mb-3">
          <Field name="user[avatar]">
            {({ field }) => (
              <UploadImage
                {...field}
                label="Foto de Perfil"
                name={user.avatarInfo?.filename}
                imageUrl={getIn(user.avatarInfo, 'fileUrl')}
                onChange={_avatar => setFieldValue(field.name, _avatar)}
                helpText="Formato sugerido 620x400px de máximo 10mb."
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center mt-2'>
          <h3 className='p-3 border border-warning' style={{backgroundColor: '#ffdea3'}}>
            Si creas más usuarios que los que tienes contratados, se te cobrará 0,4 UF + IVA por cada usuario extra.
          </h3>
      </Row>
      <Row className="d-flex justify-content-end mb-4">
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const {
    id,
    active,
    avatarInfo,
    branchOfficeIds,
    email,
    name,
    lastName,
    password,
    passwordConfirmation,
    roleIds,
    whatsappPhoneNumber
  } = props.user;

  return {
    user: {
      id,
      active,
      avatar: getIn(avatarInfo, 'fileSignature'),
      branchOfficeIds,
      email,
      name,
      lastName,
      password,
      passwordConfirmation,
      roleIds,
      whatsappPhoneNumber
    }
  };
};

const validationSchema = () => {
  return Yup.object().shape({
    user: Yup.object().shape({
      avatar: Yup.mixed(),
      email: Yup.string()
        .email('El email que ingresaste no es válido')
        .required('Debes ingresar un email'),
      name: Yup.string().nullable(),
      lastName: Yup.string().nullable(),
      roleIds: Yup.string().required('Debes seleccionar al menos un rol'),
      whatsappPhoneNumber: Yup.string()
          .nullable()
    })
  });
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(UserForm);
