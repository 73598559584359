import React from 'react';
import { Form } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

const InputNumber = ({disabled, decimalScale, value, onChange}) => {
  const handleFormatting = (e) => {
    const formattedValue = e.target.value.replace(/\./g, '');
    const numberValue = parseFloat(formattedValue.replace(',', '.'));
    onChange(numberValue || null);
  };
  return (
    <NumberFormat
      allowEmptyFormatting
      className="form-control"
      disabled={disabled}
      allowNegative={false}
      customInput={Form.Control}
      decimalScale={decimalScale}
      decimalSeparator=","
      thousandSeparator="."
      value={parseFloat(value)}
      onChange={handleFormatting}
    />
  );
}
export default InputNumber;