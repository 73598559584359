import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useField } from 'formik';
import './styles.scss';
import InputTime from './InputTime';
import * as validations from '../../../utils/validations';

const TimeInputField = ({ required, disabled, helpText, label, margin, ...props }) => {
  const validate = required && validations.required();
  const [field, meta] = useField({ validate, ...props });
  const { error, touched } = meta;

  const handleChange = value => {
    field.onChange({
      target: {
        name: props.name,
        value
      }
    });
  };
  return (
    <Form.Group className={`${disabled ? 'disabled' : ''} ${margin} ${error && touched ? 'is-invalid' : ''}`}>
      {label && (
        <Form.Label>
          {label} {required && <abbr className="text-danger ml-1">*</abbr>}
        </Form.Label>
      )}
      <InputGroup>
        <InputTime
          {...field}
          disabled={disabled}
          onChange={handleChange}
        />
      </InputGroup>
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};
export default TimeInputField;
