import React from 'react';
import { Row, Col } from 'react-bootstrap';

const ProjectVehicleDetails = ({ vehicle, rememberMaintenanceFormated }) => {
  const {
    fuelTypeName,
    engineCapacity,
    plate,
    year,
    lastMaintenance,
    technicalReviewExpiration,
    brand,
    model,
    vin
  } = vehicle;

  return (
    <Row className="pt-2">
      <Col md="6">
        <p>
          <span className="font-weight-bold data-title">PATENTE/CÓDIGO:</span> {plate}
        </p>
        <p>
          <span className="font-weight-bold data-title">MARCA:</span> {brand?.name}
        </p>
        <p>
          <span className="font-weight-bold data-title">MODELO:</span> {model?.name}
        </p>
        <p>
          <span className="font-weight-bold data-title">TIPO DE COMBUSTIBLE:</span> {fuelTypeName}
        </p>
        <p>
          <span className="font-weight-bold data-title">TAMAÑO MOTOR:</span>
          {engineCapacity && `${engineCapacity} [CC]`}
        </p>
      </Col>
      <Col md="6">
        <p>
          <span className="font-weight-bold data-title">AÑO:</span> {year}
        </p>
        <p>
          <span className="font-weight-bold data-title">VIN:</span> {vin}
        </p>
        <p>
          <span className="font-weight-bold data-title">ÚLTIMA MANTENCIÓN:</span> {lastMaintenance}
        </p>
        <p>
          <span className="font-weight-bold data-title">VENC. MANTENCIÓN:</span> {technicalReviewExpiration}
        </p>
        <p>
          <span className="font-weight-bold data-title">RECORDAR MANTENCIÓN:</span> {rememberMaintenanceFormated}
        </p>
      </Col>
    </Row>
  );
};

export default ProjectVehicleDetails;
