import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import snakeCaseKeys from 'snakecase-keys';
import { useHistory } from 'react-router-dom';

import { sendAlert } from '../../actions/utils';
import { BasicTextArea, FormikCheckBox, FormikInput } from '../../components';
import SelectInputField from '../../components/Utils/Input/SelectInputField';
import NumberInputField from '../../components/Utils/Input/NumberInputField';
import { updateAdvancedConfigurationRequest } from '../../requests/projects';
import useCountryFactory from '../../hooks/useCountryFactory';
import { documentTypeOptions } from '../Project/Form/FormOptions';
import { useListProviders } from '../../hooks/useProviders';

const ProjectDetailAdvancedConfiguration = ({ onHide, originalValues, setOriginalValues, ...props }) => {
  const { inputName, handleEditConfirmation, restoreOriginalValues, vProjectDetail } = props;
  
  const { errors, setFieldValue, touched, values } = useFormikContext();
  const {
    documentType,
    movementDetails,
    quantity,
    product: vProduct,
    productId: vProductId,
    requestPurchase: vRequestPurchase
  } = vProjectDetail;
  const [allDisabled, setAllDisabled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(!vRequestPurchase);
  const [selectedProvider, setSelectedProvider] = useState(() => {
    if (vProduct?.providerId && vProduct?.providerName) {
      return { value: vProduct.providerId, label: vProduct.providerName };
    }
    return null;
  });
  const { hasOnePurchase, purchaseMade, requestPurchase } = originalValues;
  const project = getIn(values, 'project');
  const projectDetail = getIn(values, inputName);
  const countryFactory = useCountryFactory();
  const dispatch = useDispatch();
  const history = useHistory();

  const { providers, isLoading } = useListProviders();

  const providerOptions = useMemo(() => 
    providers?.map(provider => ({
      value: provider.id,
      label: provider.legalName
    })) || []
  , [providers]);

  useEffect(() => {
    if (providers?.length > 0 && vProduct?.providerId && !selectedProvider) {
      const initialProvider = providers.find(p => p.id === vProduct.providerId);
      if (initialProvider) {
        setSelectedProvider({
          value: initialProvider.id,
          label: initialProvider.legalName
        });
      }
    }
  }, [providers, vProduct?.providerId]);

  const handleChangeCost = value => {
    setFieldValue(`${inputName}[cost]`, value);
    setFieldValue(`${inputName}[totalCost]`, value * quantity);
  };

  const handleInitialValues = () => {
    if (hasOnePurchase && purchaseMade && requestPurchase) setAllDisabled(true);
  };

  useEffect(handleInitialValues, []);

  const handlePurchaseCheckbox = () => {
    setIsDisabled(!vRequestPurchase);
    if (!vRequestPurchase) setFieldValue(`${inputName}[purchaseMade]`, false);
  };

  useEffect(handlePurchaseCheckbox, [vRequestPurchase]);  

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessUpdate = () => {
    dispatch(sendAlert({ kind: 'success', message: 'Configuración actualizada con éxito' }));
    setOriginalValues(vProjectDetail);
    onHide();
  };

  const handleUpdateRequest = () => {
    updateAdvancedConfigurationRequest(project.id, {
      dispatch,
      params: snakeCaseKeys(values),
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const handleCheckRemoveIva = e => {
    let newValue;
    setFieldValue(e.target.name, e.target.checked);
    if (projectDetail.cost !== '' && projectDetail.cost !== 0) {
      if (e.target.checked) {
        newValue = projectDetail.cost / 1.19;
      } else {
        newValue = projectDetail.cost * 1.19;
      }
      setFieldValue(`${inputName}[cost]`, newValue);
    }
  };

  return (
    <Row>
      <Col>
        <FormikInput disabled label="Nombre / Código" value={vProduct?.label || vProjectDetail?.productNameCustom} />
      </Col>
      {!vProductId && (
        <Col md={6}>
          <NumberInputField
            name={`${inputName}[cost]`}
            leftAddon={countryFactory.getSymbol()}
            label="Costo Unitario"
            decimalScale={2}
            onChange={handleChangeCost}
          />
          <Col className="project-config-btn" style={{ left: '120px', top: '0' }}>
            <Field name={`${inputName}[costWithTax]`}>
              {({ field }) => (
                <FormikCheckBox {...field} field={field} label="Quitar IVA" custom onChange={handleCheckRemoveIva} />
              )}
            </Field>
          </Col>
        </Col>
      )}
      {vProductId && !vProduct?.inventoryable && (
        <>
          <Col md={6}>
            <FormikInput disabled label="Nombre Venta" value={vProduct?.saleName} />
          </Col>
          <Col md={4}>
            <NumberInputField
              name={`${inputName}[cost]`}
              leftAddon={countryFactory.getSymbol()}
              label="Costo Unitario"
              decimalScale={2}
              onChange={handleChangeCost}
            />
          </Col>
          <Col md={4}>
            <NumberInputField
              name={`${inputName}[costQuotation]`}
              leftAddon={countryFactory.getSymbol()}
              label="Costo Cotización"
              decimalScale={2}
              disabled
              value={vProduct?.costQuotation || 0}
            />
          </Col>
          <Col md={4}>
            <NumberInputField
              name={`${inputName}[totalCost]`}
              leftAddon={countryFactory.getSymbol()}
              label="Costo Total"
              decimalScale={2}
              disabled
            />
          </Col>
          <Col md={6}>
            <SelectInputField
              name={`${inputName}[documentType]`}
              label="Tipo de Documento"
              placeholder="Seleccionar..."
              options={documentTypeOptions}
              value={documentType}
            />
          </Col>
          <Col md={6}>
            <Field name={`${inputName}[folio]`}>
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Folio"
                  placeholder="Ingrese folio"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </>
      )}
      <Col md={12}>
        <Field name={`${inputName}[notes]`}>
          {({ field }) => (
            <BasicTextArea
              {...field}
              row="3"
              label="Notas"
              customClass="two-lines"
              placeholder="Agregar nota"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </Col>
      <Col md={12} className="mt-2">
        <Row>
          <Col md={6}>
            <Field name={`${inputName}[requestPurchase]`}>
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  field={field}
                  label="Solicitar compra"
                  customClass="pl-2"
                  disabled={allDisabled || hasOnePurchase}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name={`${inputName}[purchaseMade]`}>
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  field={field}
                  label="Compra realizada"
                  customClass="pl-2"
                  disabled={allDisabled || isDisabled || (hasOnePurchase && purchaseMade)}
                  onChange={e => {
                    field.onChange(e);
                    if (e.target.checked) {
                      setFieldValue(`${inputName}[purchaseDate]`, new Date().toLocaleDateString('es-ES'));
                    } else {
                      setFieldValue(`${inputName}[purchaseDate]`, '');
                    }
                  }}
                />
              )}
            </Field>
          </Col>
        </Row>
      </Col>
      {movementDetails.length > 0 && (
        <Col md={12}>
          <h4 className="text-uppercase text-center my-3">Historial</h4>
          <Table responsive borderless hover>
            <thead className="border-top-borderless-table">
              <tr className="header-border-bottom">
                <th>Fecha de Movimiento</th>
                <th>Tipo</th>
                <th>Cantidad</th>
                <th>Valor Unitario</th>
              </tr>
            </thead>
            <tbody>
              {movementDetails.map((movementDetail, index) => (
                <tr key={`movement-detail-${index.toString()}`}>
                  <td>{movementDetail?.createdAt}</td>
                  <td>{movementDetail?.translatedMovementType}</td>
                  <td>{movementDetail?.quantity || 0}</td>
                  <td>{movementDetail?.parsedSalePrice}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      )}
      <Col md={12}>
        <Col md={14}>
          <div className="mb-1">
            <strong>Proveedor</strong>
          </div>

          {isLoading ? (
            <div>Loading Providers...</div>
          ) : (
            <>
              <SelectInputField
                name={`${inputName}[providerName]`}
                placeholder="Seleccionar Proveedor"
                options={providerOptions}
                value={selectedProvider}
              />

              <Button
                block
                variant="secondary"
                onClick={() => history.push('/providers/new')}
                className="mt-2"
              >
                Crear Proveedor
              </Button>
            </>
          )}
        </Col>
      </Col>
      <Col md={12} className="mt-3">
        <Row>
          <Col md={4}>
            <Button block variant="outline-dark" onClick={restoreOriginalValues}>
              Cancelar
            </Button>
          </Col>
          {vProductId && (
            <Col md={4}>
              <Button block variant="secondary" onClick={handleEditConfirmation}>
                Editar ítem
              </Button>
            </Col>
          )}
          {(!allDisabled || !vProductId) && (
            <Col md={!vProductId ? { span: 4, offset: 4 } : 4}>
              <Button block variant="primary" onClick={handleUpdateRequest}>
                Guardar
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default ProjectDetailAdvancedConfiguration;