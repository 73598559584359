import React, { useState } from 'react';
import { Col, Row, Button, Table } from 'react-bootstrap';
import { Trash, CheckCircle, Image as FeatherImage } from 'react-feather';
import { withFormik, Form, Field, FieldArray, getIn, useFormikContext } from 'formik';
import { FormikInput } from '../../../components';
import ImageGallery from 'react-image-gallery';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { SimpleCenteredModal } from '../../../components/Utils/Modal';
import { uuid } from '../../../utils/utils';
import getImage from '../../../utils/getImage';
import AutopartSelect from '../../../components/SharedFields/AutopartSelect';

const ProjectAutopartsListEdit = ({ project, setMoreData }) => {
  const {errors, setFieldValue, touched} = useFormikContext();
  const { projectAutopartsAttributes } = project;
  const modelKey = 'project[projectAutopartsAttributes]';
  const [modalBody, setModalBody] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const handleModalClose = () => {
    setModalTitle('');
    setModalBody('');
    setModalShow(false);
  };

  const handleImgClick = (images, name) => {    
    setModalBody(<ImageGallery items={images} showPlayButton={false} showFullscreenButton={false} lazyLoad />);
    setModalShow(true);
    setModalTitle(`IMÁGENES DE ${name.toUpperCase()}`);
  };

  const handleAddAutopartClick = ({ autopart = null }) => {
    const newValue = projectAutopartsAttributes.filter(x => {return x.categoryId === autopart.value && !x._destroy});
    if (newValue.length === 0) {
      projectAutopartsAttributes.push({ name: autopart.label, categoryId: autopart.value, parentCategoryId: autopart.parentId, quantity: 1, purchasedQuantity: 0, receivedQuantity: 0, imagesInfo: [], key: uuid(), new: true });
      setFieldValue(modelKey, projectAutopartsAttributes);
    }
    setMoreData(m => !m);
  };

  const resetForm = () => {
    projectAutopartsAttributes.forEach(ap => {
      if (ap.new) {
        ap._destroy = true;
      } else {
        ap._destroy = false;
      }      
    });
    setFieldValue(modelKey, projectAutopartsAttributes);
  }

  const handleRemoveBtn = (autopart) => {
    autopart._destroy = true;
    setFieldValue(modelKey, projectAutopartsAttributes);
  };

  const imageIcon = (imagesInfo, name) => {
    const images = imagesInfo.map(image => {
      return {
        original: getImage(image.key, 800, 800, 'inside'),
        thumbnail: getImage(image.key, 100, 100, 'inside')
      };
    });
    if (images.length) {
      return (
        <span style={{ color: '#00517B', cursor: 'pointer' }} className="font-weight-bold" onClick={() => handleImgClick(images, name)}>
          <FeatherImage className="icon-file-text float-right" strokeWidth="1.5" size={16} />
        </span>
      );
    }
    return undefined;
  };

  return (
    <Form>
      {projectAutopartsAttributes.length ? (
        <Table responsive size="sm" className="mt-2 mb-0">
          <thead>
            <tr>
              <th>Repuesto</th>
              <th>Notas</th>
              <th style={{ width: '3rem'}}>Cantidad</th>
              <th style={{ width: '3rem'}}>Cantidad comprada</th>
              <th style={{ width: '3rem'}}>Cantidad recibida</th>
              <th style={{ width: '1rem'}}></th>
            </tr>
          </thead>
          <tbody>
            <FieldArray name={modelKey}>
              {({ push }) => (
                <>
                  {projectAutopartsAttributes.map((autopart, index) => {
                    if (autopart._destroy) {
                      return undefined;
                    }
                    return (<tr key={`project-autopart-input-${index.toString()}`}>
                      <td>
                        {autopart.name}
                        {imageIcon(autopart.imagesInfo, autopart.name)}
                      </td>
                      <td>
                        <Field name={`${modelKey}[${index}][notes]`}>
                          {({field}) => (
                            <FormikInput
                              {...field}
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                            />
                          )}
                        </Field>
                      </td>
                      <td>
                        <Field name={`${modelKey}[${index}][quantity]`}>
                          {({field}) => (
                            <FormikInput
                              {...field}
                              inputType="number"
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                            />
                          )}
                        </Field>
                      </td>
                      <td>
                        <Field name={`${modelKey}[${index}][purchasedQuantity]`}>
                          {({field}) => (
                            <FormikInput
                              {...field}
                              inputType="number"
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                            />
                          )}
                        </Field>
                      </td>
                      <td>
                        <Field name={`${modelKey}[${index}][receivedQuantity]`}>
                          {({field}) => (
                            <FormikInput
                              {...field}
                              inputType="number"
                              error={getIn(errors, field.name)}
                              touched={getIn(touched, field.name)}
                            />
                          )}
                        </Field>
                      </td>
                      <td>
                        {autopart.purchasedQuantity === autopart.receivedQuantity &&
                          autopart.purchasedQuantity > 0 &&
                          <CheckCircle
                            color="green"
                            size={16}
                          />
                        }
                        {(autopart.purchasedQuantity !== autopart.receivedQuantity ||
                          autopart.purchasedQuantity === 0) &&
                          <Trash
                            color="red"
                            style={{float: 'right'}}
                            className="clickable"
                            size={16}
                            onClick={() => handleRemoveBtn(autopart, index)}
                          />
                        }
                      </td>
                    </tr>);
                  })}
                </>
              )}
            </FieldArray>
          </tbody>
        </Table>) : undefined
      }
      <Row className='mb-2' style={{ borderBottom: 'solid', borderWidth: '1px' }}>
        <Col md={3}>
          <strong className='float-right' style={{ color: '#00517B' }}>AGREGAR:</strong>
        </Col>
        <Col md={8} className="d-flex">
          <AutopartSelect
            onAutopartChange={data => {
              handleAddAutopartClick({ autopart: camelCaseRecursive(data) });
            }}
          />      
        </Col>
      </Row>
      <Button className="px-5 float-left" variant="outline-danger" onClick={() => resetForm()}>
        Deshacer
      </Button>
      <Button className="px-5 float-right" type="submit">
        Guardar
      </Button>
      <SimpleCenteredModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        onHide={handleModalClose}
        closeButton
      />
    </Form>
  );
};

const setInitialValues = ({ project }) => {
  return { project };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  enableReinitialize: true,
  validateOnChange: false
})(ProjectAutopartsListEdit);

